import React from "react";
import { SelectedProject } from "../Projects";
import Grid from "@mui/material/Grid2";
import ProjectProducts from "./ProjectProducts";
import ProjectFiles from "./ProjectFiles";
import ProjectPlan from "./ProjectPlan";
import { Fade } from "@mui/material";
import Notes from "./Notes";
export const FocusContext = React.createContext({
    focused: {
        products: false,
        files: false,
        plan: false,
    },
    setFocused: (focus) => { },
});
const ProjectView = () => {
    const project = React.useContext(SelectedProject);
    const [focused, setFocused] = React.useState({
        products: false,
        files: false,
        plan: false,
    });
    const handleFocus = (focus) => {
        setFocused((prev) => ({
            ...prev,
            [focus]: !prev[focus],
        }));
    };
    // Determine if any component is focused
    const isAnyFocused = Object.values(focused).some((value) => value);
    return (React.createElement(FocusContext.Provider, { value: { focused, setFocused: handleFocus } },
        React.createElement(Notes, null),
        React.createElement(Grid, { container: true, spacing: 1, sx: { maxHeight: "800px" } },
            React.createElement(Fade, { in: !isAnyFocused || focused.products },
                React.createElement(Grid, { size: focused.products ? 12 : 4, sx: {
                        transition: "all 0.5s",
                        transitionDelay: "0.5s",
                        height: focused.products ? 800 : isAnyFocused ? 0 : "fit-content",
                    } },
                    React.createElement(ProjectProducts, null))),
            React.createElement(Fade, { in: !isAnyFocused || focused.files },
                React.createElement(Grid, { size: focused.files ? 12 : 4, sx: {
                        transition: "all 0.5s",
                        transitionDelay: "0.5s",
                        height: focused.files ? 800 : isAnyFocused ? 0 : "fit-content",
                    } },
                    React.createElement(ProjectFiles, null))),
            React.createElement(Fade, { in: !isAnyFocused || focused.plan },
                React.createElement(Grid, { size: focused.plan ? 12 : 4, sx: {
                        transition: "all 0.5s",
                        transitionDelay: "0.5s",
                        height: focused.plan ? 800 : isAnyFocused ? 0 : "fit-content",
                    } },
                    React.createElement(ProjectPlan, null))))));
};
export default ProjectView;
const views = {
    products: ProjectProducts,
    files: ProjectFiles,
    plan: ProjectPlan,
};
