import { Button, Box, Paper, Dialog, Stack, styled } from "@mui/material";
import React from "react";
import ImageCrop from "src/components/ImageCrop";
import { selector, useComponentCreateStore, } from "../store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
import { CameraAlt, Upload } from "@mui/icons-material";
import { DisabledContext, NodeContext } from "../ComponentNode";
const cloudfrontUrl = "https://cdn.manufacture.app/";
const Image = () => {
    const { getNode, onNodeChange } = useComponentCreateStore(useShallow(selector));
    const { id, data } = React.useContext(NodeContext);
    const [imgSrc, setImgSrc] = React.useState(undefined);
    const onConfirmBlob = (blob) => {
        const url = URL.createObjectURL(blob);
        setImgSrc(url);
        // onNodeChange(id, { field: "image", value: url });
    };
    const onConfirm = React.useCallback((file) => {
        onNodeChange(id, { field: "image", value: file });
    }, [id]);
    return (React.createElement(Box, { sx: { mt: 1, display: "flex", justifyContent: "center" } },
        React.createElement(ImageCropDialog, { key: id, onConfirmBlob: onConfirmBlob, onConfirm: onConfirm, cropSrc: imgSrc ? imgSrc : data.image ? `${data.image}` : undefined })));
};
export default Image;
const ImageCropDialog = ({ onConfirm, onConfirmBlob, id, circle, cropSrc, }) => {
    const [open, setOpen] = React.useState(false);
    const [imgSrc, setImgSrc] = React.useState(undefined);
    const [completedCrop, setCompletedCrop] = React.useState(null);
    const imgRef = React.useRef(null);
    const [originalFile, setOriginalFile] = React.useState(null);
    const handleFileSelect = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setOriginalFile(file);
            const reader = new FileReader();
            reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
            reader.readAsDataURL(file);
            setOpen(true);
        }
    };
    const handleClose = () => {
        setImgSrc(undefined);
        setOpen(false);
    };
    const handleUploadAnother = () => setImgSrc(undefined);
    const handleConfirm = async () => {
        if (!completedCrop || !imgRef.current || !originalFile) {
            return;
        }
        const canvas = document.createElement("canvas");
        const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
        const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
        canvas.width = completedCrop.width * scaleX;
        canvas.height = completedCrop.height * scaleY;
        const ctx = canvas.getContext("2d");
        if (ctx) {
            ctx.drawImage(imgRef.current, completedCrop.x * scaleX, completedCrop.y * scaleY, completedCrop.width * scaleX, completedCrop.height * scaleY, 0, 0, completedCrop.width * scaleX, completedCrop.height * scaleY);
        }
        canvas.toBlob((blob) => {
            if (blob) {
                const file = new File([blob], originalFile.name, {
                    type: originalFile.type,
                });
                onConfirm && onConfirm(file, id);
                onConfirmBlob && onConfirmBlob(blob);
                setOpen(false);
            }
        }, originalFile.type);
    };
    const disabled = React.useContext(DisabledContext);
    return (React.createElement(React.Fragment, null,
        cropSrc ? (React.createElement(Box, { sx: {
                cursor: disabled ? "default" : "pointer",
                mb: -1,
                cursorEvents: disabled ? "none" : "auto",
            }, component: "label" // Turn the Box into a label
            , htmlFor: `contained-button-file-${id}`, onClick: (e) => {
                if (disabled) {
                    e.preventDefault(); // Prevent the default behavior if disabled is true
                }
            } },
            React.createElement("img", { src: cropSrc, alt: id, style: {
                    width: "100%",
                    aspectRatio: "1",
                    objectFit: "cover",
                    borderRadius: "4px",
                } }),
            React.createElement(VisuallyHiddenInput, { id: `contained-button-file-${id}`, type: "file", accept: "image/*", onChange: handleFileSelect }))) : (React.createElement(Button, { component: "label", role: undefined, variant: "contained", disabled: disabled, tabIndex: -1, startIcon: React.createElement(CameraAlt, null) },
            "Upload Image",
            React.createElement(VisuallyHiddenInput, { type: "file", accept: "image/*", onChange: handleFileSelect }))),
        React.createElement(Dialog, { open: open, onClose: handleClose, maxWidth: "sm", sx: {
                background: "transparent",
                "& .MuiPaper-root": {
                    background: "transparent",
                    boxShadow: "none",
                },
                // "& .MuiBackdrop-root": {
                //   backgroundColor: "transparent",
                // },
            } }, imgSrc && (React.createElement(Paper, { sx: { backgroundColor: "white", padding: 2 } },
            React.createElement(ImageCrop, { imgSrc: imgSrc, onComplete: (crop) => setCompletedCrop(crop), imgRef: imgRef, circle: circle }),
            React.createElement(Stack, { spacing: 2, direction: "row", justifyContent: "center", sx: { mt: 1 } },
                React.createElement(Button, { onClick: handleClose, variant: "outlined", sx: { backgroundColor: "default" } }, "Cancel"),
                React.createElement("label", { htmlFor: "contained-button-file" },
                    React.createElement(Button, { component: "span", onClick: handleUploadAnother, variant: "contained", endIcon: React.createElement(Upload, null) }, "Upload Another")),
                React.createElement(Button, { onClick: handleConfirm, variant: "contained" }, "Confirm")))))));
};
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
