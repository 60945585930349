import api from "./api";
export const getFinancials = async () => {
    const response = await api.get(`/api/financial`);
    return response.data;
};
export const createFinancial = async (financial) => {
    const response = await api.post("/api/financial", financial);
    return response.data;
};
export const updateFinancial = async (financial) => {
    let id = financial.get("_id");
    const response = await api.patch(`/api/financial/${id}`, financial);
    return response.data;
};
// export const getProjectFinancials = async (user, projectId) => {
//   const response = await financialApi.get("/financial", {
//     params: { user: user, project: projectId },
//   });
//   return response.data;
// };
// export const updateFinancial = async ({ id, pack }) => {
//   return await financialApi.patch(`/api/financial/${id}`, pack);
// };
export const deleteFinancial = async (id) => {
    const response = await api.delete(`/api/financial/${id}`);
    return response.data;
};
// export const createFinancial = async (pack) => {
//   //   for (const value of pack.values()) {
//   //   }
//   try {
//     return await financialApi.post("/api/financial", pack, { timeout: 5000 });
//   } catch (err) {
//     console.log("Post to financials failed!");
//   }
// };
