import React from "react";
import CustomSelect from "src/components/Select";
import useProductsStore from "../productsStore";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
const Option = ({ productId, option }) => {
    const { components, onOptionSelect, products } = useProductsStore();
    const { data: settings } = useSettingsData();
    // Get category colors from settings
    const categoryColors = React.useMemo(() => settings?.categories.reduce((acc, category) => {
        acc[category.name] = category.color;
        return acc;
    }, {}) || {}, [settings]);
    const handleOptionSelect = (choice) => {
        let product = products.find((p) => p.id === productId);
        if (!product)
            return;
        for (let i = 0; i < product.subcomponents.length; i++) {
            if (product.subcomponents[i].name === option.name) {
                let choiceEditedForThisOption = `${product.subcomponents[i].id}_${choice
                    .split("_")
                    .at(-1)}`;
                onOptionSelect(productId, product.subcomponents[i].id, choiceEditedForThisOption);
            }
        }
    };
    const value = option.selection
        ? components?.find((comp) => comp.id === option.selection)?.id
        : "";
    // Get option choices
    const optionChoices = option.subcomponents
        .map((c) => components?.find((comp) => comp.id === c.id))
        .filter(Boolean);
    // Extract categories from options
    const getOptionCategories = (option) => option.categories || [];
    return (React.createElement(CustomSelect, { options: optionChoices, value: value, renderValue: (value) => components?.find((comp) => comp.id === value)?.name || "", onChange: (evt) => handleOptionSelect(evt.target.value), size: "small", sx: { marginY: 0.5 }, 
        // New props
        getOptionCategories: getOptionCategories, categoryColors: categoryColors }));
};
export default Option;
