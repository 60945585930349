import { Avatar, Box, Collapse, IconButton, ListItemAvatar, ListItemText, Menu, MenuItem, Paper, Typography, } from "@mui/material";
import React from "react";
import { PersonAdd } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import { SelectedProject } from "../../Projects";
import { useProjectUpdate } from "../../hooks/useProjectUpdate";
import { usePeopleData } from "@features/people/hooks/usePeopleData";
export const People = () => {
    const project = React.useContext(SelectedProject);
    const { data: people } = usePeopleData();
    const { mutate: updateProject, isSuccess } = useProjectUpdate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    React.useEffect(() => {
        if (isSuccess) {
            console.log("success");
        }
    }, [isSuccess]);
    if (!project)
        return null;
    if (!people)
        return null;
    const handleSelect = (person) => {
        project.people.find((p) => p == person._id)
            ? updateProject({
                people: project.people.filter((p) => p != person._id),
                _id: project._id,
            })
            : updateProject({
                people: [...project.people, person._id],
                _id: project._id,
            });
    };
    const projectPeople = project.people
        .map((p) => people.find((person) => person._id == p))
        .filter((p) => p);
    return (React.createElement(Paper, { sx: { p: 1, mb: 1 } },
        React.createElement(Box, { sx: { display: "flex", justifyContent: "space-between", gap: 1 } },
            React.createElement(Typography, { variant: "h6" }, "People"),
            React.createElement(IconButton, { onClick: (e) => {
                    setAnchorEl(e.currentTarget);
                } },
                React.createElement(PersonAdd, null)),
            React.createElement(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: () => setAnchorEl(null) }, people.length ? (people.map((person) => (React.createElement(MenuItem, { key: person._id, sx: {
                    backgroundColor: isSelected(project.people, person)
                        ? "primary.main"
                        : "default",
                    ":hover": {
                        backgroundColor: isSelected(project.people, person)
                            ? "primary.light"
                            : "default",
                    },
                    color: isSelected(project.people, person)
                        ? "primary.contrastText"
                        : "default",
                }, onClick: () => {
                    handleSelect(person);
                } },
                React.createElement(ListItemAvatar, null,
                    React.createElement(Avatar, { alt: person.firstName, src: person.avatar, sx: { width: 32, height: 32 } })),
                React.createElement(ListItemText, { primary: `${person.firstName} ${person.lastName}` }))))) : (React.createElement(MenuItem, { disabled: true }, "No people created.")))),
        React.createElement(TransitionGroup, null,
            React.createElement(Box, { sx: { display: "flex", flexDirection: "column", gap: 1 } }, projectPeople.map((person) => (React.createElement(Collapse, { key: person._id, in: true },
                React.createElement(Paper, { key: person._id, sx: { p: 1, display: "flex", alignItems: "center", gap: 1 }, elevation: 2 },
                    React.createElement(Avatar, { alt: person.firstName, src: person.avatar, sx: { width: 32, height: 32 } }),
                    person.firstName,
                    " ",
                    person.lastName))))))));
};
const isSelected = (people, person) => {
    return people.find((p) => p == person._id);
};
