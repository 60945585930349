import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import { AdminWithSocketProvider } from "./pages/Admin";
import Buy from "./pages/Buy";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Welcome from "./pages/Welcome";
export default function Views() {
    return (React.createElement(Routes, null,
        React.createElement(Route, { path: "/", element: React.createElement(Welcome, null) }),
        React.createElement(Route, { path: "/login", element: React.createElement(Login, null) }),
        React.createElement(Route, { path: "/register", element: React.createElement(Register, null) }),
        React.createElement(Route, { element: React.createElement(ProtectedRoute, null) },
            React.createElement(Route, { path: "/buy", element: React.createElement(Buy, null) })),
        React.createElement(Route, { element: React.createElement(ProtectedRoute, { role: "admin" }) },
            React.createElement(Route, { path: "/admin", element: React.createElement(AdminWithSocketProvider, null) })),
        React.createElement(Route, { path: "*", element: React.createElement(Welcome, null) })));
}
