import { Box, Button, Fade, Typography } from "@mui/material";
import React from "react";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Add, KeyboardArrowLeft } from "@mui/icons-material";
import { columns } from "./utils/fields";
import { useProjectsData } from "./hooks/useProjectsData";
import ProjectDialog from "./components/ProjectDialog";
import ProjectView from "./components/ProjectView";
import SelectedProjectHeader from "./components/SelectedProjectHeader";
import { useOrganizationData } from "src/hooks/useOrganizationData";
export const SelectedProject = React.createContext(null);
const ProjectsTab = () => {
    const { data: projects, isLoading } = useProjectsData();
    useOrganizationData();
    const [open, setOpen] = React.useState(false);
    const [selectedProject, setSelectedProject] = React.useState(null);
    const onCancel = () => {
        setOpen(false);
    };
    React.useEffect(() => {
        if (!selectedProject)
            return;
        if (!projects)
            return;
        let project = projects.find((p) => p._id === selectedProject._id);
        if (selectedProject._id && project) {
            setSelectedProject(project);
        }
        else if (selectedProject._id && !project) {
            setSelectedProject(null);
        }
        else {
            return;
        }
    }, [projects]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Fade, { in: !!selectedProject, timeout: { enter: 500, exit: 0 }, unmountOnExit: true },
            React.createElement(Box, null,
                React.createElement(SelectedProject.Provider, { value: selectedProject },
                    React.createElement(Box, { sx: { display: "flex", gap: 4, alignItems: "center", mb: 2 } },
                        React.createElement(Button, { onClick: () => setSelectedProject(null), startIcon: React.createElement(KeyboardArrowLeft, null) }, "Back"),
                        React.createElement(Button, { variant: "contained", onClick: () => setOpen(true) }, "Edit"),
                        React.createElement(SelectedProjectHeader, null)),
                    React.createElement(ProjectView, null)))),
        React.createElement(Fade, { in: !selectedProject, timeout: { enter: 500, exit: 0 }, unmountOnExit: true },
            React.createElement("div", null,
                React.createElement(DataGrid, { autoHeight: true, loading: isLoading, columns: columns, rows: projects || [], getRowId: (row) => row._id, onRowClick: (row) => setSelectedProject(row.row), disableRowSelectionOnClick: true, hideFooter: true, slots: {
                        toolbar: () => (React.createElement(Box, { sx: {
                                display: "flex",
                                justifyContent: "space-between",
                                p: 1,
                                pb: 0,
                            } },
                            React.createElement(Box, { sx: { gap: 1 } },
                                React.createElement(GridToolbarQuickFilter, { variant: "outlined", size: "small" })),
                            React.createElement(Box, { sx: { gap: 1 } },
                                React.createElement(Button, { variant: "contained", sx: { textTransform: "none" }, startIcon: React.createElement(Add, null), onClick: () => setOpen(true) },
                                    React.createElement(Typography, null, "Project"))))),
                        noRowsOverlay: () => (React.createElement(Box, { sx: { pt: 4.5 } },
                            React.createElement(Typography, { color: "textSecondary", textAlign: "center" }, "No projects to show."))),
                    } }))),
        React.createElement(ProjectDialog, { open: open, onClose: onCancel, projectData: selectedProject })));
};
export default ProjectsTab;
