import React from "react";
import { SelectedProject } from "../Projects";
import { Box, Button, IconButton, InputAdornment, ClickAwayListener, FormControl, OutlinedInput, } from "@mui/material";
import { Add, Check, Delete } from "@mui/icons-material";
import { useProjectUpdate } from "../hooks/useProjectUpdate";
const Notes = () => {
    const project = React.useContext(SelectedProject);
    const { mutate: updateProject } = useProjectUpdate();
    const [notes, setNotes] = React.useState(project?.notes || []);
    React.useEffect(() => {
        setNotes(project?.notes || []);
    }, [project?.notes]);
    const handleAddNote = () => {
        setNotes([...notes, ""]);
    };
    const handleEditNote = (index, newNote) => {
        const newNotes = [...notes];
        newNotes[index] = newNote;
        setNotes(newNotes);
        updateProject({ _id: project?._id, notes: newNotes });
    };
    const handleDeleteNote = (index) => {
        const newNotes = [...notes];
        newNotes.splice(index, 1);
        setNotes(newNotes);
        updateProject({ _id: project?._id, notes: newNotes });
    };
    const handleCancelNote = (index) => {
        const newNotes = [...notes];
        newNotes.splice(index, 1);
        setNotes(newNotes);
    };
    return (React.createElement(Box, { sx: { display: "flex", gap: 1, flexWrap: "wrap" } },
        notes.map((note, index) => (React.createElement(Note, { key: index, note: note, index: index, handleEditNote: handleEditNote, handleDeleteNote: handleDeleteNote, handleCancelNote: handleCancelNote, isNew: note === "" }))),
        React.createElement(Button, { variant: "outlined", startIcon: React.createElement(Add, null), color: "primary", sx: { mb: 1 }, onClick: handleAddNote }, "Add Note")));
};
export default Notes;
const Note = ({ note, index, handleEditNote, handleDeleteNote, handleCancelNote, isNew = false, }) => {
    const [editing, setEditing] = React.useState(isNew);
    const [value, setValue] = React.useState(note);
    const handleSave = () => {
        const trimmedValue = value.trim();
        if (trimmedValue === "") {
            handleDeleteNote(index);
        }
        else {
            handleEditNote(index, trimmedValue);
        }
        setEditing(false);
    };
    const handleCancel = () => {
        if (isNew) {
            handleCancelNote(index);
        }
        else {
            setValue(note);
            setEditing(false);
        }
    };
    const handleDelete = () => {
        if (isNew) {
            handleCancelNote(index);
        }
        else {
            handleDeleteNote(index);
        }
    };
    console.log(isNew);
    return (React.createElement(ClickAwayListener, { onClickAway: handleCancel },
        React.createElement(FormControl, { variant: "outlined", size: "small", sx: { mb: 1, maxWidth: 300, flex: 1 } },
            React.createElement(OutlinedInput, { multiline: true, maxRows: 4, minRows: 2, value: value, onFocus: () => setEditing(true), onChange: (e) => setValue(e.target.value), endAdornment: editing && (React.createElement(InputAdornment, { position: "end" },
                    React.createElement(IconButton, { onClick: handleSave, size: "small" },
                        React.createElement(Check, null)),
                    React.createElement(IconButton, { onClick: handleDelete, size: "small" },
                        React.createElement(Delete, null)))) }))));
};
