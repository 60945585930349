import { Box, Button, Paper, Typography, } from "@mui/material";
import React from "react";
import docusignPng from "../utils/docusign.png";
import { useSettingsData } from "../hooks/useSettingsData";
import { Check } from "@mui/icons-material";
import { connectDocuSign } from "@api/signApi";
const DocuSignIntegration = () => {
    const handleDocusign = async () => {
        const data = await connectDocuSign();
        console.log(data);
        window.location.href = data;
    };
    const { data: settings } = useSettingsData();
    const connected = settings?.docusignUserId ? true : false;
    //   const sub = checkSubscription(settings);
    return (React.createElement(Paper, { sx: {
            display: "flex",
            p: 1,
            overflow: "hidden",
            color: "black",
            justifyContent: "space-between",
            alignItems: "center",
            height: 100,
            backgroundColor: "#F8F3F0",
            boxShadow: 1,
        }, elevation: 0 },
        React.createElement("img", { src: docusignPng, alt: "docusign", style: {
                height: 60,
                margin: 12,
            } }),
        React.createElement(Box, { sx: { display: "flex" } },
            connected ? (React.createElement(Box, { sx: { display: "flex", alignItems: "center", mr: 8 } },
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "body2", sx: { mr: 1 } }, "You can now send documents for signature using DocuSign.")))) : (React.createElement(Box, { sx: { display: "flex", alignItems: "center", mr: 8 } },
                React.createElement(Typography, { variant: "body2", sx: { mr: 1 } }, "Connect your DocuSign account to send documents for signature."))),
            React.createElement(Button, { variant: "contained", onClick: () => handleDocusign(), endIcon: connected ? React.createElement(Check, null) : null, sx: {
                    mr: 3,
                    backgroundColor: connected ? "success.main" : "#4C00FF",
                    ":hover": {
                        backgroundColor: connected ? "success.main" : "#4C00FF",
                        filter: "brightness(1.1)",
                    },
                } }, connected ? "Connected" : "Connect DocuSign"))));
};
export default DocuSignIntegration;
