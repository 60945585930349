// variables.ts
// Example variable maps (DisplayName -> Key)
export const customerVariables = {
    "Customer First Name": "firstName",
    "Customer Last Name": "lastName",
    "Customer Email": "email",
    "Customer Phone": "phone",
    "Customer Address": "address",
};
export const projectVariables = {
    "Project Name": "name",
    "Project Creation Date": "creationDate",
    "Project Due Date": "dueDate",
    "Project Subtotal": "projectValue",
    "Project Discount": "discountValue",
    "Project Total": "total",
    "Project Paid": "paid",
    "Project Balance": "balance",
};
export const supplierVariables = {
    "Supplier Name": "name",
    "Supplier Address": "address",
    "Supplier Email": "email",
    "Supplier Phone": "phone",
    "Supplier Contact": "contact",
};
// Add more as needed
export const organizationVariables = {
    "Organization Name": "organizationName",
    "Organization Address": "organizationAddress",
    "Organization Email": "organizationEmail",
    "Organization Phone": "organizationPhone",
};
export const useProductVariables = (parameters, options) => {
    let productVariables = {
        "Product ID": "id",
        "Product Name": "name",
        "Product Image": "image",
        "Product Price": "price",
        "Product Quantity": "qty",
        "Product Total": "total",
        "Product Zone": "zone",
    };
    for (let i = 0; i < parameters.length; i++) {
        productVariables[`Product ${parameters[i]}`] = `PARAM${parameters[i]}`;
    }
    for (let i = 0; i < options.length; i++) {
        productVariables[`Product ${options[i]}`] = `OPTION${options[i]}`;
    }
    return productVariables;
};
export const useComponentVariables = (parameters) => {
    let componentVariables = {
        "Component Name": "name",
        "Component Cost": "cost",
        "Component Quantity": "qty",
        "Component Total": "total",
        "Component Supplier": "supplier",
        "Component Supplier Code": "supplierCode",
        "Component Supplier Cost": "supplierCost",
    };
    for (let i = 0; i < parameters.length; i++) {
        componentVariables[`Component ${parameters[i]}`] = `PARAM${parameters[i]}`;
    }
    return componentVariables;
};
export const templateVariables = {
    "Template Name": "name",
    "Template Date": "date",
    "Template Number": "number",
};
export const orderVariables = {
    "Order Total": "total",
};
