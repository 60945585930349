import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSupplier } from "@api/supplierApi";
import useNotificationStore from "@stores/notificationStore";
export function useSupplierUpdate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: updateSupplier,
        onSuccess: (updatedSupplier) => {
            queryClient.setQueryData(["suppliers"], (oldData) => {
                return oldData.map((supplier) => {
                    if (supplier._id === updatedSupplier._id) {
                        return updatedSupplier;
                    }
                    return supplier;
                });
            });
            addNotification("Supplier updated.", "success");
        },
        onError: () => {
            addNotification("Could not update supplier.", "error");
        },
    });
}
