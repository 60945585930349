import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography, } from "@mui/material";
import { useSuppliersData } from "../../supplier/hooks/useSuppliersData";
import { useOrderCreate } from "../hooks/useOrderCreate";
import CreateOrder from "./CreateOrder"; // Reuse CreateOrder for individual orders
import { Receipt } from "@mui/icons-material";
import CustomButton from "src/components/CustomButton";
import { useOrdersData } from "../hooks/useOrdersData";
import { deleteFiles } from "@api/fileApi";
const GenerateOrders = ({ selection }) => {
    const { data: suppliers } = useSuppliersData();
    const { data: ordersData } = useOrdersData();
    const { mutate: createOrder } = useOrderCreate();
    const [orders, setOrders] = useState({});
    const [open, setOpen] = useState(false);
    console.log(ordersData);
    // Initialize orders based on selection
    useEffect(() => {
        if (!selection || !suppliers || !ordersData)
            return;
        const initialOrders = {};
        selection.forEach((component) => {
            const componentSuppliers = component.suppliers.map((s) => s.supplier.toString());
            // If component has suppliers, assign to the first supplier
            if (componentSuppliers.length > 0) {
                const supplierId = componentSuppliers[0];
                let supplierExists = suppliers.find((s) => s._id === supplierId);
                if (!supplierExists) {
                    console.log("Supplier not found", supplierId);
                    return;
                }
                if (!initialOrders[supplierId]) {
                    const orderName = `${suppliers.find((s) => s._id === supplierId)?.name} Order ${ordersData.filter((order) => order.supplier?._id === supplierId)
                        .length + 1 || 1}`;
                    initialOrders[supplierId] = {
                        name: orderName,
                        supplier: supplierId,
                        items: [],
                        total: 0,
                        status: "",
                        financial: "",
                        project: "",
                        file: null,
                    };
                }
                const supplierData = component.suppliers.find((s) => s.supplier.toString() === supplierId);
                initialOrders[supplierId].items.push({
                    component: component._id,
                    quantity: component.quantity || 1,
                    cost: supplierData?.cost || 0,
                    supplierData: supplierData || {},
                });
                initialOrders[supplierId].total = initialOrders[supplierId].items.reduce((sum, item) => sum + item.quantity * item.cost, 0);
            }
            else {
                // Handle components without suppliers if needed
            }
        });
        setOrders(initialOrders);
    }, [selection, suppliers, ordersData]);
    // Handle changes in individual orders
    const handleOrderChange = (supplierId, updatedOrder) => {
        setOrders((prevOrders) => ({
            ...prevOrders,
            [supplierId]: updatedOrder,
        }));
    };
    // Move item to another supplier's order
    const moveItemToSupplier = (fromSupplierId, toSupplierId, itemToMove) => {
        setOrders((prevOrders) => {
            const updatedOrders = { ...prevOrders };
            // Remove item from current supplier
            const fromOrder = updatedOrders[fromSupplierId];
            fromOrder.items = fromOrder.items.filter((item) => item.component !== itemToMove.component);
            fromOrder.total = fromOrder.items.reduce((sum, item) => sum + item.quantity * item.cost, 0);
            // Add item to new supplier
            if (!updatedOrders[toSupplierId]) {
                updatedOrders[toSupplierId] = {
                    name: "",
                    supplier: toSupplierId,
                    items: [],
                    total: 0,
                    status: "",
                    financial: "",
                    project: "",
                    file: null,
                };
            }
            updatedOrders[toSupplierId].items.push(itemToMove);
            updatedOrders[toSupplierId].total = updatedOrders[toSupplierId].items.reduce((sum, item) => sum + item.quantity * item.cost, 0);
            // Remove empty orders
            if (fromOrder.items.length === 0) {
                delete updatedOrders[fromSupplierId];
            }
            return updatedOrders;
        });
    };
    // Remove item from order
    const removeItem = (supplierId, componentId) => {
        setOrders((prevOrders) => {
            const updatedOrders = { ...prevOrders };
            const order = updatedOrders[supplierId];
            order.items = order.items.filter((item) => item.component !== componentId);
            order.total = order.items.reduce((sum, item) => sum + item.quantity * item.cost, 0);
            // Remove empty orders
            if (order.items.length === 0) {
                delete updatedOrders[supplierId];
            }
            return updatedOrders;
        });
    };
    // Save all orders
    const handleSave = () => {
        Object.values(orders).forEach((order) => {
            if (!order.supplier || !order.items.length)
                return;
            // Prepare JSON body
            const itemsToSave = order.items.map(({ supplierData, ...rest }) => rest);
            const payload = {
                name: order.name,
                supplier: order.supplier,
                total: order.total,
                items: itemsToSave,
                file: order.file,
            };
            //
            createOrder(payload);
        });
        // Close dialog or provide feedback
        setOpen(false);
    };
    const handleCancel = async () => {
        setOpen(false);
        let fileIds = [];
        Object.values(orders).forEach((order) => {
            if (order.file) {
                fileIds.push({ id: order.file });
            }
        });
        // Delete all files
        await deleteFiles(fileIds);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomButton, { variant: "outlined", color: "primary", icon: React.createElement(Receipt, null), tooltip: !selection.length
                ? "Select components to generate orders"
                : !suppliers?.length
                    ? "No suppliers available"
                    : "", disabled: !selection.length || !suppliers?.length, label: "Generate Orders", onClick: () => setOpen(true) }),
        React.createElement(Dialog, { open: open, onClose: handleCancel, maxWidth: "lg", fullWidth: true },
            React.createElement(DialogTitle, null, "Generate Orders"),
            React.createElement(DialogContent, null, Object.keys(orders).map((supplierId) => {
                const order = orders[supplierId];
                const supplier = suppliers?.find((s) => s._id === supplierId);
                return (React.createElement(Paper, { key: supplierId, sx: { mb: 2, p: 2 } },
                    React.createElement(Typography, { variant: "h6" },
                        "Supplier: ",
                        supplier?.name || "Unknown"),
                    React.createElement(CreateOrder, { selection: selection, order: order, handleChange: (id, key, value) => {
                            if (id) {
                                // Update specific item
                                const updatedItems = order.items.map((item) => item.component === id ? { ...item, [key]: value } : item);
                                const total = updatedItems.reduce((sum, item) => sum + item.quantity * item.cost, 0);
                                handleOrderChange(supplierId, {
                                    ...order,
                                    items: updatedItems,
                                    total,
                                });
                            }
                            else {
                                // Update order-level field (e.g. "file")
                                handleOrderChange(supplierId, {
                                    ...order,
                                    [key]: value,
                                });
                            }
                        }, moveItemToSupplier: moveItemToSupplier, removeItem: removeItem, suppliers: suppliers || [] })));
            })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleCancel }, "Cancel"),
                React.createElement(Button, { variant: "contained", onClick: handleSave }, "Save All Orders")))));
};
export default GenerateOrders;
