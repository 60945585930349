import useAuthStore from "../../../stores/authStore";
import { useQuery } from "@tanstack/react-query";
import { getProjects } from "../../../api/projectApi";
export const useProjectsData = () => {
    const { user } = useAuthStore();
    if (!user) {
        throw new Error("No user!");
    }
    return useQuery({
        queryKey: ["projects"],
        queryFn: getProjects,
        staleTime: 1800 * 1000,
    });
};
