import { getFinancials } from "@api/financialApi";
import useAuthStore from "../../../stores/authStore";
import { useQuery } from "@tanstack/react-query";
export const useFinancialsData = () => {
    const { user } = useAuthStore();
    if (!user) {
        throw new Error("No user!");
    }
    return useQuery({
        queryKey: ["financials"],
        queryFn: getFinancials,
        staleTime: Infinity,
    });
};
