import React, { useEffect, useRef } from "react";
import { useData } from "../hooks";
import { DocumentEditorContainerComponent, } from "@syncfusion/ej2-react-documenteditor";
import GenerateToolbar from "./GenerateToolbar";
import { getTemplateDocument } from "@api/fileApi";
import { replaceAllVariables } from "../replaceVariables";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import { useComponentVariables, useProductVariables } from "../variables";
import { calculateProjectFinancials } from "@features/project/utils/calculateProjectFinancials";
import { useFinancialsData } from "@features/financial/hooks/useFinancialsData";
import { PictureAsPdf } from "@mui/icons-material";
const GenerateFromTemplate = (props) => {
    console.log(props.initialSelection);
    const containerRef = useRef(null);
    const { data: financial } = useFinancialsData();
    const { open, onClose, onSuccess, destinationId, destinationName, initialSelection, filename: initialFilename, } = props;
    const [selection, setSelection] = React.useState(initialSelection);
    useEffect(() => {
        if (initialSelection.order) {
            setSelection((prev) => ({
                ...prev,
                order: initialSelection.order,
                components: initialSelection.order.components,
                supplier: initialSelection.order.supplier,
            }));
        }
        else {
            setSelection(initialSelection);
        }
    }, [initialSelection]);
    const onSelectionChange = (newSelection) => {
        setSelection((prev) => ({ ...prev, ...newSelection }));
    };
    const [filename, setFilename] = React.useState(initialFilename || "");
    const { customers, projects, settings, components, suppliers, organization, templates, } = useData();
    const [originalTemplate, setOriginalTemplate] = React.useState("");
    const [loadingDoc, setLoadingDoc] = React.useState(false);
    // Load selected template
    useEffect(() => {
        const loadTemplate = async (docxId) => {
            setLoadingDoc(true);
            try {
                const sfdt = await getTemplateDocument(docxId);
                const sfdtString = JSON.stringify(sfdt);
                setOriginalTemplate(sfdtString);
                containerRef.current?.documentEditor.open(sfdtString);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoadingDoc(false);
            }
        };
        if (selection.template?.id && containerRef.current) {
            loadTemplate(selection.template.id);
            const selectedFile = templates.find((t) => t.id === selection.template.id);
            if (selectedFile && !filename) {
                setFilename(selectedFile.name.replace(/\.[^.]+$/, ""));
            }
        }
    }, [selection.template, templates]);
    // Define variables that depend on dynamic data
    const parameters = settings?.parameters.map((p) => p.name) || [];
    const options = components?.filter((c) => c.isOption).map((c) => c.name) || [];
    const productVariables = useProductVariables(parameters, options);
    const componentVariables = useComponentVariables(parameters);
    console.log(selection);
    // Apply selection to document
    useEffect(() => {
        if (!containerRef.current || !originalTemplate) {
            return;
        }
        containerRef.current?.documentEditor.open(originalTemplate);
        if (!selection.project) {
            replaceAllVariables(containerRef.current.documentEditor, selection, productVariables, componentVariables);
        }
        else {
            const { overallTotal, discountAmount, totalAfterDiscount, totalPayments, balance,
            // ... any other fields you need
             } = calculateProjectFinancials(selection.project, financial);
            replaceAllVariables(containerRef.current.documentEditor, {
                ...selection,
                project: {
                    ...selection.project,
                    projectValue: overallTotal,
                    discountValue: discountAmount,
                    total: totalAfterDiscount,
                    paid: totalPayments,
                    balance: balance,
                },
            }, productVariables, componentVariables);
        }
    }, [selection, originalTemplate]);
    const handleGenerate = async () => {
        if (!containerRef.current?.documentEditor)
            return;
        const editor = containerRef.current.documentEditor;
        const sfdt = { content: editor.serialize() };
        try {
            const response = await fetch("/api/template/exportPdf", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    sfdt,
                    destinationId,
                    destinationName,
                    filename: filename,
                }),
                credentials: "include",
            });
            const jsonResponse = await response.json();
            // savedFile should be the File object returned by the server
            onSuccess(jsonResponse.file.id); // Signal success to the parent
            onClose(); // Close the dialog
        }
        catch (error) {
            console.error("Failed to save template document:", error);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: open, onClose: onClose, fullScreen: true },
            React.createElement(DialogTitle, null, "Generate from Template"),
            React.createElement(DialogContent, { style: { display: "flex", flexDirection: "column", height: "100%" } },
                React.createElement(GenerateToolbar, { selection: selection, onSelectionChange: onSelectionChange, filename: filename, setFilename: setFilename }),
                React.createElement(DocumentEditorContainerComponent, { ref: (scope) => {
                        containerRef.current = scope;
                    }, height: "100%", width: "100%", enableToolbar: false, showPropertiesPane: false })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: onClose }, "Cancel"),
                React.createElement(Button, { onClick: handleGenerate, color: "primary", startIcon: React.createElement(PictureAsPdf, null), variant: "contained" }, "Generate PDF")))));
};
export default GenerateFromTemplate;
