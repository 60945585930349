import { Fade } from "@mui/material";
import React from "react";
import { DialogManager } from "@features/Dialogs/DialogManager";
import AppBar from "@features/appBar/AppBar";
import Customers from "@features/customer/Customers";
import Projects from "@features/project/Projects";
import useGeneralStore, { Tabs } from "../../stores/generalStore";
import People from "@features/people/People";
import Inventory from "@features/inventory/Inventory";
import { useComponentsData } from "@features/inventory/hooks/useComponentsData";
import Financials from "@features/financial/Financials";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
import useThemeStore from "@features/theme/themeStore";
import Calendar from "@features/calendar/Calendar";
import Suppliers from "@features/supplier/Suppliers";
import { SocketProvider } from "src/utils/SocketProvider";
import Orders from "@features/order/Orders";
import { useOrdersData } from "@features/order/hooks/useOrdersData";
import { useSuppliersData } from "@features/supplier/hooks/useSuppliersData";
import FileManager from "@features/file-manager/FileManager";
export default function Admin() {
    const { tab } = useGeneralStore();
    const { initializeTheme } = useThemeStore();
    const { data: components } = useComponentsData();
    const { data: settings } = useSettingsData();
    const { data: suppliers } = useSuppliersData();
    const { data: orders } = useOrdersData();
    React.useEffect(() => {
        if (!settings)
            return;
        if (!settings.theme)
            return;
        initializeTheme(settings.theme);
    }, [settings]);
    return (React.createElement(Fade, { in: true },
        React.createElement("div", null,
            React.createElement(AppBar, null),
            tab === Tabs.Calendar ? React.createElement(Calendar, null) : null,
            tab === Tabs.Projects ? React.createElement(Projects, null) : null,
            tab === Tabs.Customers ? React.createElement(Customers, null) : null,
            tab === Tabs.Suppliers ? React.createElement(Suppliers, null) : null,
            tab === Tabs.People ? React.createElement(People, null) : null,
            tab === Tabs.Inventory ? React.createElement(Inventory, null) : null,
            tab === Tabs.Files ? React.createElement(FileManager, null) : null,
            tab === Tabs.Orders ? React.createElement(Orders, null) : null,
            tab === Tabs.Financials ? React.createElement(Financials, null) : null,
            React.createElement(DialogManager, null))));
}
export const AdminWithSocketProvider = () => {
    return (React.createElement(SocketProvider, null,
        React.createElement(Admin, null)));
};
