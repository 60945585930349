import { Paper, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
export const ProjectEvent = ({ event }) => {
    return (React.createElement(Paper, { sx: { height: "100%" } },
        React.createElement(Typography, { variant: "h6" }, event.title),
        event.events?.map((e) => (React.createElement(ProjectEvent, { key: e.title, event: e })))));
};
ProjectEvent.propTypes = {
    event: PropTypes.object,
};
