import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuthStore from "../stores/authStore";
const ProtectedRoute = ({ role }) => {
    const { user } = useAuthStore();
    if (!user?.id) {
        return React.createElement(Navigate, { to: "/" });
    }
    if ((role && user?.role !== role) || (role && user.status !== "active")) {
        return React.createElement(Navigate, { to: "/" });
    }
    return React.createElement(Outlet, null);
};
export default ProtectedRoute;
