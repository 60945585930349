import React from "react";
import { Stack, MenuItem, Divider, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TextField, Typography, Button, Menu, } from "@mui/material";
import CustomButton from "src/components/CustomButton";
import { Delete, SwapVert } from "@mui/icons-material";
import GenerateFromTemplate from "@features/file-manager/components/GenerateFromTemplate";
import { deleteFiles } from "@api/fileApi";
const CreateOrder = ({ selection, order, handleChange, moveItemToSupplier, removeItem, suppliers, }) => {
    const [openTemplateDialog, setOpenTemplateDialog] = React.useState(false);
    const supplier = suppliers.find((s) => s._id === order.supplier);
    // Map supplier IDs to suppliers for quick access
    const suppliersMap = suppliers.reduce((acc, supplier) => {
        acc[supplier._id] = supplier;
        return acc;
    }, {});
    // Combine order items with component details
    const itemsWithDetails = order.items.map((orderItem) => {
        const componentDetails = selection.find((comp) => comp._id === orderItem.component);
        return {
            ...orderItem,
            componentDetails,
            supplierData: orderItem.supplierData,
        };
    });
    // Generate a unique file name for the order
    const fileName = `${order.name}`;
    // Components data formatted for the template dialog
    const dialogComponents = React.useMemo(() => itemsWithDetails.map((item) => ({
        ...item,
        ...item.componentDetails,
        options: [],
        componentId: item.componentDetails?._id || "",
        productId: item.componentDetails?.productId || "",
        name: item.componentDetails?.name
            ? item.componentDetails.name
            : "Component Name",
        image: item.componentDetails?.image,
        qty: item.quantity,
        supplier: supplier?.name || "Supplier Name",
        supplierCost: item.cost,
        supplierCode: item.supplierData?.code,
        selected: true,
        total: item.quantity * item.cost,
    })), [itemsWithDetails, supplier]);
    const handleGenerateSuccess = (fileId) => {
        // fileId is a string representing the saved file in the system
        handleChange(null, "file", fileId);
    };
    const handleDeleteFile = () => {
        handleChange(null, "file", null);
        deleteFiles([{ id: order.file }]);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { spacing: 2, sx: { pt: 1 } },
            React.createElement(TextField // Order Name
            , { label: "Order Name", value: order.name, onChange: (e) => handleChange(null, "name", e.target.value), fullWidth: true }),
            React.createElement(TableContainer, { component: Paper },
                React.createElement(Table, { "aria-label": "selected items" },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Component"),
                            removeItem && moveItemToSupplier && (React.createElement(TableCell, { align: "center" }, "Actions")),
                            React.createElement(TableCell, { align: "right" }, "Code"),
                            React.createElement(TableCell, { align: "right" }, "Quantity"),
                            React.createElement(TableCell, { align: "right" }, "Cost"),
                            React.createElement(TableCell, { align: "right" }, "Item Total"))),
                    React.createElement(TableBody, null,
                        itemsWithDetails.map((item) => {
                            const { componentDetails, supplierData } = item;
                            const availableSuppliers = componentDetails?.suppliers.map((s) => s.supplier.toString()) || [];
                            return (React.createElement(OrderItemRow, { key: item.component, item: item, componentDetails: componentDetails, supplierData: supplierData, availableSuppliers: availableSuppliers, handleChange: handleChange, moveItemToSupplier: moveItemToSupplier, removeItem: removeItem, order: order, suppliersMap: suppliersMap }));
                        }),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 4, align: "right" },
                                React.createElement(Typography, { variant: "h6" }, "Total")),
                            React.createElement(TableCell, { align: "right" },
                                React.createElement(Typography, { variant: "h6" }, order.total.toFixed(2))),
                            removeItem && moveItemToSupplier && React.createElement(TableCell, null))))),
            React.createElement(Divider, null),
            React.createElement(Stack, { spacing: 2, direction: "row", alignItems: "center" }, typeof order.file === "string" && order.file ? (React.createElement(React.Fragment, null,
                React.createElement(Typography, { variant: "body2" }, "File Saved"),
                React.createElement(Button, { variant: "outlined", color: "secondary", onClick: handleDeleteFile }, "Delete File"))) : (React.createElement(Button, { variant: "contained", onClick: () => setOpenTemplateDialog(true) }, "Generate Template PDF")))),
        React.createElement(GenerateFromTemplate, { open: openTemplateDialog, onClose: () => setOpenTemplateDialog(false), destinationName: "Orders", onSuccess: (fileId) => {
                setOpenTemplateDialog(false);
                handleGenerateSuccess(fileId);
            }, filename: fileName, initialSelection: {
                order: {
                    total: order.total,
                    components: dialogComponents,
                    supplier: supplier,
                },
            } })));
};
const OrderItemRow = ({ item, componentDetails, supplierData, availableSuppliers, handleChange, moveItemToSupplier, removeItem, order, suppliersMap, }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleSupplierChange = (newSupplierId) => {
        if (moveItemToSupplier && componentDetails) {
            const newSupplierData = componentDetails.suppliers.find((s) => s.supplier.toString() === newSupplierId);
            moveItemToSupplier(order.supplier, newSupplierId, {
                ...item,
                cost: newSupplierData?.cost || 0,
                supplierData: newSupplierData || {},
            });
        }
        handleMenuClose();
    };
    const itemQuantity = item.quantity;
    const itemCost = item.cost;
    const itemTotal = itemQuantity * itemCost;
    const code = supplierData?.code || "";
    return (React.createElement(TableRow, { key: item.component },
        React.createElement(TableCell, { component: "th", scope: "row" }, componentDetails?.name),
        removeItem && moveItemToSupplier && (React.createElement(TableCell, { align: "center" },
            availableSuppliers.length > 1 && (React.createElement(React.Fragment, null,
                React.createElement(CustomButton, { variant: "outlined", onClick: handleMenuOpen, icon: React.createElement(SwapVert, null), sx: { mr: 1 } }),
                React.createElement(Menu, { anchorEl: anchorEl, open: open, onClose: handleMenuClose }, availableSuppliers.map((supId) => (React.createElement(MenuItem, { key: supId, onClick: () => handleSupplierChange(supId) }, suppliersMap[supId]?.name || supId)))))),
            React.createElement(CustomButton, { onClick: () => removeItem(order.supplier, item.component), variant: "outlined", icon: React.createElement(Delete, null) }))),
        React.createElement(TableCell, { align: "right" }, code),
        React.createElement(TableCell, { align: "right" },
            React.createElement(TextField, { type: "number", value: itemQuantity, onChange: (e) => handleChange(item.component, "quantity", parseInt(e.target.value, 10)), inputProps: { min: 0 }, size: "small" })),
        React.createElement(TableCell, { align: "right" },
            React.createElement(TextField, { type: "number", value: itemCost, onChange: (e) => handleChange(item.component, "cost", parseFloat(e.target.value)), inputProps: { min: 0, step: 0.01 }, size: "small" })),
        React.createElement(TableCell, { align: "right" }, itemTotal.toFixed(2))));
};
export default CreateOrder;
