// theme.js
import { createTheme } from "@mui/material/styles";
export const getTheme = (mode, primary, secondary) => createTheme({
    palette: {
        mode,
        primary: {
            main: primary,
        },
        secondary: {
            main: secondary,
        },
        background: {
            default: mode === "light" ? "#eee8d5" : "#121212",
            paper: mode === "light" ? "#fdf6e3" : "#1c1c1c",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
        // MuiDataGrid: {
        //   styleOverrides: {
        //     root: {
        //       // Existing overrides
        //       "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus":
        //         {
        //           outline: "none",
        //         },
        //       "& .MuiDataGrid-columnHeader": {
        //         textTransform: "uppercase",
        //       },
        //       // Add styles for incomplete product rows
        //       "& .MuiDataGrid-row.incomplete-product": {
        //         backgroundColor: mode === "light" ? "#ffe6e6" : "#4a0000",
        //         "&:hover": {
        //           backgroundColor: mode === "light" ? "#ffd6d6" : "#600000",
        //         },
        //       },
        //     },
        //   },
        // },
    },
});
