import { Box } from "@mui/material";
import React from "react";
import { People } from "./People";
import Grid from "@mui/material/Grid2";
import { SelectedProject } from "../../Projects";
import AddTasks from "./AddTasks";
import { Milestone } from "./Milestone";
import { Calendar } from "./OldCalendar";
import { DragContext } from "./DragContext";
const PlanTab = () => {
    const project = React.useContext(SelectedProject);
    const [draggedTask, setDraggedTask] = React.useState(null);
    if (!project)
        return null;
    return (React.createElement(DragContext.Provider, { value: { draggedTask, setDraggedTask } },
        React.createElement(Box, { sx: { width: "100%" } },
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { size: 3 },
                    React.createElement(People, null),
                    React.createElement(AddTasks, null),
                    project.milestones.map((milestone) => (React.createElement(Milestone, { key: milestone.name, milestone: milestone })))),
                React.createElement(Grid, { size: 9 },
                    React.createElement(Calendar, null))))));
};
export default PlanTab;
