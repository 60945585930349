import api from "./api";
export const getProjects = async () => {
    const response = await api.get(`/api/project`);
    return response.data;
};
export const createProject = async (project) => {
    const response = await api.post(`/api/project`, project);
    return response.data;
};
export const updateProject = async (project) => {
    const response = await api.patch(`/api/project/${project._id}`, project);
    return response.data;
};
export const deleteProject = async (project) => {
    const response = await api.delete(`/api/project/${project._id}`);
    return response.data;
};
