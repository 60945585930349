import React from "react";
import { TABS } from "../UserSettings";
import { Tab, Tabs } from "@mui/material";
const DialogMenu = ({ activeTab, setTab }) => {
    const handleTabChange = (value) => {
        setTab(value);
    };
    return (React.createElement(Tabs, { onChange: (e, value) => handleTabChange(value), value: activeTab, centered: true }, TABS.map((tab) => (React.createElement(Tab, { label: tab.label, key: tab.value, value: tab.value, sx: {
            justifyContent: "flex-start",
            height: 25,
            textTransform: "none",
        } })))));
};
export default DialogMenu;
