import { deleteComponent } from "@api/componentApi";
import useNotificationStore from "@stores/notificationStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export function useComponentDelete() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: deleteComponent,
        onSuccess: ({ updatedComponents, deletedComponentIds }) => {
            queryClient.setQueryData(["components"], (oldData = []) => {
                // Remove the deleted component from the data
                let newData = oldData.filter((component) => !deletedComponentIds.includes(component._id));
                if (updatedComponents.length === 0) {
                    return newData;
                }
                for (const component of updatedComponents) {
                    let cleanComponent = { ...component };
                    delete cleanComponent.fresh;
                    let existingIndex = newData.findIndex((existingComponent) => existingComponent._id === component._id);
                    if (existingIndex !== -1) {
                        // Update existing component in newData
                        newData[existingIndex] = cleanComponent;
                    }
                    else {
                        // Add new component to newData
                        newData.push(cleanComponent);
                    }
                }
                return newData;
            });
            addNotification("Component deleted.", "success");
        },
        onError: (error) => {
            console.error(error);
            addNotification("Could not delete component.", "error");
        },
    });
}
