import React from "react";
import { Box, Chip } from "@mui/material";
import { options } from "../components/StatusButton";
import { Circle } from "@mui/icons-material";
export const columns = [
    {
        field: "name",
        headerName: "Name",
        flex: 1,
        renderCell: (params) => {
            return (React.createElement(Box, { sx: { display: "flex", alignItems: "center", gap: 1 } },
                React.createElement(Circle, { sx: { color: params.row.color } }),
                params.value));
        },
    },
    {
        field: "customer",
        headerName: "Customer",
        flex: 1,
        valueGetter: (params) => `${params.firstName} ${params.lastName}`,
    },
    {
        field: "creationDate",
        headerName: "Start",
        flex: 1,
        valueGetter: (params) => new Date(params).toLocaleDateString(),
    },
    {
        field: "dueDate",
        headerName: "End",
        flex: 1,
        valueGetter: (params) => params ? new Date(params).toLocaleDateString() : "",
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => {
            return (React.createElement(Chip, { label: params.value, color: options.find((option) => option.value === params.value)?.color ||
                    "primary" }));
        },
    },
];
export const newProjectFields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        required: true,
    },
    {
        name: "customer",
        label: "Customer",
        type: "autocomplete",
        required: true,
    },
    {
        name: "creationDate",
        label: "Start",
        type: "date",
        required: true,
    },
    {
        name: "endDate",
        label: "End",
        type: "date",
        required: true,
    },
];
