import { Loop } from "@mui/icons-material";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter, } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useComponentsData } from "../../inventory/hooks/useComponentsData";
import { useComponentCreateStore } from "../store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
import { getContrastYIQ } from "src/utils/colorUtils";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
const selector = (state) => ({
    getParentNode: state.getParentNode,
    switchComponentNode: state.switchComponentNode,
});
const SwitchComponentNode = ({ id, disabled, }) => {
    const { getParentNode, switchComponentNode } = useComponentCreateStore(useShallow(selector));
    const { data: settings } = useSettingsData();
    const { data } = useComponentsData();
    const components = data || [];
    const COLUMNS = [
        { field: "name", headerName: "Name", width: 150 },
        {
            field: "categories",
            headerName: "Category",
            flex: 1,
            renderCell: (params) => (React.createElement(React.Fragment, null, params.value.length ? (React.createElement(Box, { sx: {
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    height: "100%",
                } }, params.value.map((category) => (React.createElement(Chip, { key: category, label: category, size: "small", sx: {
                    borderRadius: 1,
                    backgroundColor: settings?.categories.find((cat) => cat.name === category)?.color,
                    color: getContrastYIQ(settings?.categories.find((cat) => cat.name === category)
                        ?.color || "grey"),
                } }))))) : null)),
        },
    ];
    const [open, setOpen] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [rows, setRows] = useState(components);
    useEffect(() => {
        const updatedRows = components.map((component) => {
            const isDisabled = !isComponentValidForSwitch(id, component._id, getParentNode(id)?.data.subcomponents || []);
            return { ...component, disabled: isDisabled };
        });
        console.log({ updatedRows });
        setRows(updatedRows);
    }, [selectionModel, components, id, getParentNode]);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSwitch = () => {
        const componentsToSwitch = selectionModel.map((id) => components.find((component) => component._id === id));
        const dataForSwitch = componentsToSwitch
            .map((component) => {
            let nodes = JSON.parse(component.flowData).nodes;
            // go through each node and return the component that matches the last part of the id
            return nodes.map((node) => {
                return components.find((comp) => comp._id === node.id.split("_").pop());
            });
        })
            .flat();
        //remove duplicates
        const uniqueDataForSwitch = dataForSwitch.filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i);
        switchComponentNode(id, componentsToSwitch, uniqueDataForSwitch);
        setOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { onClick: handleOpen, size: "small", color: "primary", disabled: disabled },
            React.createElement(Loop, null)),
        React.createElement(Dialog, { open: open, onClose: handleClose, maxWidth: "md", fullWidth: true },
            React.createElement(DialogTitle, null, "Switch Component"),
            React.createElement(DialogContent, null,
                React.createElement(DataGrid, { rows: rows, sx: {
                        height: 400,
                        mt: 1,
                    }, slots: {
                        toolbar: () => (React.createElement(Box, null,
                            React.createElement(GridToolbarQuickFilter, { sx: { ml: 1, mb: 0.5, mt: 1 }, variant: "outlined", size: "small" }))),
                    }, density: "compact", getRowId: (row) => row._id, columns: COLUMNS, checkboxSelection: true, rowSelectionModel: selectionModel, onRowSelectionModelChange: (newSelection) => {
                        setSelectionModel(newSelection);
                    }, hideFooter: true, isRowSelectable: (params) => !params.row.disabled })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleClose, variant: "outlined" }, "Cancel"),
                React.createElement(Button, { onClick: handleSwitch, variant: "contained" }, "Switch")))));
};
export default SwitchComponentNode;
const isComponentValidForSwitch = (target, value, parentSubcomponents) => {
    console.log({ target, value, parentSubcomponents });
    if (target.includes(value))
        return false;
    if (parentSubcomponents.find((sub) => sub.id.split("_").at(-1) === value))
        return false;
    // let allRelateds: string[] = [];
    // selection.forEach((selected) => {
    //   allRelateds = allRelateds.concat(
    //     getAllStreamIds(selected as string, components)
    //   );
    // });
    // if (allRelateds.includes(value)) return false;
    return true;
};
