import { Box, Slide, Stack, useTheme } from "@mui/material";
import React from "react";
import UserSettings from "../userSettings/UserSettings";
import AppBarTabs from "./AppBarTabs";
import Profile from "../people/Profile";
const CustomAppBar = () => {
    const theme = useTheme();
    return (React.createElement(Slide, { in: true, direction: "down" },
        React.createElement(Box, { sx: {
                pt: 2,
                pb: 2,
            } },
            React.createElement(Stack, { direction: "row", justifyContent: "space-between", position: "static" },
                React.createElement(AppBarTabs, null),
                React.createElement(Box, { sx: { display: "flex" } },
                    React.createElement(UserSettings, null),
                    React.createElement(Profile, null))))));
};
export default CustomAppBar;
