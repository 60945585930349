import { updateCustomer } from "../../../api/customerApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useNotificationStore from "../../../stores/notificationStore";
export function useCustomerUpdate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: updateCustomer,
        onSuccess: (updatedCustomer) => {
            queryClient.setQueryData(["customers"], (oldData) => {
                return oldData.map((customer) => {
                    if (customer._id === updatedCustomer._id) {
                        return updatedCustomer;
                    }
                    return customer;
                });
            });
            addNotification("Customer updated.", "success");
        },
        onError: () => {
            addNotification("Could not update customer.", "error");
        },
    });
}
