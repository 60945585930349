import { Cancel, Refresh } from "@mui/icons-material";
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Tooltip, } from "@mui/material";
import React from "react";
const PaymentLinkField = ({ amount, paymentLink, handleGeneratePaymentLink, handleDeletePaymentLink, editable, paid, disabled, }) => {
    return (React.createElement(FormControl, { fullWidth: true, sx: { mt: 1 } },
        React.createElement(InputLabel, { htmlFor: "payment-link-field" }, "Payment Link"),
        React.createElement(OutlinedInput, { id: "outlined-adornment-password", onChange: () => null, disabled: !editable, value: paymentLink, startAdornment: React.createElement(React.Fragment, null, !paymentLink && (React.createElement(Tooltip, { title: disabled
                    ? "Connect Stripe first."
                    : amount < 1
                        ? "Enter a positive amount."
                        : null, arrow: true, placement: "right" },
                React.createElement(InputAdornment, { position: "start" },
                    React.createElement(Button, { disabled: !editable || amount < 1 || paid || disabled, variant: "contained", onClick: handleGeneratePaymentLink }, "Generate Link"))))), endAdornment: React.createElement(React.Fragment, null, paymentLink && (React.createElement(InputAdornment, { position: "end", sx: { mr: 1 } },
                React.createElement(Tooltip, { title: "Generate New Link" },
                    React.createElement(IconButton, { edge: "end", onClick: handleGeneratePaymentLink, disabled: !editable },
                        React.createElement(Refresh, null))),
                React.createElement(Tooltip, { title: "Cancel Link" },
                    React.createElement(IconButton, { edge: "end", onClick: handleDeletePaymentLink, disabled: !editable },
                        React.createElement(Cancel, null)))))), label: "Payment Link" })));
};
export default PaymentLinkField;
