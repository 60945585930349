import api from "./api";
export const getCustomers = async () => {
    const response = await api.get(`/api/customer`);
    return response.data;
};
export const updateCustomer = async (customer) => {
    const response = await api.patch(`/api/customer/${customer._id}`, customer);
    return response.data;
};
export const deleteCustomer = async (customer) => {
    const response = await api.delete(`/api/customer/${customer._id}`);
    return response.data;
};
export const createCustomer = async (customer) => {
    const response = await api.post("/api/customer", customer);
    return response.data;
};
