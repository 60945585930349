import { customerVariables, orderVariables, projectVariables, supplierVariables, } from "./variables";
/**
 * Main function to call from ParentComponent:
 * Replaces all known variables and handles tables if needed.
 */
export function replaceAllVariables(editor, selection, productVariables, componentVariables, organizationData) {
    if (!editor)
        return;
    console.log("selection", selection);
    const { customer, project, supplier, products, components, order } = selection;
    if (customer)
        replaceScalarVariables(editor, customerVariables, customer);
    if (project)
        replaceScalarVariables(editor, projectVariables, project);
    if (supplier)
        replaceScalarVariables(editor, supplierVariables, supplier);
    if (order)
        replaceScalarVariables(editor, orderVariables, order);
    // If you have organizationVariables, you can call replaceScalarVariables here as well:
    // replaceScalarVariables(editor, organizationVariables, organizationData);
    if (project && productVariables && products) {
        const selectedProducts = products.filter((p) => p.selected);
        replaceProductsInTable(editor, selectedProducts, productVariables);
    }
    if (components && components.length > 0 && componentVariables) {
        const selectedComponents = components.filter((c) => c.selected);
        replaceComponentsInTable(editor, selectedComponents, componentVariables);
    }
    // Update fields in case any MERGEFIELD fields exist
    editor.updateFields();
}
/**
 * Replace scalar (simple text) variables in the document.
 * Variables are represented as «Display Name».
 */
function replaceScalarVariables(editor, variableMap, dataObj) {
    // Serialize the entire document once
    let sfdtString = editor.serialize();
    // Replace all placeholders in one go
    for (const displayName of Object.keys(variableMap)) {
        const key = variableMap[displayName];
        const value = dataObj && dataObj[key] != null ? dataObj[key].toString() : "";
        const placeholder = `«${displayName}»`;
        // Replace all occurrences of placeholder with value
        // Using split/join instead of regex to avoid escaping issues
        sfdtString = sfdtString.split(placeholder).join(value);
    }
    // Re-open the modified document
    editor.open(sfdtString);
}
/**
 * Combines products that have identical values for all identity fields
 * (i.e. all fields except Product Quantity and Product Total) into a single product.
 */
function combineLikeProductsDynamic(products, variables, templateRow) {
    const combined = combineLikeItemsDynamic(products, variables, templateRow, "Product");
    return combined;
}
/**
 * Combines components that have identical values for all identity fields
 * (i.e. all fields except Component Quantity and Component Total).
 */
function combineLikeComponentsDynamic(components, variables, templateRow) {
    const combined = combineLikeItemsDynamic(components, variables, templateRow, "Component");
    return combined;
}
/**
 * A generic function to combine like items (products or components) dynamically,
 * excluding Quantity and Total fields from identity fields.
 */
function combineLikeItemsDynamic(items, variables, templateRow, prefix) {
    // Extract placeholders from templateRow
    const placeholderPattern = /«([^«»]+)»/g;
    const allPlaceholders = new Set();
    for (const cellText of templateRow) {
        let match;
        while ((match = placeholderPattern.exec(cellText)) !== null) {
            allPlaceholders.add(match[1]);
        }
    }
    // Exclude Quantity and Total for identity fields
    const excludedFields = [`${prefix} Quantity`, `${prefix} Total`];
    const identityPlaceholders = Array.from(allPlaceholders).filter((ph) => !excludedFields.includes(ph));
    const itemMap = new Map();
    for (const item of items) {
        // Build identity key from identityPlaceholders
        const identityKeyParts = identityPlaceholders.map((displayName) => {
            const key = variables[displayName];
            return `${displayName}:${prefix === "Product"
                ? getProductValue(item, key)
                : getComponentValue(item, key)}`;
        });
        const identityKey = identityKeyParts.join("|");
        const qty = item.qty || 1;
        const price = Number(prefix === "Product" ? item.price : item.supplierCost || item.cost);
        if (itemMap.has(identityKey)) {
            const existing = itemMap.get(identityKey);
            const existingQty = existing.qty || 1;
            const newQty = existingQty + qty;
            existing.qty = newQty;
            existing.total = String(price * newQty);
            itemMap.set(identityKey, existing);
        }
        else {
            item.total = String(price * qty);
            if (!item.qty)
                item.qty = qty;
            itemMap.set(identityKey, { ...item });
        }
    }
    return Array.from(itemMap.values());
}
/**
 * Replaces products in a table given product variables.
 */
export function replaceProductsInTable(editor, products, variables) {
    const templatePlaceholder = "«Product Name»";
    replaceItemsInTable(editor, products, variables, templatePlaceholder, "Product", combineLikeProductsDynamic);
}
/**
 * Replaces components in a table given component variables.
 * Identical approach as products, but using a "«Component Name»" placeholder.
 */
export function replaceComponentsInTable(editor, components, variables) {
    console.log("replaceComponentsInTable", components);
    const templatePlaceholder = "«Component Name»";
    replaceItemsInTable(editor, components, variables, templatePlaceholder, "Component", combineLikeComponentsDynamic);
}
/**
 * A generic function to replace items (products or components) in a table.
 * @param editor DocumentEditor instance
 * @param items Array of items (products or components)
 * @param variables Variable map (e.g., productVariables or componentVariables)
 * @param templatePlaceholder A placeholder unique to identify the template row (e.g., "«Product Name»" or "«Component Name»")
 * @param prefix "Product" or "Component" to determine field naming
 * @param combineFn A function to combine like items (products or components)
 */
function replaceItemsInTable(editor, items, variables, templatePlaceholder, prefix, combineFn) {
    editor.search.findAll(templatePlaceholder);
    if (editor.search.searchResults.length === 0) {
        console.warn("Template row not found. Cannot populate", prefix.toLowerCase() + "s.");
        return;
    }
    // Select the entire row containing the placeholder
    editor.selection.selectRow();
    moveToFirstCell(editor);
    editor.selection.selectCell();
    // build the template row
    let templateRow = [];
    let previousCellText = "";
    let currentCellText = editor.selection.text
        .replace("\n", "")
        .replaceAll("\r", "");
    while (currentCellText !== previousCellText) {
        templateRow.push(currentCellText);
        moveCursorToNextCell(editor);
        editor.selection.selectCell();
        previousCellText = currentCellText;
        currentCellText = editor.selection.text
            .replace("\n", "")
            .replaceAll("\r", "");
    }
    const combinedItems = combineFn(items, variables, templateRow);
    editor.selection.selectRow();
    moveToFirstCell(editor);
    editor.selection.selectCell();
    if (combinedItems.length === 0) {
        return;
    }
    // Overwrite template row with the first item's data
    const firstItem = combinedItems[0];
    let cells = getCellTextsForItem(firstItem, templateRow, variables, prefix);
    for (let i = 0; i < cells.length; i++) {
        editor.selection.selectCell();
        editor.editor.insertText(cells[i]);
        if (i < cells.length - 1) {
            moveCursorToNextCell(editor);
        }
    }
    // Now handle the rest of the items (if any)
    for (let p = 1; p < combinedItems.length; p++) {
        const itm = combinedItems[p];
        cells = getCellTextsForItem(itm, templateRow, variables, prefix);
        editor.selection.selectRow();
        editor.editor.insertRow(); // Insert a new row below the current selected row
        for (let i = 0; i < cells.length; i++) {
            editor.selection.selectCell();
            editor.editor.insertText(cells[i]);
            if (i < cells.length - 1) {
                moveCursorToNextCell(editor);
            }
        }
    }
    // Update fields if needed
    editor.updateFields();
}
/**
 * Returns cell texts for a given item (product or component) by replacing placeholders.
 */
function getCellTextsForItem(item, templateRow, variables, prefix) {
    return templateRow.map((cellText) => {
        return cellText.replace(/«([^«»]+)»/g, (match, key) => {
            return prefix === "Product"
                ? getProductValue(item, variables[key])
                : getComponentValue(item, variables[key]);
        });
    });
}
function moveCursorToNextCell(editor) {
    var startOffset = editor.selection.startOffset;
    let newOffset = startOffset.split(";");
    newOffset[3] = `${parseInt(newOffset[3]) + 1}`;
    startOffset = newOffset.join(";");
    editor.selection.select(startOffset, startOffset);
}
function moveToFirstCell(editor) {
    editor.selection.moveToLineStart();
    editor.selection.moveToLineEnd();
    editor.selection.moveToLineStart();
}
// Utility function to get product value based on variable key
function getProductValue(product, key) {
    if (!product)
        return "";
    if (key.startsWith("PARAM")) {
        const paramName = key.replace("PARAM", "");
        const param = product.parameters.find((p) => p.name === paramName);
        return param ? String(param.calculatedValue ?? param.value ?? "") : "";
    }
    if (key.startsWith("OPTION")) {
        const optionName = key.replace("OPTION", "");
        const opt = product.options.find((o) => o.name === optionName);
        return opt ? String(opt.choiceName ?? "") : "";
    }
    return product[key] ? String(product[key]) : "";
}
// Utility function to get component value based on variable key
function getComponentValue(component, key) {
    console.log("component", component);
    console.log("key", key);
    if (!component)
        return "";
    if (key.startsWith("PARAM")) {
        const paramName = key.replace("PARAM", "");
        const param = component.parameters?.find((p) => p.name === paramName);
        return param ? String(param.calculatedValue ?? param.value ?? "") : "";
    }
    // If needed, handle options for components similar to products, if they exist
    // if (key.startsWith("OPTION")) {
    //   const optionName = key.replace("OPTION", "");
    //   const opt = component.options?.find((o: any) => o.name === optionName);
    //   return opt ? String(opt.choiceName ?? "") : "";
    // }
    return component[key] ? String(component[key]) : "";
}
