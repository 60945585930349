import { KeyboardBackspace, Redo, Save, Undo, } from "@mui/icons-material";
import { Box, Button, ButtonGroup, CircularProgress, Paper, Tooltip, } from "@mui/material";
import React from "react";
import Variables from "./componentNode/Variables";
import { useComponentCreateStore, useTemporalStore, } from "./store/createComponentStore";
import { useComponentCreate } from "./hooks/useComponentCreate";
import { useShallow } from "zustand/react/shallow";
import { useComponentsData } from "@features/inventory/hooks/useComponentsData";
const selector = (state) => ({
    nodes: state.nodes,
    initializeComponentEdit: state.initializeComponentEdit,
    groupNodesByCategories: state.groupNodesByCategories,
    groupNodes: state.groupNodes,
    createPack: state.createPack,
    canSave: state.canSave,
});
// function useTemporalStore(): TemporalState<ComponentCreateStore>;
// function useTemporalStore<T>(selector: (state: TemporalState<ComponentCreateStore>) => T): T;
// function useTemporalStore<T>(
//   selector: (state: TemporalState<ComponentCreateStore>) => T,
//   equality: (a: T, b: T) => boolean,
// ): T;
const CreationControls = ({ onBackClick }) => {
    const { mutate: createComponent, data, isPending } = useComponentCreate();
    const { data: components } = useComponentsData();
    const { nodes, initializeComponentEdit, createPack, canSave, groupNodesByCategories, groupNodes, } = useComponentCreateStore(useShallow(selector));
    const saveError = canSave();
    React.useEffect(() => {
        if (data && components) {
            const comp = data[data.length - 1];
            initializeComponentEdit(comp, components?.filter((c) => comp.flowData.includes(c._id)));
        }
    }, [data]);
    const { undo, redo, futureStates, pastStates, clear } = useTemporalStore((state) => state);
    const handleSave = async () => {
        const pack = createPack();
        createComponent(pack);
    };
    const handleBack = () => {
        clear();
        onBackClick();
    };
    const handleGroupNodes = () => {
        const selectedNodes = nodes
            .filter((node) => node.selected)
            .map((node) => node.id);
        groupNodes(selectedNodes);
    };
    console.log("creationControls");
    return (React.createElement(Box, { sx: {
            position: "absolute",
            width: "100%",
            // ml: 2,
            pl: 2,
            pt: 1,
            alignItems: "center",
            display: "flex",
        } },
        React.createElement(Button, { onClick: handleBack, variant: "contained", sx: { zIndex: 4, position: "fixed", boxShadow: 6 }, startIcon: React.createElement(KeyboardBackspace, null) }, "Back"),
        React.createElement(Box, { sx: {
                display: "flex",
                justifyContent: "center",
                gap: 1,
                mx: "auto",
            } },
            React.createElement(Paper, { sx: {
                    display: "flex",
                    gap: 2,
                    p: 1,
                    zIndex: 6,
                    boxShadow: 6,
                }, elevation: 6 },
                React.createElement(Variables, null),
                React.createElement(Button, { variant: "contained", onClick: handleGroupNodes, disabled: nodes.filter((node) => node.selected).length < 2 }, "Group"),
                React.createElement(ButtonGroup, null,
                    React.createElement(Button, { onClick: () => undo(), variant: "contained", disabled: pastStates.length === 0 },
                        React.createElement(Undo, null)),
                    React.createElement(Button, { onClick: () => redo(), variant: "contained", disabled: futureStates.length === 0 },
                        React.createElement(Redo, null))),
                React.createElement(Tooltip, { title: saveError || isPending ? saveError : "Save" },
                    React.createElement(Box, null,
                        React.createElement(Button, { variant: "contained", onClick: handleSave, disabled: isPending || !!saveError, startIcon: isPending ? React.createElement(CircularProgress, { size: 24 }) : React.createElement(Save, null) }, "Save")))))));
};
export default CreationControls;
