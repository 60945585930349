// useOrderCreate.ts
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createOrder } from "@api/orderApi";
import useNotificationStore from "@stores/notificationStore";
export function useOrderCreate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: createOrder,
        onSuccess: (newOrder) => {
            // Update the cache
            queryClient.setQueryData(["orders"], (oldData = []) => {
                return [...oldData, newOrder];
            });
            queryClient.invalidateQueries({ queryKey: ["financial"] });
            addNotification("Order created successfully.", "success");
        },
        onError: () => {
            addNotification("Failed to create order.", "error");
        },
    });
}
