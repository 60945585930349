import { ArrowRightAlt, CalendarToday, EventAvailable, Person, } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { SelectedProject } from "../Projects";
import dayjs from "dayjs";
import StatusButton from "./StatusButton";
import ProjectFinancial from "./ProjectFinancial";
import { calculateProjectFinancials } from "../utils/calculateProjectFinancials";
import { useComponentsData } from "@features/inventory/hooks/useComponentsData";
import { useFinancialsData } from "@features/financial/hooks/useFinancialsData";
import CustomButton from "src/components/CustomButton";
const SelectedProjectHeader = () => {
    const project = React.useContext(SelectedProject);
    if (!project)
        return null;
    const { data: components } = useComponentsData();
    const { data: financial } = useFinancialsData();
    const [open, setOpen] = React.useState(false);
    const handleFinancialClick = () => {
        setOpen(true);
    };
    // Use the utility function
    const { balance, projectPayments } = calculateProjectFinancials(project, financial || []);
    // Format balance as currency
    const formattedBalance = balance.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
    if (!components)
        return null;
    const hasPendingPayments = projectPayments.some((payment) => !payment.paid);
    return (React.createElement(Box, { sx: { display: "flex", alignItems: "center", gap: 1, width: "100%" } },
        React.createElement(Typography, { variant: "h5", sx: { mr: 4 } }, project.name),
        React.createElement(Person, { sx: { mr: 1 } }),
        React.createElement(Typography, { variant: "body1" },
            project.customer?.firstName,
            " ",
            project.customer?.lastName),
        React.createElement(CalendarToday, { sx: { ml: 4, mr: 1 } }),
        React.createElement(Typography, { variant: "body1" }, dayjs(project.creationDate).format("MM/DD/YYYY")),
        React.createElement(ArrowRightAlt, { sx: { ml: 1, mr: 1 } }),
        React.createElement(EventAvailable, { sx: { mr: 1 } }),
        React.createElement(Typography, { variant: "body1" }, dayjs(project.dueDate).format("MM/DD/YYYY")),
        React.createElement(CustomButton, { sx: { marginLeft: "auto" }, color: hasPendingPayments ? "warning" : "success", tooltip: hasPendingPayments ? "Pending Payments" : "None", variant: "contained", onClick: handleFinancialClick, label: `Balance: ${formattedBalance}` }),
        React.createElement(StatusButton, null),
        React.createElement(ProjectFinancial, { open: open, setOpen: setOpen })));
};
export default SelectedProjectHeader;
