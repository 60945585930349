import React from "react";
import { useShallow } from "zustand/react/shallow";
import { useComponentCreateStore } from "../store/createComponentStore";
import { TextField } from "@mui/material";
import { DisabledContext, NodeContext } from "../ComponentNode";
const selector = (state) => ({
    onNodeChange: state.onNodeChange,
});
const Code = () => {
    const { onNodeChange } = useComponentCreateStore(useShallow(selector));
    const { data, id } = React.useContext(NodeContext);
    const disabled = React.useContext(DisabledContext);
    const onChange = React.useCallback((evt) => {
        onNodeChange(id, { field: "code", value: evt.target.value });
    }, [id]);
    return (React.createElement(TextField, { label: "Code", value: data.code, onChange: onChange, disabled: disabled, id: `code-${id}`, sx: { mt: 1 }, fullWidth: true, size: "small" }));
};
export default Code;
