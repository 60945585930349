import { Box, Button, ClickAwayListener, Collapse, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, } from "@mui/material";
import React from "react";
import { useComponentCreateStore } from "../store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
import { CustomIconButton } from "src/components/CustomIconButtom";
import { AltRoute, CopyAll } from "@mui/icons-material";
import { Conditions } from "./Conditions";
import { DisabledContext, NodeContext } from "../ComponentNode";
const selector = (state) => ({
    onNodeChange: state.onNodeChange,
    pasteParameter: state.pasteParameter,
});
const Parameter = ({ name }) => {
    const { onNodeChange, pasteParameter } = useComponentCreateStore(useShallow(selector));
    const { data, id } = React.useContext(NodeContext);
    const disabled = React.useContext(DisabledContext);
    const [focused, setFocused] = React.useState(false);
    const parameter = useComponentCreateStore(useShallow((state) => state.getParameter(id, name)));
    if (!parameter)
        return null;
    const focus = () => {
        if (data.isOption)
            return;
        setFocused(true);
    };
    const unfocus = () => setFocused(false);
    const handleNameClick = () => {
        onNodeChange(id, {
            field: disabled ? "parametersOverride" : "parameters",
            value: {
                ...parameter,
                variable: parameter.variable ? "" : `${data.name}${parameter.name}`,
            },
        });
    };
    const onChange = (value) => {
        if (isConditional) {
            value = `${parameter.value.split("!!")[0]}!!${value}`;
        }
        onNodeChange(id, {
            field: disabled ? "parametersOverride" : "parameters",
            value: { ...parameter, value: value },
        });
    };
    const handleConditionClick = () => {
        if (parameter.value.includes("!!")) {
            onNodeChange(id, {
                field: disabled ? "parametersOverride" : "parameters",
                value: { ...parameter, value: parameter.value.split("!!")[1] },
            });
        }
        else {
            onChange(`!!${parameter.value}`);
        }
    };
    const onPasteClick = () => {
        pasteParameter(id, parameter);
    };
    const isFunction = !isNumeric(parameter.value);
    const isConditional = parameter.value.includes("!!");
    const expanded = isFunction && focused;
    return (React.createElement(React.Fragment, null,
        React.createElement(ClickAwayListener, { onClickAway: unfocus },
            React.createElement(Paper, { sx: {
                    mt: 1,
                    ml: focused && (parameter.value.length > 18 || isConditional)
                        ? -12
                        : 0, // Adjust based on overflow threshold
                    mr: focused && (parameter.value.length > 18 || isConditional)
                        ? -12
                        : 0,
                    p: focused && isConditional ? 1 : 0,
                    transition: "all 0.2s",
                }, elevation: expanded ? 3 : 1, onClick: focus },
                React.createElement(Collapse, { in: focused && isConditional, unmountOnExit: true },
                    React.createElement(Conditions, { parameter: parameter, id: id })),
                React.createElement(FormControl, { fullWidth: true, variant: "outlined", size: "small", disabled: data?.isOption, id: parameter.name + id },
                    isConditional && focused && React.createElement(InputLabel, null, "Default Value"),
                    React.createElement(OutlinedInput, { value: isConditional ? parameter.value.split("!!")[1] : parameter.value, multiline: data?.isOption, sx: {
                            border: 1,
                            borderColor: parameter.isOverride ? "#f0f0f0" : "transparent",
                        }, onChange: (evt) => onChange(evt.target.value), label: isConditional && focused ? "Default Value" : null, placeholder: data?.isOption
                            ? "Variable represents value selected choice's variable."
                            : "", startAdornment: React.createElement(Box, { sx: { display: "flex" } },
                            React.createElement(Button, { size: "small", onClick: handleNameClick, sx: {
                                    ml: -1,
                                    pl: 1,
                                    pr: 1,
                                    pt: 0.2,
                                    pb: 0.2,
                                    mr: 0.5,
                                    color: parameter.variable ? "default" : "text.primary",
                                    fontSize: 15,
                                    boxSizing: "border-box",
                                    border: "1px solid transparent",
                                } }, parameter.name),
                            React.createElement(Collapse, { in: focused || isConditional, unmountOnExit: true, orientation: "horizontal" },
                                React.createElement(CustomIconButton, { onClick: handleConditionClick, size: "small", sx: {
                                        ml: -1,
                                        // pl: 1,
                                        // pr: 1,
                                        pt: 0.2,
                                        pb: 0.2,
                                        mr: 0.5,
                                        color: parameter.value.includes("!!")
                                            ? "default"
                                            : "text.primary",
                                        // fontSize: 15,
                                        boxSizing: "border-box",
                                        border: "1px solid transparent",
                                    }, icon: React.createElement(AltRoute, null) }))), endAdornment: React.createElement(InputAdornment, { position: "end" }, focused && (React.createElement(IconButton, { size: "small", onClick: onPasteClick, sx: { p: 1, mr: -1 } },
                            React.createElement(CopyAll, null)))) }))))));
};
export default Parameter;
function isNumeric(str) {
    if (!str)
        return true;
    if (typeof str == "number")
        return true; // we only process strings!
    if (typeof str != "string")
        return false; // we only process strings!
    let isNum = !isNaN(Number(str));
    return isNum; // ...and ensure strings of whitespace fail
}
