import useAuthStore from "../../../stores/authStore";
import { useQuery } from "@tanstack/react-query";
import { getPeople } from "@api/personApi";
export const usePeopleData = () => {
    const { user } = useAuthStore();
    if (!user) {
        throw new Error("No user!");
    }
    return useQuery({
        queryKey: ["people"],
        queryFn: getPeople,
        staleTime: 1800 * 1000,
    });
};
