import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
const filter = createFilterOptions();
export default function CustomAutocomplete({ options, label, onInputChange, ...props }) {
    const [value, setValue] = React.useState(props.value);
    console.log(value);
    React.useEffect(() => {
        if (onInputChange) {
            onInputChange(value);
        }
    }, [value]);
    return (React.createElement(Autocomplete, { ...props, value: value, onChange: (event, newValue) => {
            if (typeof newValue === "string") {
                setValue({
                    title: newValue,
                });
            }
            else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setValue({
                    title: newValue.inputValue,
                });
            }
            else {
                setValue(newValue);
            }
        }, filterOptions: (options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.title);
            if (inputValue !== "" && !isExisting) {
                filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                });
            }
            return filtered;
        }, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, options: options, getOptionLabel: (option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
                return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
                return option.inputValue;
            }
            // Regular option
            return option.title;
        }, renderOption: (props, option) => {
            const { key, ...optionProps } = props;
            return (React.createElement("li", { key: key, ...optionProps }, option.title));
        }, freeSolo: true, renderInput: (params) => React.createElement(TextField, { ...params, label: label }) }));
}
const parseInitialValue = (value, options) => {
    const option = options.find((option) => option.value === value);
    if (!option)
        return "";
    return option.title;
};
