import axios from "axios";
import api from "./api";
export const login = async (pack) => {
    const response = await api.post(`/api/user/login`, pack);
    console.log(response);
    return response.data;
};
export const logout = async () => {
    await api.post(`/api/user/logout`);
};
export const register = async (pack) => {
    try {
        const response = await api.post(`/api/user/register`, pack);
        return response?.data;
    }
    catch (error) {
        console.log(error);
        if (axios.isAxiosError(error) && error.response) {
            // If error is from the server (4xx or 5xx response)
            throw new Error(error.response.data.message ||
                "An server error occurred while registering.");
        }
        else {
            // If error is a network error or any other error
            throw new Error("A network error occurred while registering.");
        }
    }
};
export const editUser = async (pack) => {
    const response = await api.patch(`/api/user`, pack);
    return response.data;
};
export const confirmEmail = async (token) => {
    try {
        const response = await api.get(`/api/user/confirm?token=${token}`);
        return { email: response.data.email };
    }
    catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            // If error is from the server (4xx or 5xx response)
            throw new Error(error.response.data.message ||
                "An server error occurred while confirming email.");
        }
        else {
            // If error is a network error or any other error
            throw new Error("A network error occurred while confirming email.");
        }
    }
};
export const resendEmail = async (pack) => {
    await api.post(`/api/user/resend`, pack);
};
export const tokenLogin = async () => {
    const response = await api.post(`/api/user/token-login/`);
    console.log(response);
    return response.data;
};
export const checkSubscription = async () => {
    const response = await api.get(`/api/user/check-subscription`);
    return response.data;
};
