import { AttachMoney, CalendarMonth, Engineering, Folder, Home, Inventory, People, Receipt, TextSnippet, Warehouse, Work, } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { useWindowSize } from "react-use";
import useGeneralStore, { Tabs } from "src/stores/generalStore";
import TabButton from "./TabButton";
const getIcon = (tab) => {
    switch (tab) {
        case "Dashboard":
            return React.createElement(Home, null);
        case "Calendar":
            return React.createElement(CalendarMonth, null);
        case "Customers":
            return React.createElement(People, null);
        case "Projects":
            return React.createElement(Work, null);
        case "Suppliers":
            return React.createElement(Warehouse, null);
        case "People":
            return React.createElement(Engineering, null);
        case "Inventory":
            return React.createElement(Inventory, null);
        case "Financials":
            return React.createElement(AttachMoney, null);
        case "Files":
            return React.createElement(Folder, null);
        case "Templates":
            return React.createElement(TextSnippet, null);
        case "Orders":
            return React.createElement(Receipt, null);
        default:
            return React.createElement(Home, null);
    }
};
export default function AppBarTabs() {
    const { tab, setTab } = useGeneralStore();
    // if window width is less than 800px, use icons only
    const { width } = useWindowSize();
    const isSmallScreen = width < 1365;
    return (React.createElement(Box, { sx: {
            display: "flex",
            gap: 2,
            alignItems: "center",
            width: "100%",
        } }, Object.values(Tabs).map((t) => (React.createElement(TabButton, { title: t, key: t, icon: getIcon(t), iconOnly: t !== tab && isSmallScreen, selected: t === tab, onClick: () => setTab(t) })))));
}
