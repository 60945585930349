import React from "react";
import { showCustomerDialog } from "../Dialogs/DialogManager";
import CustomDataGrid, { Keyword } from "../core/CustomDataGrid";
import { usePeopleData } from "./hooks/usePeopleData";
import { PeopleFields } from "@mytypes/personTypes";
import { Avatar, Box } from "@mui/material";
export default function People() {
    const { isLoading, data } = usePeopleData();
    const columns = Object.entries(PeopleFields).map(([field, label]) => ({
        field,
        headerName: label,
        width: 200,
        flex: field === "avatar" ? 0.2 : 1,
        renderCell: (params) => {
            if (field === "avatar") {
                return (React.createElement(Box, { sx: { display: "flex", alignItems: "center", height: "100%" } },
                    React.createElement(Avatar, { src: params.value, alt: "avatar" })));
            }
            return params.value;
        },
    }));
    //   const onRowClick = async ({ row }: { row: Person }) => {
    //     await showCustomerDialog(row);
    //   };
    const onAddClick = async () => {
        const result = await showCustomerDialog();
    };
    return (React.createElement(Box, null,
        React.createElement(CustomDataGrid, { rows: data || [], autoHeight: true, loading: isLoading, 
            // fields={CustomerFields}
            columns: columns, keyword: Keyword.Person, 
            //   onRowClick={onRowClick}
            onAddClick: onAddClick })));
}
