import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { SelectedProject } from "../Projects";
import { useProjectUpdate } from "../hooks/useProjectUpdate";
import { KeyboardArrowDown } from "@mui/icons-material";
export const options = [
    {
        value: "Not Started",
        label: "Not Started",
        color: "warning",
    },
    {
        value: "In Progress",
        label: "In Progress",
        color: "info",
    },
    {
        value: "On Hold",
        label: "On Hold",
        color: "error",
    },
    {
        value: "Completed",
        label: "Completed",
        color: "success",
    },
];
const StatusButton = () => {
    const project = React.useContext(SelectedProject);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { mutate: updateProject } = useProjectUpdate();
    const onButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onSelect = (value) => {
        updateProject({ _id: project?._id, status: value });
        setAnchorEl(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { sx: { ml: 1 }, color: options.find((option) => option.value === project?.status)?.color ||
                "primary", variant: "contained", onClick: onButtonClick, endIcon: React.createElement(KeyboardArrowDown, null) }, project?.status || "Started"),
        React.createElement(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: () => setAnchorEl(null) }, options.map((option) => (React.createElement(MenuItem, { key: option.value, onClick: () => onSelect(option.value) }, option.label))))));
};
export default StatusButton;
