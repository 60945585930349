import React from "react";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
import { Chip, Stack, Tooltip } from "@mui/material";
import { getContrastYIQ } from "src/utils/colorUtils";
/**
 * CategoryFilterChips Component
 * Displays clickable chips for filtering DataGrid based on categories.
 */
const CategoryFilter = ({ selectedCategories, setSelectedCategories, }) => {
    const { data: settings } = useSettingsData();
    const categories = settings?.categories || [];
    /**
     * Toggles the selection state of a category.
     * @param {string} categoryName - The name of the category to toggle.
     */
    const handleToggle = (categoryName) => {
        setSelectedCategories((prevSelected) => {
            const isSelected = prevSelected.includes(categoryName);
            const updatedSelected = isSelected
                ? prevSelected.filter((name) => name !== categoryName)
                : [categoryName];
            return updatedSelected;
        });
    };
    /**
     * Clears all selected category filters.
     */
    const handleClearAll = () => {
        setSelectedCategories([]);
    };
    if (categories.length === 0) {
        return null; // Or display a message indicating no categories are available
    }
    console.log("selected categories", selectedCategories);
    return (React.createElement(Stack, { direction: "row", spacing: 1, alignItems: "center", sx: { mb: 2, flexWrap: "wrap" } }, categories.map((category) => (React.createElement(Tooltip, { key: category.name, title: category.name },
        React.createElement(Chip, { label: category.name, onClick: () => handleToggle(category.name), variant: selectedCategories.includes(category.name) ? "filled" : "outlined", sx: {
                borderRadius: 1,
                borderWidth: 2,
                transition: "background-color 0.3s",
                backgroundColor: selectedCategories.includes(category.name)
                    ? category.color
                    : "transparent",
                color: selectedCategories.includes(category.name)
                    ? getContrastYIQ(category.color)
                    : (theme) => theme.palette.text.primary,
                borderColor: category.color,
                "&:hover": {
                    backgroundColor: selectedCategories.includes(category.name)
                        ? category.color
                        : category.color + "33", // Add transparency on hover for outlined chips
                },
                cursor: "pointer",
            } }))))));
};
export default CategoryFilter;
