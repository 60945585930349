import useAuthStore from "@stores/authStore";
import { useQuery } from "@tanstack/react-query";
import { getSettings } from "@api/settingsApi";
export const useSettingsData = () => {
    const { user } = useAuthStore();
    if (!user) {
        throw new Error("No user!");
    }
    return useQuery({
        queryKey: ["settings"],
        queryFn: getSettings,
        staleTime: Infinity,
    });
};
