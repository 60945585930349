import React from "react";
import { useFinancialsData } from "./hooks/useFinancialsData";
import CustomDataGrid, { Keyword } from "../core/CustomDataGrid";
import { showFinancialDialog } from "../Dialogs/DialogManager";
import { useProjectsData } from "../project/hooks/useProjectsData";
import makeColumns from "./utils/makeColumns";
const Financials = () => {
    const { data: financials, isLoading } = useFinancialsData();
    const { data: projects } = useProjectsData();
    const files = [];
    const onRowClick = async ({ row }) => {
        await showFinancialDialog(row);
    };
    const onAddClick = async () => {
        const result = await showFinancialDialog();
    };
    if (!financials)
        return null;
    const columns = makeColumns(projects || []);
    const rows = financials
        .sort((a, b) => (a.date > b.date ? -1 : 1))
        .map((f) => f.file
        ? {
            ...f,
            file: files?.find((file) => file._id === f.file)?.s3Key,
        }
        : f) || [];
    return (React.createElement("div", null,
        React.createElement(CustomDataGrid
        // autoHeight
        , { 
            // autoHeight
            rows: rows || [], loading: isLoading, columns: columns, keyword: Keyword.Financial, onAddClick: onAddClick, onRowClick: onRowClick, sx: { maxHeight: "calc(100vh - 100px)" } })));
};
export default Financials;
