export const isSubnode = (parent, subnode) => {
    const parentParts = parent.id.split("_");
    const subnodeParts = subnode.id.split("_");
    console.log(parentParts, subnodeParts);
    return (
    // subnodeParts.length > parentParts.length &&
    parentParts.every((value, index) => subnodeParts[index] === value));
};
export const isSubedge = (parent, edge) => {
    const parentParts = parent.id.split("_");
    const edgeSourceParts = edge.source.split("_");
    return (
    // edgeSourceParts.length > parentParts.length &&
    parentParts.every((value, index) => edgeSourceParts[index] === value));
};
export const getAllStreamIds = (id, components) => {
    return components
        .filter((component) => component.flowData.includes(id) && component._id !== id)
        .map((component) => component._id);
};
