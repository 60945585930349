import { CustomerFields } from "@mytypes/customerTypes";
import React from "react";
import { showCustomerDialog } from "../Dialogs/DialogManager";
import CustomDataGrid, { Keyword } from "../core/CustomDataGrid";
import { useCustomersData } from "./hooks/useCustomersData";
export default function CustomersView() {
    const { isLoading, data } = useCustomersData();
    const columns = Object.entries(CustomerFields).map(([field, label]) => ({
        field,
        headerName: label,
        width: 200,
    }));
    const onRowClick = async ({ row }) => {
        await showCustomerDialog(row);
    };
    const onAddClick = async () => {
        const result = await showCustomerDialog();
    };
    return (React.createElement(CustomDataGrid, { rows: data || [], loading: isLoading, columns: columns, keyword: Keyword.Customer, onRowClick: onRowClick, onAddClick: onAddClick }));
}
