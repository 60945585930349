// productStoreUtils.ts
import { isDescendant, idStartsWith } from "./productStoreHelpers";
import _ from "lodash";
import { getParameterList } from "./parameterCalculation";
/**
 * Utility function for handling option selection on a product.
 * @param product - The current product.
 * @param components - The list of all components.
 * @param globalParameters - The global parameters.
 * @param option - The option component ID.
 * @param choice - The choice selected for the option.
 * @returns The updated product with the new option selection.
 */
export function onOptionSelectUtil(product, components, globalParameters, option, choice, existingParameters) {
    // Remove subcomponents related to the previously selected option
    let subcomponents = _.cloneDeep(product.subcomponents).filter((c) => !isDescendant(option, c.id));
    console.log({ product, option, choice, subcomponents });
    // Find the index of the option component and update its selection
    const indexOfOption = subcomponents.findIndex((c) => c.id === option);
    subcomponents[indexOfOption].selection = choice;
    subcomponents[indexOfOption].parameters.forEach((p) => {
        p.variableFor = choice;
    });
    // Get the subcomponents related to the new choice
    let subcomponentsToAdd = components.filter((c) => idStartsWith(choice, c.id));
    // Separate out options and subcomponents without options
    let options = subcomponentsToAdd.filter((c) => c.isOption);
    let subcomponentsWithoutOptionChoices = subcomponentsToAdd.filter((c) => !options.some((o) => isDescendant(o.id, c.id)));
    // Set the new subcomponents and update the parameters list
    let subcomponentsToSet = subcomponents.concat(subcomponentsWithoutOptionChoices);
    const updatedProduct = {
        ...product,
        subcomponents: subcomponentsToSet,
        allParameters: getParameterList({
            ...product,
            subcomponents: subcomponentsToSet,
        }, globalParameters, existingParameters),
    };
    if (updatedProduct.priceModifier) {
        updatedProduct.price = calculatePrice(updatedProduct);
    }
    return updatedProduct;
}
/**
 * Utility function for handling parameter selection on a product.
 * @param product - The current product.
 * @param globalParameters - The global parameters.
 * @param parameter - The parameter being updated.
 * @param value - The new value for the parameter.
 * @returns The updated product with recalculated parameters.
 */
export function onParameterSelectUtil(product, globalParameters, parameter, value, existingParameters) {
    const startTime = performance.now();
    let parameters = product.allParameters.map((p) => p.id === parameter.id && p.name === parameter.name
        ? { ...p, value: value, originalValue: null }
        : { ...p });
    console.log({ parameters, parameter });
    let allParameters = getParameterList({
        ...product,
        allParameters: parameters,
    }, globalParameters, parameters);
    console.log(`PERF: Got parameters in ${performance.now() - startTime}ms`);
    const updatedProduct = {
        ...product,
        allParameters: allParameters,
    };
    if (updatedProduct.priceModifier) {
        updatedProduct.price = calculatePrice(updatedProduct);
        console.log(`PERF: Calculated price in ${performance.now() - startTime}ms`);
    }
    return updatedProduct;
}
export const calculatePrice = (product) => {
    let price = 0;
    product.subcomponents.forEach((c) => {
        let qty = product.allParameters.find((p) => p.id === c.id && p.name === "qty");
        if (!qty) {
            return;
        }
        let cost = c.cost;
        if (c.suppliers.length > 0) {
            let supplier = c.suppliers.find((s) => s.default);
            if (supplier) {
                cost = supplier.cost;
            }
        }
        if (qty.calculatedValue !== "error") {
            price += c.cost * qty.calculatedValue;
        }
        // else {
        //   price += c.cost;
        // }
    });
    return (price * product.priceModifier).toFixed(2);
};
