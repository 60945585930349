import { useMutation, useQueryClient } from "@tanstack/react-query";
import useNotificationStore from "../../../stores/notificationStore";
import { createSupplier } from "@api/supplierApi";
export function useSupplierCreate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: createSupplier,
        onSuccess: (newSupplier) => {
            queryClient.setQueryData(["suppliers"], (oldData) => {
                return [...oldData, newSupplier];
            });
            addNotification("Supplier created.", "success");
        },
        onError: () => {
            addNotification("Could not create supplier.", "error");
        },
    });
}
