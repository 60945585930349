// App.js
import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import Notifications from "../components/Notifications";
import useAuthStore from "../stores/authStore";
import Loading from "./Loading";
import Views from "./Views";
import useThemeStore from "src/features/theme/themeStore";
import { getTheme } from "src/features/theme/themeUtils";
const apiUrl = import.meta.env.VITE_TEST;
console.log(apiUrl);
export const queryClient = new QueryClient();
const App = () => {
    const { user, loggingIn, initialize } = useAuthStore();
    const { mode, primary, secondary, initializeTheme } = useThemeStore();
    const navigate = useNavigate();
    // console.log(process.env.APP_URL);
    useEffect(() => {
        // Initialize user if not already initialized
        if (!user) {
            initialize();
        }
    }, [user, initialize]);
    useEffect(() => {
        // Initialize theme from localStorage
        const storedTheme = localStorage.getItem("theme");
        if (storedTheme) {
            const parsed = JSON.parse(storedTheme);
            initializeTheme(parsed);
        }
    }, [initializeTheme]);
    useEffect(() => {
        // Update localStorage when theme changes
        const currentTheme = { mode, primary, secondary };
        const storedThemeJSON = localStorage.getItem("theme");
        const storedTheme = storedThemeJSON ? JSON.parse(storedThemeJSON) : null;
        // Compare the current theme with the stored theme
        if (!storedTheme ||
            storedTheme.mode !== mode ||
            storedTheme.primary !== primary ||
            storedTheme.secondary !== secondary) {
            // Update localStorage
            localStorage.setItem("theme", JSON.stringify(currentTheme));
        }
    }, [mode, primary, secondary]);
    useEffect(() => {
        let link = document.getElementById("syncfusion-theme-link");
        if (!link) {
            link = document.createElement("link");
            link.id = "syncfusion-theme-link";
            link.rel = "stylesheet";
            document.head.appendChild(link);
        }
        link.href =
            theme.palette.mode === "dark" ? "/sf-styles-dark.css" : "/sf-styles.css";
    }, [mode]);
    const theme = useMemo(() => {
        return getTheme(mode, primary, secondary);
    }, [mode, primary, secondary]);
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(ErrorBoundary, null,
            React.createElement(ThemeProvider, { theme: theme },
                React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                    React.createElement(CssBaseline, { enableColorScheme: true }),
                    React.createElement(Notifications, null),
                    React.createElement(Container, { maxWidth: "xl" }, loggingIn ? React.createElement(Loading, null) : React.createElement(Views, null)))))));
};
export default App;
