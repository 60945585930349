// useProductsStore.ts
import { create } from "zustand";
import { isDescendant } from "../product/productStoreHelpers";
import { onOptionSelectUtil, onParameterSelectUtil, } from "../product/productStoreUtils";
import { flowFromComponent } from "../componentCreate/store/helpers";
import { getParametersOverride } from "../componentCreate/store/storeUtils";
import { getQty } from "./components/ProductAddDialog";
import { getParameterList } from "@features/product/parameterCalculation";
function memoize(fn) {
    const cache = new Map();
    return function (...args) {
        const key = JSON.stringify(args); // Be careful with complex objects
        if (cache.has(key)) {
            return cache.get(key);
        }
        const result = fn.apply(this, args);
        cache.set(key, result);
        return result;
    };
}
const memoizedGetParametersOverride = memoize(getParametersOverride);
const memoizedGetQty = memoize(getQty);
const useProductsStore = create((set, get) => ({
    products: [],
    packedProducts: [],
    selectedProductIds: [],
    setSelectedProductIds: (ids) => {
        set({ selectedProductIds: ids });
    },
    globalParameters: [],
    components: [],
    setGlobalParameters: (parameters) => {
        set({ globalParameters: parameters });
    },
    addProduct: (product, components) => {
        const newProduct = initializeProduct(product, components, get().globalParameters);
        set((state) => ({
            products: [...state.products, newProduct],
            components: [
                ...state.components,
                ...components.map((c) => ({ ...c, productId: newProduct.id })),
            ],
        }));
    },
    removeProducts: (productIds) => {
        set((state) => ({
            products: state.products.filter((p) => !productIds.includes(p.id)),
            components: state.components.filter((c) => !productIds.includes(c.productId)),
        }));
    },
    onOptionSelect: (productId, optionId, choiceId) => {
        const selectedProductIds = get().selectedProductIds;
        set((state) => ({
            products: state.products.map((product) => {
                const isSelected = selectedProductIds.length > 0
                    ? selectedProductIds.includes(product.id)
                    : product.id === productId;
                if (isSelected) {
                    // Find the original product and option subcomponent
                    const originalProduct = state.products.find((p) => p.id === productId);
                    const originalOptionSubcomponent = originalProduct?.subcomponents.find((sc) => sc.id === optionId);
                    if (originalOptionSubcomponent) {
                        // Find the corresponding option in the current product
                        const currentOptionSubcomponent = product.subcomponents.find((sc) => sc.name === originalOptionSubcomponent.name);
                        if (currentOptionSubcomponent) {
                            const adjustedOptionId = currentOptionSubcomponent.id;
                            const choiceSuffix = choiceId.split("_").at(-1);
                            const adjustedChoiceId = `${currentOptionSubcomponent.id}_${choiceSuffix}`;
                            return onOptionSelectUtil(product, state.components.filter((c) => c.productId === product.id), state.globalParameters, adjustedOptionId, adjustedChoiceId, product.allParameters);
                        }
                    }
                }
                return product;
            }),
        }));
    },
    onParameterSelect: (productId, parameter, value) => {
        set((state) => ({
            products: state.products.map((product) => product.id === productId
                ? onParameterSelectUtil(product, state.globalParameters, parameter, value)
                : product),
        }));
    },
    onZoneSelect: (productId, zone) => {
        const selectedProductIds = get().selectedProductIds;
        set((state) => ({
            products: state.products.map((product) => {
                const isSelected = selectedProductIds.length > 0
                    ? selectedProductIds.includes(product.id)
                    : product.id === productId;
                console.log("selected product ids", selectedProductIds);
                console.log("product id", product.id);
                if (isSelected) {
                    return {
                        ...product,
                        zone: zone,
                    };
                }
                return product;
            }),
        }));
    },
    unpackProducts: (packedData, componentsData) => {
        const startTime = performance.now();
        const { products: packedProducts } = packedData;
        const { globalParameters } = get();
        // Create a Map for componentsData
        const componentsDataMap = new Map();
        componentsData.forEach((component) => {
            componentsDataMap.set(component._id, component);
        });
        // Cache for flowFromComponent results
        const flowCache = new Map();
        let productsToAdd = [];
        let componentsToAdd = [];
        let failedUnpacks = [];
        for (const packedProduct of packedProducts) {
            let prod;
            let comps = [];
            let incomplete = false;
            try {
                // Get base product from the map
                const baseProduct = componentsDataMap.get(packedProduct.componentId);
                if (!baseProduct) {
                    throw new Error(`Component with id ${packedProduct.componentId} not found`);
                }
                // Use cached flowFromComponent result if available
                let nodes;
                if (flowCache.has(baseProduct._id)) {
                    nodes = flowCache.get(baseProduct._id);
                }
                else {
                    const flowResult = flowFromComponent(baseProduct, componentsData);
                    nodes = flowResult.nodes;
                    flowCache.set(baseProduct._id, nodes);
                }
                // Use memoized getParametersOverride
                const overrides = memoizedGetParametersOverride(nodes);
                console.log(`PERF: Got parameters override for product ${packedProduct.id} in ${performance.now() - startTime} ms`);
                // Create subcomponents (comps)
                comps = nodes
                    .map((node) => {
                    const componentData = node.data;
                    const image = componentsDataMap.get(componentData._id)?.image;
                    return {
                        ...componentData,
                        id: node.id,
                        image: image,
                        qty: {
                            ...memoizedGetQty(node.id, nodes, overrides),
                            id: node.id,
                        },
                        productId: packedProduct.id,
                    };
                })
                    .filter((c) => c.id.split("_").length > 1);
                // Create the main product object (prod)
                prod = {
                    ...nodes[0].data,
                    id: packedProduct.id,
                    zone: packedProduct.zone,
                    price: packedProduct.price,
                    cost: packedProduct.cost,
                };
                prod = initializeProduct(prod, comps, globalParameters, packedProduct);
                console.log(`PERF: Initialized product ${packedProduct.id} in ${performance.now() - startTime} ms`);
                // // Apply parameters to the product
                // for (const param of packedProduct.parameters) {
                //   if (param.options) {
                //     prod = onParameterSelectUtil(
                //       prod,
                //       globalParameters,
                //       param,
                //       param.value,
                //       prod.allParameters
                //     );
                //   }
                // }
                // console.log(
                //   `PERF: Applied parameters to product ${packedProduct.id} in ${
                //     performance.now() - startTime
                //   } ms`
                // );
                // Apply option selections
                for (const option of packedProduct.options) {
                    prod = onOptionSelectUtil(prod, comps, globalParameters, option.id, option.choice, prod.allParameters);
                }
                console.log(`PERF: Applied options to product ${packedProduct.id} in ${performance.now() - startTime} ms`);
            }
            catch (error) {
                console.error(`Failed to unpack product with id ${packedProduct.id}: ${error.message}`);
                failedUnpacks.push({
                    productId: packedProduct.id,
                    reason: error.message,
                });
                // Create a partial product
                incomplete = true;
                prod = {
                    ...packedProduct,
                    id: packedProduct.id,
                    incomplete: true, // Flag the product as incomplete
                };
                comps = []; // No components available
            }
            productsToAdd.push(prod);
            componentsToAdd.push(comps);
        }
        if (failedUnpacks.length > 0) {
            console.warn(`${failedUnpacks.length} products failed to unpack.`, failedUnpacks);
        }
        set((state) => ({
            products: [...state.products, ...productsToAdd],
            components: [...state.components, ...componentsToAdd.flat()],
        }));
        const endTime = performance.now();
        console.log("PERF: Unpacked products in", endTime - startTime, "ms");
    },
    clear: () => {
        set({ products: [], components: [] });
    },
    // Other methods...
}));
export default useProductsStore;
const initializeProduct = (product, components, globalParameters, packedProduct) => {
    if (product.incomplete) {
        // For incomplete products, return as is
        return product;
    }
    const subcomponents = components;
    const options = components.filter((c) => c.isOption);
    const subcomponentsWithoutOptionChoices = subcomponents.filter((c) => !options.some((o) => isDescendant(o.id, c.id)));
    let newProduct = {
        ...product,
        subcomponents: subcomponentsWithoutOptionChoices,
        allParameters: getParameterList({
            ...product,
            subcomponents: subcomponentsWithoutOptionChoices,
        }, globalParameters, packedProduct?.parameters),
    };
    console.log("Successfully initialized product", newProduct);
    return newProduct;
};
