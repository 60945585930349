import React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import { useData } from "../hooks";
import { SelectProductsButton } from "./SelectProductsButton";
import { SelectComponentsButton } from "./SelectComponentsButton";
const GenerateToolbar = ({ selection, onSelectionChange, filename, setFilename, }) => {
    const { customers, projects, components, suppliers, templates } = useData();
    const handleProjectSelect = (projectId) => {
        const project = projects.find((p) => p._id === projectId);
        const customer = customers.find((c) => c._id === project?.customer._id);
        const products = project?.products.map((p) => ({
            ...p,
            selected: true,
        }));
        const components = project?.components.map((c) => ({
            ...c,
            selected: true,
            total: 0,
            qty: c.parameters.find((p) => p.name === "quantity")?.calculatedValue || 1,
        }));
        onSelectionChange({ project, customer, products, components });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", alignItems: "center", gap: 2, mb: 2, sx: { pt: 1 }, flexWrap: "wrap" },
            React.createElement(FormControl, { style: { minWidth: 200 }, size: "small" },
                React.createElement(InputLabel, null, "Template"),
                React.createElement(Select, { value: selection.template?.id || "", onChange: (e) => onSelectionChange({
                        template: templates.find((t) => t.id === e.target.value),
                    }), label: "Template" }, templates.map((t) => (React.createElement(MenuItem, { key: t.id, value: t.id }, t.name))))),
            React.createElement(FormControl, { style: { minWidth: 200 }, size: "small" },
                React.createElement(InputLabel, null, "Project"),
                React.createElement(Select, { label: "Project", value: selection.project?._id || "", onChange: (e) => handleProjectSelect(e.target.value) }, projects?.map((p) => (React.createElement(MenuItem, { key: p._id, value: p._id }, p.name))))),
            React.createElement(FormControl, { style: { minWidth: 200 }, size: "small" },
                React.createElement(InputLabel, null, "Customer"),
                React.createElement(Select, { label: "Customer", value: selection.customer?._id || "", onChange: (e) => onSelectionChange({
                        customer: customers.find((c) => c._id === e.target.value),
                    }) }, customers?.map((c) => (React.createElement(MenuItem, { key: c._id, value: c._id },
                    c.firstName,
                    " ",
                    c.lastName))))),
            React.createElement(FormControl, { style: { minWidth: 200 }, size: "small" },
                React.createElement(InputLabel, null, "Supplier"),
                React.createElement(Select, { label: "Supplier", value: selection.supplier?._id || "", onChange: (e) => onSelectionChange({
                        supplier: suppliers.find((s) => s._id === e.target.value),
                    }) }, suppliers?.map((s) => (React.createElement(MenuItem, { key: s._id, value: s._id }, s.name))))),
            React.createElement(TextField, { label: "PDF File Name", size: "small", value: filename, onChange: (e) => setFilename(e.target.value), style: { minWidth: 200 } }),
            React.createElement(SelectProductsButton, { products: selection.products, onSelectionChange: (updatedProducts) => onSelectionChange({ products: updatedProducts }) }),
            React.createElement(SelectComponentsButton, { components: selection.components, onSelectionChange: (updatedComponents) => onSelectionChange({ components: updatedComponents }) }))));
};
export default GenerateToolbar;
