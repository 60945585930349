import React from "react";
import { NumericFormat } from "react-number-format";
import { TextField } from "@mui/material";
const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (React.createElement(NumericFormat, { ...other, getInputRef: inputRef, onValueChange: (values) => onChange({
            target: {
                value: values.value,
            },
        }), prefix: "$", thousandSeparator: true, decimalScale: 2, fixedDecimalScale: true }));
};
const CurrencyTextField = (props) => {
    return (React.createElement(TextField, { ...props, InputProps: {
            inputComponent: NumberFormatCustom,
        } }));
};
export default CurrencyTextField;
