import { useMutation } from "@tanstack/react-query";
import useNotificationStore from "../../../stores/notificationStore";
import { createPaymentLink } from "@api/paymentApi";
export function usePaymentLink(onSuccess) {
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: createPaymentLink,
        onSuccess: (data) => {
            addNotification("Payment link created.", "success");
            if (onSuccess) {
                onSuccess(data);
            }
        },
        onError: () => {
            addNotification("Could not create payment link.", "error");
        },
    });
}
