import React from "react";
import { useComponentCreateStore } from "../store/createComponentStore";
import { Box, TextField, IconButton, InputAdornment, Tooltip, Typography, Radio, FormControlLabel, FormControl, Collapse, Divider, } from "@mui/material";
import { NodeContext } from "../ComponentNode";
import { useSuppliersData } from "@features/supplier/hooks/useSuppliersData";
import { ContentCopy, ExpandMore, ExpandLess } from "@mui/icons-material";
const Suppliers = () => {
    const { onNodeChange } = useComponentCreateStore();
    const { data, id } = React.useContext(NodeContext);
    const { data: suppliersData } = useSuppliersData();
    const selectedSuppliers = data.suppliers || [];
    // State to control expansion of the suppliers list
    const [expanded, setExpanded] = React.useState(true);
    // Handler to toggle the expanded state
    const handleExpandClick = () => {
        setExpanded((prev) => !prev);
    };
    const handleSupplierChange = (supplierId, field, value) => {
        const updatedSuppliers = selectedSuppliers.map((s) => {
            if (s.supplier.toString() === supplierId) {
                return {
                    ...s,
                    [field]: value,
                };
            }
            return s;
        });
        onNodeChange(id, { field: "suppliers", value: updatedSuppliers });
    };
    const handleDefaultSupplierChange = (supplierId) => {
        const updatedSuppliers = selectedSuppliers.map((s) => ({
            ...s,
            default: s.supplier.toString() === supplierId,
        }));
        onNodeChange(id, { field: "suppliers", value: updatedSuppliers });
    };
    const handleCopyLink = (link) => {
        if (link) {
            navigator.clipboard.writeText(link).catch((err) => {
                console.error("Could not copy link: ", err);
            });
        }
    };
    React.useEffect(() => {
        setExpanded(true);
    }, [data.suppliers?.length]);
    return (React.createElement(Box, { sx: { mt: 1 } },
        React.createElement(Divider, { sx: { ml: -1, mr: -1 } }),
        React.createElement(Box, { onClick: handleExpandClick, sx: {
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                mt: 1,
                mb: 1,
            } },
            React.createElement(Typography, { variant: "subtitle1", sx: { flexGrow: 1 } },
                "Suppliers (",
                selectedSuppliers.length,
                ")"),
            expanded ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)),
        React.createElement(Collapse, { in: expanded },
            React.createElement(FormControl, { component: "fieldset" }, selectedSuppliers.map((supplier) => {
                const supplierData = suppliersData?.find((s) => s._id === supplier.supplier);
                return (React.createElement(Box, { key: supplier.supplier },
                    React.createElement(Box, { sx: { display: "flex", alignItems: "center" } },
                        React.createElement(FormControlLabel, { control: React.createElement(Radio, { checked: supplier.default === true, onChange: () => handleDefaultSupplierChange(supplier.supplier.toString()) }), label: supplierData?.name || "" }),
                        supplier.default && (React.createElement(Typography, { variant: "caption", sx: { ml: 1 } }, "(Default)"))),
                    React.createElement(Box, { sx: { display: "flex", gap: 1, mb: 1 } },
                        React.createElement(TextField, { label: "Cost", value: supplier.cost, onChange: (e) => handleSupplierChange(supplier.supplier.toString(), "cost", e.target.value), size: "small", fullWidth: true }),
                        React.createElement(TextField, { label: "Code", value: supplier.code, onChange: (e) => handleSupplierChange(supplier.supplier.toString(), "code", e.target.value), size: "small", fullWidth: true })),
                    React.createElement(TextField, { label: "Link", value: supplier.link, onChange: (e) => handleSupplierChange(supplier.supplier.toString(), "link", e.target.value), size: "small", fullWidth: true, InputProps: {
                            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                React.createElement(Tooltip, { title: "Copy link" },
                                    React.createElement(IconButton, { onClick: () => handleCopyLink(supplier.link) },
                                        React.createElement(ContentCopy, null))))),
                        } })));
            }))),
        React.createElement(Divider, { sx: { ml: -1, mr: -1 } })));
};
export default Suppliers;
