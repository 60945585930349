import { useMutation, useQueryClient } from "@tanstack/react-query";
import useNotificationStore from "../../../stores/notificationStore";
import { createFinancial } from "@api/financialApi";
export function useFinancialCreate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: createFinancial,
        onSuccess: (newFinancial) => {
            queryClient.setQueryData(["financials"], (oldData) => {
                return [...oldData, newFinancial];
            });
            addNotification("Financial created.", "success");
        },
        onError: () => {
            addNotification("Could not create financial.", "error");
        },
    });
}
