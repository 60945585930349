export function getContrastYIQ(hexcolor) {
    // Convert hex color to RGB
    var r = parseInt(hexcolor.substr(1, 2), 16);
    var g = parseInt(hexcolor.substr(3, 2), 16);
    var b = parseInt(hexcolor.substr(5, 2), 16);
    // Calculate YIQ (brightness) value
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    // Return 'black' or 'white' based on the YIQ value
    return yiq >= 128 ? "black" : "white";
}
