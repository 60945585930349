import React from "react";
import { Box, Collapse, IconButton, Paper, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SelectedProject } from "../../Projects";
import Task from "./Task";
export const Milestone = ({ milestone }) => {
    const project = React.useContext(SelectedProject);
    const [expanded, setExpanded] = React.useState(milestone.active);
    if (!project)
        return null;
    const milestoneTasks = project.tasks.filter((task) => task.milestone === milestone.name);
    const totalTasks = milestoneTasks.length;
    const completedTasks = milestoneTasks.filter((task) => task.completed).length;
    return (React.createElement(Paper, { sx: { display: "flex", flexDirection: "column", gap: 1, p: 1, mt: 1 } },
        React.createElement(Box, { sx: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 1,
                pl: 1,
                alignItems: "center",
            } },
            React.createElement(Typography, { variant: "h6" },
                milestone.name,
                " "),
            React.createElement(Box, { sx: {
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                } },
                React.createElement(Typography, { variant: "h6" }, `${completedTasks}/${totalTasks}`),
                React.createElement(IconButton, { onClick: () => {
                        setExpanded(!expanded);
                    } },
                    React.createElement(ExpandMoreIcon, { sx: {
                            transform: expanded ? "rotate(180deg)" : null,
                            transition: "all 500ms",
                        } })))),
        React.createElement(Collapse, { in: expanded, unmountOnExit: true },
            React.createElement(Box, { sx: { display: "flex", flexDirection: "column", gap: 1 } }, milestoneTasks.map((task) => (React.createElement(Task, { key: task.name, task: task })))))));
};
