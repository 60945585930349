// src/utils/makeComponentColumns.ts
import React from "react";
import { Check } from "@mui/icons-material";
/**
 * Defines the columns for the components DataGrid.
 * @param products - Array of product objects.
 * @param components - Array of component objects.
 * @returns Array of GridColDef objects.
 */
const makeColumns = (components) => {
    console.log(components);
    // Extract unique parameter names
    const parameters = new Set();
    components.forEach((component) => {
        component.parameters.forEach((param) => {
            if (param.name !== "qty")
                parameters.add(param.name);
        });
    });
    // Initialize columns with fixed fields
    const columns = [
        {
            field: "productId",
            headerName: "ID",
            width: 90,
        },
        {
            field: "quantity",
            headerName: "Qty",
            width: 90,
            renderCell: (params) => {
                if (params.value) {
                    return formatParameter(params.value);
                }
                else {
                    let param = params.row.parameters.find((p) => p.name === "qty");
                    if (param)
                        return formatParameter(param.calculatedValue);
                }
            },
        },
        {
            field: "name",
            headerName: "Product Name",
            width: 200,
        },
        {
            field: "zone",
            headerName: "Zone",
            width: 150,
            renderCell: (params) => {
                return params.row.zone || "";
            },
            sortable: true,
            filterable: true,
        },
    ];
    // Dynamically add columns for each unique parameter
    parameters.forEach((param) => {
        columns.push({
            field: param,
            headerName: capitalizeFirstLetter(param),
            flex: 0.4,
            renderCell: (params) => {
                // Find the parameter in the product's parameters array
                const p = params.row.parameters.find((p) => p.name === param);
                if (!p)
                    return "";
                return formatParameter(p.calculatedValue);
            },
            sortable: false,
            filterable: false,
        });
    });
    columns.push({
        field: "cost",
        headerName: "Cost",
        flex: 0.2,
        renderCell: (params) => {
            return params.row.cost ? React.createElement(Check, null) : "";
        },
    });
    columns.push({
        field: "categories",
        headerName: "Categories",
        flex: 1,
    });
    console.log(columns);
    return columns;
};
/**
 * Formats a price value to USD currency.
 * @param price - The price to format.
 * @returns Formatted price string.
 */
const formatPrice = (price) => {
    return price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
};
/**
 * Formats a parameter value, ensuring consistent decimal places.
 * @param paramValue - The parameter value to format.
 * @returns Formatted parameter value string.
 */
export const formatParameter = (paramValue) => {
    const value = parseFloat(paramValue);
    if (isNaN(value))
        return "";
    let stringified = `${value}`;
    if (stringified.split(".")[1]?.length > 3) {
        return value.toFixed(3);
    }
    else {
        return stringified;
    }
};
/**
 * Capitalizes the first letter of a string.
 * @param str - The string to capitalize.
 * @returns Capitalized string.
 */
const capitalizeFirstLetter = (str) => {
    if (!str)
        return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
};
export default makeColumns;
