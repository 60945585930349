import React from "react";
import { useShallow } from "zustand/react/shallow";
import { useComponentCreateStore } from "../store/createComponentStore";
import CurrencyTextField from "src/utils/CurrencyTextField";
import { DisabledContext, NodeContext } from "../ComponentNode";
import { Box, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { CustomIconButton } from "src/components/CustomIconButtom";
import { Balance } from "@mui/icons-material";
const selector = (state) => ({
    getNode: state.getNode,
    onNodeChange: state.onNodeChange,
});
const Price = () => {
    const { onNodeChange, getNode } = useComponentCreateStore(useShallow(selector));
    const { id, data } = React.useContext(NodeContext);
    const disabled = React.useContext(DisabledContext);
    const onChange = React.useCallback((evt) => {
        onNodeChange(id, { field: "price", value: evt.target.value });
    }, [id]);
    const onModifierChange = React.useCallback((evt) => {
        onNodeChange(id, { field: "priceModifier", value: evt.target.value });
    }, [id]);
    const isUsingModifier = data.priceModifier !== null;
    console.log("Price");
    return (React.createElement(Box, { sx: { display: "flex", gap: 1, alignItems: "cener", mt: 1 } },
        isUsingModifier ? (React.createElement(FormControl, { fullWidth: true },
            React.createElement(InputLabel, { htmlFor: `price-${id}` }, "Markup"),
            React.createElement(OutlinedInput, { label: "Markup", value: data.priceModifier, disabled: disabled, onChange: onModifierChange, id: `price-${id}`, fullWidth: true, size: "small", startAdornment: "x" }))) : (React.createElement(CurrencyTextField, { label: "Price", value: data.price, disabled: disabled, onChange: onChange, id: `price-${id}`, fullWidth: true, size: "small" })),
        React.createElement(CustomIconButton, { onClick: () => {
                onNodeChange(id, {
                    field: "priceModifier",
                    value: isUsingModifier ? null : 1,
                });
            }, icon: React.createElement(Balance, null), title: isUsingModifier
                ? "Remove markup and use price"
                : "Use cost of components and add markup", disabled: disabled, variant: isUsingModifier ? "contained" : "outlined" })));
};
export default Price;
