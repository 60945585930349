// components/ManageSubscriptionButton.jsx
import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { createCustomerPortalSession } from "@api/paymentApi";
const ManageSubscriptionButton = () => {
    const [loading, setLoading] = useState(false);
    const handleManageSubscription = async () => {
        setLoading(true);
        try {
            const portalUrl = await createCustomerPortalSession();
            window.location.href = portalUrl;
        }
        catch (error) {
            // Handle error (e.g., show a notification)
            console.error("Failed to redirect to customer portal:", error);
        }
        finally {
            setLoading(false);
        }
    };
    return (React.createElement(Button, { variant: "contained", onClick: handleManageSubscription, disabled: loading }, loading ? React.createElement(CircularProgress, { size: 24 }) : "Manage Subscription"));
};
export default ManageSubscriptionButton;
