// helpers.ts
import _ from "lodash";
import OptionNode from "../OptionNode";
import CustomNode from "../ComponentNode";
import CustomEdge from "../Edge";
import GroupNode from "../GroupNode";
export function getId(id) {
    return id.slice(id.lastIndexOf("_") + 1);
}
export function handleOverrides(overrides, parameter, originalParameter) {
    // Set the override
    // If the override is the same as the old value, remove it
    // If the override is different, change it
    // If the override is not there, add it
    let newOverrides = [...overrides];
    let existing = overrides.find((o) => o.name === parameter.name && o.id === parameter.id);
    if (existing) {
        if (originalParameter.value === parameter.value &&
            originalParameter.variable === parameter.variable) {
            newOverrides = newOverrides.filter((o) => o !== existing);
        }
        else {
            newOverrides = newOverrides.map((o) => {
                if (o === existing) {
                    return parameter;
                }
                else {
                    return o;
                }
            });
        }
    }
    else {
        newOverrides.push(parameter);
    }
    return newOverrides;
}
export function isIdOfParent(childId, parentId) {
    return childId.slice(0, childId.lastIndexOf("_")) === parentId;
}
export function applyQtyChange(id, value, subcomponents) {
    return subcomponents.map((subcomponent) => {
        if (subcomponent.id === id) {
            return { ...subcomponent, qty: value };
        }
        else {
            return subcomponent;
        }
    });
}
export function nodesEqual(past, current) {
    let parsedPast = {
        ...past,
        saved: undefined,
        fresh: undefined,
        id: undefined,
        flowData: undefined,
    };
    let parsedCurrent = {
        ...current,
        saved: undefined,
        fresh: undefined,
        id: undefined,
        flowData: undefined,
    };
    for (let key in parsedPast) {
        if (!_.isEqual(parsedPast[key], parsedCurrent[key])) {
            return false;
        }
    }
    return true;
}
export function isEqual(local, cloud) {
    return _.isEqual({
        ...local,
        saved: undefined,
        fresh: undefined,
        id: undefined,
        flowData: undefined,
        subcomponents: local.subcomponents.map((s) => ({
            qty: s.qty,
            id: getId(s.id),
        })),
    }, {
        ...cloud,
        saved: undefined,
        fresh: undefined,
        id: undefined,
        flowData: undefined,
        subcomponents: cloud.subcomponents.map((s) => ({
            qty: s.qty,
            id: s.id,
        })),
    });
}
/**
 * Adjusts IDs of nodes and edges to create a duplicate component.
 * @param nodes - The array of nodes from the component's flow data.
 * @param edges - The array of edges from the component's flow data.
 * @param idSuffix - A suffix to append to IDs to make them unique.
 * @returns An object containing the adjusted nodes and edges.
 */
export function adjustComponentIds(nodes, edges, idSuffix) {
    const idMap = {};
    // Generate new IDs for all nodes
    nodes.forEach((node) => {
        const newId = `${node.id}${idSuffix}`;
        idMap[node.id] = newId;
    });
    // Adjust nodes with new IDs and update subcomponent IDs
    const newNodes = nodes.map((node) => {
        const newNodeId = idMap[node.id];
        const newNodeData = _.cloneDeep(node.data);
        // Adjust subcomponent IDs
        if (newNodeData.subcomponents) {
            newNodeData.subcomponents = newNodeData.subcomponents.map((subcomponent) => {
                const originalSubId = subcomponent.id;
                const newSubId = idMap[originalSubId];
                return {
                    ...subcomponent,
                    id: newSubId,
                    qty: {
                        ...subcomponent.qty,
                        id: newSubId,
                    },
                };
            });
        }
        // Adjust parametersOverride IDs
        if (newNodeData.parametersOverride) {
            newNodeData.parametersOverride = newNodeData.parametersOverride.map((param) => {
                return {
                    ...param,
                    id: newNodeId,
                };
            });
        }
        return {
            ...node,
            id: newNodeId,
            data: newNodeData,
            position: {
                x: node.position.x + 40,
                y: node.position.y + 40,
            },
            selected: true,
        };
    });
    // Adjust edges with new source and target IDs
    const newEdges = edges.map((edge) => {
        const newSource = idMap[edge.source];
        const newTarget = idMap[edge.target];
        const newId = `${newSource}-${newTarget}`;
        return {
            ...edge,
            id: newId,
            source: newSource,
            target: newTarget,
        };
    });
    return { nodes: newNodes, edges: newEdges };
}
export function flowFromComponent(component, data) {
    const flowData = JSON.parse(component.flowData);
    console.log({ flowData });
    const nodes = flowData.nodes
        .sort((a, b) => a.id.split("_").length - b.id.split("_").length)
        .map((node, i) => {
        if (node.type === "groupNode") {
            return { ...node, dragHandle: ".drag-handle" };
        }
        let nodeData = data.find((d) => d._id == getId(node.id));
        let subcomponents = nodeData?.subcomponents || [];
        let parentId = node.parentId;
        let position = node.position;
        if (parentId) {
            let positionOffset = { x: 0, y: 0 };
            let parent = flowData.nodes.find((n) => n.id === parentId);
            console.log({ parent });
            if (parent) {
                positionOffset = parent.position;
            }
            // position = {
            //   x: node.position.x + positionOffset.x,
            //   y: node.position.y + positionOffset.y,
            // };
            console.log({ node, position });
        }
        return {
            ...node,
            position,
            dragHandle: ".drag-handle",
            data: {
                ...nodeData,
                fresh: i === 0 || nodeData?.fresh,
                subcomponents: subcomponents.map((subcomponent) => {
                    return {
                        ...subcomponent,
                        id: `${node.id}_${subcomponent.id}`,
                    };
                }),
            },
        };
    });
    return { nodes, edges: flowData.edges };
}
export function duplicateNodeRecursively(nodeId, parentId, nodes, idMap, id, isNewComponent = false) {
    const node = nodes.find((n) => n.id === nodeId);
    if (!node)
        return { nodes: [], edges: [] };
    // Generate new ID for the duplicated node
    const newNodeId = isNewComponent
        ? _.uniqueId()
        : parentId
            ? `${parentId}_${getId(nodeId)}`
            : _.uniqueId(`${nodeId.slice(0, nodeId.lastIndexOf("_"))}_`);
    idMap[nodeId] = newNodeId;
    const newNode = {
        ...node,
        id: newNodeId,
        position: {
            x: node.position.x + 40,
            y: node.position.y + 40,
        },
        data: {
            ...node.data,
            flowData: "",
            id: newNodeId,
            fresh: false,
            name: nodeId === id ? `${node.data.name} Copy` : node.data.name,
        },
        selected: true,
    };
    if (nodeId === id) {
        newNode.data._id = "";
    }
    // Adjust parametersOverride to match the new node id
    if (nodeId === id) {
        newNode.data.parametersOverride = newNode.data.parametersOverride.map((param) => {
            // the parameter override's id doesnt include any parents
            const newId = newNodeId.slice(newNodeId.lastIndexOf("_") + 1) +
                param.id.slice(param.id.indexOf("_"));
            return { ...param, id: newId };
        });
    }
    let duplicatedNodes = [newNode];
    let duplicatedEdges = [];
    if (node.data.subcomponents && node.data.subcomponents.length > 0) {
        newNode.data.subcomponents = node.data.subcomponents.map((subcomponent) => {
            const originalSubId = subcomponent.id;
            const newSubId = `${newNodeId}_${getId(originalSubId)}`;
            const result = duplicateNodeRecursively(originalSubId, newNodeId, nodes, idMap, id);
            duplicatedNodes = duplicatedNodes.concat(result.nodes);
            duplicatedEdges = duplicatedEdges.concat(result.edges);
            return {
                ...subcomponent,
                id: newSubId,
            };
        });
        for (let subcomponent of newNode.data.subcomponents) {
            duplicatedEdges.push({
                id: `${newNode.id}-${subcomponent.id}`,
                source: newNode.id,
                target: subcomponent.id,
            });
        }
    }
    else {
        newNode.data.subcomponents = [];
    }
    return { nodes: duplicatedNodes, edges: duplicatedEdges };
}
const WIDTH = 400;
export function addNodesFromSwitch(componentsToAdd, dataForNodes, position, parentId) {
    return componentsToAdd
        .map((component, i) => {
        let flowData = JSON.parse(component.flowData);
        flowData.nodes = flowData.nodes.map((node) => {
            let id = `${parentId}_${node.id}`;
            let data = dataForNodes.find((data) => data._id === node.id.slice(node.id.lastIndexOf("_") + 1));
            return {
                ...node,
                dragHandle: ".drag-handle",
                selected: true,
                data: {
                    ...data,
                    subcomponents: data?.subcomponents.map((subcomponent) => {
                        return {
                            ...subcomponent,
                            id: `${id}_${subcomponent.id}`,
                        };
                    }),
                },
                id: id,
                position: {
                    x: position.x + node.position.x + WIDTH * i,
                    y: position.y + node.position.y,
                },
            };
        });
        return flowData.nodes;
    })
        .flat();
}
export function addEdgesFromSwitch(componentsToAdd, parentId) {
    // add edges from parent to new components
    let edgesFromParentToComponents = componentsToAdd.map((component) => {
        return {
            id: `${parentId}_${component._id}`,
            source: parentId,
            target: `${parentId}_${component._id}`,
        };
    });
    // add edges from new components to their children
    return componentsToAdd
        .map((component) => {
        let flowData = JSON.parse(component.flowData);
        flowData.edges = flowData.edges.map((edge) => {
            let source = `${parentId}_${edge.source}`;
            let target = `${parentId}_${edge.target}`;
            let id = `${source}-${target}`;
            return {
                ...edge,
                source,
                target,
                id,
            };
        });
        return flowData.edges;
    })
        .concat(edgesFromParentToComponents)
        .flat();
}
export const nodeTypes = {
    component: CustomNode,
    groupNode: GroupNode,
    option: OptionNode,
};
export const edgeTypes = {
    default: CustomEdge,
};
export function newComponent(id, name) {
    return {
        _id: "",
        id: id,
        name: name,
        description: "",
        isOption: false,
        categories: [],
        price: null,
        priceModifier: null,
        cost: null,
        subcomponents: [],
        code: null,
        suppliers: [],
        parameters: [],
        parametersOverride: [],
        options: [],
        stock: 0,
        image: null,
        fresh: true,
    };
}
export function getComponentParametersOverride(component, id) {
    if (component.parametersOverride) {
        // Determine the hierarchy level by counting underscores in node.id
        const hierarchyLevel = (id.match(/_/g) || []).length;
        return component.parametersOverride.map((param) => {
            // Create a unique key for each parameter override based on name and parameter id
            const paramKey = `${param.name}-${param.id}`;
            // Adjust the parameter id so that its beginning matches the node id
            let newId = param.id.slice(param.id.indexOf("_") + 1);
            newId = `${id}_${newId}`;
            let newParam = { ...param, id: newId };
            return {
                param: newParam,
                hierarchyLevel,
                paramKey,
            };
        });
    }
    else {
        return [];
    }
}
export function makeUniqueParametersOverride(parametersOverride) {
    // Create a map to store the highest parent overrides
    const overrideMap = new Map();
    parametersOverride.forEach(({ param, hierarchyLevel, paramKey }) => {
        if (overrideMap.has(paramKey)) {
            const existingEntry = overrideMap.get(paramKey);
            // Keep the override from the highest parent (fewest underscores)
            if (hierarchyLevel < existingEntry.hierarchyLevel) {
                overrideMap.set(paramKey, { param, hierarchyLevel });
            }
        }
        else {
            overrideMap.set(paramKey, { param, hierarchyLevel });
        }
    });
    // Return the parameter overrides from the highest parents
    const uniqueOverrides = Array.from(overrideMap.values()).map((entry) => entry.param);
    return uniqueOverrides;
}
export function getParentNodeId(nodeId) {
    const parts = nodeId.split("_");
    if (parts.length > 1) {
        return parts.slice(0, -1).join("_");
    }
    return null;
}
export function calculateGroupPosition(nodeIds, nodes) {
    const positions = nodeIds
        .map((id) => nodes.find((node) => node.id === id))
        .filter((node) => node !== undefined)
        .map((node) => node.position);
    const avgX = positions.reduce((sum, pos) => sum + pos.x, 0) / positions.length;
    const avgY = positions.reduce((sum, pos) => sum + pos.y, 0) / positions.length;
    return { x: avgX, y: avgY - 200 };
}
export function updateEdgesForGrouping(edges, parentId, groupId, nodeIds) {
    // Remove edges from parent to child nodes
    const updatedEdges = edges.filter((edge) => !(edge.source === parentId && nodeIds.includes(edge.target)));
    // Add edge from parent to group node
    updatedEdges.push({
        id: `e_${parentId}_${groupId}`,
        source: parentId,
        target: groupId,
    });
    // Add edges from group node to child nodes
    nodeIds.forEach((nodeId) => {
        updatedEdges.push({
            id: `e_${groupId}_${nodeId}`,
            source: groupId,
            target: nodeId,
        });
    });
    return updatedEdges;
}
