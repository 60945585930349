// Overrides.tsx
import React, { useContext, useState } from "react";
import { NodeContext, DisabledContext } from "../ComponentNode";
import { List, ListItem, ListItemText, IconButton, Paper, Typography, Collapse, Box, } from "@mui/material";
import { Delete, ExpandLess, ExpandMore } from "@mui/icons-material";
import { useComponentCreateStore } from "../store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
const selector = (state) => ({
    getNode: state.getNode,
    onNodeChange: state.onNodeChange,
});
const Overrides = () => {
    const { id, data } = useContext(NodeContext);
    const disabled = useContext(DisabledContext);
    const { onNodeChange, getNode } = useComponentCreateStore(useShallow(selector));
    const [collapsed, setCollapsed] = useState(true);
    const handleDeleteOverride = (override) => {
        // Remove the override from data.parametersOverride
        const updatedOverrides = (data.parametersOverride || []).filter((parameter) => !(parameter.name === override.name && parameter.id === override.id));
        // Update the node data using onNodeChange
        onNodeChange(id, { field: "parametersOverride", value: updatedOverrides });
    };
    const handleToggleCollapse = () => {
        setCollapsed(!collapsed);
    };
    if (!data.parametersOverride || data.parametersOverride.length === 0) {
        return null;
    }
    // Function to get node name from parameter id
    const getNodeNameById = (paramId) => {
        let node = getNode(paramId);
        if (!node) {
            node = getNode(id.slice(0, id.lastIndexOf("_")) + "_" + paramId);
        }
        return node ? node.data.name : "Unknown Node";
    };
    return (React.createElement(Paper, { sx: { padding: 1, marginTop: 2 } },
        React.createElement(Box, { sx: {
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
            }, onClick: handleToggleCollapse },
            React.createElement(Typography, { variant: "subtitle1", sx: { flexGrow: 1 } },
                "Overrides (",
                data.parametersOverride.length,
                ")"),
            React.createElement(IconButton, { size: "small", onClick: handleToggleCollapse }, collapsed ? React.createElement(ExpandMore, null) : React.createElement(ExpandLess, null))),
        React.createElement(Collapse, { in: !collapsed, timeout: "auto", unmountOnExit: true },
            React.createElement(List, { dense: true }, data.parametersOverride.map((parameter) => {
                const nodeName = getNodeNameById(parameter.id);
                return (React.createElement(ListItem, { key: `${parameter.id}-${parameter.name}`, secondaryAction: React.createElement(IconButton, { edge: "end", "aria-label": "delete", onClick: () => handleDeleteOverride(parameter), disabled: disabled },
                        React.createElement(Delete, null)) },
                    React.createElement(ListItemText, { primary: `${nodeName} - ${parameter.name}`, secondary: `Value: ${parameter.value}` })));
            })))));
};
export default Overrides;
