import { createCustomer } from "../../../api/customerApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useNotificationStore from "../../../stores/notificationStore";
export function useCustomerCreate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: createCustomer,
        onSuccess: (newCustomer) => {
            queryClient.setQueryData(["customers"], (oldData) => {
                return [...oldData, newCustomer];
            });
            addNotification("Customer created.", "success");
        },
        onError: () => {
            addNotification("Could not create customer.", "error");
        },
    });
}
