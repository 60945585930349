import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Alert, List, ListItem, ListItemText, Divider, Box, } from "@mui/material";
import { TableChart, Close, Check, Close as CloseIcon, } from "@mui/icons-material";
import Papa from "papaparse";
import { useComponentCreateCsv } from "../hooks/useComponentCreateCsv";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
import { useSuppliersData } from "@features/supplier/hooks/useSuppliersData";
import { useComponentsData } from "../hooks/useComponentsData";
import CustomButton from "src/components/CustomButton";
import { DataGrid, } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
const MAX_IMAGES = 100;
// Style for rows that can't be selected
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
// "& .duplicate-row": {
//   backgroundColor: theme.palette.error.main,
//   color: theme.palette.error.contrastText,
// },
// "& .existing-row": {
//   backgroundColor: theme.palette.error.main,
//   color: theme.palette.error.contrastText,
// },
}));
const CreateFromCsv = () => {
    const { data: settings } = useSettingsData();
    const { data: suppliers } = useSuppliersData();
    const { data: existingComponents } = useComponentsData();
    // existingComponents should be something like [{name: "foo"}, {name: "bar"}]
    const [open, setOpen] = useState(false);
    const [csvFile, setCsvFile] = useState(null);
    const [imageFiles, setImageFiles] = useState([]);
    const [imageError, setImageError] = useState(null);
    const [csvDataPreview, setCsvDataPreview] = useState([]);
    const [previewMode, setPreviewMode] = useState(false);
    const [nonExistentSuppliers, setNonExistentSuppliers] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    // Row issues: key is row index, value is either "duplicate" or "existing"
    const [rowIssues, setRowIssues] = useState({});
    // Track which images exist (by name without extension, lowercase)
    const [imageExistsMap, setImageExistsMap] = useState({});
    const { mutate: createFromCsv } = useComponentCreateCsv();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setCsvFile(null);
        setImageFiles([]);
        setImageError(null);
        setCsvDataPreview([]);
        setPreviewMode(false);
        setNonExistentSuppliers([]);
        setRowIssues({});
        setSelectionModel([]);
        setImageExistsMap({});
        setOpen(false);
    };
    const handleCsvChange = (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            if (!file)
                return;
            setCsvFile(file);
            // Parse CSV on the client side:
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    if (results.errors.length) {
                        console.error("CSV parsing errors:", results.errors);
                    }
                    else {
                        setCsvDataPreview(results.data);
                    }
                },
            });
        }
    };
    const handleImagesChange = (e) => {
        if (!e.target.files)
            return;
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length > MAX_IMAGES) {
            setImageError(`You have selected ${selectedFiles.length} images. The maximum allowed is ${MAX_IMAGES}.`);
            setImageFiles(selectedFiles.slice(0, MAX_IMAGES));
        }
        else {
            setImageError(null);
            setImageFiles(selectedFiles);
            // Create a map of image names without extension, lowercase
            const imgMap = {};
            selectedFiles.forEach((file) => {
                const nameWithoutExt = file.name
                    .split(".")
                    .slice(0, -1)
                    .join(".")
                    .toLowerCase();
                imgMap[nameWithoutExt] = true;
            });
            setImageExistsMap(imgMap);
        }
    };
    const handlePreview = () => {
        if (!csvFile || csvDataPreview.length === 0)
            return;
        // Determine which suppliers do not exist
        const existingSupplierNames = suppliers?.map((s) => s.name.toLowerCase()) || [];
        const newSuppliers = new Set();
        // Check component duplicates and existing in DB
        const existingComponentNames = existingComponents?.map((c) => c.name.toLowerCase()) || [];
        const nameOccurrences = {}; // Map name -> array of row indexes
        const issues = {};
        csvDataPreview.forEach((row, index) => {
            // Check suppliers
            for (let i = 1; i <= 5; i++) {
                const supplierNameKey = `supplier${i}_name`;
                if (row[supplierNameKey]) {
                    const suppliedName = row[supplierNameKey]
                        .toString()
                        .trim()
                        .toLowerCase();
                    if (suppliedName && !existingSupplierNames.includes(suppliedName)) {
                        newSuppliers.add(suppliedName);
                    }
                }
            }
            // Check for duplicates and existing components
            const nameFieldKey = Object.keys(row).find((k) => k.toLowerCase() === "name");
            let nameVal = "";
            if (nameFieldKey) {
                nameVal = (row[nameFieldKey] || "").toString().trim().toLowerCase();
            }
            if (!nameVal)
                return; // If no name, skip duplicate/existing logic
            if (!nameOccurrences[nameVal]) {
                nameOccurrences[nameVal] = [];
            }
            nameOccurrences[nameVal].push(index);
        });
        // Determine duplicates & existing
        Object.entries(nameOccurrences).forEach(([compName, indexes]) => {
            if (indexes.length > 1) {
                // multiple occurrences in CSV, only first is allowed
                // subsequent are duplicates
                for (let i = 1; i < indexes.length; i++) {
                    issues[indexes[i]] = "duplicate";
                }
            }
            // Check if already exists in DB
            if (existingComponentNames.includes(compName)) {
                // All occurrences of this name are "existing"
                indexes.forEach((i) => {
                    issues[i] = "existing";
                });
            }
        });
        setNonExistentSuppliers(Array.from(newSuppliers));
        setRowIssues(issues);
        // By default, select all rows that are not in issues
        const initiallySelected = csvDataPreview
            .map((_, idx) => idx)
            .filter((idx) => !issues[idx]);
        setSelectionModel(initiallySelected);
        setPreviewMode(true);
    };
    const handleConfirm = async () => {
        if (!csvFile) {
            return;
        }
        // Filter out rows not selected
        const rowsToSend = csvDataPreview.filter((_, idx) => selectionModel.includes(idx));
        // Re-generate CSV from selected rows
        const headers = csvDataPreview.length > 0 ? Object.keys(csvDataPreview[0]) : [];
        const selectedCsvLines = [headers.join(",")];
        for (const idx of selectionModel) {
            const row = csvDataPreview[idx];
            selectedCsvLines.push(headers
                .map((h) => `"${(row[h] || "").toString().replace(/"/g, '""')}"`)
                .join(","));
        }
        const blob = new Blob([selectedCsvLines.join("\n")], { type: "text/csv" });
        const selectedCsvFile = new File([blob], "filtered_components.csv", {
            type: "text/csv",
        });
        const formData = new FormData();
        formData.append("csv", selectedCsvFile);
        imageFiles.forEach((file) => {
            formData.append("images", file);
        });
        createFromCsv(formData);
        handleClose();
    };
    const handleDownloadTemplate = async () => {
        let parameterNames = [];
        if (settings) {
            parameterNames = settings.parameters
                .filter((p) => !p.global)
                .map((p) => p.name);
        }
        const headers = [
            "name",
            "category",
            "price",
            "priceModifier",
            "image",
            "cost",
            "supplier1_name",
            "supplier1_link",
            "supplier1_cost",
            "supplier1_code",
            "supplier2_name",
            "supplier2_link",
            "supplier2_cost",
            "supplier2_code",
            "stock",
            "code",
            ...parameterNames,
        ];
        const csvContent = headers.join(",") + "\n";
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "component_template.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    // Create columns
    // For the image column, show a checkmark if image found, else X
    const columns = csvDataPreview.length > 0
        ? Object.keys(csvDataPreview[0]).map((key) => {
            if (key.toLowerCase() === "image") {
                return {
                    field: key,
                    headerName: key,
                    width: 150,
                    renderCell: (params) => {
                        const imageName = (params.value || "").toString().toLowerCase();
                        if (!imageName) {
                            return (React.createElement(Box, { sx: {
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                } },
                                React.createElement(CloseIcon, { color: "error", sx: { marginY: "auto" } })));
                        }
                        return imageExistsMap[imageName] ? (React.createElement(Box, { sx: {
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            } },
                            React.createElement(Check, { color: "success", sx: { margin: "auto" } }))) : (React.createElement(Box, { sx: {
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            } },
                            React.createElement(CloseIcon, { color: "error", sx: { margin: "auto" } })));
                    },
                };
            }
            return {
                field: key,
                headerName: key,
                width: 150,
            };
        })
        : [];
    const isRowSelectable = (params) => {
        // If row has an issue (duplicate or existing), can't select
        return !rowIssues[params.id];
    };
    const getRowClassName = (params) => {
        if (rowIssues[params.id]) {
            if (rowIssues[params.id] === "duplicate")
                return "duplicate-row";
            if (rowIssues[params.id] === "existing")
                return "existing-row";
        }
        return "";
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomButton, { variant: "outlined", color: "primary", startIcon: React.createElement(TableChart, null), label: "Create from CSV", onClick: handleClickOpen }),
        React.createElement(Dialog, { open: open, onClose: handleClose, maxWidth: "md", fullWidth: true },
            React.createElement(DialogTitle, null,
                previewMode
                    ? "Review Components & Suppliers"
                    : "Upload CSV and Images",
                React.createElement(IconButton, { "aria-label": "close", onClick: handleClose, sx: {
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    } },
                    React.createElement(Close, null))),
            React.createElement(DialogContent, { dividers: true },
                !previewMode && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { variant: "body1", gutterBottom: true },
                        "Please select a CSV file and up to ",
                        MAX_IMAGES,
                        " image files."),
                    React.createElement(Alert, { severity: "info", sx: { mb: 2 } },
                        React.createElement(Typography, { variant: "subtitle1", gutterBottom: true }, "Important Instructions:"),
                        React.createElement(List, { dense: true },
                            React.createElement(ListItem, null,
                                React.createElement(ListItemText, { primary: "Image Naming Convention", secondary: "Filenames of images must match values in the 'image' column of your CSV. No need to include the file extension." })),
                            React.createElement(Divider, null),
                            React.createElement(ListItem, null,
                                React.createElement(ListItemText, { primary: "Parameter Matching", secondary: "Parameter names in your CSV must match your settings' parameter names." })),
                            React.createElement(Divider, null),
                            React.createElement(ListItem, null,
                                React.createElement(ListItemText, { primary: "Supplier Information", secondary: "Use supplier1_name, supplier1_link, supplier1_cost, supplier1_code columns. Increment for additional suppliers. Ensure supplier names match those in your account." })),
                            React.createElement(Divider, null),
                            React.createElement(ListItem, null,
                                React.createElement(ListItemText, { primary: "Categories", secondary: "Separate multiple categories with commas." })),
                            React.createElement(Divider, null),
                            React.createElement(ListItem, null,
                                React.createElement(ListItemText, { primary: "Ready Made Template", secondary: "Download a template CSV with correct headers." }),
                                React.createElement(Button, { variant: "outlined", color: "primary", onClick: handleDownloadTemplate }, "Download CSV Template")))),
                    imageError && (React.createElement(Alert, { severity: "error", sx: { mb: 2 } }, imageError)),
                    React.createElement("div", { style: { marginBottom: 20 } },
                        React.createElement("input", { accept: ".csv", style: { display: "none" }, id: "csv-file-input", type: "file", onChange: handleCsvChange }),
                        React.createElement("label", { htmlFor: "csv-file-input" },
                            React.createElement(Button, { variant: "contained", component: "span", color: "primary" }, "Choose CSV File"),
                            csvFile && (React.createElement(Typography, { variant: "body2", style: { marginLeft: 10, display: "inline" } }, csvFile.name)))),
                    React.createElement("div", { style: { marginBottom: 20 } },
                        React.createElement("input", { accept: "image/*", style: { display: "none" }, id: "image-file-input", type: "file", multiple: true, onChange: handleImagesChange }),
                        React.createElement("label", { htmlFor: "image-file-input" },
                            React.createElement(Button, { variant: "contained", component: "span", color: "secondary" }, "Choose Image Files"),
                            imageFiles.length > 0 && (React.createElement(Typography, { variant: "body2", style: { marginLeft: 10, display: "inline" } },
                                imageFiles.length,
                                " file(s) selected")))))),
                previewMode && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { variant: "body1", gutterBottom: true }, "Below is a preview of the components to be created. Uncheck any rows you don't want to create. Rows shown that cannot be selected are duplicates or already exist. For the \"image\" column, a checkmark means the corresponding image file was found, and an X means it was not."),
                    React.createElement("div", { style: { height: 400, width: "100%", marginBottom: 20 } },
                        React.createElement(StyledDataGrid, { rows: csvDataPreview.map((row, index) => ({
                                id: index,
                                ...row,
                            })), columns: columns, density: "compact", checkboxSelection: true, rowSelectionModel: selectionModel, onRowSelectionModelChange: (newSelection) => setSelectionModel(newSelection), isRowSelectable: isRowSelectable, getRowClassName: getRowClassName })),
                    nonExistentSuppliers.length > 0 && (React.createElement(Alert, { severity: "warning", sx: { mb: 2 } },
                        React.createElement(Typography, { variant: "subtitle1", gutterBottom: true }, "The following suppliers do not currently exist and will be created:"),
                        React.createElement("ul", null, nonExistentSuppliers.map((sup) => (React.createElement("li", { key: sup }, sup))))))))),
            React.createElement(DialogActions, null,
                !previewMode && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: handleClose, color: "secondary" }, "Cancel"),
                    React.createElement(Button, { onClick: handlePreview, color: "primary", variant: "contained", disabled: !csvFile || imageFiles.length > MAX_IMAGES }, "Preview"))),
                previewMode && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: handleClose, color: "secondary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleConfirm, color: "primary", variant: "contained" }, "Confirm & Create")))))));
};
export default CreateFromCsv;
