import { Add, ArrowLeft, ArrowRight, AttachMoney, Category, CheckBox, CheckBoxOutlineBlank, Circle, Image, KeyboardOptionKey, MoneyOff, QrCode, Warehouse, } from "@mui/icons-material";
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, } from "@mui/material";
import { useReactFlow } from "@xyflow/react";
import React from "react";
import { useComponentCreateStore } from "../store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
import CategoryMenu from "./CategoryMenu";
import { DisabledContext } from "../ComponentNode";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
import useGeneralStore from "@stores/generalStore";
import CustomButton from "src/components/CustomButton";
import SuppliersMenu from "./SupplierMenu";
const MENU_ITEMS = [
    {
        name: "Image",
        icon: React.createElement(Image, null),
        field: "image",
    },
    {
        name: "Price",
        icon: React.createElement(AttachMoney, null),
        field: "price",
    },
    {
        name: "Cost",
        icon: React.createElement(MoneyOff, null),
        field: "cost",
    },
    // Remove the Supplier item from MENU_ITEMS
    {
        name: "Code",
        icon: React.createElement(QrCode, null),
        field: "code",
    },
];
const selector = (state) => ({
    onNodeChange: state.onNodeChange,
});
const NodeMenu = ({ id, data }) => {
    const { updateNodeData } = useReactFlow();
    const { onNodeChange } = useComponentCreateStore(useShallow(selector));
    const { data: settings } = useSettingsData();
    const { setSettingsTab } = useGeneralStore();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (field) => {
        data[field] === null
            ? updateNodeData(id, { [field]: "" })
            : updateNodeData(id, { [field]: null });
    };
    const handleParameterSelect = (parameter) => {
        const existingParameter = data.parameters.find((p) => p.name === parameter.name);
        if (existingParameter) {
            updateNodeData(id, {
                parameters: data.parameters.filter((p) => p.name !== parameter.name),
            });
        }
        else {
            updateNodeData(id, {
                parameters: [
                    ...data.parameters,
                    {
                        name: parameter.name,
                        value: "",
                        variable: "",
                        id: id,
                    },
                ],
            });
        }
    };
    const handleOptionClick = () => {
        onNodeChange(id, { field: "isOption", value: true });
    };
    const handleComponentClick = () => {
        onNodeChange(id, { field: "isOption", value: false });
        setAnchorEl(null);
    };
    const handleParameterOpen = () => {
        setSettingsTab("parameters");
        setAnchorEl(null);
    };
    const disabled = React.useContext(DisabledContext);
    const [catAnchor, setCatAnchor] = React.useState(null);
    const [suppliersAnchor, setSuppliersAnchor] = React.useState(null); // Add state for suppliers submenu
    return (React.createElement("div", null,
        React.createElement(Button, { id: "add-button", size: "small", color: "primary", variant: "contained", startIcon: React.createElement(ArrowLeft, null), "aria-controls": open ? "add-menu" : undefined, "aria-haspopup": "true", "aria-expanded": open ? "true" : undefined, onClick: handleClick, disabled: disabled, sx: { mr: 1 } }, "Edit"),
        React.createElement(Menu, { id: "add-menu", anchorEl: anchorEl, open: open, onClose: handleClose, anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "right",
            }, MenuListProps: {
                "aria-labelledby": "basic-button",
            } },
            data.isOption ? (React.createElement(MenuItem, { key: "component", onClick: handleComponentClick, disabled: false },
                React.createElement(ListItemIcon, null,
                    React.createElement(Circle, null)),
                React.createElement(ListItemText, null, "Turn Into Component"))) : (React.createElement(MenuItem, { key: "option", onClick: handleOptionClick, disabled: false },
                React.createElement(ListItemIcon, null,
                    React.createElement(KeyboardOptionKey, null)),
                React.createElement(ListItemText, null, "Turn Into Option"))),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { key: "category", onClick: (e) => setCatAnchor(e.currentTarget) },
                React.createElement(ListItemIcon, null,
                    React.createElement(Category, null)),
                React.createElement(ListItemText, null, "Category"),
                React.createElement(ArrowRight, null)),
            React.createElement(MenuItem, { key: "suppliers", onClick: (e) => setSuppliersAnchor(e.currentTarget), disabled: data.isOption },
                React.createElement(ListItemIcon, null,
                    React.createElement(Warehouse, null)),
                React.createElement(ListItemText, null, "Suppliers"),
                React.createElement(ArrowRight, null)),
            MENU_ITEMS.map((item) => (React.createElement(MenuItem, { key: item.field, onClick: () => handleMenuItemClick(item.field), disabled: data.isOption ||
                    (item.field === "cost" &&
                        data.suppliers &&
                        data.suppliers.length > 0) },
                React.createElement(ListItemIcon, { sx: {
                        color: data[item.field] !== null ? "primary.main" : "default",
                    } }, item.icon),
                React.createElement(ListItemText, null, item.name)))),
            React.createElement(Divider, null,
                "Parameters",
                " ",
                React.createElement(CustomButton, { size: "small", variant: "outlined", sx: { px: 0.5, minWidth: 0 }, icon: React.createElement(Add, null), onClick: handleParameterOpen })),
            settings?.parameters
                .filter((p) => !p.global)
                .map((parameter) => (React.createElement(MenuItem, { key: parameter.name, onClick: () => handleParameterSelect(parameter), disabled: false },
                React.createElement(ListItemIcon, null, data.parameters.find((p) => p.name === parameter.name) ? (React.createElement(CheckBox, { color: "primary" })) : (React.createElement(CheckBoxOutlineBlank, null))),
                React.createElement(ListItemText, null, parameter.name)))),
            settings?.parameters.length === 0 && (React.createElement(MenuItem, { disabled: true },
                React.createElement(ListItemText, null, "No Parameters")))),
        React.createElement(CategoryMenu, { anchor: catAnchor, setAnchor: setCatAnchor, id: id }),
        React.createElement(SuppliersMenu, { anchor: suppliersAnchor, setAnchor: setSuppliersAnchor, id: id })));
};
export default NodeMenu;
