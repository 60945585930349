import { updateProject } from "@api/projectApi";
import useNotificationStore from "@stores/notificationStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export function useProjectUpdate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: updateProject,
        onSuccess: (updatedProject) => {
            queryClient.setQueryData(["projects"], (oldData) => {
                return oldData.map((project) => project._id === updatedProject._id ? updatedProject : project);
            });
            addNotification("Project updated.", "success");
        },
        onError: () => {
            addNotification("Could not updated project.", "error");
        },
    });
}
