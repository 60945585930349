import React from "react";
import { SelectedProject } from "../Projects";
import { FocusContext } from "./ProjectView";
import FileManager from "@features/file-manager/FileManager";
const ProjectFiles = () => {
    const project = React.useContext(SelectedProject);
    const { focused } = React.useContext(FocusContext);
    if (!project)
        return null;
    console.log(project);
    return (React.createElement(React.Fragment, null,
        React.createElement(FileManager, { path: `/Projects/${project.name}`, showNavigationPane: false, view: "Details" })));
};
export default ProjectFiles;
