// src/stores/authStore.ts
import { create } from "zustand";
import { confirmEmail, login, logout, tokenLogin } from "../api/userApi";
import { queryClient } from "src/app/App";
const useAuthStore = create((set) => ({
    user: null,
    loggingIn: false,
    login: async (email, password, stayLoggedIn, callback) => {
        try {
            set({ loggingIn: true });
            const data = await login({ email, password, stayLoggedIn });
            set({
                user: data.user,
                loggingIn: false,
            });
            callback(data.user.role);
        }
        catch (error) {
            set({
                user: null,
                loggingIn: false,
            });
        }
    },
    logout: async () => {
        await logout();
        queryClient.clear();
        set({
            user: null,
            loggingIn: false,
        });
    },
    initialize: async (callback) => {
        try {
            const data = await tokenLogin();
            set({
                user: data.user,
                loggingIn: false,
            });
            if (callback)
                callback(data.user.role);
        }
        catch (error) {
            console.log(error);
            set({
                user: null,
                loggingIn: false,
            });
        }
    },
    confirmEmail: async (token) => {
        try {
            const data = await confirmEmail(token);
            return data;
        }
        catch (error) {
            return error;
        }
    },
}));
export default useAuthStore;
