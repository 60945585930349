import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, } from "@mui/material";
import React from "react";
import { useComponentCreateStore } from "../store/createComponentStore";
import { useSuppliersData } from "@features/supplier/hooks/useSuppliersData";
import { Add } from "@mui/icons-material";
import { showSupplierDialog } from "@features/Dialogs/DialogManager";
const SuppliersMenu = ({ anchor, setAnchor, id, }) => {
    const { data: suppliers } = useSuppliersData();
    const { onNodeChange, getNode } = useComponentCreateStore();
    const nodeData = getNode(id)?.data;
    const selectedSuppliers = nodeData?.suppliers || [];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    React.useEffect(() => {
        setAnchorEl(anchor);
    }, [anchor]);
    const handleClose = () => {
        setAnchor(null);
    };
    const handleClick = (supplierId) => {
        const isSelected = selectedSuppliers.some((s) => s.supplier === supplierId);
        let newSuppliers;
        if (isSelected) {
            newSuppliers = selectedSuppliers.filter((s) => s.supplier !== supplierId);
        }
        else {
            newSuppliers = [
                ...selectedSuppliers,
                {
                    supplier: supplierId,
                    default: selectedSuppliers.length === 0, // Set as default if first supplier
                    cost: 0,
                    code: "",
                    link: "",
                },
            ];
        }
        if (nodeData?.cost) {
            onNodeChange(id, { field: "cost", value: null });
        }
        onNodeChange(id, { field: "suppliers", value: newSuppliers });
    };
    return (React.createElement("div", null,
        React.createElement(Menu, { id: "suppliers-menu", anchorEl: anchorEl, open: open, onClose: handleClose, anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            }, MenuListProps: {
                "aria-labelledby": "suppliers-button",
            } },
            suppliers?.map((supplier) => {
                const isSelected = selectedSuppliers.some((s) => s.supplier === supplier._id);
                return (React.createElement(MenuItem, { key: supplier._id, onClick: () => handleClick(supplier._id), sx: {
                        backgroundColor: isSelected ? "primary.main" : "default",
                        color: isSelected ? "primary.contrastText" : "default",
                        "&:hover": {
                            backgroundColor: isSelected ? "primary.light" : "default",
                        },
                    } },
                    React.createElement(ListItemText, null, supplier.name)));
            }),
            (suppliers?.length || 0) > 0 ? (React.createElement(Divider, null)) : (React.createElement(MenuItem, { disabled: true },
                React.createElement(ListItemText, null, "No suppliers"))),
            React.createElement(MenuItem, { key: "add-supplier", onClick: () => showSupplierDialog() },
                React.createElement(ListItemIcon, null,
                    React.createElement(Add, null)),
                React.createElement(ListItemText, null, "Add Supplier")))));
};
export default SuppliersMenu;
