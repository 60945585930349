import { create as mathCreate, all } from "mathjs";
const config = {};
const math = mathCreate(all, config);
// Cache to store parsed expressions
const expressionCache = {};
const parseExpression = (expression) => {
    if (expressionCache[expression]) {
        return expressionCache[expression];
    }
    const parsed = math.parse(expression);
    expressionCache[expression] = parsed;
    return parsed;
};
// Build dependency graph
export const buildDependencyGraph = (params) => {
    const graph = new Map();
    params.forEach((param) => {
        // Use a unique key for parameters without a variable
        const key = param.variable || `${param.id}_${param.name}`;
        const dependencies = new Set();
        const node = parseExpression(param.value);
        node.traverse((node) => {
            if (node.type === "SymbolNode") {
                dependencies.add(node.name);
            }
        });
        graph.set(key, dependencies);
    });
    return graph;
};
// Topological sort
export const topologicalSort = (graph) => {
    const visited = new Set();
    const temp = new Set();
    const result = [];
    const visit = (node) => {
        if (temp.has(node)) {
            throw new Error(`Circular dependency detected: ${node}`);
        }
        if (!visited.has(node)) {
            temp.add(node);
            const deps = graph.get(node);
            if (deps) {
                deps.forEach(visit);
            }
            temp.delete(node);
            visited.add(node);
            result.push(node);
        }
    };
    graph.forEach((_, node) => {
        if (!visited.has(node)) {
            visit(node);
        }
    });
    return result.reverse();
};
