import React from "react";
import { Avatar, Dialog, Paper, Typography, Button, Tooltip, } from "@mui/material";
import useAuthStore from "../../stores/authStore";
import ImageCropDialog from "src/components/ImageCropDialog";
import { usePersonUpdate } from "./hooks/usePersonUpdate";
import { usePeopleData } from "./hooks/usePeopleData";
const ProfileAvatar = () => {
    const { data: people, isLoading } = usePeopleData();
    const { user, logout } = useAuthStore();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { mutate: updatePerson } = usePersonUpdate();
    React.useEffect(() => {
        if (!people || !user)
            return;
        person = people.find((p) => p._id === user.person);
    }, [people]);
    if (!user || !people)
        return null;
    const onImageConfirm = (image) => {
        // Handle confirm logic here
        const person = new FormData();
        person.append("_id", user.person);
        person.append("avatar", image);
        updatePerson(person);
    };
    let person = people.find((p) => p._id === user.person);
    // | {} as Person;
    return (React.createElement("div", null,
        React.createElement(Avatar, { id: "profile", src: person?.avatar, alt: person?.firstName, onClick: handleClickOpen, sx: {
                cursor: "pointer",
                ":hover": {
                    opacity: [0.9, 0.8, 0.7],
                    boxShadow: 10,
                },
                ml: 1,
                transitionDuration: "0.5s",
            } }),
        React.createElement(Dialog, { open: open, onClose: handleClose, maxWidth: "sm" },
            React.createElement(Paper, { sx: { height: "100%", p: 2 } },
                React.createElement(ImageCropDialog, { onConfirm: onImageConfirm, circle: true, button: React.createElement(Tooltip, { title: "Change Profile Picture", arrow: true, placement: "right" },
                        React.createElement(Avatar, { id: "profileImg", src: person?.avatar, alt: person?.firstName, sx: {
                                cursor: "pointer",
                                ":hover": {
                                    opacity: [0.9, 0.8, 0.7],
                                    boxShadow: 10,
                                },
                                ml: 1,
                                transitionDuration: "0.5s",
                            } })) }),
                React.createElement(Typography, { variant: "h5", sx: { mt: 2 } },
                    person?.firstName,
                    " ",
                    person?.lastName),
                React.createElement(Typography, { variant: "h6" }, person?.email),
                React.createElement(Button, { variant: "contained", onClick: logout }, "Log Out")))));
};
export default ProfileAvatar;
