import React from "react";
import StripeIntegration from "./StripeIntegration";
import DocuSignIntegration from "./DocuSignIntegration";
import { Stack } from "@mui/material";
const Integrations = () => {
    return (React.createElement(Stack, { spacing: 2 },
        React.createElement(StripeIntegration, null),
        React.createElement(DocuSignIntegration, null)));
};
export default Integrations;
