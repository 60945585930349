import { Settings } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import React from "react";
import DialogMenu from "./components/DialogMenu";
import Parameters from "./components/Parameters";
import Categories from "./components/Categories";
import Zones from "./components/Zones";
import Tasks from "./components/Tasks";
import Integrations from "./components/Integrations";
import ThemeSwitcher from "../theme/ThemeControl";
import ManageSubscriptionButton from "./components/ManageSubscriptionButton";
import Organization from "./components/Organization";
import useGeneralStore from "@stores/generalStore";
export const TABS = [
    {
        label: "Profile",
        value: "profile",
        component: (React.createElement("div", null,
            React.createElement(ThemeSwitcher, null),
            React.createElement(ManageSubscriptionButton, null))),
    },
    {
        label: "Parameters",
        value: "parameters",
        component: React.createElement(Parameters, null),
    },
    {
        label: "Categories",
        value: "categories",
        component: React.createElement(Categories, null),
    },
    { label: "Zones", value: "zones", component: React.createElement(Zones, null) },
    { label: "Tasks", value: "tasks", component: React.createElement(Tasks, null) },
    {
        label: "Organization",
        value: "organization",
        component: React.createElement(Organization, null),
    },
    {
        label: "Integrations",
        value: "integrations",
        component: React.createElement(Integrations, null),
    },
];
const UserSettings = () => {
    const { settingsTab: tab, setSettingsTab: setTab } = useGeneralStore();
    const open = !!tab;
    const handleOpen = () => {
        setTab("profile");
    };
    const handleClose = () => {
        setTab("");
    };
    return (React.createElement("div", null,
        React.createElement(IconButton, { onClick: handleOpen },
            React.createElement(Settings, null)),
        React.createElement(Dialog, { open: open, onClose: handleClose, maxWidth: "lg", fullWidth: true },
            React.createElement(DialogContent, { sx: { height: 600, display: "flex", flexDirection: "column" } },
                React.createElement(DialogMenu, { activeTab: tab, setTab: setTab }),
                TABS.find((t) => t.value === tab)?.component || TABS[0].component))));
};
export default UserSettings;
