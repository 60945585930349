import { Button, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
export default function TabButton({ title, icon, iconOnly = false, selected, onClick, }) {
    const theme = useTheme();
    return iconOnly ? (React.createElement(IconButton, { color: "primary", sx: {
            color: selected ? "#000" : "inherit",
            transition: "all 0.5s",
            backgroundImage: selected
                ? `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`
                : "none",
        }, onClick: onClick }, icon)) : (React.createElement(Button, { variant: selected ? "contained" : "text", color: selected ? "primary" : "inherit", startIcon: icon, sx: {
            textTransform: "none",
            paddingX: 2,
            transition: "all 0.5s",
            backgroundImage: selected
                ? `linear-gradient(45deg, ${theme.palette.primary.main} 30%,  ${theme.palette.secondary.main} 90%)`
                : "none",
        }, onClick: onClick },
        React.createElement(Typography, null, title)));
}
