import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemIcon, ListItemText, useTheme, } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import useProductsStore from "../productsStore";
import { useProjectUpdate } from "../hooks/useProjectUpdate";
import { SelectedProject } from "../Projects";
import { Add } from "@mui/icons-material";
import makeColumns from "../utils/makeColumns";
import { useComponentsData } from "@features/inventory/hooks/useComponentsData";
import { flowFromComponent } from "@features/componentCreate/store/helpers";
import { getParametersOverride } from "@features/componentCreate/store/storeUtils";
const ProductAddDialog = ({ open, setOpen, }) => {
    const mode = useTheme().palette.mode;
    const { mutate: updateProject } = useProjectUpdate();
    const project = React.useContext(SelectedProject);
    // const [rowSelectionModel, setRowSelectionModel] = React.useState<any[]>([]);
    // const [open, setOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const { data } = useComponentsData();
    const { products, selectedProductIds, setSelectedProductIds, addProduct, components, removeProducts, } = useProductsStore();
    const filteredComponents = React.useMemo(() => {
        return data?.filter((c) => c.price || c.priceModifier);
    }, [data]);
    // React.useEffect(() => {
    //   if (!settings) return;
    //   const globalParameters = settings.parameters
    //     .filter((p) => p.global)
    //     .map((p) => ({ ...p, variable: p.name, id: "global" }));
    //   setGlobalParameters(globalParameters as Parameter[]);
    // }, [settings]);
    // React.useEffect(() => {
    //   if (!project || !data || !settings) return;
    //   clear();
    //   unpackProducts(
    //     { products: project.products, components: project.components },
    //     data
    //   );
    // }, [data, settings, open]);
    if (!data)
        return null;
    if (!filteredComponents)
        return null;
    const onProductAdd = (product) => {
        let { nodes } = flowFromComponent(product, data);
        let overrides = getParametersOverride(nodes);
        const comps = nodes
            .map((node) => ({
            ...node.data,
            id: node.id,
            image: data.find((c) => c._id === node.data._id)?.image,
            qty: { ...getQty(node.id, nodes, overrides), id: node.id },
        }))
            .filter((c) => c.id.split("_").length > 1);
        let id = products.length + 1;
        while (products.find((p) => p.id == `${id}`)) {
            id++;
        }
        let prod = { ...nodes[0].data, id: id };
        addProduct(prod, comps);
    };
    const onSave = () => {
        let pack = makePack(products, project?.components);
        updateProject({ ...pack, _id: project?._id });
        setOpen(false);
    };
    const handleExpandClick = () => {
        setExpanded((prev) => !prev);
    };
    const handleDelete = () => {
        removeProducts(selectedProductIds);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: open, onClose: () => setOpen(false), maxWidth: "xl", fullWidth: true },
            React.createElement(DialogTitle, null, "Edit Products"),
            React.createElement(DialogContent, { sx: { display: "flex", gap: 1 } },
                React.createElement(Collapse, { in: expanded, orientation: "horizontal" },
                    React.createElement(List, null, filteredComponents.map((product) => (React.createElement(ListItemButton, { key: product._id, onClick: () => onProductAdd(product), sx: { borderRadius: 1 } },
                        React.createElement(ListItemIcon, null,
                            React.createElement(Add, null)),
                        React.createElement(ListItemText, { primary: product.name })))))),
                React.createElement(DataGrid, { rows: products, columns: makeColumns(products, components, true, project?.components), getRowClassName: (params) => params.row.incomplete ? "incomplete-product" : "", checkboxSelection: true, disableRowSelectionOnClick: true, onRowSelectionModelChange: (newSelection) => {
                        setSelectedProductIds(newSelection);
                    }, rowSelectionModel: selectedProductIds, sx: {
                        height: "60vh",
                        "& .MuiDataGrid-row.incomplete-product": {
                            backgroundColor: mode === "light" ? "#ffe6e6" : "#4a0000",
                            "&:hover": {
                                backgroundColor: mode === "light" ? "#ffd6d6" : "#600000",
                            },
                        },
                    }, slots: {
                        noRowsOverlay: () => React.createElement("div", null, "No products added"),
                        toolbar: () => (React.createElement(Box, { sx: {
                                p: 1,
                                display: "flex",
                                justifyContent: "space-between",
                            } },
                            React.createElement(Button, { onClick: handleExpandClick, variant: "contained" }, expanded ? "Hide Products" : "Add Products"),
                            React.createElement(Button, { id: "delete", onClick: handleDelete, variant: "contained", color: "error", disabled: selectedProductIds.length === 0 }, "Delete"))),
                    } })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => setOpen(false), variant: "outlined" }, "Close"),
                React.createElement(Button, { onClick: onSave, variant: "contained" }, "Save")))));
};
export default ProductAddDialog;
export function getQty(id, nodes, overrides) {
    const override = overrides.find((p) => p.id === id && p.name === "qty");
    const parentNode = nodes.find((node) => node.id === id.slice(0, id.lastIndexOf("_")));
    const subcomponentIndex = parentNode?.data.subcomponents.findIndex((subcomponent) => subcomponent.id === id);
    if (override) {
        return { ...override, isOverride: true };
    }
    return parentNode?.data.subcomponents[subcomponentIndex]?.qty;
}
const makePack = (products, projectComponents) => {
    let pack = {
        products: [],
        components: [],
    };
    products.forEach((p) => {
        if (!p.allParameters) {
            pack.products.push(p);
            projectComponents.forEach((c) => {
                if (c.productId === p.id) {
                    pack.components.push(c);
                }
            });
            return;
        }
        console.log(p);
        let packedProduct = {
            name: p.name,
            zone: p.zone,
            parameters: [],
            options: [],
            price: p.price,
            id: p.id,
            cost: p.cost,
            componentId: p._id,
        };
        p.allParameters.forEach((param) => {
            if (!param.id.includes("_")) {
                packedProduct.parameters.push(param);
            }
            if (param.options) {
                packedProduct.parameters.push(param);
            }
        });
        p.subcomponents.forEach((sub) => {
            if (sub.isOption) {
                console.log(sub, p);
                packedProduct.options.push({
                    name: sub.name,
                    id: sub.id,
                    componentId: sub._id,
                    choice: sub.selection,
                    choiceName: p.subcomponents.find((c) => c.id === sub.selection)
                        .name,
                });
            }
            else {
                let packedSub = {
                    name: sub.name,
                    zone: p.zone,
                    parameters: [],
                    id: sub.id,
                    cost: sub.cost,
                    componentId: sub._id,
                    productId: p.id,
                };
                p.allParameters.forEach((param) => {
                    if (param.id === sub.id) {
                        packedSub.parameters.push(param);
                    }
                });
                pack.components.push(packedSub);
            }
        });
        pack.products.push(packedProduct);
    });
    return pack;
};
