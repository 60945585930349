import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, TextField, } from "@mui/material";
import React from "react";
import useAuthStore from "../../stores/authStore";
import { useCustomerCreate } from "../customer/hooks/useCustomerCreate";
import { useCustomerUpdate } from "../customer/hooks/useCustomerUpdate";
export default function CustomerDialog({ data, open, onClose, onResult, }) {
    const { user } = useAuthStore();
    const { mutate: update } = useCustomerUpdate();
    const { mutate: create } = useCustomerCreate();
    const [firstName, setFirstName] = React.useState(data?.firstName || "");
    const [lastName, setLastName] = React.useState(data?.lastName || "");
    const [phone, setPhone] = React.useState(data?.phone || "");
    const [email, setEmail] = React.useState(data?.email || "");
    const [address, setAddress] = React.useState(data?.address || "");
    const [editing, setEditing] = React.useState(data ? false : true);
    const onCancel = () => {
        setFirstName(data?.firstName || "");
        setLastName(data?.lastName || "");
        setPhone(data?.phone || "");
        setEmail(data?.email || "");
        setAddress(data?.address || "");
        setEditing(false);
    };
    const onSave = async () => {
        if (!data) {
            throw new Error("Customer data is required");
        }
        if (!user) {
            throw new Error("User is not authenticated");
        }
        const pack = {
            ...data,
            firstName,
            lastName,
            phone,
            email,
            address,
        };
        const result = await update(pack);
        setEditing(false);
        onResult(result);
    };
    const onSubmit = async () => {
        if (!user) {
            throw new Error("User is not authenticated");
        }
        const pack = {
            firstName,
            lastName,
            phone,
            email,
            address,
            user: user?.id,
            organization: "", // TODO: add organization field
            stripeId: "", // TODO: add stripeId field
        };
        const result = await create(pack);
        onResult(result);
    };
    return (React.createElement(Dialog, { open: open, onClose: onClose },
        data ? (React.createElement(DialogTitle, null, `${editing ? "Editing" : ""} Customer Info: ${data.firstName} ${data.lastName}`)) : (React.createElement(DialogTitle, null, "Add Customer")),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { container: true, spacing: 2 },
                React.createElement(Grid2, { size: 6 },
                    React.createElement(TextField, { slotProps: {
                            input: {
                                readOnly: !editing,
                            },
                        }, label: "First Name", fullWidth: true, sx: { mt: 2 }, value: firstName, onChange: (e) => setFirstName(e.target.value) })),
                React.createElement(Grid2, { size: 6 },
                    React.createElement(TextField, { slotProps: {
                            input: {
                                readOnly: !editing,
                            },
                        }, label: "Last Name", fullWidth: true, sx: { mt: 2 }, value: lastName, onChange: (e) => setLastName(e.target.value) })),
                React.createElement(Grid2, { size: 6 },
                    React.createElement(TextField, { slotProps: {
                            input: {
                                readOnly: !editing,
                            },
                        }, label: "Phone", fullWidth: true, sx: { mt: 2 }, value: phone, onChange: (e) => setPhone(e.target.value) })),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(TextField, { slotProps: {
                            input: {
                                readOnly: !editing,
                            },
                        }, label: "Email", fullWidth: true, sx: { mt: 2 }, value: email, onChange: (e) => setEmail(e.target.value) })),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(TextField, { slotProps: {
                            input: {
                                readOnly: !editing,
                            },
                        }, label: "Address", fullWidth: true, sx: { mt: 2 }, value: address, onChange: (e) => setAddress(e.target.value) })))),
        data ? (React.createElement(DialogActions, null,
            editing ? null : (React.createElement(Button, { onClick: () => setEditing(true) }, "Edit")),
            editing ? React.createElement(Button, { onClick: onCancel }, "Nevermind") : null,
            editing ? React.createElement(Button, { onClick: onSave }, "Save") : null)) : (React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose }, "Cancel"),
            React.createElement(Button, { onClick: onSubmit }, "Submit")))));
}
