import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateFinancial } from "@api/financialApi";
import useNotificationStore from "@stores/notificationStore";
export function useFinancialUpdate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: updateFinancial,
        onSuccess: (updatedFinancial) => {
            queryClient.setQueryData(["financials"], (oldData) => {
                return oldData.map((financial) => {
                    if (financial._id === updatedFinancial._id) {
                        return updatedFinancial;
                    }
                    return financial;
                });
            });
            addNotification("Financial updated.", "success");
        },
        onError: () => {
            addNotification("Could not update financial.", "error");
        },
    });
}
