import React from "react";
import { Button, Dialog, Paper, Stack } from "@mui/material";
import ImageCrop from "./ImageCrop";
import { Upload } from "@mui/icons-material";
const ImageCropDialog = ({ button, onConfirm, onConfirmBlob, circle, }) => {
    const [open, setOpen] = React.useState(false);
    const [imgSrc, setImgSrc] = React.useState(undefined);
    const [completedCrop, setCompletedCrop] = React.useState(null);
    const imgRef = React.useRef(null);
    const [originalFile, setOriginalFile] = React.useState(null);
    const handleFileSelect = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setOriginalFile(file);
            const reader = new FileReader();
            reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
            reader.readAsDataURL(file);
            setOpen(true);
        }
    };
    const handleClose = () => {
        setImgSrc(undefined);
        setOpen(false);
    };
    const handleUploadAnother = () => setImgSrc(undefined);
    const handleConfirm = async () => {
        if (!completedCrop || !imgRef.current || !originalFile) {
            return;
        }
        const canvas = document.createElement("canvas");
        const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
        const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
        canvas.width = completedCrop.width * scaleX;
        canvas.height = completedCrop.height * scaleY;
        const ctx = canvas.getContext("2d");
        if (ctx) {
            ctx.drawImage(imgRef.current, completedCrop.x * scaleX, completedCrop.y * scaleY, completedCrop.width * scaleX, completedCrop.height * scaleY, 0, 0, completedCrop.width * scaleX, completedCrop.height * scaleY);
        }
        canvas.toBlob((blob) => {
            if (blob) {
                const file = new File([blob], originalFile.name, {
                    type: originalFile.type,
                });
                console.log(onConfirm, onConfirmBlob);
                onConfirm && onConfirm(file);
                onConfirmBlob && onConfirmBlob(blob);
                setOpen(false);
            }
        }, originalFile.type);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { accept: "image/*", id: "contained-button-file", hidden: true, type: "file", onChange: handleFileSelect }),
        React.createElement("label", { htmlFor: "contained-button-file" }, React.cloneElement(button, { component: "span" })),
        React.createElement(Dialog, { open: open, onClose: handleClose, maxWidth: "sm", sx: {
                background: "transparent",
                "& .MuiPaper-root": {
                    background: "transparent",
                    boxShadow: "none",
                },
                // "& .MuiBackdrop-root": {
                //   backgroundColor: "transparent",
                // },
            } }, imgSrc && (React.createElement(Paper, { sx: { backgroundColor: "white", padding: 2 } },
            React.createElement(ImageCrop, { imgSrc: imgSrc, onComplete: (crop) => setCompletedCrop(crop), imgRef: imgRef, circle: circle }),
            React.createElement(Stack, { spacing: 2, direction: "row", justifyContent: "center", sx: { mt: 1 } },
                React.createElement(Button, { onClick: handleClose, variant: "outlined", sx: { backgroundColor: "default" } }, "Cancel"),
                React.createElement("label", { htmlFor: "contained-button-file" },
                    React.createElement(Button, { component: "span", onClick: handleUploadAnother, variant: "contained", endIcon: React.createElement(Upload, null) }, "Upload Another")),
                React.createElement(Button, { onClick: handleConfirm, variant: "contained" }, "Confirm")))))));
};
export default ImageCropDialog;
