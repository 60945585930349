import * as React from "react";
import { TextField, Box } from "@mui/material";
const isAlpha = (ch) => {
    return typeof ch === "string" && ch.length === 1 && /[A-Za-z]/.test(ch);
};
export const ConditionField = (props) => {
    const { userInput, setUserInput, fieldType, 
    // checkFormula,
    fieldId, ...other } = props;
    const inputRef = React.useRef(null);
    const onChange = (e) => {
        if (!inputRef.current) {
            return;
        }
        const curr = e.currentTarget.value;
        const position = inputRef.current.selectionStart || 0;
        if (curr[position - 1] === "/") {
            const newInput = curr.slice(0, position - 1) + "÷";
            setUserInput(newInput);
            return;
        }
        if (curr.length === 0) {
            return;
        }
        if (curr[position - 1] === "/") {
            const updatedCurr = curr.split("");
            updatedCurr[position - 1] = "÷";
            setUserInput(updatedCurr.join(""));
            return;
        }
        if (curr.length === 1 && !isAlpha(curr[0])) {
            setUserInput(curr);
            return;
        }
        if (!isAlpha(curr[position - 1])) {
            setUserInput(curr);
            return;
        }
        setUserInput(curr);
    };
    const [error, setErrorState] = React.useState("");
    const checkError = (value) => {
        value = value.replaceAll(/([^<>])=([^=])/g, "$1==$2");
        // const errorCheck = checkFormula(value);
        // if (errorCheck.error) {
        //   setErrorState(`${errorCheck.error}`);
        // } else {
        //   try {
        //     // const result = checkFormula(value);
        //     if (fieldType === "if") {
        //       if (typeof result !== "boolean") {
        //         setErrorState(
        //           `Must evaluate to either true or false. Result is ${result}.`
        //         );
        //       } else {
        //         setErrorState("");
        //       }
        //     } else {
        //       if (typeof result === "boolean" || isNaN(result)) {
        //         setErrorState(`Must evaluate to a number. Result is ${result}.`);
        //       } else {
        //         setErrorState("");
        //       }
        //     }
        //   } catch (e: any) {
        //     setErrorState(e.message);
        //   }
        // }
    };
    React.useEffect(() => {
        if (userInput) {
            checkError(userInput);
        }
    }, [userInput]);
    //   React.useEffect(() => {
    //     setError((prev) => {
    //       const existingError = prev.find((e) => e.id === fieldId);
    //       if (existingError) {
    //         return prev.map((e) =>
    //           e.id === fieldId ? { ...e, message: error } : e
    //         );
    //       } else {
    //         return [...prev, { id: fieldId, message: error }];
    //       }
    //     });
    //   }, [error, setError, fieldId]);
    return (React.createElement(Box, { sx: { width: "100%" } },
        React.createElement(TextField, { fullWidth: true, size: "small", ...other, inputRef: inputRef, value: userInput, error: !!error, helperText: error, inputProps: { spellCheck: "false" }, onChange: onChange })));
};
