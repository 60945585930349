import api from "./api";
export const getSuppliers = async () => {
    const response = await api.get(`/api/supplier`);
    return response.data;
};
export const updateSupplier = async (supplier) => {
    const response = await api.patch(`/api/supplier/${supplier._id}`, supplier);
    return response.data;
};
// export const updateOrder = async ({ id, pack }) => {
//   return await api.patch(`/api/supplier/${id}/o`, pack);
// };
export const deleteSupplier = async (supplier) => {
    const response = await api.delete(`/api/supplier/${supplier._id}`);
    return response.data;
};
export const createSupplier = async (supplier) => {
    const response = await api.post(`/api/supplier`, supplier);
    return response.data;
};
