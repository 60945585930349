import React from "react";
import ReactCrop, { centerCrop, makeAspectCrop, } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(makeAspectCrop({
        unit: "%",
        width: 90,
    }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
};
const ImageCrop = ({ imgSrc, onComplete, imgRef, circle }) => {
    const [crop, setCrop] = React.useState();
    const aspect = 1;
    const onImageLoad = (e) => {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    };
    return (React.createElement(ReactCrop, { crop: crop, onChange: (_, percentCrop) => setCrop(percentCrop), onComplete: (c) => onComplete(c), aspect: 1, circularCrop: !!circle },
        React.createElement("img", { ref: imgRef, alt: "Crop me", src: imgSrc, style: { transform: `scale(${1}) rotate(${1}deg)` }, onLoad: onImageLoad })));
};
export default ImageCrop;
