import axios from "axios";
import { useState, useEffect } from "react";
import { useCustomersData } from "@features/customer/hooks/useCustomersData";
import { useProjectsData } from "@features/project/hooks/useProjectsData";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
import { useComponentsData } from "@features/inventory/hooks/useComponentsData";
import { useSuppliersData } from "@features/supplier/hooks/useSuppliersData";
import { useOrganizationData } from "src/hooks/useOrganizationData";
export function useTemplates() {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchTemplates = async () => {
            setLoading(true);
            try {
                const initialResponse = await axios.post("/api/filemanager", {
                    action: "read",
                    data: [],
                    path: "/",
                    showHiddenItems: false,
                });
                if (initialResponse.status !== 200) {
                    throw new Error("Failed to fetch templates");
                }
                const templateFolder = initialResponse.data.files.find((file) => file.name === "Templates");
                if (!templateFolder) {
                    throw new Error("Templates folder not found");
                }
                const response = await axios.post("/api/filemanager", {
                    action: "read",
                    data: [],
                    path: "/" + templateFolder.id,
                    showHiddenItems: false,
                });
                const data = response.data;
                const docxTemplates = data.files
                    .filter((file) => file.isFile && file.type.toLowerCase() === "docx")
                    .map((file) => ({
                    name: file.name,
                    id: file.id,
                    isFile: file.isFile,
                    type: file.type,
                }));
                setTemplates(docxTemplates);
            }
            catch (err) {
                console.error(err);
                setError(err.message);
            }
            finally {
                setLoading(false);
            }
        };
        fetchTemplates();
    }, []);
    return { templates, loading, error };
}
export const useData = () => {
    const { data: customers } = useCustomersData();
    const { data: projects } = useProjectsData();
    const { data: settings } = useSettingsData();
    const { data: components } = useComponentsData();
    const { data: suppliers } = useSuppliersData();
    const { data: organization } = useOrganizationData();
    const { templates } = useTemplates();
    return {
        customers: customers || [],
        projects: projects || [],
        settings,
        components,
        suppliers: suppliers || [],
        organization,
        templates,
    };
};
