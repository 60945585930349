import { Box, Button, ClickAwayListener, IconButton, Paper, Stack, TextField, Typography, } from "@mui/material";
import React from "react";
import { useSettingsData } from "../hooks/useSettingsData";
import { useSettingsUpdate } from "../hooks/useSettingsUpdate";
import { AddTask, Delete as DeleteIcon, Edit as EditIcon, } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
const Tasks = () => {
    const { data: settings } = useSettingsData();
    const [newMilestone, setNewMilestone] = React.useState(null);
    const { mutate: save } = useSettingsUpdate();
    const [milestones, setMilestones] = React.useState(settings?.milestones || []);
    React.useEffect(() => {
        if (settings) {
            setMilestones(settings.milestones);
        }
    }, [settings]);
    const onButtonClick = () => {
        setNewMilestone("New Milestone");
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter" && newMilestone) {
            const updatedMilestones = [
                ...milestones,
                { name: newMilestone, tasks: [] },
            ];
            setMilestones(updatedMilestones);
            save({ milestones: updatedMilestones });
            setNewMilestone(null);
        }
        else if (e.key === "Escape") {
            setNewMilestone(null);
        }
    };
    const handleDragEnd = (result) => {
        const { destination, source, type } = result;
        if (!destination) {
            return;
        }
        if (type === "milestone") {
            const newMilestones = Array.from(milestones);
            const [movedMilestone] = newMilestones.splice(source.index, 1);
            newMilestones.splice(destination.index, 0, movedMilestone);
            setMilestones(newMilestones);
            save({ milestones: newMilestones });
        }
        else if (type === "task") {
            const sourceMilestone = milestones.find((m) => m.name === source.droppableId);
            const destinationMilestone = milestones.find((m) => m.name === destination.droppableId);
            if (!sourceMilestone || !destinationMilestone) {
                return;
            }
            const sourceTasks = Array.from(sourceMilestone.tasks);
            const [movedTask] = sourceTasks.splice(source.index, 1);
            const destinationTasks = sourceMilestone === destinationMilestone
                ? sourceTasks
                : Array.from(destinationMilestone.tasks);
            destinationTasks.splice(destination.index, 0, movedTask);
            const newMilestones = milestones.map((m) => {
                if (m === sourceMilestone) {
                    return { ...m, tasks: sourceTasks };
                }
                else if (m === destinationMilestone) {
                    return { ...m, tasks: destinationTasks };
                }
                else {
                    return m;
                }
            });
            setMilestones(newMilestones);
            save({ milestones: newMilestones });
        }
    };
    return (React.createElement(DragDropContext, { onDragEnd: handleDragEnd },
        React.createElement(Box, { sx: {
                overflowX: "auto",
                mx: -3,
                px: 3,
            } },
            React.createElement(Droppable, { droppableId: "all-milestones", direction: "horizontal", type: "milestone" }, (provided) => (React.createElement(Stack, { direction: "row", gap: 1, ref: provided.innerRef, ...provided.droppableProps, sx: {
                    width: "max-content",
                    overflowX: "auto",
                    pt: 1,
                    pb: 1,
                } },
                milestones.map((milestone, index) => (React.createElement(Milestone, { key: milestone.name, milestone: milestone, milestones: milestones, index: index, setMilestones: setMilestones }))),
                provided.placeholder,
                newMilestone !== null ? (React.createElement(ClickAwayListener, { onClickAway: () => setNewMilestone(null) },
                    React.createElement(Paper, { sx: { p: 1, minWidth: 300 } },
                        React.createElement(TextField, { value: newMilestone, onChange: (e) => setNewMilestone(e.target.value), onKeyDown: handleKeyDown, autoFocus: true, onFocus: (e) => e.currentTarget.select(), size: "small", fullWidth: true })))) : (React.createElement(Button, { variant: "contained", size: "small", onClick: onButtonClick, sx: { minWidth: 25 } }, milestones.length === 0 ? "Add Milestone" : "+"))))))));
};
const Milestone = ({ milestone, milestones, index, setMilestones }) => {
    const [newTask, setNewTask] = React.useState(null);
    const { mutate: save } = useSettingsUpdate();
    const onButtonClick = () => {
        setNewTask("New Task");
    };
    const [tasks, setTasks] = React.useState(milestone.tasks);
    React.useEffect(() => {
        setTasks(milestone.tasks);
    }, [milestone.tasks]);
    const handleKeyDown = (e) => {
        if (e.key === "Enter" && newTask) {
            const updatedTasks = [...tasks, newTask];
            const updatedMilestones = milestones.map((m) => m.name === milestone.name ? { ...m, tasks: updatedTasks } : m);
            setTasks(updatedTasks);
            save({ milestones: updatedMilestones });
            setNewTask(null);
        }
        else if (e.key === "Escape") {
            setNewTask(null);
        }
    };
    const onDelete = () => {
        const updatedMilestones = milestones.filter((m) => m.name !== milestone.name);
        setMilestones(updatedMilestones);
        save({ milestones: updatedMilestones });
    };
    // Handle renaming milestone
    const [isEditing, setIsEditing] = React.useState(false);
    const [milestoneName, setMilestoneName] = React.useState(milestone.name);
    const handleRenameMilestone = () => {
        const updatedMilestones = milestones.map((m) => m.name === milestone.name ? { ...m, name: milestoneName } : m);
        setMilestones(updatedMilestones);
        save({ milestones: updatedMilestones });
        setIsEditing(false);
    };
    const handleMilestoneKeyDown = (e) => {
        if (e.key === "Enter") {
            handleRenameMilestone();
        }
        else if (e.key === "Escape") {
            setIsEditing(false);
            setMilestoneName(milestone.name);
        }
    };
    const handleDeleteTask = (taskName) => {
        const updatedTasks = tasks.filter((t) => t !== taskName);
        const updatedMilestones = milestones.map((m) => m.name === milestone.name ? { ...m, tasks: updatedTasks } : m);
        setTasks(updatedTasks);
        save({ milestones: updatedMilestones });
    };
    return (React.createElement(Draggable, { draggableId: milestone.name, index: index }, (provided) => (React.createElement(ClickAwayListener, { onClickAway: () => setNewTask(null) },
        React.createElement(Paper, { ref: provided.innerRef, ...provided.draggableProps, sx: {
                p: 1,
                pb: 0,
                minWidth: 300,
                display: "flex",
                flexDirection: "column",
            } },
            React.createElement(Stack, { direction: "row", justifyContent: "space-between", sx: { pb: 1 } },
                React.createElement(Typography, { variant: "h6", ...provided.dragHandleProps }, isEditing ? (React.createElement(TextField, { value: milestoneName, onChange: (e) => setMilestoneName(e.target.value), onKeyDown: handleMilestoneKeyDown, autoFocus: true, size: "small" })) : (React.createElement("span", { onDoubleClick: () => setIsEditing(true) }, milestone.name))),
                React.createElement("div", null,
                    React.createElement(IconButton, { size: "small", onClick: () => setIsEditing(true) },
                        React.createElement(EditIcon, null)),
                    React.createElement(IconButton, { size: "small", onClick: onButtonClick },
                        React.createElement(AddTask, null)),
                    React.createElement(IconButton, { size: "small", onClick: onDelete },
                        React.createElement(DeleteIcon, null)))),
            React.createElement(Droppable, { droppableId: milestone.name, type: "task" }, (provided) => (React.createElement(Stack, { direction: "column", gap: 1, sx: { pt: 1, flexGrow: 1 }, ref: provided.innerRef, ...provided.droppableProps },
                tasks.map((task, index) => (React.createElement(Task, { key: task, task: task, index: index, onDelete: handleDeleteTask }))),
                provided.placeholder,
                newTask !== null && (React.createElement(TextField, { value: newTask, onChange: (e) => setNewTask(e.target.value), onKeyDown: handleKeyDown, autoFocus: true, onFocus: (e) => e.currentTarget.select(), size: "small", fullWidth: true, sx: { pb: 1 } }))))))))));
};
const Task = ({ task, index, onDelete }) => {
    return (React.createElement(Draggable, { draggableId: task, index: index }, (provided) => (React.createElement(Paper, { ref: provided.innerRef, ...provided.draggableProps, ...provided.dragHandleProps, sx: {
            p: 1,
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        }, elevation: task === "" ? 0 : 2 },
        React.createElement(Typography, null, task),
        React.createElement(IconButton, { size: "small", onClick: () => onDelete(task) },
            React.createElement(DeleteIcon, null))))));
};
export default Tasks;
