import api from "./api";
export const getPeople = async () => {
    try {
        const response = await api.get(`/api/person`);
        console.log(response);
        return response?.data;
    }
    catch (error) {
        console.log(error);
        throw new Error("An error occurred while fetching people.");
    }
};
export const updatePerson = async (person) => {
    const response = await api.patch(`/api/person`, person);
    return response.data;
};
export const deletePerson = async (person) => {
    const response = await api.delete(`/api/person/${person._id}`);
    return response.data;
};
export const createPerson = async (person) => {
    const response = await api.post("/api/person", person);
    return response.data;
};
//router.route("/:id/data").get(personController.getPersonData);
// export const getPersonData = async (id) => {
//   const response = await api.get(`/api/person/${id}/data`);
//   return response.data;
// };
