import { useQuery } from "@tanstack/react-query";
import { getSuppliers } from "@api/supplierApi";
import useAuthStore from "@stores/authStore";
export const useSuppliersData = () => {
    const { user } = useAuthStore();
    if (!user) {
        throw new Error("No user!");
    }
    return useQuery({
        queryKey: ["suppliers"],
        queryFn: getSuppliers,
        staleTime: Infinity,
    });
};
