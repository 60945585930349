import React from "react";
import { TransitionGroup } from "react-transition-group";
import { Collapse } from "@mui/material";
import Parameter from "./Parameter";
import { NodeContext } from "../ComponentNode";
const Parameters = () => {
    const { id, data } = React.useContext(NodeContext);
    // const parametersOverride = useComponentCreateStore(
    //   useShallow((state) => state.getParametersOverride(id))
    // );
    // const [parameters, setParameters] = React.useState(
    //   getParameters(data, parametersOverride, id)
    // );
    // React.useEffect(() => {
    //   const newParameters = getParameters(data, parametersOverride, id);
    //   let isDifferent = false;
    //   if (parameters.length !== newParameters.length) {
    //     isDifferent = true;
    //   } else {
    //     for (let i = 0; i < parameters.length; i++) {
    //       if (parameters[i].value !== newParameters[i].value) {
    //         isDifferent = true;
    //         break;
    //       }
    //       if (parameters[i].variable !== newParameters[i].variable) {
    //         isDifferent = true;
    //         break;
    //       }
    //     }
    //   }
    //   if (isDifferent) {
    //     setParameters(newParameters);
    //   }
    // }, [data, parametersOverride]);
    // const parameters = React.useMemo(() => {
    //   console.log(data, parametersOverride);
    //   return getParameters(data, parametersOverride, id);
    // }, [data, parametersOverride, id]);
    return (React.createElement("div", null,
        React.createElement(TransitionGroup, null, data.parameters.map((parameter) => (React.createElement(Collapse, null,
            React.createElement(Parameter, { name: parameter.name })))))));
};
export default Parameters;
const getParameters = (data, parametersOverride, id) => {
    return data.parameters.map((parameter) => {
        let override = parametersOverride?.find((p) => p.name === parameter.name && p.id === id);
        return override ? { ...override, isOverride: true } : parameter;
    });
};
