import { Box, Button, ClickAwayListener, Collapse, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, } from "@mui/material";
import React from "react";
import { useComponentCreateStore } from "../store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
import { CustomIconButton } from "src/components/CustomIconButtom";
import { AltRoute, CopyAll } from "@mui/icons-material";
import { Conditions } from "./Conditions";
const selector = (state) => ({
    getNode: state.getNode,
    getQty: state.getQty,
    onQtyChange: state.onQtyChange,
    onNodeChange: state.onNodeChange,
    pasteQty: state.pasteQty,
    getParentNode: state.getParentNode,
});
const Quantity = ({ id, disabled }) => {
    const [focused, setFocused] = React.useState(false);
    const focus = () => setFocused(true);
    const unfocus = () => setFocused(false);
    const { getNode, onQtyChange, onNodeChange, pasteQty } = useComponentCreateStore(useShallow(selector));
    // Subscribe to the qty parameter in the store
    const parameter = useComponentCreateStore(useShallow((state) => state.getQty(id)));
    if (!parameter)
        return null;
    const handleNameClick = () => {
        if (disabled) {
            onNodeChange(id, {
                field: "parametersOverride",
                value: {
                    ...parameter,
                    variable: parameter.variable ? "" : `${parameter.name}`,
                },
            });
        }
        else {
            onQtyChange(id, {
                ...parameter,
                variable: parameter.variable ? "" : `${parameter.name}`,
            });
        }
    };
    const onChange = (value) => {
        if (isConditional) {
            value = `${parameter.value.split("!!")[0]}!!${value}`;
        }
        console.log(disabled);
        if (disabled) {
            onNodeChange(id, {
                field: "parametersOverride",
                value: { ...parameter, value: value },
            });
        }
        else {
            onQtyChange(id, { ...parameter, value: value });
        }
    };
    const handleConditionClick = () => {
        if (disabled) {
            if (parameter.value.includes("!!")) {
                onNodeChange(id, {
                    field: "parametersOverride",
                    value: { ...parameter, value: parameter.value.split("!!")[1] },
                });
            }
            else {
                onChange(`!!${parameter.value}`);
            }
        }
        else {
            if (parameter.value.includes("!!")) {
                onQtyChange(id, {
                    ...parameter,
                    value: parameter.value.split("!!")[1],
                });
            }
            else {
                onChange(`!!${parameter.value}`);
            }
        }
    };
    const isFunction = !isNumeric(parameter.value);
    const isConditional = parameter.value.includes("!!");
    const expanded = isFunction && focused;
    const node = getNode(id);
    const onPasteClick = () => {
        pasteQty(id, parameter);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ClickAwayListener, { onClickAway: unfocus },
            React.createElement(Paper, { sx: {
                    mt: 1,
                    ml: expanded ? -12 : 0,
                    mr: expanded ? -12 : 0,
                    p: expanded ? 1 : 0,
                    transition: "all 0.2s",
                }, elevation: expanded ? 3 : 1, onClick: focus },
                React.createElement(Collapse, { in: focused && isConditional, unmountOnExit: true },
                    React.createElement(Conditions, { parameter: parameter, id: id })),
                React.createElement(FormControl, { fullWidth: true, variant: "outlined", size: "small" },
                    isConditional && React.createElement(InputLabel, null, "Default Value"),
                    React.createElement(OutlinedInput, { value: isConditional ? parameter.value.split("!!")[1] : parameter.value, onChange: (evt) => {
                            onChange(evt.target.value);
                        }, sx: {
                            border: 1,
                            borderColor: parameter.isOverride ? "#f0f0f0" : "transparent",
                        }, label: isConditional ? "Default Value" : null, placeholder: node?.data?.isOption
                            ? "Variable represents selected choice."
                            : "", startAdornment: React.createElement(Box, { sx: { display: "flex" } },
                            React.createElement(Button, { size: "small", onClick: handleNameClick, sx: {
                                    ml: -1,
                                    pl: 1,
                                    pr: 1,
                                    pt: 0.2,
                                    pb: 0.2,
                                    mr: 0.5,
                                    color: parameter.variable ? "default" : "text.primary",
                                    fontSize: 15,
                                    boxSizing: "border-box",
                                    border: "1px solid transparent",
                                } }, parameter.name),
                            React.createElement(Collapse, { in: focused || isConditional, unmountOnExit: true, orientation: "horizontal" },
                                React.createElement(CustomIconButton, { onClick: handleConditionClick, disabled: disabled, size: "small", sx: {
                                        ml: -1,
                                        // pl: 1,
                                        // pr: 1,
                                        pt: 0.2,
                                        pb: 0.2,
                                        mr: 0.5,
                                        color: parameter.value.includes("!!")
                                            ? "default"
                                            : "text.primary",
                                        // fontSize: 15,
                                        boxSizing: "border-box",
                                        border: "1px solid transparent",
                                    }, icon: React.createElement(AltRoute, null) }))), endAdornment: React.createElement(InputAdornment, { position: "end" }, focused && (React.createElement(IconButton, { size: "small", onClick: onPasteClick, sx: { p: 1, mr: -1 } },
                            React.createElement(CopyAll, null)))) }))))));
};
export default Quantity;
function isNumeric(str) {
    if (!str)
        return true;
    if (typeof str == "number")
        return true; // we only process strings!
    if (typeof str != "string")
        return false; // we only process strings!
    let isNum = !isNaN(Number(str));
    return isNum; // ...and ensure strings of whitespace fail
}
