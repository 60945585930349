import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, } from "@mui/material";
import { useProjectCreate } from "../hooks/useProjectCreate";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CustomAutocomplete from "src/components/Autocomplete";
import ColorPicker from "src/components/ColorPicker";
import { useProjectUpdate } from "../hooks/useProjectUpdate";
import ConfirmButton from "src/components/ConfirmButton";
import { Delete, DeleteForever } from "@mui/icons-material";
import { useProjectDelete } from "../hooks/useProjectDelete";
import { useCustomersData } from "@features/customer/hooks/useCustomersData";
export default function ProjectDialog({ projectData, open, onClose }) {
    console.log(projectData);
    const { mutate: update } = useProjectUpdate();
    const { mutate: create } = useProjectCreate();
    const { mutate: deleteProject } = useProjectDelete();
    const { data: customers } = useCustomersData();
    const [data, setData] = React.useState({
        name: projectData?.name || "",
        color: projectData?.color || "",
        customer: projectData?.customer._id || "",
        creationDate: projectData?.creationDate || new Date(),
        dueDate: projectData?.dueDate ||
            new Date(new Date().setDate(new Date().getDate() + 7)),
    });
    React.useEffect(() => {
        if (projectData) {
            setData({
                name: projectData.name,
                color: projectData.color,
                customer: projectData.customer._id || "",
                creationDate: projectData.creationDate,
                dueDate: projectData.dueDate,
            });
        }
        else {
            setData({
                name: "",
                color: "",
                customer: "",
                creationDate: new Date(),
                dueDate: new Date(new Date().setDate(new Date().getDate() + 7)),
            });
        }
    }, [projectData]);
    const onChange = (e) => {
        setData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    const onCustomerInputChange = (value) => {
        if (!value) {
            return;
        }
        setData((prev) => ({
            ...prev,
            customer: value.value || value.title,
        }));
    };
    const handleSave = () => {
        if (projectData) {
            update({ ...data, _id: projectData._id });
        }
        else {
            create(data);
        }
        onClose();
    };
    const handleDelete = () => {
        if (!projectData) {
            return;
        }
        deleteProject(projectData);
        onClose();
    };
    console.log(data);
    return (React.createElement(Dialog, { open: open, onClose: onClose },
        React.createElement(DialogTitle, { sx: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            } },
            projectData ? "Edit Project" : "New Project",
            " ",
            React.createElement(ColorPicker, { color: data.color, setColor: (color) => setData((prev) => ({ ...prev, color })) })),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { fullWidth: true, label: "Name", name: "name", value: data.name, sx: { mt: 1 }, onChange: onChange }),
            React.createElement(CustomAutocomplete, { options: customers?.map((customer) => ({
                    title: customer.firstName + " " + customer.lastName,
                    value: customer._id,
                })) || [], label: "Customer", value: data.customer
                    ? customers?.find((c) => c._id === data.customer)?.firstName +
                        " " +
                        customers?.find((c) => c._id === data.customer)?.lastName
                    : data.customer, onInputChange: onCustomerInputChange, sx: { mt: 1 } }),
            React.createElement(Stack, { direction: "row", spacing: 1, sx: { mt: 1 } },
                React.createElement(DatePicker, { label: "Start", value: dayjs(data.creationDate), onChange: (date) => {
                        setData({
                            ...data,
                            creationDate: date?.toDate() || new Date(),
                        });
                    } }),
                React.createElement(DatePicker, { label: "End", value: dayjs(data.dueDate), onChange: (date) => {
                        setData({
                            ...data,
                            dueDate: date?.toDate() || new Date(),
                        });
                    } }))),
        React.createElement(DialogActions, { sx: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            } },
            React.createElement(Box, null, projectData && (React.createElement(ConfirmButton, { onClick: handleDelete, label: "Delete", startIcon: React.createElement(Delete, null), confirmIcon: React.createElement(DeleteForever, null), color: "error" }))),
            React.createElement(Box, null,
                React.createElement(Button, { onClick: onClose }, "Cancel"),
                React.createElement(Button, { onClick: handleSave, variant: "contained" }, projectData ? "Save" : "Create")))));
}
