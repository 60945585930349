import React, { useContext } from "react";
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
// } from "@mui/material";
// import { Receipt } from "@mui/icons-material";
import { SelectionContext } from "@features/inventory/Inventory";
import GenerateOrders from "./GenerateOrders";
const CreateOrderButton = () => {
    const selection = useContext(SelectionContext);
    return React.createElement(GenerateOrders, { selection: selection });
};
export default CreateOrderButton;
// const CreateOrderButton: React.FC = () => {
//   const selection = useContext(SelectionContext);
//   const [open, setOpen] = useState(false);
//   const { data: suppliers } = useSuppliersData();
//   const { data: orders } = useOrdersData();
//   const { mutate: createOrder } = useOrderCreate();
//   const [order, setOrder] = useState<OrderPack>({
//     name: "",
//     supplier: "",
//     items: [],
//     total: 0,
//     status: "",
//     financial: "",
//     project: "",
//     file: null,
//   });
//   // Compute common suppliers
//   const commonSupplierIds = React.useMemo(() => {
//     if (selection.length === 0) return [];
//     const supplierIdsPerItem = selection.map((item) =>
//       item.suppliers.map((s) => s.supplier.toString())
//     );
//     return arrayIntersection(supplierIdsPerItem);
//   }, [selection]);
//   const isDisabled = selection.length === 0 || commonSupplierIds.length === 0;
//   // Handlers
//   const handleClickOpen = () => {
//     if (commonSupplierIds.length > 0) {
//       // Initialize supplier in order
//       setOrder((prevOrder) => ({
//         ...prevOrder,
//         supplier: commonSupplierIds[0],
//       }));
//     }
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//     setOrder({
//       name: "",
//       supplier: "",
//       items: [],
//       total: 0,
//       status: "",
//       financial: "",
//       project: "",
//       file: "",
//     });
//   };
//   // Update order when supplier or selection changes
//   useEffect(() => {
//     if (order.supplier && selection.length > 0) {
//       const items = selection.map((item) => {
//         const supplierData = item.suppliers.find(
//           (s) => s.supplier.toString() === order.supplier
//         );
//         return {
//           component: item._id,
//           quantity: 1,
//           cost: supplierData?.cost || 0,
//           supplierData: supplierData || {},
//         };
//       });
//       const supplier = suppliers?.find((s) => s._id === order.supplier);
//       const orderName = `${supplier?.name || "Order"}${
//         (orders?.filter((o) => o.supplier === order.supplier).length || 0) + 1
//       }`;
//       setOrder((prevOrder) => ({
//         ...prevOrder,
//         name: orderName,
//         items,
//         total: items.reduce((sum, item) => sum + item.quantity * item.cost, 0),
//       }));
//     }
//   }, [order.supplier, selection, suppliers, orders]);
//   const handleChange = (id: string | null, key: string, value: any) => {
//     if (id) {
//       // Update specific item
//       setOrder((prevOrder) => {
//         const updatedItems = prevOrder.items.map((item) => {
//           if (item.component === id) {
//             return { ...item, [key]: value };
//           }
//           return item;
//         });
//         const total = updatedItems.reduce(
//           (sum, item) => sum + item.quantity * item.cost,
//           0
//         );
//         return { ...prevOrder, items: updatedItems, total };
//       });
//     } else {
//       // Update order-level field
//       setOrder((prevOrder) => ({
//         ...prevOrder,
//         [key]: value,
//       }));
//     }
//   };
//   const handleSave = () => {
//     const formData = new FormData();
//     formData.append("name", order.name);
//     formData.append("supplier", order.supplier);
//     formData.append("total", order.total.toFixed(2).toString());
//     // Exclude supplierData from items before sending
//     const itemsToSave = order.items.map(({ supplierData, ...rest }) => rest);
//     formData.append("items", JSON.stringify(itemsToSave));
//     // Append file if it exists and is a File object
//     if (order.file && typeof order.file !== "string") {
//       formData.append("file", order.file);
//     }
//     // Execute order creation
//     createOrder(formData);
//     handleClose();
//   };
//   return (
//     <>
//       <CustomButton
//         variant="outlined"
//         color="primary"
//         icon={<Receipt />}
//         disabled={isDisabled}
//         sx={{ mr: 1 }}
//         label="Create Order"
//         tooltip={
//           isDisabled
//             ? "Selected components do not share a common supplier."
//             : "Select products that share a common supplier to create an order"
//         }
//         onClick={handleClickOpen}
//       />
//       <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
//         <DialogTitle>Create Order</DialogTitle>
//         <DialogContent>
//           <CreateOrder
//             selection={selection}
//             order={order}
//             handleChange={handleChange}
//             suppliers={suppliers as Supplier[]}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button
//             variant="contained"
//             onClick={handleSave}
//             disabled={!order.supplier}
//           >
//             Save
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };
// export default CreateOrderButton;
