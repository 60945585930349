import { Box, Dialog, DialogContent, DialogTitle, Typography, Stack, Collapse, IconButton, Button, TextField, RadioGroup, FormControlLabel, Radio, Table, TableRow, TableCell, } from "@mui/material";
import React, { useState } from "react";
import { SelectedProject } from "../Projects";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useProjectUpdate } from "../hooks/useProjectUpdate";
import { calculateProjectFinancials } from "../utils/calculateProjectFinancials";
import { useFinancialsData } from "@features/financial/hooks/useFinancialsData";
import { showFinancialDialog } from "@features/Dialogs/DialogManager";
const ProjectFinancial = ({ open, setOpen, }) => {
    const project = React.useContext(SelectedProject);
    const { data: financial } = useFinancialsData();
    const { mutate: updateProject } = useProjectUpdate();
    const [expandZones, setExpandZones] = useState(false);
    const [expandPayments, setExpandPayments] = useState(false);
    // Discount editing state
    const [isEditingDiscount, setIsEditingDiscount] = useState(false);
    const [discountType, setDiscountType] = useState(project.discountType || "fixed");
    const [discountValue, setDiscountValue] = useState(project.discountValue || 0);
    const onClose = () => {
        setOpen(false);
    };
    // Use the utility function to calculate financials
    if (!financial)
        return null;
    const { overallTotal, discountAmount, totalAfterDiscount, totalPayments, balance, projectPayments, } = calculateProjectFinancials(project, financial);
    // Calculate totals by zone
    const totalsByZone = calculateTotalsByZone(project);
    const formatCurrency = (value) => {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format(value);
    };
    const onPaymentAdd = () => {
        showFinancialDialog(null, { project: project._id, amount: balance });
    };
    const handleDiscountTypeChange = (event) => {
        setDiscountType(event.target.value);
        setDiscountValue(0); // Reset discount value when type changes
    };
    const handleDiscountValueChange = (event) => {
        const value = parseFloat(event.target.value);
        setDiscountValue(isNaN(value) ? 0 : value);
    };
    const handleEditDiscount = () => {
        setIsEditingDiscount(true);
    };
    const handleCancelEdit = () => {
        // Reset to project's stored discount values
        setDiscountType(project.discountType || "fixed");
        setDiscountValue(project.discountValue || 0);
        setIsEditingDiscount(false);
    };
    const handleSaveDiscount = async () => {
        // Update the project with new discount values
        const updatedProject = {
            _id: project._id,
            discountType,
            discountValue,
        };
        try {
            await updateProject(updatedProject);
            // Optionally refresh the project data if needed
            setIsEditingDiscount(false);
        }
        catch (error) {
            console.error("Error updating discount:", error);
            // Handle error (e.g., show a notification)
        }
    };
    return (React.createElement(Dialog, { open: open, onClose: onClose, maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, null, "Project Financial"),
        React.createElement(DialogContent, null,
            React.createElement(Stack, { spacing: 2 },
                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Box, { display: "flex", alignItems: "center" },
                        React.createElement(Typography, { variant: "subtitle1" }, "Project Value"),
                        React.createElement(IconButton, { size: "small", onClick: () => setExpandZones(!expandZones) }, expandZones ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null))),
                    React.createElement(Typography, null, formatCurrency(overallTotal))),
                React.createElement(Collapse, { in: expandZones, timeout: "auto", unmountOnExit: true },
                    React.createElement(Stack, { spacing: 1, pl: 2 }, Object.entries(totalsByZone)
                        .sort(([a], [b]) => a.localeCompare(b))
                        .map(([zone, total]) => (React.createElement(Box, { key: zone, display: "flex", alignItems: "center", justifyContent: "space-between" },
                        React.createElement(Typography, { variant: "body2" },
                            "Zone: ",
                            zone),
                        React.createElement(Typography, { variant: "body2" }, formatCurrency(total))))))),
                React.createElement(Box, null,
                    React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                        React.createElement(Box, { display: "flex", alignItems: "center" },
                            React.createElement(Typography, { variant: "subtitle1" }, "Discount"),
                            !isEditingDiscount && (React.createElement(Button, { variant: "outlined", size: "small", onClick: handleEditDiscount, sx: { ml: 1 } }, "Edit Discount"))),
                        React.createElement(Typography, null, formatCurrency(discountAmount))),
                    isEditingDiscount && (React.createElement(Stack, { direction: "row", spacing: 1, alignItems: "center", mt: 1 },
                        React.createElement(RadioGroup, { row: true, value: discountType, onChange: handleDiscountTypeChange },
                            React.createElement(FormControlLabel, { value: "fixed", control: React.createElement(Radio, null), label: "Fixed" }),
                            React.createElement(FormControlLabel, { value: "percent", control: React.createElement(Radio, null), label: "%" })),
                        React.createElement(TextField, { label: "Discount", type: "number", size: "small", value: discountValue, onChange: handleDiscountValueChange, InputProps: {
                                inputProps: { min: 0 },
                            }, sx: { width: 100 } }),
                        React.createElement(Button, { variant: "contained", color: "primary", onClick: handleSaveDiscount }, "Save"),
                        React.createElement(Button, { onClick: handleCancelEdit }, "Cancel")))),
                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Typography, { variant: "subtitle1" }, "Total After Discount"),
                    React.createElement(Typography, null, formatCurrency(totalAfterDiscount))),
                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Box, { display: "flex", alignItems: "center" },
                        React.createElement(Typography, { variant: "subtitle1" }, "Payments"),
                        React.createElement(IconButton, { size: "small", onClick: () => setExpandPayments(!expandPayments), disabled: projectPayments.length === 0 }, expandPayments ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null)),
                        React.createElement(Button, { variant: "contained", color: "primary", size: "small", onClick: onPaymentAdd, sx: { ml: 1 } }, "Add Payment")),
                    React.createElement(Typography, null, formatCurrency(totalPayments))),
                React.createElement(Collapse, { in: expandPayments && projectPayments.length > 0, timeout: "auto", unmountOnExit: true },
                    React.createElement(Table, { size: "small" }, projectPayments
                        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                        .map((payment) => (React.createElement(TableRow, { key: payment._id },
                        React.createElement(TableCell, null, payment.name),
                        React.createElement(TableCell, null, new Intl.DateTimeFormat("en-US").format(new Date(payment.date))),
                        React.createElement(TableCell, null, formatCurrency(payment.amount)),
                        React.createElement(TableCell, null, payment.paid ? "Paid" : "Pending")))))),
                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Typography, { variant: "h6" }, "Balance"),
                    React.createElement(Typography, { variant: "h6" }, formatCurrency(balance)))))));
};
export default ProjectFinancial;
// Function to calculate totals by zone
function calculateTotalsByZone(project) {
    const totalsByZone = {};
    project.products.forEach((product) => {
        const productPrice = parseFloat(`${product.price}`) || 0;
        if (totalsByZone[product.zone]) {
            totalsByZone[product.zone] += productPrice;
        }
        else {
            totalsByZone[product.zone] = productPrice;
        }
    });
    return totalsByZone;
}
