import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, Stack, DialogActions, Button, Box, FormControl, InputLabel, Select, MenuItem, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Collapse, Typography, } from "@mui/material";
import ConfirmButton from "src/components/ConfirmButton";
import { Delete, DeleteForever, ExpandLess, ExpandMore, } from "@mui/icons-material";
import { useOrderUpdate } from "../hooks/useOrderUpdate";
import { useOrderDelete } from "../hooks/useOrderDelete";
import { useSuppliersData } from "@features/supplier/hooks/useSuppliersData";
import { useProjectsData } from "@features/project/hooks/useProjectsData";
import CustomAutocomplete from "src/components/Autocomplete";
import { useComponentsData } from "@features/inventory/hooks/useComponentsData";
const OrderDialog = ({ orderData, open, onClose }) => {
    const { mutate: updateOrder } = useOrderUpdate();
    const { mutate: deleteOrder } = useOrderDelete();
    const { data: suppliers } = useSuppliersData();
    const { data: projects } = useProjectsData();
    const { data: components } = useComponentsData();
    const [data, setData] = useState({
        name: orderData?.name || "",
        supplier: orderData?.supplier?._id || "",
        total: orderData?.total || 0,
        project: orderData?.project?._id || "",
        items: orderData?.items || [],
    });
    const [showItems, setShowItems] = useState(false); // State for toggling items view
    // Determine if the form should be editable
    const isEditable = orderData?.received !== true;
    useEffect(() => {
        if (orderData) {
            setData({
                name: orderData.name || "",
                supplier: orderData.supplier?._id || "",
                total: orderData.total || 0,
                project: orderData.project?._id || "",
                items: orderData.items.map((item) => ({
                    ...item,
                    component: item.component,
                })),
            });
        }
        else {
            setData({
                name: "",
                supplier: "",
                total: 0,
                project: "",
                items: [],
            });
        }
    }, [orderData]);
    // Handle changes to form fields
    const onChange = (e) => {
        const { name, value } = e.target;
        if (!name)
            return;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    // Handle changes to item quantities and costs
    const handleItemChange = (index, key, value) => {
        setData((prevData) => {
            const newItems = [...prevData.items];
            newItems[index] = { ...newItems[index], [key]: value };
            return { ...prevData, items: newItems };
        });
    };
    // Recalculate total when items change
    useEffect(() => {
        const newTotal = data.items.reduce((sum, item) => {
            return sum + item.cost * item.quantity;
        }, 0);
        setData((prevData) => ({ ...prevData, total: newTotal }));
    }, [data.items]);
    const handleSave = () => {
        if (orderData) {
            updateOrder({ ...data, _id: orderData._id });
        }
        onClose();
    };
    const handleDelete = () => {
        if (!orderData) {
            return;
        }
        deleteOrder(orderData._id);
        onClose();
    };
    const toggleShowItems = () => {
        setShowItems((prev) => !prev);
    };
    return (React.createElement(Dialog, { open: open, onClose: onClose, maxWidth: "md", fullWidth: true },
        React.createElement(DialogTitle, null, "Edit Order"),
        React.createElement(DialogContent, null,
            React.createElement(Stack, { spacing: 2, sx: { mt: 1 } },
                React.createElement(TextField, { fullWidth: true, label: "Order Name", name: "name", value: data.name, onChange: onChange, disabled: !isEditable }),
                React.createElement(CustomAutocomplete, { options: suppliers?.map((supplier) => ({
                        title: supplier.name,
                        value: supplier._id,
                    })) || [], disabled: true, label: "Supplier", value: data.supplier
                        ? suppliers?.find((s) => s._id === data.supplier)?.name || ""
                        : "", onInputChange: () => { } }),
                React.createElement(FormControl, { fullWidth: true, disabled: !isEditable },
                    React.createElement(InputLabel, { id: "project-select-label" }, "Project"),
                    React.createElement(Select, { labelId: "project-select-label", id: "project-select", name: "project", value: data.project, label: "Project", onChange: onChange },
                        React.createElement(MenuItem, { value: "" },
                            React.createElement("em", null, "None")),
                        projects?.map((project) => (React.createElement(MenuItem, { key: project._id, value: project._id }, project.name))))),
                React.createElement(Button, { variant: "outlined", onClick: toggleShowItems, startIcon: showItems ? React.createElement(ExpandLess, null) : React.createElement(ExpandMore, null) }, showItems ? "Hide Items" : "Show Items"),
                React.createElement(Collapse, { in: showItems, timeout: "auto", unmountOnExit: true },
                    React.createElement(TableContainer, { component: Paper, sx: { mt: 2 } },
                        React.createElement(Table, { "aria-label": "order items" },
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, null, "Name"),
                                    React.createElement(TableCell, null, "Code"),
                                    React.createElement(TableCell, { align: "right" }, "Cost"),
                                    React.createElement(TableCell, { align: "right" }, "Quantity"),
                                    React.createElement(TableCell, { align: "right" }, "Item Total"))),
                            React.createElement(TableBody, null,
                                data.items.map((item, index) => {
                                    const orderItem = orderData?.items[index];
                                    const component = components?.find((c) => c._id === orderItem?.component);
                                    const componentName = component?.name || "";
                                    const code = component?.suppliers.find((s) => s.supplier === orderData?.supplier?._id)?.code || "";
                                    const cost = item.cost || 0;
                                    const quantity = item.quantity || 0;
                                    const itemTotal = cost * quantity;
                                    return (React.createElement(TableRow, { key: component?._id || item.component || index },
                                        React.createElement(TableCell, null, componentName),
                                        React.createElement(TableCell, null, code),
                                        React.createElement(TableCell, { align: "right" }, isEditable ? (React.createElement(TextField, { type: "number", value: cost, onChange: (e) => handleItemChange(index, "cost", parseFloat(e.target.value) || 0), inputProps: { min: 0, step: 0.01 }, size: "small" })) : (cost.toFixed(2))),
                                        React.createElement(TableCell, { align: "right" }, isEditable ? (React.createElement(TextField, { type: "number", value: quantity, onChange: (e) => handleItemChange(index, "quantity", parseInt(e.target.value, 10) || 0), inputProps: { min: 0 }, size: "small" })) : (quantity)),
                                        React.createElement(TableCell, { align: "right" }, itemTotal.toFixed(2))));
                                }),
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { colSpan: 4, align: "right" },
                                        React.createElement(Typography, { variant: "h6" }, "Order Total")),
                                    React.createElement(TableCell, { align: "right" },
                                        React.createElement(Typography, { variant: "h6" }, data.total.toFixed(2)))))))))),
        React.createElement(DialogActions, { sx: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            } },
            React.createElement(Box, null, orderData && (React.createElement(ConfirmButton, { onClick: handleDelete, label: "Delete", startIcon: React.createElement(Delete, null), confirmIcon: React.createElement(DeleteForever, null), color: "error", disabled: !isEditable }))),
            React.createElement(Box, null,
                React.createElement(Button, { onClick: onClose }, "Cancel"),
                React.createElement(Button, { onClick: handleSave, variant: "contained", disabled: !isEditable }, "Save")))));
};
export default OrderDialog;
