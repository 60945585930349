import { updatePerson } from "@api/personApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useNotificationStore from "@stores/notificationStore";
export function usePersonUpdate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: updatePerson,
        onSuccess: (updatedPerson) => {
            queryClient.setQueryData(["people"], (oldData) => {
                if (!oldData)
                    return [];
                return oldData.map((person) => {
                    if (person._id === updatedPerson._id) {
                        return updatedPerson;
                    }
                    return person;
                });
            });
            addNotification("Person updated.", "success");
        },
        onError: (error) => {
            console.error(error);
            addNotification("Could not update person.", "error");
        },
    });
}
