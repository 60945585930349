import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, } from "@mui/material";
/**
 * This component shows a button and a dialog to toggle "selected" state on products.
 * It no longer filters products, but toggles a "selected" boolean based on user input.
 */
export function SelectProductsButton({ products, onSelectionChange, }) {
    const [open, setOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    useEffect(() => {
        if (open) {
            // When dialog opens, use current product's "selected" state to set checkboxes
            const currentlySelected = products
                .filter((p) => p.selected)
                .map((p) => p._id);
            setSelectedIds(currentlySelected);
        }
    }, [open, products]);
    const handleCheckboxChange = (id, checked) => {
        setSelectedIds((prev) => checked ? [...prev, id] : prev.filter((itemId) => itemId !== id));
    };
    const handleConfirm = () => {
        // Update the products array with the selected state
        const updated = products.map((p) => ({
            ...p,
            selected: selectedIds.includes(p._id),
        }));
        onSelectionChange(updated);
        setOpen(false);
    };
    const abled = products && products.length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "outlined", disabled: !abled, onClick: () => setOpen(true) }, "Select Products"),
        abled && (React.createElement(Dialog, { open: open, onClose: () => setOpen(false) },
            React.createElement(DialogTitle, null, "Select Products"),
            React.createElement(DialogContent, null, products?.map((p) => (React.createElement("div", { key: p._id },
                React.createElement("input", { type: "checkbox", checked: selectedIds.includes(p._id), onChange: (e) => handleCheckboxChange(p._id, e.target.checked) }),
                p.name,
                " (ID: ",
                p.id,
                ")")))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => setOpen(false) }, "Cancel"),
                React.createElement(Button, { onClick: handleConfirm }, "Confirm"))))));
}
