import React, { useEffect, useMemo, useCallback } from "react";
import { Box, IconButton, Paper, Stack, TextField, Tooltip, Typography, useTheme, Fade, Collapse, } from "@mui/material";
import { Delete, CopyAll, DragHandle, } from "@mui/icons-material";
import { Handle, NodeToolbar, Position } from "@xyflow/react";
import { useShallow } from "zustand/react/shallow";
import { HandleBackContext } from "../inventory/Inventory";
import { useComponentCreateStore } from "./store/createComponentStore";
import { useComponentsData } from "../inventory/hooks/useComponentsData";
import { useComponentDelete } from "./hooks/useComponentDelete";
import SwitchComponentNode from "./componentNode/SwitchComponentNode";
import Saved from "./componentNode/Saved";
import Parameters from "./componentNode/Parameters";
import Quantity from "./componentNode/Quantity";
import { Categories } from "./componentNode/Categories";
import Image from "./componentNode/Image";
import Price from "./componentNode/Price";
import Cost from "./componentNode/Cost";
import Suppliers from "./componentNode/Suppliers";
import Code from "./componentNode/Code";
import Overrides from "./componentNode/Overrides";
import NodeMenu from "./componentNode/NodeMenu";
export const DisabledContext = React.createContext(false);
export const NodeContext = React.createContext({
    id: "",
    data: {},
});
const selector = (state) => ({
    onNodeChange: state.onNodeChange,
    getSaved: state.getSaved,
    getParentNode: state.getParentNode,
    deleteNode: state.deleteNode,
    duplicateNode: state.duplicateNode,
});
const ComponentNode = React.memo(({ id, data, selected }) => {
    const { onNodeChange, getSaved, getParentNode, deleteNode, duplicateNode } = useComponentCreateStore(useShallow(selector));
    const { data: componentsData } = useComponentsData();
    const { mutate: deleteComponent } = useComponentDelete();
    const components = componentsData || [];
    const handleBack = React.useContext(HandleBackContext);
    const theme = useTheme();
    // Memoize the status to avoid unnecessary recalculations
    const status = useMemo(() => getSaved(id, components.find((c) => c._id === id.split("_").pop())), [id, components, data, getSaved]);
    // Update node when status changes
    useEffect(() => {
        onNodeChange(id, { field: "saved", value: status });
    }, [status, id, onNodeChange]);
    // Memoize onChange handler
    const onChange = useCallback((field, value) => {
        onNodeChange(id, { field, value });
    }, [id, onNodeChange]);
    // Memoize parent node retrieval
    const parent = getParentNode(id);
    const parentDisabled = useMemo(() => parent?.data.saved === "locked", [parent]);
    // Memoize delete handler
    const handleDelete = useCallback(() => {
        deleteNode(id);
    }, [id, deleteComponent, handleBack, deleteNode]);
    // Memoize duplicate handler
    const handleDuplicate = useCallback(() => {
        duplicateNode(id);
    }, [id, duplicateNode]);
    // Memoize context value
    const nodeContextValue = useMemo(() => ({ id, data }), [id, data]);
    // Memoize styles
    const nodeToolbarStyles = useMemo(() => ({
        width: "100%",
        display: "flex",
        gap: 1,
        direction: "row",
        justifyContent: "space-between",
    }), []);
    const dragHandleStyles = useMemo(() => ({
        cursor: "move",
    }), []);
    console.log("rendering node", id);
    return (React.createElement(DisabledContext.Provider, { value: status === "locked" },
        React.createElement(NodeContext.Provider, { value: nodeContextValue },
            React.createElement(React.Fragment, null,
                React.createElement(NodeToolbar, { align: "start" },
                    React.createElement(Box, { sx: nodeToolbarStyles },
                        React.createElement(Box, { sx: { display: "flex" } },
                            React.createElement(Fade, { in: true, timeout: { enter: 500, exit: 500 } },
                                React.createElement("div", null,
                                    React.createElement(NodeMenu, { id: id, data: data }))),
                            React.createElement(Fade, { in: true, timeout: { enter: 1000, exit: 500 } },
                                React.createElement("div", null,
                                    React.createElement(SwitchComponentNode, { id: id, disabled: parentDisabled }))),
                            React.createElement(Fade, { in: true, timeout: { enter: 1500, exit: 500 } },
                                React.createElement("div", null,
                                    React.createElement(Saved, { id: id, status: status }))),
                            React.createElement(Fade, { in: true, timeout: { enter: 2000, exit: 500 } },
                                React.createElement("div", null,
                                    React.createElement(IconButton, { onClick: handleDelete, size: "small", disabled: parentDisabled || !id.includes("_") },
                                        React.createElement(Delete, null)))),
                            React.createElement(Fade, { in: true, timeout: { enter: 2000, exit: 500 } },
                                React.createElement("div", null,
                                    React.createElement(IconButton, { onClick: handleDuplicate, size: "small", disabled: parentDisabled },
                                        React.createElement(CopyAll, null))))))),
                React.createElement(Paper, { id: id, sx: {
                        p: 1,
                        width: 350,
                        transition: "all 0.5s",
                        boxShadow: selected ? 6 : 2,
                        cursor: "default",
                        border: 2,
                        borderColor: selected
                            ? "primary.main"
                            : data.isOption
                                ? theme.palette.text.primary
                                : "transparent",
                        borderStyle: data.isOption ? "dashed" : "solid",
                    } },
                    id.includes("_") && (React.createElement(Handle, { type: "target", position: Position.Top, style: {
                            borderColor: selected
                                ? theme.palette.primary.main
                                : "white",
                        } })),
                    React.createElement(Stack, { direction: "row", spacing: 1, alignItems: "center" },
                        React.createElement(IconButton, { className: "drag-handle", sx: dragHandleStyles },
                            React.createElement(DragHandle, null)),
                        React.createElement(TextField, { id: `label-${id}`, name: "label", value: data.name, disabled: status === "locked", fullWidth: true, onChange: (evt) => onChange("name", evt.target.value), size: "small" })),
                    React.createElement(Collapse, { in: data.image !== null, unmountOnExit: true },
                        React.createElement(Image, null)),
                    React.createElement(Categories, { categories: data.categories }),
                    id.includes("_") && !data.isOption && (React.createElement(Quantity, { id: id, disabled: parentDisabled })),
                    React.createElement(Collapse, { in: data.price !== null || data.priceModifier !== null, unmountOnExit: true },
                        React.createElement(Price, null)),
                    React.createElement(Collapse, { in: data.cost !== null && data.suppliers.length === 0, unmountOnExit: true },
                        React.createElement(Cost, null)),
                    React.createElement(Collapse, { in: data.suppliers?.length > 0, unmountOnExit: true },
                        React.createElement(Suppliers, null)),
                    React.createElement(Collapse, { in: data.code !== null, unmountOnExit: true },
                        React.createElement(Code, null)),
                    data.parameters && data.parameters.length > 0 && React.createElement(Parameters, null),
                    React.createElement(Collapse, { in: data.parametersOverride?.length > 0, unmountOnExit: true },
                        React.createElement(Overrides, null)),
                    React.createElement(Tooltip, { title: "Drag and drop to add a subcomponent." },
                        React.createElement(Handle, { type: "source", id: "component", isConnectable: status !== "locked", style: {
                                width: "fit-content",
                                height: "fit-content",
                                bottom: -24,
                                borderRadius: 4,
                                border: 0,
                                boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                transition: "opacity 0.5s",
                            }, onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }, position: Position.Bottom, children: React.createElement(Paper, { sx: {
                                    p: 1,
                                    pointerEvents: "none",
                                    border: 2,
                                    borderColor: selected ? "primary.main" : "transparent",
                                    transition: "all 0.5s",
                                    borderStyle: data.isOption ? "dashed" : "solid",
                                } },
                                React.createElement(Typography, null, data.isOption ? "Choices" : "Subcomponents")) })))))));
}, (prevProps, nextProps) => {
    return (prevProps.data === nextProps.data &&
        prevProps.selected === nextProps.selected);
});
export default ComponentNode;
