import React from "react";
import { Box, Chip, Fade } from "@mui/material";
import { useComponentsData } from "./hooks/useComponentsData";
import { useSettingsData } from "../userSettings/hooks/useSettingsData";
import { useSuppliersData } from "../supplier/hooks/useSuppliersData";
import ComponentCreation from "../componentCreate/ComponentCreation";
import { Keyword } from "../core/CustomDataGrid";
import { useComponentDelete } from "../componentCreate/hooks/useComponentDelete";
import { useComponentUpdate } from "./hooks/useComponentUpdate";
import InventoryDataGrid from "./components/InventoryDataGrid";
import { getContrastYIQ } from "src/utils/colorUtils";
import { KeyboardOptionKey } from "@mui/icons-material";
export const HandleBackContext = React.createContext(() => { });
export const SelectionContext = React.createContext([]);
const Inventory = () => {
    const { data: components, isLoading } = useComponentsData();
    const { data: settings } = useSettingsData();
    const { data: suppliers } = useSuppliersData();
    const { mutate: deleteComponent } = useComponentDelete();
    const { mutate: updateComponent } = useComponentUpdate();
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [selectedComponent, setSelectedComponent] = React.useState(null);
    const [creatingComponent, setCreatingComponent] = React.useState(false);
    // Precompute supplier names and default cost for each component to avoid doing it in renderCell
    const processedComponents = React.useMemo(() => {
        if (!components || !suppliers)
            return components || [];
        // Pre-map suppliers by id to name for faster lookup
        const supplierMap = new Map(suppliers.map((s) => [s._id, s.name]));
        return components.map((c) => {
            const supplierNames = c.suppliers
                .slice() // clone array if needed
                .sort((a, b) => (b.default ? 1 : 0) - (a.default ? 1 : 0))
                .map((sup) => supplierMap.get(sup.supplier) || "Unknown")
                .join(", ");
            const defaultSupplier = c.suppliers.find((sup) => sup.default);
            const finalCost = defaultSupplier?.cost || c.cost;
            const finalPrice = c.priceModifier ? `x${c.priceModifier}` : c.price;
            let categoryChips = [];
            if (c.categories && settings?.categories) {
                const categoryColorMap = new Map(settings.categories.map((cat) => [cat.name, cat.color]));
                categoryChips = c.categories.map((catName) => {
                    const color = categoryColorMap.get(catName) || "grey";
                    return {
                        name: catName,
                        color,
                        contrast: getContrastYIQ(color),
                    };
                });
            }
            return {
                ...c,
                __supplierNames: supplierNames,
                __finalCost: finalCost,
                __finalPrice: finalPrice,
                __categoryChips: categoryChips,
            };
        });
    }, [components, suppliers, settings]);
    const selection = React.useMemo(() => selectionModel
        .map((id) => processedComponents?.find((c) => c._id === id))
        .filter(Boolean), [selectionModel, processedComponents]);
    const singleSelection = selection.length === 1;
    const multipleSelection = selection.length > 0;
    const handleBack = React.useCallback(() => {
        setSelectedComponent(null);
        setCreatingComponent(false);
    }, []);
    const onAddClick = React.useCallback(() => {
        setSelectedComponent(null);
        setCreatingComponent(true);
    }, []);
    const onDuplicate = React.useCallback(() => {
        if (singleSelection) {
            setSelectedComponent({ ...selection[0], duplicate: true });
            setCreatingComponent(true);
        }
    }, [singleSelection, selection]);
    const onEdit = React.useCallback(() => {
        if (singleSelection) {
            setSelectedComponent(selection[0]);
            setCreatingComponent(true);
        }
    }, [singleSelection, selection]);
    const onDelete = React.useCallback(() => {
        if (multipleSelection) {
            deleteComponent(selectionModel);
            setSelectionModel([]);
        }
        else if (singleSelection) {
            deleteComponent([selectionModel[0]]);
            setSelectionModel([]);
        }
    }, [multipleSelection, singleSelection, selectionModel, deleteComponent]);
    const handleCellEditCommit = React.useCallback((params) => {
        const { id, field, value } = params;
        if (field === "stock") {
            const componentToUpdate = processedComponents?.find((component) => component._id === id);
            if (componentToUpdate) {
                updateComponent({ _id: componentToUpdate._id, stock: value });
            }
        }
    }, [processedComponents, updateComponent]);
    const columns = React.useMemo(() => {
        return [
            {
                field: "image",
                headerName: "",
                width: 75,
                renderCell: (params) => {
                    const src = params.value;
                    return src ? (React.createElement("img", { src: src, alt: "image", style: { width: 50, height: 50, objectFit: "cover" } })) : null;
                },
            },
            {
                field: "name",
                headerName: "Name",
                flex: 1,
                renderCell: (params) => (React.createElement(Box, { sx: { display: "flex", alignItems: "center", gap: 1 } },
                    params.row.isOption && React.createElement(KeyboardOptionKey, null),
                    params.row.name)),
            },
            {
                field: "stock",
                headerName: "Stock",
                flex: 0.3,
                type: "number",
                editable: true,
            },
            { field: "code", headerName: "Code", flex: 0.5 },
            {
                field: "cost",
                headerName: "Cost",
                flex: 0.5,
                type: "number",
                valueGetter: (value, row) => row.__finalCost,
            },
            {
                field: "price",
                headerName: "Price",
                flex: 0.5,
                type: "number",
                valueGetter: (value, row) => row.__finalPrice,
            },
            {
                field: "suppliers",
                headerName: "Supplier",
                flex: 0.5,
                valueGetter: (value, row) => row.__supplierNames,
            },
            {
                field: "categories",
                headerName: "Category",
                flex: 1,
                renderCell: (params) => {
                    const chips = params.row.__categoryChips || [];
                    return chips.length ? (React.createElement(Box, { sx: {
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            height: "100%",
                        } }, chips.map((chip) => (React.createElement(Chip, { key: chip.name, label: chip.name, size: "small", sx: {
                            borderRadius: 1,
                            backgroundColor: chip.color,
                            color: chip.contrast,
                        } }))))) : null;
                },
            },
        ];
    }, []);
    return (React.createElement(SelectionContext.Provider, { value: selection },
        React.createElement(HandleBackContext.Provider, { value: handleBack }, creatingComponent ? (React.createElement(Fade, { in: true, timeout: { enter: 500, exit: 0 }, unmountOnExit: true },
            React.createElement(Box, { sx: {
                    width: "100vw",
                    height: "calc(100vh - 72px)",
                    position: "absolute",
                    boxShadow: "inset(10px 0 0 0 black)",
                    top: 72,
                    left: 0,
                    zIndex: 0,
                } },
                React.createElement(ComponentCreation, { handleBack: handleBack, editComponent: selectedComponent })))) : (React.createElement(InventoryDataGrid, { rows: processedComponents || [], loading: isLoading, columns: columns, keyword: Keyword.Component, onAddClick: onAddClick, onEditClick: onEdit, onDuplicateClick: onDuplicate, onDeleteClick: onDelete, canEdit: singleSelection, canDuplicate: singleSelection, canDelete: multipleSelection, checkboxSelection: true, rowSelectionModel: selectionModel, onRowSelectionModelChange: (newSelection) => setSelectionModel(newSelection), onCellEditStop: handleCellEditCommit, onRowDoubleClick: ({ row }) => {
                setSelectedComponent(row);
                setCreatingComponent(true);
            }, sx: { height: 750 } })))));
};
export default Inventory;
