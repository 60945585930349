import React from "react";
import { Button, Tooltip } from "@mui/material";
const CustomButton = ({ onClick, icon, label, confirmIcon, tooltip, tooltipPosition = "bottom", ...rest }) => {
    const [clicked, setClicked] = React.useState(false);
    const handleClick = (e) => {
        if (confirmIcon) {
            if (clicked) {
                onClick(e);
                setClicked(false);
            }
            else {
                setClicked(true);
                setTimeout(() => {
                    setClicked(false);
                }, 3000);
            }
        }
        else {
            onClick(e);
        }
    };
    const renderIcon = () => {
        if (clicked && confirmIcon) {
            return confirmIcon;
        }
        return rest.startIcon || icon;
    };
    const buttonContent = label
        ? clicked && confirmIcon
            ? "Confirm"
            : label
        : null;
    const button = (React.createElement(Button, { ...rest, onClick: handleClick, variant: confirmIcon ? (clicked ? "contained" : "outlined") : rest.variant, size: rest.size || "medium", sx: label
            ? rest.sx
            : { pl: 1, pr: 1, minWidth: "40px", maxWidth: "40px", ...rest.sx }, startIcon: label ? renderIcon() : undefined }, label ? buttonContent : renderIcon()));
    const styles = rest.sx ? rest.sx : {};
    return !label && tooltip ? (React.createElement(Tooltip, { title: clicked ? "Confirm" : tooltip, placement: tooltipPosition, arrow: true },
        React.createElement("span", { style: styles }, button))) : (React.createElement(Tooltip, { title: tooltip, placement: tooltipPosition, arrow: true },
        React.createElement("span", { style: styles }, button)));
};
export default CustomButton;
