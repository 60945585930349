import { Avatar, AvatarGroup, Box, Checkbox, FormControlLabel, ListItemAvatar, ListItemText, Menu, MenuItem, Paper, Typography, } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import { SelectedProject } from "../../Projects";
import { useProjectUpdate } from "../../hooks/useProjectUpdate";
import { usePeopleData } from "@features/people/hooks/usePeopleData";
const Task = ({ task }) => {
    const project = React.useContext(SelectedProject);
    // const { setDraggedTask } = React.useContext(DragContext);
    const { data: people } = usePeopleData();
    const { mutate: updateProject } = useProjectUpdate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    if (!project)
        return null;
    const handleCheckTask = () => {
        console.log("Check Task");
        if (task.completed) {
            updateProject({
                tasks: project.tasks.map((t) => t._id === task._id ? { ...t, completed: false } : t),
                _id: project._id,
            });
        }
        else {
            updateProject({
                tasks: project.tasks.map((t) => t._id === task._id ? { ...t, completed: true } : t),
                _id: project._id,
            });
        }
    };
    const handleAssignTask = (person) => {
        if (task.assignedTo.includes(person)) {
            updateProject({
                _id: project._id,
                tasks: project.tasks.map((t) => t._id === task._id
                    ? { ...t, assignedTo: t.assignedTo.filter((p) => p !== person) }
                    : t),
            });
        }
        else {
            updateProject({
                _id: project._id,
                tasks: project.tasks.map((t) => t._id === task._id
                    ? { ...t, assignedTo: [...t.assignedTo, person] }
                    : t),
            });
        }
    };
    // const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    //   setDraggedTask(task);
    //   e.dataTransfer.setData("text/plain", task.name);
    // };
    const projectPeople = project.people
        .map((p) => people?.find((person) => person._id === p))
        .filter((p) => p);
    console.log(task);
    return (React.createElement(Paper, { sx: { p: 1 }, elevation: 2 },
        React.createElement(Box, { sx: { display: "flex", justifyContent: "space-between", gap: 1 } },
            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: task.completed, onChange: handleCheckTask }), label: task.name }),
            React.createElement(Box, { sx: { display: "flex", justifyContent: "flex-end", gap: 1 } },
                React.createElement(AvatarGroup, { max: 4, onClick: (e) => setAnchorEl(e.currentTarget), sx: {
                        cursor: "pointer",
                        ":hover": { opacity: 0.7 },
                        transition: "all 0.3s",
                    } },
                    task.assignedTo.map((personId) => {
                        const person = people?.find((p) => p._id === personId);
                        if (!person)
                            return null;
                        return (React.createElement(Avatar, { key: person._id, alt: person.firstName, src: person.avatar, sx: { width: 32, height: 32 } }));
                    }),
                    task.assignedTo.length === 0 && (React.createElement(Avatar, { sx: { width: 32, height: 32 } }))),
                React.createElement(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: () => setAnchorEl(null), anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    } }, projectPeople.length ? (projectPeople.map((person) => (React.createElement(MenuItem, { key: person._id, onClick: () => handleAssignTask(person), sx: {
                        backgroundColor: task.assignedTo.includes(person._id)
                            ? "primary.main"
                            : "default",
                        ":hover": {
                            backgroundColor: task.assignedTo.includes(person._id)
                                ? "primary.light"
                                : "default",
                        },
                        color: task.assignedTo.includes(person._id)
                            ? "primary.contrastText"
                            : "default",
                    } },
                    React.createElement(ListItemAvatar, null,
                        React.createElement(Avatar, { alt: person.firstName, src: person.avatar, sx: { width: 32, height: 32 } })),
                    React.createElement(ListItemText, { primary: `${person.firstName} ${person.lastName}` }))))) : (React.createElement(MenuItem, { disabled: true }, "No people in project."))))),
        React.createElement(Box, { sx: { display: "flex", justifyContent: "space-between", gap: 1 } },
            React.createElement(Typography, { variant: "caption" }, dayjs(task.start).format("DD/MM/YYYY")),
            React.createElement(Typography, { variant: "caption" }, dayjs(task.end).format("DD/MM/YYYY")))));
};
export default Task;
