// useOrdersData.ts
import { useQuery } from "@tanstack/react-query";
import { getOrders } from "@api/orderApi";
export const useOrdersData = () => {
    return useQuery({
        queryKey: ["orders"],
        queryFn: getOrders,
        staleTime: Infinity,
    });
};
