import { Box, Button, Checkbox, Fade, FormControlLabel, Stack, TextField, } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams, } from "react-router-dom";
import useAuthStore from "../../stores/authStore";
import { confirmEmail } from "../../api/userApi";
import useNotificationStore from "../../stores/notificationStore";
import { ArrowBack } from "@mui/icons-material";
const Login = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { login } = useAuthStore();
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [stayLoggedIn, setStayLoggedIn] = React.useState(false);
    const { addNotification } = useNotificationStore();
    const handleLogin = async () => {
        await login(email, password, stayLoggedIn, (role) => {
            if (role) {
                navigate(`/${role}`);
            }
            else {
                navigate(`/`);
            }
        });
    };
    const handleEmailConfirmation = async () => {
        const token = searchParams.get("token");
        if (token) {
            try {
                const data = await confirmEmail(token);
                addNotification("Email confirmed. You can now log in.", "success");
                setEmail(data.email);
                setSearchParams({});
            }
            catch (error) {
                console.error(error);
                addNotification("Error confirming email.", "error");
                navigate("/");
            }
        }
    };
    useEffect(() => {
        handleEmailConfirmation();
    }, []);
    return (React.createElement(Box, { sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        } },
        React.createElement(Fade, { key: "loginBack", in: true, timeout: { enter: 2000 } },
            React.createElement(Button, { onClick: () => navigate("/"), sx: { mr: 1, width: 100, ml: "-100px" }, startIcon: React.createElement(ArrowBack, null) }, "Back")),
        React.createElement(Stack, { direction: "column", spacing: 1, sx: { width: 300 } },
            React.createElement(Fade, { key: "email", in: true, timeout: { enter: 500 } },
                React.createElement(TextField, { label: "Email", value: email, onChange: (e) => setEmail(e.target.value) })),
            React.createElement(Fade, { key: "password", in: true, timeout: { enter: 1000 } },
                React.createElement(TextField, { label: "Password", value: password, type: "password", inputProps: {
                        autoComplete: "new-password",
                        form: {
                            autoComplete: "off",
                        },
                    }, onChange: (e) => setPassword(e.target.value) })),
            React.createElement(Fade, { key: "stayLoggedIn", in: true, timeout: { enter: 1500 } },
                React.createElement(FormControlLabel, { sx: { justifyContent: "center" }, control: React.createElement(Checkbox, { checked: stayLoggedIn, onChange: (e) => setStayLoggedIn(e.target.checked) }), label: "Remember" })),
            React.createElement(Fade, { key: "login", in: true, timeout: { enter: 2000 } },
                React.createElement(Button, { onClick: handleLogin, variant: "contained", sx: { transition: "all 500ms" } }, "Login")))));
};
export default Login;
