import * as React from "react";
import { Box, Button, Collapse, IconButton } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import Clear from "@mui/icons-material/Clear";
import { ConditionField } from "./ConditionField"; // Assuming ConditionField is in the same directory
import { selector, useComponentCreateStore, } from "../store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
export const Conditions = ({ id, parameter }) => {
    const value = parameter.value;
    const conditions = React.useMemo(() => checkForConditions(value), [value]);
    const { onNodeChange } = useComponentCreateStore(useShallow(selector));
    const handleConditionChange = (field, value, index) => {
        const newConditions = [...conditions];
        if (value.includes("/")) {
            value = value.replaceAll("/", "÷");
        }
        newConditions[index][field] = value;
        onNodeChange(id, {
            field: "parameters",
            value: {
                ...parameter,
                value: `${conditionsToValue(newConditions)}!!${parameter.value.split("!!")[1]}`,
            },
        });
    };
    const addOr = () => {
        const newConditions = [...conditions];
        newConditions.push({
            name: `C${conditions.length}`,
            if: "",
            then: "",
            isTrue: false,
            error: "",
        });
        onNodeChange(id, {
            field: "parameters",
            value: {
                ...parameter,
                value: `${conditionsToValue(newConditions)}!!${parameter.value.split("!!")[1]}`,
            },
        });
    };
    const deleteCondition = (i) => {
        const newConditions = [...conditions];
        newConditions.splice(i, 1);
        onNodeChange(id, {
            field: "parameters",
            value: {
                ...parameter,
                value: `${conditionsToValue(newConditions)}!!${parameter.value.split("!!")[1]}`,
            },
        });
    };
    return (React.createElement(Box, { sx: { width: "100%", mb: 1 } },
        React.createElement(TransitionGroup, null, conditions.map((condition, i) => (React.createElement(Collapse, { key: condition.name, sx: {
                width: "100%",
                mt: 1,
                ":first-of-type": {
                    mt: 0,
                },
            } },
            React.createElement(Box, null,
                React.createElement(Box, { sx: { display: "flex", gap: 1 } },
                    React.createElement(Box, { sx: { flex: 1 } },
                        React.createElement(ConditionField, { label: "If", userInput: condition.if, setUserInput: (value) => handleConditionChange("if", value, i), 
                            // checkFormula={checkFormula}
                            fieldId: `if${i}`, fieldType: "if" })),
                    React.createElement(Box, { sx: { flex: 0.91 } },
                        React.createElement(ConditionField, { label: "Then", userInput: condition.then, setUserInput: (value) => handleConditionChange("then", value, i), 
                            // checkFormula={checkFormula}
                            fieldId: `then${i}`, fieldType: "then" })),
                    i !== 0 && (React.createElement(Box, { sx: { flex: 0.07 } },
                        React.createElement(IconButton, { disabled: i === 0, onClick: () => deleteCondition(i), color: condition.isTrue ? "success" : "error" },
                            React.createElement(Clear, null))))),
                React.createElement(Box, { sx: { display: "flex", justifyContent: "center", mt: 1 } }, i !== conditions.length - 1 ? ("OR") : (React.createElement(Button, { size: "small", variant: "outlined", onClick: addOr }, "Or"))))))))));
};
const checkForConditions = (value) => {
    const initialConditions = [
        {
            name: "C0",
            if: "",
            then: "",
            isTrue: false,
            error: "",
        },
    ];
    const conditionsPart = value.split("!!")[0];
    if (!conditionsPart)
        return initialConditions;
    return conditionsPart.split("||").map((c, i) => ({
        name: `C${i}`,
        if: c.split(":")[0],
        then: c.split(":")[1],
        isTrue: false,
        error: "",
    }));
};
const conditionsToValue = (conditions) => {
    return conditions.map((c) => `${c.if}:${c.then}`).join("||");
};
