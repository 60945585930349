import { createComponent } from "@api/componentApi";
import useNotificationStore from "@stores/notificationStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export function useComponentCreate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: createComponent,
        onSuccess: (createdComponents) => {
            queryClient.setQueryData(["components"], (oldData) => {
                let newData = [...oldData];
                createdComponents.forEach((component) => {
                    if (component.fresh) {
                        console.log(component);
                        let existingIndex = oldData.findIndex((oldComponent) => oldComponent._id === component._id);
                        if (existingIndex !== -1) {
                            let cleanComponent = { ...component };
                            delete cleanComponent.fresh;
                            newData[existingIndex] = cleanComponent;
                        }
                        else {
                            let cleanComponent = { ...component };
                            delete cleanComponent.fresh;
                            newData.push(cleanComponent);
                        }
                    }
                });
                console.log(newData);
                return newData;
            });
            addNotification("Component created.", "success");
        },
        onError: (error) => {
            console.log(error);
            addNotification("Could not create component.", "error");
        },
    });
}
