// VariablesDialog.tsx
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Box, Stack, Chip, Button, GlobalStyles, Paper, } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import Draggable from "react-draggable";
function PaperComponent(props) {
    return React.createElement(Paper, { ...props, style: { margin: 0, maxHeight: "100%" } });
}
export function VariablesDialog({ variablesOpen, handleVariablesToggle, variableSets, insertVariable, }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(GlobalStyles, { styles: {
                ".variables-button-active": {
                    backgroundColor: "#d3d3d3 !important",
                },
            } }),
        React.createElement(Draggable, { handle: '[class*="MuiDialog-root"]', cancel: '[class*="MuiDialogContent-root"]' },
            React.createElement(Dialog, { open: variablesOpen, PaperComponent: PaperComponent, disableEnforceFocus: true, autoFocus: false, disableScrollLock: true, hideBackdrop: true, sx: {
                    top: "30%",
                    left: "30%",
                    height: "fit-content",
                    width: "fit-content",
                }, "aria-labelledby": "draggable-dialog-title" },
                React.createElement(DialogTitle, { style: { cursor: "move" }, id: "draggable-dialog-title" },
                    "Insert Variables",
                    React.createElement(IconButton, { "aria-label": "close", onClick: handleVariablesToggle, sx: {
                            position: "absolute",
                            right: 8,
                            top: 8,
                        } },
                        React.createElement(CloseIcon, null))),
                React.createElement(DialogContent, { dividers: true }, variableSets.map((set) => (React.createElement(Box, { key: set.name, sx: { mb: 1 } },
                    React.createElement(Typography, { variant: "subtitle1", sx: { mb: 1, fontWeight: "bold" } }, set.name),
                    React.createElement(Stack, { direction: "row", gap: 1, flexWrap: "wrap" }, Object.keys(set.variables).map((displayName) => (React.createElement(Chip, { key: displayName, sx: { borderRadius: 1 }, label: displayName, onClick: () => {
                            insertVariable(displayName);
                        }, clickable: true, variant: "outlined", size: "small" })))))))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleVariablesToggle, variant: "contained", color: "primary" }, "Close"))))));
}
