import { create as mathCreate, all, ConstantNode, } from "mathjs";
import { buildDependencyGraph, topologicalSort, } from "./parameterCalculationHelpers";
import { getComponentParametersOverride, makeUniqueParametersOverride, } from "../componentCreate/store/helpers";
import { updateQtyValues } from "./productStoreHelpers";
const config = {};
const math = mathCreate(all, config);
// Cache to store parsed expressions and parameter values
const expressionCache = {};
const valueCache = {};
const parseExpression = (expression) => {
    if (expressionCache[expression]) {
        return expressionCache[expression];
    }
    const parsed = math.parse(expression);
    expressionCache[expression] = parsed;
    return parsed;
};
const evaluateParameter = (param, cache, paramMap) => {
    const key = param.variable || `${param.id}_${param.name}`;
    if (param.calculatedValue !== undefined) {
        return param.calculatedValue;
    }
    if (param.value.includes(",") && param.options) {
        param.value = param.options[0];
    }
    try {
        const node = parseExpression(param.value);
        // Replace variables with their values
        const transformed = node.transform((node) => {
            if (node.type === "SymbolNode") {
                const symbolNode = node;
                const variable = symbolNode.name;
                if (cache[variable] !== undefined) {
                    return new ConstantNode(cache[variable]);
                }
                const refParam = paramMap[variable] ||
                    paramMap[variable] ||
                    paramMap[`${param.id}_${variable}`];
                if (refParam) {
                    const refValue = evaluateParameter(refParam, cache, paramMap);
                    return new ConstantNode(refValue);
                }
            }
            return node;
        });
        const result = transformed.evaluate();
        param.calculatedValue = result;
        cache[key] = result;
        return result;
    }
    catch (e) {
        param.calculatedValue = "error";
        console.error(`Error evaluating parameter ${param.variable || param.name}:`, e);
        return undefined;
    }
};
export const calculateParameters = (parameters, globalParameters, existingCache = {}) => {
    const cache = existingCache || {};
    // Combine parameters
    const allParams = [...globalParameters, ...parameters];
    // Build parameter map
    const paramMap = {};
    allParams.forEach((param) => {
        const key = param.variable || `${param.id}_${param.name}`;
        paramMap[key] = param;
    });
    // Build dependency graph and sort
    const dependencyGraph = buildDependencyGraph(allParams);
    let sortedVariables;
    try {
        sortedVariables = topologicalSort(dependencyGraph);
    }
    catch (e) {
        console.error(e.message);
        return { params: allParams, cache };
    }
    // Evaluate parameters
    for (const variable of sortedVariables) {
        const param = paramMap[variable];
        if (param) {
            console.log({ param, cache, paramMap });
            evaluateParameter(param, cache, paramMap);
        }
    }
    return { params: allParams, cache };
};
// Get a list of parameters for the product and its subcomponents
export const getParameterList = (product, globalParameters, existingParameters = []) => {
    // Start timing if needed
    // const startTime = performance.now();
    // Maps for quick lookups
    const parametersMap = new Map();
    const overridesMap = new Map();
    const existingParametersMap = new Map();
    const parameterByIdName = new Map();
    // Helper function to generate a unique key for parameters
    const getParamKey = (id, name) => `${id}_${name}`;
    // **1. Build the initial parameters map from product and subcomponents**
    // Process product parameters
    product.parameters.forEach((p) => {
        const key = getParamKey(product._id, p.name);
        let param = { ...p, id: product._id };
        handleValueWithCommas(param);
        parametersMap.set(key, param);
    });
    // Process subcomponent parameters and qty
    product.subcomponents.forEach((c) => {
        c.parameters.forEach((p) => {
            const key = getParamKey(c.id, p.name);
            let param = { ...p, id: c.id };
            handleValueWithCommas(param);
            parametersMap.set(key, param);
        });
        // Process qty
        if (c.qty) {
            const key = getParamKey(c.id, "qty");
            let param = { ...c.qty, id: c.id, name: "qty" };
            handleValueWithCommas(param);
            parametersMap.set(key, param);
        }
    });
    console.log("Initial parameters map", parametersMap);
    // **2. Get overrides and build overrides map**
    let parameterOverrides = getComponentParametersOverride(product, product._id);
    product.subcomponents.forEach((c) => {
        parameterOverrides = parameterOverrides.concat(getComponentParametersOverride(c, c.id));
    });
    const uniqueOverrides = makeUniqueParametersOverride(parameterOverrides);
    uniqueOverrides.forEach((override) => {
        const key = getParamKey(override.id, override.name);
        overridesMap.set(key, override);
    });
    // **3. Build existing parameters map**
    existingParameters.forEach((ep) => {
        const key = getParamKey(ep.id, ep.name);
        existingParametersMap.set(key, ep);
    });
    // **4. Process parameters in a single loop**
    const processedParameters = [];
    parametersMap.forEach((param, key) => {
        // Apply override if it exists
        const override = overridesMap.get(key);
        if (override) {
            param = { ...param, value: override.value, variable: override.variable };
        }
        // Preserve existing parameter values
        const existingParam = existingParametersMap.get(key);
        if (existingParam && existingParam.value !== undefined) {
            param = { ...param, value: existingParam.value };
        }
        // Handle options if value contains commas
        handleValueWithCommas(param);
        // Add to parameterByIdName for variableFor processing
        parameterByIdName.set(key, param);
        processedParameters.push(param);
    });
    // **5. Handle 'variableFor' parameters**
    processedParameters.forEach((p) => {
        if (p.variableFor) {
            const targetKey = getParamKey(p.variableFor, p.name);
            const targetParam = parameterByIdName.get(targetKey);
            if (targetParam) {
                targetParam.variable = p.variable;
            }
        }
    });
    // **6. Filter out parameters with 'variableFor'**
    const finalParameters = processedParameters.filter((p) => !p.variableFor);
    console.log({ finalParameters });
    // **7. Proceed with calculations**
    // First calculation to get variable values
    let { params: allParameters, cache } = calculateParameters(finalParameters, globalParameters);
    // Update qty values to reflect cumulative quantities
    updateQtyValues(allParameters, cache);
    // Recalculate parameters that depend on cumulative quantities
    ({ params: allParameters, cache } = calculateParameters(allParameters, globalParameters, cache));
    console.log("Final parameters", allParameters);
    return allParameters;
};
const handleValueWithCommas = (param) => {
    if (param.value.includes(",")) {
        param.options = param.value.split(",").map((o) => o.trim());
        // Set default value if none is selected
        if (param.value === undefined || param.value.includes(",")) {
            param.value = param.options[0];
        }
    }
};
