import api from "./api";
export const getComponents = async () => {
    const response = await api.get("/api/component");
    return response.data;
};
export const createComponent = async (pack) => {
    // make it a formData
    let formData = new FormData();
    formData.append("components", JSON.stringify(pack));
    pack.forEach((comp, index) => {
        if (typeof comp.image !== "string") {
            formData.append(`images[${index}]`, comp.image);
        }
    });
    const response = await api.post("/api/component", formData);
    return response.data;
};
export const createFromCsv = async (formData) => {
    const response = await api.post("/api/component/csv", formData);
    return response.data;
};
export const deleteComponent = async (ids) => {
    const response = await api.delete(`/api/component`, { data: { ids } });
    console.log(response);
    return response.data;
};
// export const getComponent = async (id) => {
//   const response = await componentApi.get(`/api/component/${id}`);
//   return response.data;
// };
export const updateComponent = async (pack) => {
    const response = await api.patch(`/api/component/${pack._id}`, pack);
    return response.data;
};
// export const deleteComponent = async (pack) => {
//   const response = await componentApi.delete("/api/component", { data: pack });
//   return response;
// };
// export const updateManyComponents = async (pack) => {
//   return await componentApi.patch("/api/component", pack);
// };
// export const deleteEverywhere = async ({
//   optToDelete,
//   compToDelete,
//   compsArray,
//   optsArray,
// }) => {
//   if (optToDelete) {
//     compsArray.map((comp) => {
//       comp.options = comp.options.filter((o) => o._id != optToDelete._id);
//       comp.parameters.map((p) => {
//         p.conditions = p.conditions.replaceAll(optToDelete.name, "1");
//         p.localValue = p.localValue.replaceAll(optToDelete.name, "1");
//       });
//     });
//     compsArray.forEach(async (comp) => {
//       await componentApi.patch(`/api/component/${comp._id}`, comp);
//     });
//     return await componentApi.delete(`/api/option/${optToDelete._id}`);
//   } else if (compToDelete) {
//     compsArray.map((comp) => {
//       comp.components = comp.components.filter(
//         (c) => c._id != compToDelete._id
//       );
//       comp.parameters.map((p) => {
//         p.conditions = p.conditions.replaceAll(compToDelete.name, "1");
//         p.localValue = p.localValue.replaceAll(compToDelete.name, "1");
//       });
//     });
//     optsArray.map((opt) => {
//       opt.choices = opt.choices.filter((c) => c._id != compToDelete._id);
//     });
//     compsArray.forEach(async (comp) => {
//       await componentApi.patch(`/api/component/${comp._id}`, comp);
//     });
//     optsArray.forEach(async (opt) => {
//       await componentApi.patch(`/api/option/${opt._id}`, opt);
//     });
//     return await componentApi.delete("/api/component", {
//       data: { id: compToDelete._id },
//     });
//   }
// };
// export const createManyComponents = async (pack) => {
//   return await componentApi.post("/api/component/many", pack);
// };
// export const updateEverywhere = async ({
//   compToUpdate,
//   components,
//   options,
// }) => {
//   const addToAffectedComp = (affected, comp) => {
//     let newAffected = components.filter((c) =>
//       c.subcomponents.find((s) => s === comp._id)
//     );
//     if (newAffected.length > 0) {
//       newAffected.map((c) => {
//         affected.push(c);
//         addToAffectedComp(affected, c);
//       });
//     }
//     return affected;
//   };
//   const addToAffectedOpt = (affected, comp) => {
//     let newAffected = components.filter((c) =>
//       c.choices.find((s) => s === comp._id)
//     );
//     if (newAffected.length > 0) {
//       newAffected.map((c) => {
//         affected.push(c);
//         addToAffectedOpt(affected, c);
//       });
//     }
//     return affected;
//   };
//   let affectedComponents = addToAffectedComp([], compToUpdate);
// };
// export const createComponent = async (pack) => {
//   return await componentApi.post("/api/component", pack);
// };
// const parseComponents = (component) => {
//   let components = [];
//   component.components.map((comp) => {
//     if (!comp.component) {
//       return;
//     }
//     let subComps = [];
//     if (comp.component.components.length > 0) {
//       subComps = parseComponents(comp.component);
//     }
//     let params = comp.component.parameters;
//     params.map((p) =>
//       p.name == `${comp.component.name}_qty` ? (p.localValue = comp.qty) : p
//     );
//     if (comp.parameters?.length > 0) {
//       comp.parameters.map((p) => {
//         let replacedParamIndex = params.findIndex(
//           (param) => param.name.split("_")[1] == p.name.split("_")[1]
//         );
//         if (replacedParamIndex != -1) {
//           params[replacedParamIndex].localValue = p.localValue;
//           p.conditions
//             ? (params[replacedParamIndex].conditions = p.conditions)
//             : null;
//         }
//       });
//     }
//     comp.component.parameters = params;
//     components.push(
//       Object.assign(
//         {},
//         comp.component,
//         { options: parseOptions(comp.component.options) },
//         { qty: comp.qty },
//         { components: subComps }
//       )
//     );
//   });
//   return components;
// };
// export const parseOptions = (options) => {
//   let newOptions = [];
//   options.map((opt) => {
//     let choices = [];
//     if (Object.hasOwn(opt, "option")) {
//       opt.option.choices.map((choice) => {
//         if (!choice.id) {
//           return;
//         }
//         choices.push(
//           Object.assign({}, choice.id, {
//             qty: choice.qty,
//             options:
//               choice.id.options.length > 0
//                 ? parseOptions(choice.id.options)
//                 : [],
//             components:
//               choice.id.components.length > 0 ? parseComponents(choice.id) : [],
//           })
//         );
//       });
//       newOptions.push(
//         Object.assign(
//           {},
//           opt.option,
//           { inheritedFrom: opt.inheritedFrom },
//           { choices: choices }
//         )
//       );
//     } else {
//       opt.choices.map((choice) =>
//         choices.push(
//           Object.assign({}, choice.id, {
//             qty: choice.qty,
//             options:
//               choice.id.options.length > 0
//                 ? parseOptions(choice.id.options)
//                 : [],
//             components:
//               choice.id.components.length > 0 ? parseComponents(choice.id) : [],
//           })
//         )
//       );
//       newOptions.push(
//         Object.assign(
//           {},
//           opt,
//           { inheritedFrom: opt.inheritedFrom },
//           { choices: choices }
//         )
//       );
//     }
//   });
//   return newOptions;
// };
