import { deleteProject } from "@api/projectApi";
import useNotificationStore from "@stores/notificationStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export function useProjectDelete() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: deleteProject,
        onSuccess: (deletedProjectId) => {
            queryClient.setQueryData(["projects"], (oldData) => {
                return oldData.filter((project) => project._id !== deletedProjectId);
            });
            queryClient.invalidateQueries({
                queryKey: ["files"],
            });
            addNotification("Project deleted.", "success");
        },
        onError: () => {
            addNotification("Could not delete project.", "error");
        },
    });
}
