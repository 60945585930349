import { Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter, } from "@mui/x-data-grid";
import React from "react";
import { useComponentCreateStore } from "../store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
const COLUMNS = [
    {
        field: "nodeName",
        headerName: "Component",
        flex: 1,
        editable: false,
        renderCell: (params) => {
            return (React.createElement(Box, { sx: {
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                } },
                React.createElement(Typography, { variant: "body2" }, params.row.nodeName),
                React.createElement(Typography, { variant: "body2", color: "textSecondary" }, params.row.name)));
        },
    },
    {
        field: "variable",
        label: "Variable",
        flex: 1,
        editable: true,
    },
    {
        field: "value",
        label: "Value",
        flex: 1,
        editable: true,
    },
];
const selector = (state) => ({
    getVariables: state.getVariables,
    onNodeChange: state.onNodeChange,
    onQtyChange: state.onQtyChange,
});
const Variables = () => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const { getVariables, onNodeChange, onQtyChange } = useComponentCreateStore(useShallow(selector));
    const variables = getVariables();
    const processRowUpdate = (updatedRow) => {
        console.log(updatedRow);
        if (updatedRow.name === "qty") {
            onQtyChange(updatedRow.node, updatedRow);
        }
        else {
            onNodeChange(updatedRow.node, { field: "parameters", value: updatedRow });
        }
        console.log(updatedRow);
        return updatedRow;
    };
    const hasDuplicateVariableNames = variables.some((v, i) => variables.findIndex((v2) => v2.variable === v.variable) !== i &&
        v.variable !== "");
    console.log(variables);
    return (React.createElement(React.Fragment, null,
        React.createElement(Badge, { badgeContent: hasDuplicateVariableNames ? "!" : null, color: "error" },
            React.createElement(Button, { variant: "contained", size: "small", onClick: () => setOpen(true) }, "Variables")),
        React.createElement(Dialog, { open: open, onClose: handleClose, maxWidth: "md", fullWidth: true },
            React.createElement(DialogTitle, null, "Variables"),
            React.createElement(DialogContent, null,
                React.createElement(Typography, { variant: "body2", color: "error" }, hasDuplicateVariableNames &&
                    "Warning: There are duplicate variable names. If these variables come from options that will all have the same choice, this is not an issue."),
                React.createElement(DataGrid, { rows: variables || [], slots: {
                        toolbar: () => (React.createElement(Box, { sx: { p: 1, pb: 0.5 } },
                            React.createElement(GridToolbarQuickFilter, { variant: "outlined", size: "small" }))),
                    }, density: "compact", sx: {
                        height: 400,
                        mt: 1,
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                            display: "none",
                        },
                    }, columns: COLUMNS, editMode: "row", processRowUpdate: processRowUpdate, isRowSelectable: (params) => !params.row.disabled })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleClose, variant: "outlined" }, "Close")))));
};
export default Variables;
