import React, { Fragment, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import multimonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin from "@fullcalendar/interaction";
import PropTypes from "prop-types";
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import "../utils/Calendar.css";
import { Check } from "@mui/icons-material";
import { MuiAdapter } from "src/utils/MuiAdapter";
import { ProjectEvent } from "./ProjectEvent";
function Event({ event }) {
    if (event.type == "project") {
        return React.createElement(ProjectEvent, { event: event });
    }
    return (React.createElement(Button, { color: "success", sx: { backgroundColor: "primary.main" } },
        event.title,
        event.desc && ":  " + event.desc));
}
Event.propTypes = {
    event: PropTypes.object,
};
export function ProjectCalendar({ ...props }) {
    const [events, setEvents] = React.useState(props.events);
    React.useEffect(() => {
        setEvents(props.events);
    }, [props.events]);
    const renderEventContent = useCallback((eventInfo) => {
        if (eventInfo.event.extendedProps.type == "project") {
            return (React.createElement(Paper, { id: eventInfo.event.title, sx: { p: 1 } }, eventInfo.event.title));
        }
        if (eventInfo.event.display == "background") {
            return React.createElement(Typography, { variant: "h6" }, eventInfo.event.title);
        }
        const info = eventInfo.event.extendedProps;
        return (React.createElement(Paper, { id: `${info.project}_${info.milestone}`, sx: {
                p: 0.5,
                display: "flex",
                flexDirection: "column",
                backgroundColor: info.color,
                filter: info.completed ? "brightness(60%)" : "none",
            } },
            React.createElement(Box, { sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    textWrap: "wrap",
                } },
                React.createElement(Typography, { sx: { textWrap: "wrap" }, color: info.fontColor }, eventInfo.event.title),
                info.completed ? React.createElement(Check, { color: "success" }) : null),
            React.createElement(Typography, { variant: "caption", color: info.fontColor },
                info.project,
                " - ",
                info.milestone)));
    }, [props.events]);
    const theme = useTheme();
    return (React.createElement(Fragment, null,
        React.createElement(MuiAdapter, { theme: theme }),
        " ",
        React.createElement("div", null,
            React.createElement(FullCalendar, { ...props, editable: true, eventsSet: props.eventsSet, height: "800px", events: events, plugins: [dayGridPlugin, interactionPlugin, multimonthPlugin], eventContent: renderEventContent, initialView: "dayGridMonth", headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,multiMonthYear",
                } }))));
}
ProjectCalendar.propTypes = {
    events: PropTypes.array,
};
