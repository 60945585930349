import { create } from "zustand";
export var Tabs;
(function (Tabs) {
    // Dashboard = "Dashboard",
    Tabs["Projects"] = "Projects";
    Tabs["Customers"] = "Customers";
    Tabs["Calendar"] = "Calendar";
    Tabs["Financials"] = "Financials";
    Tabs["People"] = "People";
    Tabs["Suppliers"] = "Suppliers";
    Tabs["Orders"] = "Orders";
    Tabs["Inventory"] = "Inventory";
    Tabs["Files"] = "Files";
})(Tabs || (Tabs = {}));
const useGeneralStore = create((set) => ({
    tab: Tabs.Projects,
    setTab: (tab) => set({ tab }),
    settingsTab: "",
    setSettingsTab: (settingsTab) => set({ settingsTab }),
}));
export default useGeneralStore;
