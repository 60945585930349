import axios from "axios";
import api from "./api";
export const checkout = async (pack) => {
    try {
        const data = await api.post("/api/payment/checkout", pack);
        return data.data;
    }
    catch (error) {
        console.log(error);
        if (axios.isAxiosError(error) && error.response) {
            // If error is from the server (4xx or 5xx response)
            throw new Error(error.response.data.message ||
                "An server error occurred while setting up session.");
        }
        else {
            // If error is a network error or any other error
            throw new Error("A network error occurred while setting up session.");
        }
    }
};
export const connectStripe = async () => {
    const data = await api.post("/api/payment/stripe");
    return data;
};
export const checkConnect = async () => {
    const data = await api.get("/api/payment/connect/capability");
    return data;
};
export const createPaymentLink = async (pack) => {
    const data = await api.post("/api/payment/stripe/link", pack);
    if (!data) {
        throw new Error("Payment link not created.");
    }
    console.log(data);
    return {
        url: data.data.url,
        id: data.data.id,
    };
};
export const createCustomerPortalSession = async () => {
    try {
        const response = await api.post("/api/payment/customer-portal");
        return response.data.url;
    }
    catch (error) {
        console.error("Error creating customer portal session:", error);
        throw error;
    }
};
// export const createInvoice = async ({ pack }) => {
//   const data = await paymentApi.post("/api/payment/stripe/invoice", pack);
//   return data;
// };
// export const cancelSubscription = async ({ pack }) => {
//   const data = await paymentApi.post("/api/payment/stripe/cancel", pack);
//   return data;
// };
