import { Box, CircularProgress } from "@mui/material";
import React from "react";
export default function Loading() {
    return (React.createElement(Box, { sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        } },
        React.createElement(CircularProgress, null)));
}
