// src/utils/calculateProjectFinancials.ts
export function calculateProjectFinancials(project, financial) {
    // Calculate overall total
    const overallTotal = project.products.reduce((acc, product) => acc + (parseFloat(`${product.price}`) || 0), 0);
    // Get discount type and value from the project
    const discountType = project.discountType || "fixed";
    const discountValue = project.discountValue || 0;
    // Calculate discount amount
    const discountAmount = discountType === "percent"
        ? (overallTotal * discountValue) / 100
        : discountValue;
    // Ensure discount amount does not exceed overall total
    const adjustedDiscountAmount = Math.min(discountAmount, overallTotal);
    // Calculate total after discount
    const totalAfterDiscount = overallTotal - adjustedDiscountAmount;
    // Filter payments for this project
    const projectPayments = financial.filter((payment) => payment.project === project._id);
    // Calculate total payments made
    const totalPayments = projectPayments.reduce((sum, payment) => sum + payment.amount, 0);
    // Calculate the balance remaining
    const balance = totalAfterDiscount - totalPayments;
    return {
        overallTotal,
        discountAmount: adjustedDiscountAmount,
        totalAfterDiscount,
        totalPayments,
        balance,
        projectPayments,
    };
}
