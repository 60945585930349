import React from "react";
import { BaseEdge, getBezierPath } from "@xyflow/react";
import { useComponentCreateStore } from "./store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
import { useTheme } from "@mui/material";
const selector = (state) => ({
    getNode: state.getNode,
});
const CustomEdge = React.memo(({ id, source, sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, target, }) => {
    const [edgePath] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const theme = useTheme();
    const primary = theme.palette.primary.main;
    const defaultColor = theme.palette.text.primary;
    const targetNode = useComponentCreateStore(useShallow((state) => state.getNode(target)));
    const sourceNode = useComponentCreateStore(useShallow((state) => state.getNode(source)));
    const targetSelected = targetNode?.selected;
    const sourceSelected = sourceNode?.selected;
    const isDotted = targetNode?.data?.isOption;
    console.log("rendering edge", id);
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { viewBox: "0 0 10 10", style: { height: 0, width: 0 } },
            React.createElement("defs", null,
                React.createElement("linearGradient", { id: `gradient-${id}`, gradientTransform: "rotate(90)" }, targetSelected && sourceSelected ? (React.createElement(React.Fragment, null,
                    React.createElement("stop", { offset: "100%", stopColor: primary }))) : sourceSelected ? (React.createElement(React.Fragment, null,
                    " ",
                    React.createElement("stop", { offset: targetNode?.selected ? "50%" : "0%", stopColor: primary }),
                    React.createElement("stop", { offset: "95%", stopColor: defaultColor }))) : targetSelected ? (React.createElement(React.Fragment, null,
                    React.createElement("stop", { offset: "0%", stopColor: defaultColor }),
                    React.createElement("stop", { offset: "100%", stopColor: targetNode?.selected ? primary : defaultColor }))) : (React.createElement(React.Fragment, null,
                    React.createElement("stop", { offset: "100%", stopColor: defaultColor }))))),
            React.createElement("circle", { cx: "5", cy: "5", r: "4", fill: "url('#myGradient')" })),
        React.createElement(BaseEdge, { id: id, path: edgePath, markerEnd: "url(#arrow)", style: {
                stroke: `url(#gradient-${id})`,
                strokeWidth: 2,
                strokeDasharray: isDotted ? 5 : 0,
            } })));
}, (prevProps, nextProps) => prevProps.source === nextProps.source &&
    prevProps.target === nextProps.target &&
    prevProps.sourceX === nextProps.sourceX &&
    prevProps.sourceY === nextProps.sourceY &&
    prevProps.targetX === nextProps.targetX &&
    prevProps.targetY === nextProps.targetY);
export default CustomEdge;
