// useTemplatesData.ts
import { useQuery } from "@tanstack/react-query";
import { getOrganization } from "@api/organizationApi";
export const useOrganizationData = () => {
    return useQuery({
        queryKey: ["organization"],
        queryFn: getOrganization,
        staleTime: Infinity,
    });
};
