import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { FocusContext } from "../ProjectView";
import { SelectedProject } from "../../Projects";
import { Add, Edit } from "@mui/icons-material";
const SmallProducts = ({ setOpen }) => {
    const project = React.useContext(SelectedProject);
    const { setFocused } = React.useContext(FocusContext);
    if (!project)
        return null;
    const zones = new Set();
    project.products.forEach((product) => {
        zones.add(product.zone);
    });
    // Helper function to get options and their choices for products in a zone
    const getZoneOptions = (productsInZone) => {
        const optionsMap = {};
        productsInZone.forEach((product) => {
            product.options.forEach((option) => {
                if (!optionsMap[option.name]) {
                    optionsMap[option.name] = new Set();
                }
                optionsMap[option.name].add(option.choiceName);
            });
        });
        // Convert sets to arrays for easier display
        const options = {};
        for (const [name, valuesSet] of Object.entries(optionsMap)) {
            options[name] = Array.from(valuesSet);
        }
        return options;
    };
    const hasProducts = project.products.length > 0;
    return (React.createElement(Box, { sx: { height: "max-content" } },
        React.createElement(Box, { sx: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                gap: 1,
                p: 1,
            } },
            React.createElement(Typography, { variant: "h4" },
                project?.products.length,
                " Products"),
            React.createElement(Box, null,
                React.createElement(Button, { variant: "contained", color: "primary", onClick: () => setFocused("products"), sx: { mr: 1 } }, "Details"),
                React.createElement(Button, { onClick: () => setOpen(true), variant: "contained", startIcon: hasProducts ? React.createElement(Edit, null) : React.createElement(Add, null) }, hasProducts ? "Edit" : "Add"))),
        React.createElement(Stack, { sx: { overflow: "auto", pt: 1 } }, Array.from(zones).map((zone) => {
            const productsInZone = project.products.filter((p) => p.zone === zone);
            const zoneOptions = getZoneOptions(productsInZone);
            return (React.createElement(Paper, { elevation: 3, key: zone, sx: { m: 1, p: 1, mt: 0 } },
                React.createElement(Box, { sx: { display: "flex", gap: 2, alignItems: "center" } },
                    React.createElement(Typography, { variant: "h6" }, zone),
                    React.createElement(Typography, null,
                        productsInZone.length,
                        " Product",
                        productsInZone.length === 1 ? "" : "s")),
                Object.entries(zoneOptions).map(([optionName, optionValues]) => (React.createElement(Box, { key: optionName, sx: { pl: 2, mt: 1 } },
                    React.createElement(Typography, { variant: "subtitle1" }, optionName),
                    React.createElement(Typography, { variant: "body2" }, optionValues.join(", ")))))));
        }))));
};
export default SmallProducts;
const Zone = ({ zone, products, }) => {
    return (React.createElement(Box, null,
        React.createElement(Typography, { variant: "h6" }, zone),
        React.createElement(Typography, null,
            products.length,
            " Products")));
};
