import React from "react";
import { useShallow } from "zustand/react/shallow";
import { useComponentCreateStore } from "../store/createComponentStore";
import CurrencyTextField from "src/utils/CurrencyTextField";
import { DisabledContext, NodeContext } from "../ComponentNode";
const selector = (state) => ({
    onNodeChange: state.onNodeChange,
});
const Cost = () => {
    const { onNodeChange } = useComponentCreateStore(useShallow(selector));
    const { data, id } = React.useContext(NodeContext);
    const disabled = React.useContext(DisabledContext);
    const onChange = React.useCallback((evt) => {
        onNodeChange(id, { field: "cost", value: evt.target.value });
    }, [id]);
    return (React.createElement(CurrencyTextField, { label: "Cost", value: data.cost, disabled: disabled, onChange: onChange, id: `cost-${id}`, sx: { mt: 1 }, fullWidth: true, size: "small" }));
};
export default Cost;
