import { OpenInNew } from "@mui/icons-material";
import { Box, Button, Checkbox, Tooltip } from "@mui/material";
import React from "react";
const makeColumns = (projects) => {
    let columns = [
        {
            field: "name",
            headerName: "Name",
            width: 200,
            sortable: true,
        },
        {
            field: "amount",
            headerName: "Amount",
            width: 150,
            type: "number",
            sortable: true,
            renderCell: (params) => (React.createElement(Box, { sx: {
                    backgroundColor: params.value > 0
                        ? "rgba(0, 999, 0, 0.25)"
                        : "rgba(999, 0, 0, 0.25)",
                    width: "100%",
                    pr: 1,
                } }, params.value !== undefined
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                }).format(params.value)
                : "")),
        },
        {
            field: "date",
            headerName: "Date",
            width: 150,
            sortable: true,
            valueGetter: (params) => params ? new Date(params).toLocaleDateString() : "",
        },
        {
            field: "file",
            headerName: "File",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                console.log(params);
                if (!params.value)
                    return "";
                return (React.createElement(Tooltip, { title: "View File" },
                    React.createElement(Button, { href: params.value, target: "_blank", rel: "noopener noreferrer", variant: "contained", color: "primary", size: "small", startIcon: React.createElement(OpenInNew, null) }, "View")));
            },
        },
        {
            field: "paymentLinkUrl",
            headerName: "Payment Link",
            width: 250,
            sortable: false,
            renderCell: (params) => {
                const url = params.value;
                if (!url)
                    return "";
                return (React.createElement(Tooltip, { title: "View Payment" },
                    React.createElement(Button, { href: url, target: "_blank", rel: "noopener noreferrer", variant: "contained", sx: { backgroundColor: "#635bff" }, size: "small", startIcon: React.createElement(OpenInNew, null) }, "View")));
            },
        },
        {
            field: "paid",
            headerName: "Paid",
            width: 100,
            type: "boolean",
            sortable: true,
            renderCell: (params) => React.createElement(Checkbox, { checked: !!params.value, disabled: true }),
        },
        {
            field: "project",
            headerName: "Project",
            width: 200,
            sortable: true,
            renderCell: (params) => {
                const project = projects.find((project) => project._id === params.value);
                return project ? project.name : "";
            },
            // valueGetter: (params) => params?.value || "",
        },
    ];
    return columns;
};
export default makeColumns;
