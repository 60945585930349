import React from "react";
import CustomSelect from "src/components/Select";
import useProductsStore from "../productsStore";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
const Zone = ({ productId, zone }) => {
    const { onZoneSelect } = useProductsStore();
    const { data } = useSettingsData();
    const zones = data?.zones || ["No Zones"];
    console.log(zone);
    return (React.createElement(CustomSelect, { options: zones.map((v) => ({ name: v, id: v })), value: zone, onChange: (evt) => onZoneSelect(productId, evt.target.value), size: "small", sx: { marginY: 0.5 } }));
};
export default Zone;
