import { create as mathCreate, all, ConstantNode, } from "mathjs";
import { calculateParameters } from "./parameterCalculation";
// Function to check if child ID starts with parent ID (component-wise)
// Returns true even if parent and child are equal
const idStartsWith = (parent, child) => {
    const parentParts = parent.split("_");
    const childParts = child.split("_");
    if (parentParts.length > childParts.length) {
        return false; // Parent ID cannot be longer than child ID
    }
    // Compare each component of the parent ID with the child ID
    for (let i = 0; i < parentParts.length; i++) {
        if (parentParts[i] !== childParts[i]) {
            return false; // Mismatch found
        }
    }
    return true; // All components match; child starts with parent
};
// Updated isDescendant function using idStartsWith
const isDescendant = (parent, child) => {
    if (parent === child) {
        return false; // A component is not a descendant of itself
    }
    // A descendant must have more components than the parent
    const parentParts = parent.split("_");
    const childParts = child.split("_");
    if (childParts.length <= parentParts.length) {
        return false; // Child is not deeper than parent
    }
    // Use idStartsWith to check if child starts with parent
    return idStartsWith(parent, child);
};
// Initialize math.js with all configurations
const config = {};
const math = mathCreate(all, config);
const resolveDependencies = (param, params, cache) => {
    try {
        let node = math.parse(param.value);
        const transformed = node.transform((node) => {
            if (node.type === "SymbolNode") {
                const symbolNode = node;
                const refParam = params.find((p) => p.variable === symbolNode.name);
                if (refParam) {
                    const refValue = isFullyEvaluated(refParam.calculatedValue) &&
                        refParam.calculatedValue !== "error"
                        ? refParam.calculatedValue
                        : resolveDependencies(refParam, params, cache);
                    return new ConstantNode(refValue);
                }
            }
            return node;
        });
        const result = transformed.compile().evaluate(cache);
        param.calculatedValue = result;
        if (param.variable) {
            cache[param.variable] = result;
        }
        return result;
    }
    catch (e) {
        param.calculatedValue = "error";
        console.error(`Error resolving dependencies for ${param.variable}:`, e);
    }
};
// Cache to store parsed expressions and parameter values
const expressionCache = {};
const valueCache = {};
const parseExpression = (expression) => {
    if (expressionCache[expression]) {
        return expressionCache[expression];
    }
    const parsed = math.parse(expression);
    expressionCache[expression] = parsed;
    return parsed;
};
const evaluateParameter = (param, cache, paramMap) => {
    if (param.calculatedValue !== undefined) {
        return param.calculatedValue;
    }
    try {
        const node = parseExpression(param.value);
        // Replace variables with their values
        const transformed = node.transform((node) => {
            if (node.type === "SymbolNode") {
                const symbolNode = node;
                const variable = symbolNode.name;
                if (cache[variable] !== undefined) {
                    return new ConstantNode(cache[variable]);
                }
                const refParam = paramMap[variable];
                if (refParam) {
                    const refValue = evaluateParameter(refParam, cache, paramMap);
                    return new ConstantNode(refValue);
                }
            }
            return node;
        });
        const result = transformed.evaluate();
        param.calculatedValue = result;
        if (param.variable) {
            cache[param.variable] = result;
        }
        return result;
    }
    catch (e) {
        param.calculatedValue = "error";
        console.error(`Error evaluating parameter ${param.variable || param.name}:`, e);
        return undefined;
    }
};
// const calculateParameters = (
//   parameters: Parameter[],
//   globalParameters: Parameter[],
//   existingCache: { [key: string]: any } = {}
// ): { params: Parameter[]; cache: { [key: string]: any } } => {
//   const cache = existingCache || {};
//   // Combine parameters
//   const allParams = [...globalParameters, ...parameters];
//   // Build parameter map
//   const paramMap: { [variable: string]: Parameter } = {};
//   allParams.forEach((param) => {
//     if (param.variable) {
//       paramMap[param.variable] = param;
//     }
//   });
//   // Build dependency graph and sort
//   const dependencyGraph = buildDependencyGraph(allParams);
//   let sortedVariables: string[];
//   try {
//     sortedVariables = topologicalSort(dependencyGraph);
//   } catch (e) {
//     console.error(e.message);
//     return { params: allParams, cache };
//   }
//   // Evaluate parameters
//   for (const variable of sortedVariables) {
//     const param = paramMap[variable];
//     if (param) {
//       evaluateParameter(param, cache, paramMap);
//     }
//   }
//   return { params: allParams, cache };
// };
/**
 * Updates the 'qty' parameters in the dataset to reflect cumulative quantities.
 * @param parameters Array of parameter objects.
 */
function updateQtyValues(parameters, variableCache) {
    console.log({ parameters });
    // Create a map to store components by their id
    const componentMap = {};
    // Populate the component map
    parameters.forEach((param) => {
        const componentId = param.id;
        if (!componentMap[componentId]) {
            componentMap[componentId] = {
                id: componentId,
                params: [],
                qty: null,
                parentId: null,
            };
        }
        componentMap[componentId].params.push(param);
        if (param.name === "qty") {
            componentMap[componentId].qty = param;
        }
    });
    // Determine parent IDs based on the hierarchy in the id field
    Object.keys(componentMap).forEach((id) => {
        const idParts = id.split("_");
        if (idParts.length > 1) {
            const parentId = idParts.slice(0, -1).join("_");
            componentMap[id].parentId = parentId;
        }
    });
    // Recursive function to compute cumulative qty expression
    function computeCumulativeQty(componentId, visited = new Set()) {
        if (visited.has(componentId)) {
            throw new Error(`Cyclic dependency detected at component ${componentId}`);
        }
        visited.add(componentId);
        const component = componentMap[componentId];
        if (!component) {
            // If the component is not in the map, assume qty = 1
            return "1";
        }
        // Use the user's selected qty value
        let ownQty = "1";
        if (component.qty) {
            ownQty = `(${component.qty.value || "1"})`;
        }
        if (component.parentId) {
            const parentQty = computeCumulativeQty(component.parentId, visited);
            return `(${parentQty})*(${ownQty})`;
        }
        else {
            return ownQty;
        }
    }
    // Update the qty parameters with the cumulative quantities
    Object.values(componentMap).forEach((component) => {
        if (component.qty) {
            try {
                const cumulativeQtyExpr = computeCumulativeQty(component.id);
                // Simplify the expression if possible
                const simplifiedQty = math.simplify(cumulativeQtyExpr).toString();
                // **Evaluate the cumulative qty expression using the variable cache**
                let evaluatedQty;
                try {
                    evaluatedQty = math.evaluate(simplifiedQty, variableCache);
                }
                catch (e) {
                    evaluatedQty = simplifiedQty; // Keep the expression if it can't be evaluated
                }
                // **Store the evaluated cumulative qty in calculatedValue**
                component.qty.calculatedValue = evaluatedQty;
                // **Update cache if the qty has a variable**
                if (component.qty.variable && isFullyEvaluated(evaluatedQty)) {
                    variableCache[component.qty.variable] = evaluatedQty;
                }
            }
            catch (error) {
                console.error(`Error computing cumulative qty for component ${component.id}:`, error.message);
                component.qty.calculatedValue = "error";
            }
        }
    });
}
export { idStartsWith, isDescendant, calculateParameters, updateQtyValues };
function isFullyEvaluated(value) {
    return typeof value === "number" && isFinite(value);
}
