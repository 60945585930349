import React from "react";
import { useSettingsData } from "../hooks/useSettingsData";
import { Box, ClickAwayListener, Collapse, Fade, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, } from "@mui/material";
import { useSettingsUpdate } from "../hooks/useSettingsUpdate";
import { TransitionGroup } from "react-transition-group";
import { Check, Delete } from "@mui/icons-material";
const Zones = () => {
    const { data: settings } = useSettingsData();
    const { mutate: updateSettings } = useSettingsUpdate();
    const [value, setValue] = React.useState("");
    const [isFocused, setIsFocused] = React.useState(false);
    const zones = settings?.zones || [];
    const handleAddZone = () => {
        if (!value)
            return;
        if (zones.includes(value))
            return; // Prevent duplicates
        updateSettings({ zones: [...zones, value] });
        setValue("");
        setIsFocused(false);
    };
    const handleEditZone = (oldValue, newValue) => {
        if (!newValue)
            return; // Prevent empty zone names
        if (zones.includes(newValue))
            return; // Prevent duplicates
        updateSettings({
            zones: zones.map((zone) => (zone === oldValue ? newValue : zone)),
        });
    };
    const handleDeleteZone = (value) => {
        updateSettings({
            zones: zones.filter((zone) => zone !== value),
        });
    };
    const handleClickAway = () => {
        setIsFocused(false);
    };
    return (React.createElement(Box, { sx: { mt: 1 } },
        React.createElement(TransitionGroup, null,
            React.createElement(Stack, { direction: "row", gap: 1, alignItems: "end", flexWrap: "wrap" },
                React.createElement(ClickAwayListener, { onClickAway: handleClickAway },
                    React.createElement(FormControl, { size: "small", sx: { width: 250 } },
                        React.createElement(InputLabel, null, "Add Zone"),
                        React.createElement(OutlinedInput, { value: value, onChange: (e) => setValue(e.target.value), label: "Add Zone", onFocus: () => setIsFocused(true), size: "small", onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                    handleAddZone();
                                }
                            }, endAdornment: isFocused && (React.createElement(Fade, { in: isFocused, unmountOnExit: true },
                                React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { onClick: handleAddZone, size: "small" },
                                        React.createElement(Check, null))))) }))),
                zones
                    .sort((a, b) => a.localeCompare(b))
                    .map((zone) => (React.createElement(Fade, { key: zone, in: true },
                    React.createElement("div", { key: zone },
                        React.createElement(ZoneItem, { zone: zone, handleEdit: handleEditZone, handleDelete: handleDeleteZone })))))))));
};
export default Zones;
const MIN_INPUT_WIDTH = 100;
const BUTTONS_WIDTH = 68; // Approximate width needed for buttons
const PADDING = 28; // Left and right padding in the input
const getInputSize = (name, editing) => {
    const textWidth = name.length * 12; // Approximate character width
    const baseWidth = Math.max(textWidth + PADDING, MIN_INPUT_WIDTH);
    return editing ? baseWidth + BUTTONS_WIDTH : baseWidth;
};
const ZoneItem = ({ zone, handleEdit, handleDelete, }) => {
    const [editing, setEditing] = React.useState(false);
    const [value, setValue] = React.useState(zone);
    const inputRef = React.useRef(null);
    const handleSave = () => {
        if (!value || value === zone) {
            setEditing(false);
            return;
        }
        handleEdit(zone, value);
        setEditing(false);
    };
    const handleCancel = () => {
        setValue(zone);
        setEditing(false);
    };
    const handleDeleteClick = () => {
        handleDelete(zone);
    };
    // Focus the input when editing starts
    React.useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);
    return (React.createElement(ClickAwayListener, { onClickAway: handleCancel },
        React.createElement("div", null,
            React.createElement(FormControl, { onClick: () => {
                    if (!editing) {
                        setEditing(true);
                    }
                } },
                React.createElement(OutlinedInput, { value: value, onChange: (e) => setValue(e.target.value), size: "small", inputRef: inputRef, sx: {
                        width: getInputSize(value, editing),
                        transition: "width 0.3s",
                    }, endAdornment: editing ? (React.createElement(InputAdornment, { position: "end", sx: { mr: -1 } },
                        React.createElement(Collapse, { in: editing, unmountOnExit: true, orientation: "horizontal" },
                            React.createElement(IconButton, { onClick: handleSave, size: "small" },
                                React.createElement(Check, null)),
                            React.createElement(IconButton, { onClick: handleDeleteClick, size: "small" },
                                React.createElement(Delete, null))))) : null })))));
};
