import React from "react";
import useNotificationStore from "../stores/notificationStore";
import { Snackbar, Alert } from "@mui/material";
const Notifications = () => {
    const { notifications, removeNotification } = useNotificationStore();
    console.log(notifications);
    return (React.createElement(React.Fragment, null, notifications.map((notification, i) => (React.createElement(Snackbar, { key: notification.id, open: true, autoHideDuration: 6000, anchorOrigin: { vertical: "top", horizontal: "center" }, sx: { mt: -1 }, onClose: (e, reason) => {
            if (reason === "clickaway") {
                return;
            }
            removeNotification(notification.id);
        } },
        React.createElement(Alert, { onClose: () => removeNotification(notification.id), severity: notification.type, sx: { width: "100%" } }, notification.message))))));
};
export default Notifications;
