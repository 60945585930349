// useOrderDelete.ts
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteOrder } from "@api/orderApi";
import useNotificationStore from "@stores/notificationStore";
export function useOrderDelete() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: deleteOrder,
        onSuccess: (orderId) => {
            // Update the cache
            queryClient.setQueryData(["orders"], (oldData = []) => {
                return oldData.filter((order) => order._id !== orderId);
            });
            addNotification("Order deleted successfully.", "success");
        },
        onError: () => {
            addNotification("Failed to delete order.", "error");
        },
    });
}
