import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Link, Paper, TextField, Typography, } from "@mui/material";
import React from "react";
import { Add, Cancel } from "@mui/icons-material";
import { SelectedProject } from "../../Projects";
import { useProjectUpdate } from "../../hooks/useProjectUpdate";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
const AddTasks = () => {
    const project = React.useContext(SelectedProject);
    const { data: settings } = useSettingsData();
    const [open, setOpen] = React.useState(false);
    const [addedTasks, setAddedTasks] = React.useState([]);
    const [adding, setAdding] = React.useState({
        milestone: null,
        task: null,
    });
    const { mutate: updateProject } = useProjectUpdate();
    const [allMilestones, setAllMilestones] = React.useState([]);
    React.useEffect(() => {
        if (project) {
            setAddedTasks([
                ...project.tasks
                    .filter((t) => t.custom)
                    .map((t) => ({ ...t, selected: true })),
            ]);
        }
    }, [project]);
    // Update milestones when settings and project data become available
    React.useEffect(() => {
        if (settings && project) {
            setAllMilestones(settings.milestones?.map((m) => ({
                name: m.name,
                selected: !!project.milestones.find((ms) => ms.name === m.name),
                completed: project.milestones.find((ms) => ms.name === m.name)
                    ?.completed,
                active: false,
                tasks: m.tasks.map((t) => ({
                    name: t,
                    milestone: m.name,
                    selected: !!project.tasks.find((task) => task.name === t),
                    completed: project.tasks.find((task) => task.name === t)?.completed,
                })),
            })));
        }
    }, [settings, project]);
    // Handle cases where project or settings are not available
    if (!project || !settings)
        return null;
    const handleCheck = ({ milestone, task, event, }) => {
        if (milestone && !task) {
            setAllMilestones((prev) => {
                const newMilestones = [...prev];
                const newMilestone = newMilestones.find((m) => m.name === milestone.name);
                if (newMilestone) {
                    newMilestone.selected = event.target.checked;
                    newMilestone.tasks.forEach((t) => {
                        t.selected = event.target.checked;
                    });
                }
                return newMilestones;
            });
        }
        else if (task && milestone) {
            setAllMilestones((prev) => {
                const newMilestones = [...prev];
                const newMilestone = newMilestones.find((m) => m.name === milestone.name);
                if (newMilestone) {
                    const newTask = newMilestone.tasks.find((t) => t.name === task.name);
                    if (newTask) {
                        newTask.selected = event.target.checked;
                    }
                }
                return newMilestones;
            });
        }
    };
    const handleConfirm = () => {
        const newMilestones = allMilestones.filter((m) => m.selected);
        let newTasks = allMilestones
            .filter((m) => m.selected)
            .map((m) => m.tasks.filter((t) => t.selected))
            .flat();
        newTasks = [
            ...newTasks,
            ...addedTasks.map((t) => ({
                name: t.name,
                milestone: t.milestone,
                custom: true,
                selected: true,
                completed: false,
            })),
        ];
        const pack = {
            milestones: [],
            tasks: [],
        };
        newTasks.forEach((t) => {
            const found = project.tasks.find((task) => task.name === t.name);
            if (!found) {
                pack.tasks.push({
                    name: t.name,
                    assignedTo: [],
                    milestone: t.milestone,
                    completed: false,
                    completedDate: null,
                    start: null,
                    end: null,
                    custom: t.custom || false,
                });
            }
            else {
                pack.tasks.push(found);
            }
        });
        newMilestones.forEach((m) => {
            const found = project.milestones.find((ms) => ms.name === m.name);
            if (!found) {
                pack.milestones.push({
                    name: m.name,
                    active: false,
                    completed: false,
                    tasks: [],
                });
            }
            else {
                pack.milestones.push(found);
            }
        });
        // Set the first milestone to active if none are active
        if (!pack.milestones.some((m) => m.active) && pack.milestones.length) {
            pack.milestones[0].active = true;
        }
        updateProject({
            ...pack,
            _id: project._id,
        });
        setOpen(false);
    };
    const handleAddingTask = (milestone) => {
        setAdding({ milestone: milestone.name, task: null });
    };
    const handleAdd = () => {
        setAddedTasks((prev) => [
            ...prev,
            {
                milestone: adding.milestone,
                name: adding.task,
                selected: true,
            },
        ]);
        setAdding({ milestone: null, task: null });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: () => setOpen(true), size: "small", variant: "contained", sx: { width: "100%" } }, "Tasks"),
        React.createElement(Dialog, { open: open, onClose: () => setOpen(false), maxWidth: "lg", fullWidth: true },
            React.createElement(DialogTitle, { sx: { display: "flex", justifyContent: "space-between" } }, "Add Tasks To Project"),
            React.createElement(DialogContent, null, allMilestones.length > 0 ? (React.createElement(Box, { sx: { display: "flex", gap: 1, flexWrap: "wrap" } }, allMilestones.map((m) => (React.createElement(Paper, { key: m.name, sx: {
                    p: 1,
                    m: 1,
                    width: "fit-content",
                    maxWidth: "35%",
                }, elevation: 0 },
                React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: m.selected, 
                        // disabled={m.completed || m.started}
                        disabled: m.completed, onChange: (e) => handleCheck({ milestone: m, event: e }) }), label: React.createElement(Typography, { variant: "h6" }, m.name) }),
                React.createElement(Box, { sx: {
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                    } },
                    m.tasks.map((t) => (React.createElement(Paper, { key: t.name, elevation: 4, sx: {
                            pl: 1,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                        } },
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: t.selected, disabled: t.completed, onChange: (e) => handleCheck({ task: t, event: e, milestone: m }) }), label: React.createElement(Typography, null, t.name) })))),
                    addedTasks
                        .filter((t) => t.milestone === m.name)
                        .map((t) => (React.createElement(Paper, { key: t.name, elevation: 4, sx: {
                            pl: 1,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        } },
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: true, disabled: true, onChange: (e) => handleCheck({
                                    task: t,
                                    event: e,
                                    milestone: m,
                                }) }), label: React.createElement(Typography, null, t.name) }),
                        React.createElement(IconButton, { size: "small", onClick: () => setAddedTasks((prev) => prev.filter((task) => task.name !== t.name)) },
                            React.createElement(Cancel, null))))),
                    adding.milestone === m.name ? (React.createElement(Box, { sx: { display: "flex", gap: 1, alignItems: "center" } },
                        React.createElement(TextField, { value: adding.task || "", onChange: (e) => setAdding((prev) => ({
                                ...prev,
                                task: e.target.value,
                            })), size: "small" }),
                        React.createElement(IconButton, { size: "small", onClick: () => setAdding({ milestone: null, task: null }) },
                            React.createElement(Cancel, null)),
                        React.createElement(IconButton, { size: "small", onClick: handleAdd },
                            React.createElement(Add, null)))) : (React.createElement(Button, { onClick: () => handleAddingTask(m), size: "small", variant: "contained" }, "Add Task")))))))) : (React.createElement(React.Fragment, null,
                React.createElement(Typography, { variant: "body1" }, "No tasks available."),
                React.createElement(Link, { sx: { cursor: "pointer" } }, "Create tasks in settings.")))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { variant: "outlined", onClick: () => setOpen(false) }, "Cancel"),
                React.createElement(Button, { variant: "contained", onClick: handleConfirm, disabled: !settings.milestones || settings.milestones.length === 0 }, "Confirm")))));
};
export default AddTasks;
