import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSettings } from "@api/settingsApi";
import useNotificationStore from "@stores/notificationStore";
export function useSettingsUpdate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: updateSettings,
        onMutate: async (newSettings) => {
            await queryClient.cancelQueries({ queryKey: ["settings"] });
            // Snapshot the previous value
            const previousTodos = queryClient.getQueryData(["settings"]);
            // Optimistically update to the new value
            queryClient.setQueryData(["settings"], (old) => ({
                ...old,
                ...newSettings,
            }));
            // Return a context object with the snapshotted value
            return { previousTodos };
        },
        onSuccess: (updatedSettings) => {
            console.log(updatedSettings);
            queryClient.setQueryData(["settings"], updatedSettings);
            addNotification("Settings updated.", "success");
        },
        onError: () => {
            addNotification("Could not update settings.", "error");
        },
    });
}
