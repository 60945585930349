import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import { SelectedProject } from "../../Projects";
import { useProjectUpdate } from "../../hooks/useProjectUpdate";
import { FocusContext } from "../ProjectView";
import { ProjectCalendar } from "@features/calendar/components/ProjectCalendar";
export const Calendar = () => {
    const project = React.useContext(SelectedProject);
    const { setFocused } = React.useContext(FocusContext);
    if (!project)
        return null;
    const { mutate: updateProject } = useProjectUpdate();
    const [edited, setEdited] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const theme = useTheme();
    React.useEffect(() => {
        const startTime = performance.now();
        console.log(project);
        console.log(`Setting events with color ${project.color}`);
        let initialEvents = [
            {
                start: dayjs(project.creationDate).toDate(),
                end: dayjs(project.dueDate).toDate(),
                allDay: true,
                display: "background",
            },
            ...createEvents(project, theme),
        ];
        setEvents(initialEvents);
        console.log(`Events set in ${performance.now() - startTime} ms`);
    }, [
        project,
        project.name,
        project.creationDate,
        project.dueDate,
        project.milestones,
        project.tasks,
        project.color,
    ]);
    const handleEvents = (es) => {
        console.log("Setting events");
        if (!es.length)
            return;
        console.log(es);
        setEdited(es);
    };
    const handleSave = () => {
        let newTasks = project.tasks.map((task) => ({
            ...task,
            start: edited.find((e) => e.title == task.name).start,
            end: edited.find((e) => e.title == task.name).end,
        }));
        updateProject({
            _id: project._id,
            tasks: newTasks,
        });
    };
    const checkForChanges = () => {
        let different = false;
        events.forEach((event) => {
            let task = edited.find((t) => t.title == event.title);
            console.log(event, task);
            if (!task)
                return false;
            if (dayjs(task.start).format("YYYY-MM-DD") !==
                dayjs(event.start).format("YYYY-MM-DD") ||
                dayjs(task.end).format("YYYY-MM-DD") !==
                    dayjs(event.end).format("YYYY-MM-DD")) {
                different = true;
            }
        });
        return different;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: () => setFocused("plan") }, "Collapse"),
        React.createElement(Paper, { sx: { p: 1 } },
            checkForChanges() && (React.createElement(Box, { sx: { display: "flex", justifyContent: "center", gap: 1 } },
                React.createElement(Typography, { variant: "h6" }, "Changes have been made to the calendar."),
                React.createElement(Button, { onClick: handleSave, variant: "contained", size: "small" }, "Save"))),
            React.createElement(Box, { sx: { flex: 1 } },
                React.createElement(ProjectCalendar, { events: events, eventsSet: handleEvents })))));
};
const createEvents = (project, theme) => {
    let newEvents = [];
    let startDate = dayjs(project.creationDate).toDate();
    let endDate = dayjs(project.creationDate).add(1, "day").toDate();
    project.tasks.forEach((task) => {
        task.start ? (startDate = task.start) : null;
        task.end ? (endDate = task.end) : null;
        newEvents.push({
            title: task.name,
            start: startDate,
            end: endDate,
            allDay: true,
            backgroundColor: project.color,
            fontColor: project.color
                ? getContrastYIQ(project.color)
                : theme.palette.text.primary,
            milestone: task.milestone,
            project: project.name,
            assignedTo: task.assignedTo.map((p) => p),
            extendedProps: {
                assignedTo: task.assignedTo.map((p) => p),
                milestone: task.milestone,
                color: project.color,
                start: startDate,
                end: endDate,
            },
        });
        startDate = endDate;
        endDate = dayjs(endDate).add(1, "day").toDate();
    });
    return newEvents;
};
function getContrastYIQ(hexcolor) {
    // Convert hex color to RGB
    var r = parseInt(hexcolor.substr(1, 2), 16);
    var g = parseInt(hexcolor.substr(3, 2), 16);
    var b = parseInt(hexcolor.substr(5, 2), 16);
    // Calculate YIQ (brightness) value
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    // Return 'black' or 'white' based on the YIQ value
    return yiq >= 128 ? "black" : "white";
}
