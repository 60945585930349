import { Button } from "@mui/material";
import React from "react";
const ConfirmButton = (props) => {
    const [clicked, setClicked] = React.useState(false);
    const onClick = () => {
        if (clicked) {
            props.onClick();
        }
        else {
            setClicked(true);
            setTimeout(() => {
                setClicked(false);
            }, 3000);
        }
    };
    return (React.createElement(Button, { ...props, onClick: onClick, variant: clicked ? "contained" : "outlined", startIcon: clicked ? props.confirmIcon : props.startIcon }, clicked ? "Confirm" : props.label));
};
export default ConfirmButton;
