import { Box, Button, CircularProgress, Fade, Stack, TextField, Typography, } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { register } from "../../api/userApi";
import { ArrowBack } from "@mui/icons-material";
import useNotificationStore from "@stores/notificationStore";
const Register = () => {
    const navigate = useNavigate();
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const { addNotification } = useNotificationStore();
    const handleRegister = async () => {
        setLoading(true);
        const data = await register({
            email,
            password,
            name,
        });
        console.log(data);
        if (data?.success) {
            setSuccess(true);
            setLoading(false);
        }
        else {
            addNotification("Error registering.", "error");
            setLoading(false);
        }
    };
    return (React.createElement(Box, { sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        } }, success ? (React.createElement(Typography, null, "Success! Please check your email.")) : (React.createElement(React.Fragment, null,
        React.createElement(Fade, { key: "back", in: true, timeout: { enter: 2000 } },
            React.createElement(Button, { startIcon: React.createElement(ArrowBack, null), onClick: () => navigate("/"), sx: { mr: 1, width: 100, ml: "-100px" } }, "Back")),
        React.createElement(Stack, { direction: "column", spacing: 1, sx: { width: 300 } },
            React.createElement(Fade, { key: "name", in: true, timeout: { enter: 500 } },
                React.createElement(TextField, { label: "Name", value: name, autoComplete: "off", onChange: (e) => setName(e.target.value) })),
            React.createElement(Fade, { key: "email", in: true, timeout: { enter: 1000 } },
                React.createElement(TextField, { label: "Email", type: "email", autoComplete: "off", value: email, onChange: (e) => setEmail(e.target.value) })),
            React.createElement(Fade, { key: "password", in: true, timeout: { enter: 1500 } },
                React.createElement(TextField, { label: "Password", value: password, type: "password", inputProps: {
                        autocomplete: "new-password",
                        form: {
                            autocomplete: "off",
                        },
                    }, onChange: (e) => setPassword(e.target.value) })),
            React.createElement(Fade, { key: "confirm", in: true, timeout: { enter: 2000 } },
                React.createElement(TextField, { label: "Confirm", value: confirmPassword, autoComplete: "off", error: password !== confirmPassword, type: "password", onChange: (e) => setConfirmPassword(e.target.value) })),
            React.createElement(Fade, { key: "register", in: true, timeout: { enter: 2500 } },
                React.createElement(Button, { onClick: handleRegister, variant: "contained", disabled: name.length === 0 ||
                        email.length === 0 ||
                        password.length === 0 ||
                        password !== confirmPassword ||
                        loading }, loading ? React.createElement(CircularProgress, { size: 24 }) : "Register")))))));
};
export default Register;
