import { updateComponent } from "@api/componentApi";
import useNotificationStore from "@stores/notificationStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export function useComponentUpdate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: updateComponent,
        onSuccess: (updatedComponent) => {
            queryClient.setQueryData(["components"], (oldData) => {
                let newData = [...oldData];
                const index = newData.findIndex((component) => component.id === updatedComponent.id);
                newData[index] = updatedComponent;
                return newData;
            });
            addNotification("Component updated.", "success");
        },
        onError: (error) => {
            console.log(error);
            addNotification("Could not update component.", "error");
        },
    });
}
