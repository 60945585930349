import React from "react";
import useNotificationStore from "../stores/notificationStore";
import { Paper, Typography } from "@mui/material";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(_) {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        console.error("Uncaught error:", error, errorInfo);
        // Add error notification
        const addNotification = useNotificationStore.getState().addNotification;
        addNotification("An unexpected error occurred. Please try again.", "error");
    }
    render() {
        if (this.state.hasError) {
            return (React.createElement(Paper, { sx: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100vh",
                    width: "100%",
                    backgroundColor: "grey",
                } },
                React.createElement(Typography, { variant: "h4" }, "Something went wrong.")));
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
