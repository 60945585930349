import { create } from "zustand";
import { applyNodeChanges, applyEdgeChanges, } from "@xyflow/react";
import { temporal } from "zundo";
import _, { uniqueId } from "lodash";
import { duplicateComponent, duplicateNode, getParametersOverride, getQty, getVariables, onConnectEnd, pasteParameter, pasteQty, switchComponentNode, updateNodeData, updateParameters, updateParametersOverride, } from "./storeUtils";
import { calculateGroupPosition, flowFromComponent, getParentNodeId, updateEdgesForGrouping, } from "./helpers";
import { isSubedge, isSubnode } from "../flowHelpers";
import { useStoreWithEqualityFn } from "zustand/traditional";
const initialNodes = [
    {
        type: "component",
        id: "initialNode",
        data: {
            _id: "",
            name: "Initial Component",
            description: "",
            parameters: [],
            parametersOverride: [],
            subcomponents: [],
            categories: [],
            suppliers: [],
            isOption: false,
            image: null,
            price: null,
            priceModifier: null,
            cost: null,
            code: null,
            stock: 0,
            position: { x: 0, y: 0 },
            options: [],
        },
        position: { x: 0, y: 0 },
        dragHandle: ".drag-handle",
    },
];
const useComponentCreateStore = create()(temporal((set, get) => {
    const createPack = () => {
        const pack = assignFlowData(get().nodes, get().edges);
        return pack;
    };
    return {
        nodes: initialNodes,
        edges: [],
        connectingNodeId: { current: null },
        connectingHandleId: { current: null },
        createPack,
        reset: () => {
            set({
                nodes: initialNodes,
                edges: [],
            });
        },
        onNodesChange: (changes) => {
            set({
                nodes: applyNodeChanges(changes, get().nodes),
            });
        },
        onEdgesChange: (changes) => {
            set({
                edges: applyEdgeChanges(changes, get().edges),
            });
        },
        setNodes: (nodes) => {
            set({ nodes });
        },
        setEdges: (edges) => {
            set({ edges });
        },
        onConnectStart: (_, { nodeId, handleId }) => {
            set({
                connectingNodeId: { current: nodeId },
                connectingHandleId: { current: handleId },
            });
        },
        collapseOptions: () => {
            const optionIds = get().nodes.filter((node) => node.data.isOption);
            set({
                nodes: get().nodes.map((node) => {
                    if (optionIds.some((opt) => isSubnode(opt, node)) &&
                        !node.data.isOption) {
                        return {
                            ...node,
                            hidden: true,
                        };
                    }
                    else {
                        return node;
                    }
                }),
                edges: get().edges.map((edge) => {
                    if (optionIds.some((opt) => isSubedge(opt, edge))) {
                        return {
                            ...edge,
                            hidden: true,
                        };
                    }
                    else {
                        return edge;
                    }
                }),
            });
        },
        expandOptions: () => {
            set({
                nodes: get().nodes.map((node) => {
                    if (node.hidden) {
                        return {
                            ...node,
                            hidden: false,
                        };
                    }
                    else {
                        return node;
                    }
                }),
                edges: get().edges.map((edge) => {
                    if (edge.hidden) {
                        return {
                            ...edge,
                            hidden: false,
                        };
                    }
                    else {
                        return edge;
                    }
                }),
            });
        },
        areOptionsCollapsed: () => {
            return get().nodes.some((node) => node.hidden);
        },
        onConnectEnd: (event, screenToFlowPosition) => onConnectEnd(set, get, event, screenToFlowPosition),
        onNodeChange: (id, data) => {
            const { getNode } = get();
            const isOverride = getNode(id)?.data.saved === "locked";
            if ((isOverride &&
                (data.field === "parameters" ||
                    data.field === "parametersOverride")) ||
                data.value?.isOverride) {
                updateParametersOverride(set, get, id, data);
            }
            else if (data.field === "parameters") {
                updateParameters(set, get, id, data);
                return;
            }
            else if (data.field === "isOption") {
                if (data.value) {
                    set({
                        nodes: get().nodes.map((node) => {
                            if (node.id === id) {
                                return {
                                    ...node,
                                    data: {
                                        ...node.data,
                                        image: null,
                                        cost: null,
                                        price: null,
                                        priceModifier: null,
                                        supplier: null,
                                        code: null,
                                        parameters: [],
                                        parametersOverride: [],
                                        isOption: true,
                                    },
                                };
                            }
                            else {
                                return node;
                            }
                        }),
                    });
                }
                else {
                    set({
                        nodes: get().nodes.map((node) => {
                            if (node.id === id) {
                                return {
                                    ...node,
                                    data: {
                                        ...node.data,
                                        isOption: false,
                                    },
                                };
                            }
                            else {
                                return node;
                            }
                        }),
                    });
                }
            }
            else {
                updateNodeData(set, get, id, data);
            }
        },
        initializeComponentEdit: (component, data) => {
            console.log("initializeComponentEdit", { component, data });
            const { nodes, edges } = flowFromComponent(component, data);
            set({
                nodes: nodes,
                edges: edges,
            });
        },
        duplicateNode: (id) => duplicateNode(set, get, id),
        duplicateComponent: (component, data) => duplicateComponent(set, component, data),
        switchComponentNode: (id, components, dataForNodes) => switchComponentNode(set, get, id, components, dataForNodes),
        // setParametersOverride: () => {
        //   set({ parametersOverride: getParametersOverride(get().nodes) });
        // },
        // parametersOverride: [],
        getParametersOverride: (id) => {
            let overrides = getParametersOverride(get().nodes);
            if (id) {
                overrides = overrides.filter((override) => override.id === id);
            }
            return overrides;
        },
        getParameter: (id, name) => {
            let node = get().nodes.find((node) => node.id === id);
            let param = node?.data.parameters.find((parameter) => parameter.name === name);
            let overrides = get().getParametersOverride(id);
            let override = overrides.find((override) => override.name === name);
            if (override) {
                return override;
            }
            else {
                return param;
            }
        },
        getVariables: () => getVariables(get),
        getQty: (id) => getQty(get, id),
        getParentNode: (id) => {
            return get().nodes.find((node) => node.id === id.slice(0, id.lastIndexOf("_")));
        },
        getSaved: (id, component) => {
            if (!component) {
                return "unsaved";
            }
            let local = get().nodes.find((node) => node.id === id)?.data;
            if (!local) {
                return "unsaved";
            }
            const exists = !!component;
            const fresh = local.fresh;
            const equal = isEqual(local, component);
            if (exists) {
                if (fresh) {
                    if (equal) {
                        return "saved";
                    }
                    else {
                        return "unsaved";
                    }
                }
                else {
                    return "locked";
                }
            }
            else {
                return "unsaved";
            }
        },
        handleLockClick: (id, status) => {
            set({
                nodes: get().nodes.map((node) => {
                    if (node.id === id) {
                        let fresh = node.data.fresh;
                        if (!fresh) {
                            return {
                                ...node,
                                data: {
                                    ...node.data,
                                    fresh: true,
                                },
                            };
                        }
                        else {
                            // here this should relock the component if it is saved
                            if (status === "saved") {
                                return {
                                    ...node,
                                    data: {
                                        ...node.data,
                                        fresh: false,
                                    },
                                };
                            }
                            else {
                                return node;
                            }
                        }
                    }
                    else {
                        return node;
                    }
                }),
            });
        },
        onQtyChange: (id, qty) => {
            set({
                nodes: get().nodes.map((node) => {
                    if (isIdOfParent(id, node.id)) {
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                subcomponents: applyQtyChange(id, qty, node.data.subcomponents),
                            },
                        };
                    }
                    else {
                        return node;
                    }
                }),
            });
        },
        pasteParameter: (id, parameter) => pasteParameter(set, get, id, parameter),
        pasteQty: (id, qty) => pasteQty(set, get, id, qty),
        getNode: (id) => {
            return get().nodes.find((node) => node.id === id);
        },
        deleteNode: (id) => {
            set({
                nodes: get()
                    .nodes.filter((node) => node.id !== id && !node.id.startsWith(id))
                    .map((node) => {
                    if (node.id === get().getParentNode(id)?.id) {
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                subcomponents: node.data.subcomponents.filter((subcomponent) => subcomponent.id !== id),
                            },
                        };
                    }
                    else {
                        return node;
                    }
                }),
                edges: get().edges.filter((edge) => edge.source !== id && edge.target !== id),
            });
        },
        canSave: () => {
            // check for duplicate names in fresh components
            let names = get()
                .nodes.filter((node) => !node.data._id)
                .map((node) => node.data.name);
            let uniqueNames = new Set(names);
            if (names.length !== uniqueNames.size) {
                return "You are attempting to create components with duplicate names.";
            }
            else {
                return "";
            }
        },
        isLockDisabled: (id) => {
            let node = get().nodes.find((node) => node.id === id);
            let otherNode = get().nodes.find((n) => n.id !== id && n.data._id === node?.data._id);
            if (otherNode && otherNode.data.fresh) {
                return true;
            }
            else {
                return false;
            }
        },
        groupNodesByCategories: () => {
            const { nodes, edges } = get();
            // Map to hold groupings per parent node
            const groupingsPerParent = new Map(); // parentId -> category -> grouping
            // Collect child nodes per parent
            const parentChildMap = new Map();
            nodes.forEach((node) => {
                const parentId = getParentNodeId(node.id);
                if (parentId) {
                    if (!parentChildMap.has(parentId)) {
                        parentChildMap.set(parentId, []);
                    }
                    parentChildMap.get(parentId).push(node);
                }
            });
            // For each parent, group child nodes by shared categories
            parentChildMap.forEach((childNodes, parentId) => {
                const categoryToNodes = new Map(); // category -> nodeIds
                const categoryCountMap = new Map(); // category -> count of nodes having this category
                childNodes.forEach((node) => {
                    node.data.categories.forEach((category) => {
                        if (!categoryToNodes.has(category)) {
                            categoryToNodes.set(category, []);
                        }
                        categoryToNodes.get(category).push(node.id);
                        categoryCountMap.set(category, (categoryCountMap.get(category) || 0) + 1);
                    });
                });
                const totalChildNodes = childNodes.length;
                // Now, for each category, decide whether to create a group
                categoryToNodes.forEach((nodeIds, category) => {
                    const categoryCount = categoryCountMap.get(category) || 0;
                    if (categoryCount === totalChildNodes) {
                        // All nodes have this category, skip grouping
                        return;
                    }
                    if (nodeIds.length > 1) {
                        if (!groupingsPerParent.has(parentId)) {
                            groupingsPerParent.set(parentId, new Map());
                        }
                        const groupings = groupingsPerParent.get(parentId);
                        if (!groupings.has(category)) {
                            groupings.set(category, { category, nodeIds });
                        }
                    }
                });
            });
            let newNodes = [...nodes];
            let newEdges = [...edges];
            groupingsPerParent.forEach((groupings, parentId) => {
                groupings.forEach((group) => {
                    const { category, nodeIds } = group;
                    const groupId = `group_${parentId}_${category}`;
                    // Create group node
                    const groupNode = {
                        id: groupId,
                        type: "groupNode", // New node type
                        data: {
                            name: `Group: ${category}`, // Default name, can be edited
                            categories: [category],
                            nodeIds: nodeIds,
                            collapsed: true, // Start in collapsed mode
                        },
                        position: calculateGroupPosition(nodeIds, newNodes),
                        dragHandle: ".drag-handle",
                    };
                    // Assign parentNode to member nodes and adjust their positions
                    newNodes = newNodes.map((node) => {
                        if (nodeIds.includes(node.id)) {
                            const parentNode = groupNode;
                            return {
                                ...node,
                                parentId: groupId,
                                position: {
                                    x: node.position.x - parentNode.position.x,
                                    y: node.position.y - parentNode.position.y,
                                },
                            };
                        }
                        return node;
                    });
                    newNodes.unshift(groupNode);
                    // Update edges
                    newEdges = updateEdgesForGrouping(newEdges, parentId, groupId, nodeIds);
                });
            });
            // Remove edges from parent to grouped nodes
            groupingsPerParent.forEach((groupings, parentId) => {
                groupings.forEach((group) => {
                    const { nodeIds } = group;
                    newEdges = newEdges.filter((edge) => !(edge.source === parentId && nodeIds.includes(edge.target)));
                });
            });
            // Update the store
            set({ nodes: newNodes, edges: newEdges });
        },
        disbandGroup: (groupId) => {
            const { nodes, edges } = get();
            const groupNode = nodes.find((node) => node.id === groupId);
            if (!groupNode)
                return;
            const childNodeIds = groupNode.data.nodeIds;
            // Update child nodes to remove parentNode and adjust positions
            const updatedNodes = nodes.map((node) => {
                if (childNodeIds.includes(node.id)) {
                    return {
                        ...node,
                        parentId: undefined,
                        hidden: false,
                        position: {
                            x: node.position.x + groupNode.position.x,
                            y: node.position.y + groupNode.position.y,
                        },
                    };
                }
                return node;
            });
            // Remove the group node
            const newNodes = updatedNodes.filter((node) => node.id !== groupId);
            // Update edges: Remove edges from group node
            const newEdges = edges.filter((edge) => edge.source !== groupId && edge.target !== groupId);
            // Add edges from parent to child nodes if needed
            const parentId = getParentNodeId(childNodeIds[0]);
            if (parentId) {
                childNodeIds.forEach((childId) => {
                    newEdges.push({
                        id: `${parentId}_${childId}`,
                        source: parentId,
                        target: childId,
                    });
                });
            }
            console.log(newEdges);
            set({ nodes: newNodes, edges: newEdges });
        },
        groupNodes: (nodeIdsToGroup) => {
            const { nodes, edges } = get();
            // Get the nodes to group
            const nodesToGroup = nodes.filter((node) => nodeIdsToGroup.includes(node.id));
            if (nodesToGroup.length < 2) {
                console.error("At least two nodes are required to form a group.");
                return;
            }
            if (nodesToGroup.some((node) => node.type === "groupNode" || node.parentId)) {
                console.error("Cannot group nodes that are already part of a group.");
                return;
            }
            // Get the parentId, assuming all nodes have the same parent
            const parentIds = new Set(nodesToGroup.map((node) => getParentNodeId(node.id)));
            if (parentIds.size !== 1) {
                console.error("Nodes to group must have the same parent.");
                return;
            }
            const parentId = Array.from(parentIds)[0];
            // Get common categories among nodes
            const categoriesArray = nodesToGroup.map((node) => node.data.categories || []);
            const commonCategories = categoriesArray.reduce((a, b) => a.filter((c) => b.includes(c)));
            // Determine the group name
            let groupName = "";
            if (commonCategories.length > 0) {
                groupName = `${uniqueId(commonCategories.join(", "))}`;
            }
            else {
                groupName = `${uniqueId("group")}`; // Use a unique timestamp as group name
            }
            const groupId = `${parentId}_group${groupName}`; // Unique group ID
            // Create group node
            const groupNode = {
                id: groupId,
                type: "groupNode",
                data: {
                    name: groupName,
                    categories: commonCategories,
                    nodeIds: nodeIdsToGroup,
                    collapsed: true,
                },
                position: nodesToGroup[0].position,
                dragHandle: ".drag-handle",
            };
            // Adjust nodes
            let newNodes = nodes.map((node) => {
                if (nodeIdsToGroup.includes(node.id)) {
                    const parentNode = groupNode;
                    return {
                        ...node,
                        selected: false,
                        parentId: groupId,
                        hidden: true,
                        position: {
                            x: node.position.x - parentNode.position.x,
                            y: node.position.y - parentNode.position.y + 150,
                        },
                    };
                }
                else {
                    return {
                        ...node,
                        selected: false,
                    };
                }
            });
            newNodes.unshift(groupNode);
            // Update edges
            let newEdges = edges;
            // Remove edges from parent to child nodes
            newEdges = newEdges.filter((edge) => !(edge.source === parentId && nodeIdsToGroup.includes(edge.target)));
            // Add edge from parent to group node
            newEdges.push({
                id: `${parentId}_${groupId}`,
                source: parentId,
                target: groupId,
            });
            // Add edges from group node to child nodes
            nodeIdsToGroup.forEach((nodeId) => {
                newEdges.push({
                    id: `${groupId}_${nodeId}`,
                    source: groupId,
                    target: nodeId,
                });
            });
            set({ nodes: newNodes, edges: newEdges });
        },
    };
}, {
    partialize: (state) => {
        return {
            nodes: state.nodes,
            edges: state.edges,
        };
    },
    equality: (prev, curr) => {
        return areEqual(prev, curr);
    },
}));
export function useTemporalStore(selector, equality) {
    return useStoreWithEqualityFn(useComponentCreateStore.temporal, selector, equality);
}
const selector = (state) => ({
    nodes: state.nodes,
    edges: state.edges,
    connectingNodeId: state.connectingNodeId,
    reset: state.reset,
    onNodesChange: state.onNodesChange,
    onEdgesChange: state.onEdgesChange,
    onConnectStart: state.onConnectStart,
    onConnectEnd: state.onConnectEnd,
    collapseOptions: state.collapseOptions,
    expandOptions: state.expandOptions,
    areOptionsCollapsed: state.areOptionsCollapsed,
    onNodeChange: state.onNodeChange,
    onQtyChange: state.onQtyChange,
    pasteParameter: state.pasteParameter,
    pasteQty: state.pasteQty,
    handleLockClick: state.handleLockClick,
    getNode: state.getNode,
    getParentNode: state.getParentNode,
    getSaved: state.getSaved,
    getQty: state.getQty,
    getVariables: state.getVariables,
    // setParametersOverride: state.setParametersOverride,
    // parametersOverride: state.parametersOverride,
    switchComponentNode: state.switchComponentNode,
    initializeComponentEdit: state.initializeComponentEdit,
    duplicateNode: state.duplicateNode,
    duplicateComponent: state.duplicateComponent,
    deleteNode: state.deleteNode,
    canSave: state.canSave,
    isLockDisabled: state.isLockDisabled,
});
function areEqual(prev, next) {
    if (prev.nodes.length !== next.nodes.length) {
        return false;
    }
    if (prev.edges.length !== next.edges.length) {
        return false;
    }
    for (let i = 0; i < prev.nodes.length; i++) {
        if (!nodesEqual(prev.nodes[i].data, next.nodes[i].data)) {
            return false;
        }
    }
    for (let i = 0; i < prev.edges.length; i++) {
        if (!_.isEqual(prev.edges[i], next.edges[i])) {
            return false;
        }
    }
    return true;
}
function isIdOfParent(childId, parentId) {
    return childId.slice(0, childId.lastIndexOf("_")) === parentId;
}
function applyQtyChange(id, value, subcomponents) {
    return subcomponents.map((subcomponent) => {
        if (subcomponent.id === id) {
            return { ...subcomponent, qty: value };
        }
        else {
            return subcomponent;
        }
    });
}
const isEqual = (local, cloud) => {
    return _.isEqual({
        ...local,
        saved: undefined,
        fresh: undefined,
        id: undefined,
        flowData: undefined,
        subcomponents: local.subcomponents.map((s) => ({
            qty: s.qty,
            id: getId(s.id),
        })),
    }, {
        ...cloud,
        saved: undefined,
        fresh: undefined,
        id: undefined,
        flowData: undefined,
        subcomponents: cloud.subcomponents.map((s) => ({
            qty: s.qty,
            id: s.id,
        })),
    });
};
const getId = (id) => {
    return id.slice(id.lastIndexOf("_") + 1);
};
const nodesEqual = (past, current) => {
    let parsedPast = {
        ...past,
        saved: undefined,
        fresh: undefined,
        id: undefined,
        flowData: undefined,
    };
    let parsedCurrent = {
        ...current,
        saved: undefined,
        fresh: undefined,
        id: undefined,
        flowData: undefined,
    };
    for (let key in parsedPast) {
        // if (key === "subcomponents") {
        //   for (let i = 0; i < parsedPast[key].length; i++) {
        //     if (!_.isEqual(parsedPast[key][i], parsedCurrent[key][i])) {
        //       return false;
        //     }
        //     if (!_.isEqual(parsedPast[key][i]?.qty, parsedCurrent[key][i]?.qty)) {
        //       return false;
        //     }
        //   }
        // } else
        if (!_.isEqual(parsedPast[key], parsedCurrent[key])) {
            console.log(key, parsedPast[key], parsedCurrent[key], "unequal");
            return false;
        }
    }
    return true;
};
/**
 * Takes in nodes and assigns a flowData to it representing itself and every node below it.
 * @constructor
 * @param {array} nodes - the nodes.
 * @param {array} edges - the edges.
 * @returns {array} - the nodes with flowData added in its data property.
 */
const assignFlowData = (nodes, edges) => {
    const nodesToReturn = offsetNodes(nodes).map((node) => {
        return {
            id: node.id,
            ...node.data,
            flowData: {
                nodes: offsetNodes(nodes.filter((subnode) => isSubnode(node, subnode))),
                edges: edges.filter((edge) => isSubedge(node, edge)),
            },
        };
    });
    console.log(nodesToReturn);
    return nodesToReturn;
};
function offsetNodes(nodes) {
    console.log(nodes);
    const offset = { x: nodes[0].position.x, y: nodes[0].position.y };
    return nodes.map((node) => {
        return {
            ...node,
            position: {
                x: node.position.x - offset.x,
                y: node.position.y - offset.y,
            },
        };
    });
}
export { useComponentCreateStore, selector };
