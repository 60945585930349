// src/components/Parameter.tsx
import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { formatParameter } from "../utils/makeColumns";
import useProductsStore from "../productsStore";
/**
 * Parameter component utilizing MUI's Autocomplete to allow selection and manual input.
 */
const Parameter = ({ productId, parameter }) => {
    const { onParameterSelect } = useProductsStore();
    if (!parameter)
        return null;
    console.log("Parameter:", parameter);
    // If there are no options, display the formatted calculatedValue
    if (!parameter.options?.length) {
        return React.createElement(React.Fragment, null, formatParameter(parameter.calculatedValue));
    }
    /**
     * Handles changes when an option is selected from the Autocomplete dropdown.
     * @param event - The event source of the callback.
     * @param newValue - The new value of the autocomplete.
     */
    const handleOptionChange = (event, newValue) => {
        if (newValue !== null) {
            onParameterSelect(productId, parameter, newValue);
        }
    };
    /**
     * Handles changes when the input value is modified manually.
     * @param event - The event source of the callback.
     * @param newInputValue - The new input value.
     */
    const handleInputChange = (event, newInputValue) => {
        // Validate that the input is a number
        const numericValue = parseFloat(newInputValue);
        if (!isNaN(numericValue)) {
            console.log("Numeric value:", numericValue);
            onParameterSelect(productId, parameter, numericValue.toString());
        }
    };
    return (React.createElement(Autocomplete, { freeSolo: true, options: parameter.options, value: parameter.value, onChange: handleOptionChange, onInputChange: handleInputChange, renderInput: (params) => (React.createElement(TextField, { ...params, variant: "outlined", size: "small", placeholder: "Enter value" })), 
        // Ensures that the component occupies the full width of its container
        fullWidth: true, 
        // Limits the number of options displayed in the dropdown
        limitTags: 3, 
        // Styles for consistent spacing
        sx: { marginY: 0.5 } }));
};
export default Parameter;
