import { useMutation, useQueryClient } from "@tanstack/react-query";
import useNotificationStore from "@stores/notificationStore";
import { updateOrganization } from "@api/organizationApi";
export function useOrganizationUpdate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: updateOrganization,
        onSuccess: (updatedOrg) => {
            queryClient.setQueryData(["organization"], updatedOrg);
            addNotification("Organization updated.", "success");
        },
        onError: () => {
            addNotification("Could not update organization.", "error");
        },
    });
}
