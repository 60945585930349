import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Checkbox, FormControlLabel, Stack, } from "@mui/material";
import React from "react";
import { useFinancialCreate } from "../hooks/useFinancialCreate";
import { useFinancialUpdate } from "../hooks/useFinancialUpdate";
import CurrencyTextField from "src/utils/CurrencyTextField";
import { usePaymentLink } from "../hooks/usePaymentLink";
import PaymentLinkField from "./PaymentLinkField";
import ConfirmButton from "src/components/ConfirmButton";
import { Delete, DeleteForever } from "@mui/icons-material";
import { useFinancialDelete } from "../hooks/useFinancialDelete";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
const FinancialDialog = ({ data, createData, open, onClose, onResult, }) => {
    const { mutate: create } = useFinancialCreate();
    const { mutate: update } = useFinancialUpdate();
    const { mutate: deleteFinancial } = useFinancialDelete();
    const onPaymentLinkResult = (result) => {
        setPaymentLinkUrl(result.url);
        setPaymentLinkId(result.id);
    };
    const { mutate: createPaymentLink } = usePaymentLink(onPaymentLinkResult);
    const { data: settings } = useSettingsData();
    const [editing, setEditing] = React.useState(data ? false : true);
    const [name, setName] = React.useState(data?.name || "");
    const [date, setDate] = React.useState(data?.date ? new Date(data.date) : new Date());
    const [amount, setAmount] = React.useState(data?.amount || createData?.amount || "0");
    const [type, setType] = React.useState(data?.type || "");
    const [paid, setPaid] = React.useState(data ? data.paid : false);
    const [dueDate, setDueDate] = React.useState(data?.dueDate ? new Date(data.dueDate) : new Date());
    const [paymentLinkUrl, setPaymentLinkUrl] = React.useState(data?.paymentLinkUrl || "");
    const [paymentLinkId, setPaymentLinkId] = React.useState(data?.paymentLinkId);
    const [file, setFile] = React.useState(null);
    const handleGeneratePaymentLink = () => {
        if (amount <= 0 || !name) {
            throw new Error("Amount must be greater than 0");
        }
        createPaymentLink({ amount: amount, name });
    };
    const onCancel = () => {
        setName(data?.name || "");
        setDate(data?.date ? new Date(data.date) : new Date());
        setAmount(data?.amount || createData?.amount || "0");
        setType(data?.type || "");
        setPaid(data ? data.paid : false);
        setDueDate(data?.dueDate ? new Date(data.dueDate) : new Date());
        setPaymentLinkUrl(data?.paymentLinkUrl || "");
        setPaymentLinkId(data?.paymentLinkId);
        setFile(null);
        setEditing(false);
    };
    const onSave = async () => {
        if (!data) {
            throw new Error("Financial data is required");
        }
        const formData = new FormData();
        formData.append("_id", data._id);
        formData.append("name", name);
        formData.append("date", date.toISOString());
        formData.append("amount", amount);
        formData.append("type", type);
        formData.append("paid", paid.toString());
        formData.append("dueDate", dueDate.toISOString());
        formData.append("paymentLinkUrl", paymentLinkUrl);
        formData.append("paymentLinkId", paymentLinkId || "");
        if (file) {
            formData.append("file", file);
        }
        const result = await update(formData);
        onResult(result);
        setEditing(false);
    };
    const onSubmit = async () => {
        if (!name || !amount) {
            throw new Error("Name and amount fields are required");
        }
        const formData = new FormData();
        formData.append("name", name);
        formData.append("date", date.toISOString());
        formData.append("amount", amount);
        formData.append("type", type);
        createData?.project && formData.append("project", createData.project);
        formData.append("paid", paid.toString());
        formData.append("dueDate", dueDate.toISOString());
        formData.append("paymentLinkUrl", paymentLinkUrl);
        formData.append("paymentLinkId", paymentLinkId || "");
        if (file) {
            formData.append("file", file);
        }
        const result = await create(formData);
        onResult(result);
    };
    const onDelete = async () => {
        if (!data) {
            throw new Error("Financial data is required");
        }
        const result = await deleteFinancial(data._id);
        onResult(result);
        setEditing(false);
    };
    const handleDeletePaymentLink = () => {
        setPaymentLinkUrl("");
        setPaymentLinkId("");
    };
    return (React.createElement(Dialog, { open: open, onClose: onClose },
        data ? (React.createElement(DialogTitle, null, `${editing ? "Editing" : ""} Financial Info: ${data.name}`)) : (React.createElement(DialogTitle, null, "Add Financial")),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { label: "Name", value: name, onChange: (e) => setName(e.target.value), fullWidth: true, disabled: !editing && data ? true : false, margin: "dense" }),
            React.createElement(TextField, { label: "Date", type: "date", value: date.toISOString().split("T")[0], onChange: (e) => setDate(new Date(e.target.value)), fullWidth: true, disabled: !editing && data ? true : false, margin: "dense", slotProps: {
                    inputLabel: {
                        shrink: true,
                    },
                } }),
            React.createElement(Stack, { direction: "row", spacing: 2, sx: { mt: 1 } },
                React.createElement(CurrencyTextField, { label: "Amount", value: amount, onChange: (e) => setAmount(e.target.value), fullWidth: true, disabled: !editing && data ? true : false, margin: "dense" }),
                React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: paid, onChange: (e) => setPaid(e.target.checked), disabled: (!editing && data) || paymentLinkUrl ? true : false }), label: "Paid" })),
            React.createElement(PaymentLinkField, { disabled: !settings?.stripeAccountId, paymentLink: paymentLinkUrl, handleGeneratePaymentLink: handleGeneratePaymentLink, handleDeletePaymentLink: handleDeletePaymentLink, editable: editing, amount: amount, paid: paid }),
            React.createElement(TextField, { type: "file", label: "Upload File", onChange: (e) => {
                    const target = e.target;
                    if (target.files && target.files.length > 0) {
                        setFile(target.files[0]);
                    }
                }, fullWidth: true, disabled: !editing && data ? true : false, margin: "dense", slotProps: {
                    inputLabel: {
                        shrink: true,
                    },
                } })),
        data ? (React.createElement(DialogActions, null,
            !editing && (React.createElement(React.Fragment, null,
                React.createElement(ConfirmButton, { onClick: onDelete, label: "Delete", variant: "contained", color: "error", startIcon: React.createElement(Delete, null), confirmIcon: React.createElement(DeleteForever, null), sx: { mr: "auto" } }),
                React.createElement(Button, { onClick: () => setEditing(true), variant: "contained" }, "Edit"))),
            editing && (React.createElement(Button, { onClick: onCancel, variant: "outlined" }, "Cancel")),
            editing && (React.createElement(Button, { onClick: onSave, variant: "contained" }, "Save")))) : (React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, variant: "outlined" }, "Cancel"),
            React.createElement(Button, { onClick: onSubmit, variant: "contained" }, "Submit")))));
};
export default FinancialDialog;
