import React from "react";
import CustomerDialog from "./CustomerDialog";
import SupplierDialog from "./SupplierDialog";
import FinancialDialog from "../financial/components/FinancialDialog";
function useDialog() {
    const promiseRef = React.useRef();
    const [dialogComponent, setDialogComponent] = React.useState(null);
    const onClose = (result, callback) => {
        if (callback) {
            callback(result);
        }
        setDialogComponent(null);
        promiseRef.current?.resolve(result);
    };
    // close any open dialog and reject the promise
    const CloseAndReject = (reason) => {
        setDialogComponent(null);
        promiseRef.current?.reject(reason);
    };
    // show a dialog and return a promise that resolves when the dialog is closed
    function ShowDialog(dialog) {
        setDialogComponent(dialog(onClose));
        return new Promise((resolve, reject) => {
            promiseRef.current = { resolve, reject };
        });
    }
    // is there an open dialog?
    function IsDialogOpen() {
        return !!dialogComponent;
    }
    async function ShowCustomerDialog(customerData) {
        return ShowDialog((resolve) => (React.createElement(CustomerDialog, { data: customerData, open: true, onClose: () => resolve(false), onResult: resolve })));
    }
    async function ShowSupplierDialog(supplierData) {
        return ShowDialog((resolve) => (React.createElement(SupplierDialog, { data: supplierData, open: true, onClose: () => resolve(false), onResult: resolve })));
    }
    async function ShowFinancialDialog(financialData, createData) {
        return ShowDialog((resolve) => (React.createElement(FinancialDialog, { data: financialData, createData: createData, open: true, onClose: () => resolve(false), onResult: resolve })));
    }
    return {
        ShowDialog,
        IsDialogOpen,
        CloseAndReject,
        ShowCustomerDialog,
        ShowSupplierDialog,
        ShowFinancialDialog,
        DialogComponent: dialogComponent,
    };
}
let isDialogOpen = () => false;
let closeAndReject = (_arg0) => { };
let showCustomerDialog = (_arg0) => Promise.resolve(false);
let showSupplierDialog = (_arg0) => Promise.resolve(false);
let showFinancialDialog = (_arg0, _arg1) => Promise.resolve(false);
/**
 * DialogManager is a hook that provides a way to show dialogs and wait for them to close.
 * Can be used from any component or hook.
 */
function DialogManager() {
    const dialogs = useDialog();
    isDialogOpen = dialogs.IsDialogOpen;
    closeAndReject = dialogs.CloseAndReject;
    showCustomerDialog = dialogs.ShowCustomerDialog;
    showSupplierDialog = dialogs.ShowSupplierDialog;
    showFinancialDialog = dialogs.ShowFinancialDialog;
    return dialogs.DialogComponent;
}
export { DialogManager, closeAndReject, isDialogOpen, showCustomerDialog, showSupplierDialog, showFinancialDialog, };
