import { create } from "zustand";
const useNotificationStore = create((set) => ({
    notifications: [],
    addNotification: (message, type) => set((state) => {
        const id = Math.random().toString(36).substring(2, 9);
        const newNotification = { id, message, type };
        return {
            notifications: [newNotification, ...state.notifications].slice(0, 20),
        };
    }),
    removeNotification: (id) => set((state) => ({
        notifications: state.notifications.filter((notification) => notification.id !== id),
    })),
}));
export default useNotificationStore;
