// src/context/SocketContext.tsx
import { getFile } from "@api/fileApi";
import useAuthStore from "@stores/authStore";
import { useQueryClient } from "@tanstack/react-query";
import React, { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
export const SocketContext = createContext({
    socket: null,
});
export const SocketProvider = ({ children, }) => {
    const { user } = useAuthStore();
    const queryClient = useQueryClient();
    const [socket, setSocket] = useState(null);
    useEffect(() => {
        console.log("SocketProvider mounted");
        // Retrieve the token when the component mounts
        const token = localStorage.getItem("authToken");
        console.log("Token:", token);
        // Initialize the socket with the token
        const newSocket = io(undefined, {
            transports: ["websocket"],
            secure: true,
            withCredentials: true,
            rejectUnauthorized: false,
        });
        // Set the socket in state
        setSocket(newSocket);
        const handleDocumentSigned = async (data) => {
            console.log("Document signed event received:", data);
            const file = await getFile(data.fileId);
            queryClient.setQueryData(["files"], (oldData) => {
                return oldData?.map((oldFile) => {
                    if (oldFile._id === data.fileId) {
                        return file;
                    }
                    return oldFile;
                });
            });
        };
        const handlePaymentReceived = (data) => {
            queryClient.setQueryData(["financials"], (oldData) => {
                return oldData?.map((financial) => {
                    if (financial._id === data.paymentId) {
                        return {
                            ...financial,
                            paid: true,
                        };
                    }
                    return financial;
                });
            });
        };
        // Handle connection and disconnection events
        newSocket.on("connect", () => {
            console.log(`Connected to server with ID: ${newSocket.id}`);
        });
        newSocket.on("disconnect", () => {
            console.log("Disconnected from server");
        });
        newSocket.on("documentSigned", handleDocumentSigned);
        newSocket.on("paymentReceived", (data) => {
            console.log("Payment received event received:", data);
            handlePaymentReceived(data);
        });
        // Clean up on unmount
        return () => {
            newSocket.off("connect");
            newSocket.off("disconnect");
            newSocket.off("documentSigned", handleDocumentSigned);
            newSocket.off("paymentReceived", handlePaymentReceived);
            newSocket.disconnect(); // Close the socket connection
        };
    }, [user]);
    return (React.createElement(SocketContext.Provider, { value: { socket } }, children));
};
