import React from "react";
import { SelectedProject } from "../Projects";
import SmallPlan from "./smallViews/SmallPlan";
import { FocusContext } from "./ProjectView";
import PlanTab from "./tasks/Plan";
import { Box, Fade } from "@mui/material";
const ProjectPlan = () => {
    const project = React.useContext(SelectedProject);
    const { focused } = React.useContext(FocusContext);
    if (!project)
        return null;
    const expanded = focused.plan;
    return (React.createElement(React.Fragment, null,
        React.createElement(Fade, { in: expanded },
            React.createElement(Box, { sx: { minHeight: 0, height: expanded ? "400px" : 0 } },
                React.createElement(PlanTab, null))),
        React.createElement(Fade, { in: !expanded },
            React.createElement(Box, null,
                React.createElement(SmallPlan, null)))));
};
export default ProjectPlan;
