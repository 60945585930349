// storeUtils.ts
import { addEdge } from "@xyflow/react";
import { addEdgesFromSwitch, addNodesFromSwitch, duplicateNodeRecursively, flowFromComponent, getComponentParametersOverride, getId, handleOverrides, isIdOfParent, makeUniqueParametersOverride, newComponent, } from "./helpers";
import _ from "lodash";
// Existing functions
export function updateParameters(set, get, id, data) {
    set({
        nodes: get().nodes.map((node) => {
            if (node.id === id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        [data.field]: node.data[data.field].map((p) => {
                            if (p.name === data.value.name) {
                                return {
                                    ...p,
                                    value: data.value.value,
                                    variable: data.value.variable,
                                };
                            }
                            else {
                                return p;
                            }
                        }),
                    },
                };
            }
            else {
                return node;
            }
        }),
    });
}
export function updateParametersOverride(set, get, id, data) {
    const getParent = get().getParentNode;
    // Overrides are saved in the first unlocked parent, not always at the top
    function getFirstUnlockedParent(nodeId) {
        let parent = getParent(nodeId);
        while (parent?.data.saved === "locked") {
            parent = getParent(parent.id);
        }
        return parent;
    }
    const parent = getFirstUnlockedParent(id);
    const idToChange = parent?.id;
    const parentId = getId(idToChange);
    const overrideAdjustedId = id.slice(id.lastIndexOf(parentId));
    const override = { ...data.value, id: overrideAdjustedId };
    // Find the original parameter
    const node = get().nodes.find((node) => node.id === id);
    let originalParameter = node?.data.parameters.find((p) => p.name === data.value.name);
    // If not found in parameters, look in subcomponents' qty
    if (!originalParameter && parent?.data.subcomponents) {
        for (const subcomponent of parent.data.subcomponents) {
            if (subcomponent.qty?.name === data.value.name) {
                originalParameter = subcomponent.qty;
                break;
            }
        }
    }
    const existingOverrides = get().nodes.find((node) => node.id === idToChange)?.data
        .parametersOverride || [];
    const overrides = handleOverrides(existingOverrides, override, originalParameter);
    const nodesToSet = get().nodes.map((node) => {
        if (node.id === idToChange) {
            return {
                ...node,
                data: {
                    ...node.data,
                    parametersOverride: overrides,
                },
            };
        }
        else {
            return node;
        }
    });
    const allOverrides = getParametersOverride(nodesToSet);
    set({
        nodes: nodesToSet,
        parametersOverride: allOverrides,
    });
}
export function updateNodeData(set, get, id, data) {
    set({
        nodes: get().nodes.map((node) => {
            if (node.id === id) {
                return {
                    ...node,
                    data: { ...node.data, [data.field]: data.value },
                };
            }
            else {
                return node;
            }
        }),
    });
}
export function onConnectEnd(set, get, event, screenToFlowPosition) {
    const connectingNodeId = get().connectingNodeId.current;
    if (!connectingNodeId)
        return;
    const mouseEvent = event;
    const eventTarget = event.target;
    const targetIsPane = eventTarget.classList.contains("react-flow__pane");
    if (targetIsPane) {
        const id = _.uniqueId(`${connectingNodeId}_`); // new node's id
        let position = screenToFlowPosition({
            x: mouseEvent.clientX,
            y: mouseEvent.clientY,
        });
        const newNode = {
            type: "component",
            id: id,
            data: newComponent(id, "Component"),
            position: position,
            origin: [0.5, 0.0],
            dragHandle: ".drag-handle",
        };
        set({
            nodes: get()
                .nodes.map((node) => {
                if (node.id === connectingNodeId) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            subcomponents: [
                                ...node.data.subcomponents,
                                {
                                    id: id,
                                    qty: {
                                        value: "1",
                                        variable: "",
                                        name: "qty",
                                        id: id,
                                    },
                                },
                            ],
                        },
                    };
                }
                else {
                    return node;
                }
            })
                .concat(newNode),
            edges: addEdge({
                id: `${connectingNodeId}-${id}`,
                source: connectingNodeId || "",
                target: id,
            }, get().edges),
        });
    }
}
export function duplicateNode(set, get, id) {
    const { nodes, edges, getParentNode } = get();
    const nodeToDuplicate = nodes.find((node) => node.id === id);
    if (!nodeToDuplicate) {
        console.error(`Node with id ${id} not found`);
        return;
    }
    const idMap = {};
    const result = duplicateNodeRecursively(id, null, nodes, idMap, id);
    console.log(result);
    const parentNode = getParentNode(id);
    if (parentNode) {
        const parentId = parentNode.id;
        const newNodeId = idMap[id];
        const newSubcomponent = {
            id: newNodeId,
            qty: {
                value: "1",
                variable: "",
                name: "qty",
                id: newNodeId,
            },
        };
        const updatedNodes = nodes.map((node) => {
            if (node.id === parentId) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        subcomponents: [...node.data.subcomponents, newSubcomponent],
                    },
                };
            }
            else if (node.id === id) {
                return { ...node, selected: false };
            }
            else {
                return node;
            }
        });
        const newEdge = {
            id: `${parentId}-${newNodeId}`,
            source: parentId,
            target: newNodeId,
        };
        set({
            nodes: updatedNodes.concat(result.nodes),
            edges: edges.concat(result.edges).concat(newEdge),
        });
    }
    else {
        set({
            nodes: nodes.concat(result.nodes),
            edges: edges.concat(result.edges),
        });
    }
}
export function duplicateComponent(set, component, data) {
    const { nodes, edges } = flowFromComponent(component, data);
    const idMap = {};
    const result = duplicateNodeRecursively(component._id, null, nodes, idMap, component._id, true);
    set({
        nodes: result.nodes,
        edges: result.edges,
    });
}
export function switchComponentNode(set, get, id, components, dataForNodes) {
    let parentId = id.slice(0, id.lastIndexOf("_"));
    let position = get().nodes.find((node) => node.id === id)
        ?.position;
    // here we need to:
    // - add the new components to the parent
    // - remove the subcomponent from the parent
    // - add the new nodes to the nodes array (and also their flows)
    // - add the new edges to the edges array
    const nodesToSet = get()
        .nodes.map((node) => {
        if (isIdOfParent(id, node.id)) {
            return {
                ...node,
                dragHandle: ".drag-handle",
                data: {
                    ...node.data,
                    subcomponents: [
                        ...node.data.subcomponents.filter((subcomponent) => subcomponent.id !== id),
                        ...components.map((component) => ({
                            id: `${parentId}_${component._id}`,
                            qty: {
                                value: "1",
                                variable: "",
                                name: "qty",
                                id: `${parentId}_${component._id}`,
                            },
                        })),
                    ],
                },
            };
        }
        else {
            return node;
        }
    })
        .filter((n) => !n.id.startsWith(id))
        .concat(addNodesFromSwitch(components, dataForNodes, position, parentId));
    set({
        nodes: nodesToSet,
        edges: get()
            .edges.filter((edge) => edge.source !== id && edge.target !== id && edge.id !== id)
            .concat(addEdgesFromSwitch(components, parentId)),
    });
}
export function getParametersOverride(nodes) {
    // Collect all parameter overrides from all nodes, along with their hierarchy level
    let allOverrides = [];
    nodes.forEach((node) => {
        allOverrides = allOverrides.concat(getComponentParametersOverride(node.data, node.id));
    });
    return makeUniqueParametersOverride(allOverrides);
}
export function pasteParameter(set, get, id, parameter) {
    const { nodes, getParentNode } = get();
    // Get the prefix of the id to identify matching nodes
    const prefix = id.substring(0, id.lastIndexOf("_") + 1); // e.g., 'nodeA_nodeB_'
    const excludeId = prefix.slice(0, -1); // e.g., 'nodeA_nodeB'
    // Overrides are saved in the first unlocked parent, not always at the top
    function getFirstUnlockedParent(nodeId) {
        let parent = getParentNode(nodeId);
        while (parent?.data.saved === "locked") {
            parent = getParentNode(parent.id);
        }
        return parent;
    }
    let parent = getFirstUnlockedParent(id);
    let updatedParametersOverride = parent?.data.parametersOverride || [];
    // Map over nodes to update them if they match
    const updatedNodes = nodes.map((node) => {
        if (node.id.startsWith(prefix) &&
            node.id !== id &&
            node.id.split("_").length === id.split("_").length) {
            const isOverride = node.data.saved === "locked";
            if (isOverride) {
                // If parameter is an override, update parametersOverride
                // Remove any existing override for this node and parameter name
                updatedParametersOverride = updatedParametersOverride.filter((p) => !(p.name === parameter.name && node.id.startsWith(p.id)));
                // Id for override is the id of the node sliced so that first part is the parent id
                let overrideId = node.id.slice(node.id.indexOf(getId(parent?.id)));
                // Add the new parameter override
                updatedParametersOverride.push({
                    ...parameter,
                    id: overrideId, // Set parameter id to node's id
                });
            }
            else {
                // Else, update or add the parameter in node.data.parameters
                const existingParameterIndex = node.data.parameters.findIndex((p) => p.name === parameter.name);
                let newParameters;
                if (existingParameterIndex >= 0) {
                    // Update existing parameter
                    newParameters = node.data.parameters.map((p, idx) => {
                        if (idx === existingParameterIndex) {
                            return {
                                ...p,
                                value: parameter.value,
                                variable: parameter.variable,
                                id: node.id, // Set parameter id to node's id
                            };
                        }
                        else {
                            return p;
                        }
                    });
                }
                else {
                    // Add new parameter
                    const newParameter = {
                        ...parameter,
                        isOverride: false,
                        id: node.id,
                    };
                    newParameters = [...node.data.parameters, newParameter];
                }
                // Update the node's data.parameters
                node = {
                    ...node,
                    data: {
                        ...node.data,
                        parameters: newParameters,
                    },
                };
            }
        }
        return node;
    });
    if (parent) {
        const parentIndex = updatedNodes.findIndex((n) => n.id === parent?.id);
        updatedNodes[parentIndex] = {
            ...parent,
            data: {
                ...parent?.data,
                parametersOverride: updatedParametersOverride,
            },
        };
        // Update the nodes in the store
        set({
            nodes: updatedNodes,
        });
    }
}
export function pasteQty(set, get, id, qtyParameter) {
    const { nodes, getParentNode } = get();
    // Get the prefix of the id to identify matching subcomponent ids
    const prefix = id.substring(0, id.lastIndexOf("_") + 1); // e.g., 'nodeA_nodeB_'
    // Overrides are saved in the first unlocked parent, not always at the top
    function getFirstUnlockedParent(nodeId) {
        let parent = getParentNode(nodeId);
        while (parent?.data.saved === "locked") {
            parent = getParentNode(parent.id);
        }
        return parent;
    }
    const immediateParent = getParentNode(id);
    const parent = getFirstUnlockedParent(id);
    if (!immediateParent || !parent) {
        console.warn(`Parent node for id ${id} not found.`);
        return;
    }
    const isOverride = immediateParent.data.saved === "locked";
    let updatedParametersOverride = parent.data.parametersOverride || [];
    if (isOverride) {
        // Immediate parent is locked; we need to add overrides for each matching subcomponent
        // // Remove existing overrides for matching subcomponents' qty parameters
        // updatedParametersOverride = updatedParametersOverride.filter(
        //   (p: Parameter) => {
        //     const pIdParts = p.id.split("_");
        //     const pIdPrefix =
        //       pIdParts.slice(0, prefix.split("_").length).join("_") + "_";
        //     return !(
        //       p.name === qtyParameter.name &&
        //       pIdPrefix === prefix &&
        //       p.id !== id
        //     );
        //   }
        // );
        immediateParent.data.subcomponents.forEach((subcomponent) => {
            if (subcomponent.id === id) {
                return;
            }
            // Adjust the id to be relative to the first unlocked parent
            const originalParentId = getId(parent.id);
            const overrideId = subcomponent.id.slice(subcomponent.id.indexOf(originalParentId));
            let existingOverrideIndex = updatedParametersOverride.findIndex((p) => p.id === overrideId && p.name === qtyParameter.name);
            if (existingOverrideIndex >= 0) {
                // Update existing override
                updatedParametersOverride[existingOverrideIndex] = {
                    ...qtyParameter,
                    id: overrideId,
                };
            }
            else {
                // Add the override for the subcomponent's qty
                updatedParametersOverride.push({
                    ...qtyParameter,
                    id: overrideId,
                });
            }
        });
        // Update the first unlocked parent node
        const updatedNodes = nodes.map((node) => {
            if (node.id === parent.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        parametersOverride: updatedParametersOverride,
                    },
                };
            }
            else {
                return node;
            }
        });
        // Update the nodes in the store
        set({
            nodes: updatedNodes,
        });
    }
    else {
        // Immediate parent is unlocked; we can update subcomponents directly
        // Update subcomponents in the immediate parent's data
        const newSubcomponents = immediateParent.data.subcomponents.map((subcomponent) => {
            const subId = subcomponent.id;
            if (subId.startsWith(prefix) &&
                subId !== id &&
                subId.split("_").length === id.split("_").length) {
                return {
                    ...subcomponent,
                    qty: {
                        ...subcomponent.qty,
                        value: qtyParameter.value,
                        variable: qtyParameter.variable,
                        id: subId, // Set qty id to subcomponent's id
                    },
                };
            }
            else {
                // No change to this subcomponent
                return subcomponent;
            }
        });
        // Update the immediate parent node
        const updatedNodes = nodes.map((node) => {
            if (node.id === immediateParent.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        subcomponents: newSubcomponents,
                    },
                };
            }
            else {
                return node;
            }
        });
        // Update the nodes in the store
        set({
            nodes: updatedNodes,
        });
    }
}
export function getVariables(get) {
    const { nodes, getParametersOverride } = get();
    // Build a map of overrides, keyed by parameter id and name
    const overrideMap = new Map();
    const allOverrides = getParametersOverride();
    allOverrides.forEach((override) => {
        const key = `${override.id}-${override.name}`;
        overrideMap.set(key, override);
    });
    // Function to get the effective parameter (with overrides applied)
    function getEffectiveParameter(nodeId, param) {
        const key = `${nodeId}-${param.name}`;
        return overrideMap.get(key) || param;
    }
    // Collect variables from nodes and subcomponents
    const variables = nodes.flatMap((node) => {
        const nodeId = node.id;
        const nodeName = node.data.name;
        // Apply overrides to parameters
        const parameters = node.data.parameters || [];
        const effectiveParameters = parameters.map((param) => getEffectiveParameter(nodeId, param));
        // Collect variables from parameters
        const parameterVariables = effectiveParameters
            .filter((param) => param.variable)
            .map((param) => ({
            ...param,
            node: nodeId,
            nodeName: nodeName,
        }));
        // Collect variables from subcomponents' qty (with overrides)
        const subcomponentVariables = (node.data.subcomponents || []).flatMap((subcomponent) => {
            const subcomponentId = subcomponent.id;
            const subcomponentName = nodes.find((n) => n.id === subcomponentId)
                ?.data.name;
            const qty = subcomponent.qty;
            const effectiveQty = getEffectiveParameter(subcomponentId, qty);
            if (effectiveQty.variable) {
                return {
                    ...effectiveQty,
                    node: subcomponentId,
                    nodeName: subcomponentName,
                };
            }
            else {
                return [];
            }
        });
        return [...parameterVariables, ...subcomponentVariables].map((variable) => ({
            ...variable,
            id: variable.node + variable.name,
        }));
    });
    return variables;
}
export function getQty(get, id) {
    const { getParametersOverride, nodes } = get();
    const override = getParametersOverride().find((p) => p.id === id && p.name === "qty");
    const parentNode = nodes.find((node) => node.id === id.slice(0, id.lastIndexOf("_")));
    const subcomponentIndex = parentNode?.data.subcomponents.findIndex((subcomponent) => subcomponent.id === id);
    if (override) {
        return { ...override, isOverride: true };
    }
    return parentNode?.data.subcomponents[subcomponentIndex]?.qty;
}
