// ThemeSwitcher.js
import React from "react";
import { Box, Button, IconButton, Typography, Stack, Divider, Tooltip, } from "@mui/material";
import { Brightness4, Brightness7, Save } from "@mui/icons-material";
import useThemeStore from "./themeStore";
import ColorPicker from "src/components/ColorPicker";
import { useSettingsUpdate } from "../userSettings/hooks/useSettingsUpdate";
const ThemeSwitcher = () => {
    const { mutate: updateSettings } = useSettingsUpdate();
    const { mode, primary, secondary, setPrimary, setSecondary, toggleMode } = useThemeStore();
    const [unsavedChanges, setUnsavedChanges] = React.useState(false);
    const handleModeToggle = () => {
        toggleMode();
        setUnsavedChanges(true);
    };
    const handlePrimaryChange = (color) => {
        setPrimary(color);
        setUnsavedChanges(true);
    };
    const handleSecondaryChange = (color) => {
        setSecondary(color);
        setUnsavedChanges(true);
    };
    const handleSaveTheme = () => {
        localStorage.setItem("theme", JSON.stringify({
            mode,
            primary,
            secondary,
        }));
        updateSettings({
            theme: {
                mode,
                primary,
                secondary,
            },
        });
        setUnsavedChanges(false);
    };
    return (React.createElement(Box, { sx: { p: 2 } },
        React.createElement(Typography, { variant: "h6", gutterBottom: true }, "Theme Settings"),
        React.createElement(Stack, { direction: { xs: "column", sm: "row" }, spacing: 2, alignItems: "center", divider: React.createElement(Divider, { orientation: "vertical", flexItem: true }), sx: { mb: 2 } },
            React.createElement(Box, { sx: { textAlign: "center" } },
                React.createElement(Tooltip, { title: "Toggle Light/Dark Mode" },
                    React.createElement(IconButton, { color: "inherit", onClick: handleModeToggle, size: "large" }, mode === "light" ? React.createElement(Brightness4, null) : React.createElement(Brightness7, null))),
                React.createElement(Typography, { variant: "body2" }, mode === "light" ? "Light Mode" : "Dark Mode")),
            React.createElement(Box, { sx: { textAlign: "center" } },
                React.createElement(ColorPicker, { color: primary, setColor: handlePrimaryChange })),
            React.createElement(Box, { sx: { textAlign: "center" } },
                React.createElement(ColorPicker, { color: secondary, setColor: handleSecondaryChange })),
            React.createElement(Button, { variant: "contained", startIcon: React.createElement(Save, null), onClick: handleSaveTheme, disabled: !unsavedChanges, color: unsavedChanges ? "primary" : "success" }, unsavedChanges ? "Save Theme" : "Theme Saved")),
        unsavedChanges && (React.createElement(Typography, { variant: "body2", color: "warning.main", sx: { mb: 1 } }, "You have unsaved changes."))));
};
export default ThemeSwitcher;
