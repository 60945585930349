import { createProject } from "@api/projectApi";
import useNotificationStore from "@stores/notificationStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export function useProjectCreate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: createProject,
        onSuccess: (newProject) => {
            queryClient.setQueryData(["projects"], (oldData) => {
                return [...oldData, newProject];
            });
            queryClient.invalidateQueries({
                queryKey: ["customers"],
            });
            queryClient.invalidateQueries({
                queryKey: ["files"],
            });
            addNotification("Project created.", "success");
        },
        onError: () => {
            addNotification("Could not create project.", "error");
        },
    });
}
