import { DragHandle } from "@mui/icons-material";
import { Box, Collapse, Fade, IconButton, Paper, Stack, TextField, Tooltip, Typography, } from "@mui/material";
import { Handle, NodeToolbar, Position } from "@xyflow/react";
import React from "react";
import { selector, useComponentCreateStore, } from "./store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
import Saved from "./componentNode/Saved";
import { useComponentsData } from "../inventory/hooks/useComponentsData";
import NodeMenu from "./componentNode/NodeMenu";
import SwitchComponentNode from "./componentNode/SwitchComponentNode";
const OptionNode = ({ id, data, selected }) => {
    const { onNodeChange, getQty, onQtyChange, getSaved } = useComponentCreateStore(useShallow(selector));
    const { data: componentsData } = useComponentsData();
    const components = componentsData || [];
    const status = getSaved(id, components.find((c) => c._id === id.split("_").pop()));
    const qty = getQty(id);
    const onChange = React.useCallback((field, value) => {
        onNodeChange(id, { field: field, value: value });
    }, []);
    const handleQtyChange = React.useCallback((evt) => {
        onQtyChange(id, evt.target.value);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(NodeToolbar, { style: { width: "350px" } },
            React.createElement(Box, { sx: {
                    width: "100%",
                    display: "flex",
                    gap: 1,
                    direction: "row",
                    justifyContent: "space-between",
                } },
                React.createElement(Saved, { id: id, status: status }),
                React.createElement(Box, { sx: { display: "flex" } },
                    React.createElement(Fade, { in: true, timeout: { enter: 500, exit: 500 } },
                        React.createElement("div", null,
                            React.createElement(SwitchComponentNode, { id: id, disabled: false }))),
                    React.createElement(Fade, { in: true, timeout: { enter: 500, exit: 500 } },
                        React.createElement("div", null,
                            React.createElement(NodeMenu, { id: id, data: data })))))),
        React.createElement(Paper, { id: id, sx: {
                p: 1,
                width: 350,
                transition: "all 0.5s",
                boxShadow: selected ? 4 : 1,
                border: 1,
                borderColor: "primary.main",
                //   backgroundColor: "#f0f0f0",
                // border: selected ? "1px solid #fff" : "1px solid transparent",
            } },
            React.createElement(Handle, { type: "target", position: Position.Top }),
            React.createElement(Stack, { direction: "row", spacing: 1, alignItems: "center" },
                React.createElement(IconButton, { className: "drag-handle" },
                    React.createElement(DragHandle, null)),
                React.createElement(TextField, { id: `label-${id}`, name: "label", value: data.name, disabled: status === "locked", fullWidth: true, onChange: (evt) => onChange("name", evt.target.value), size: "small" }),
                id.includes("_") && (React.createElement(TextField, { id: `{id}-qty`, name: "qty", value: qty, fullWidth: true, onChange: handleQtyChange, size: "small" }))),
            React.createElement(Collapse, { in: data.price !== undefined },
                React.createElement(TextField, { id: `label-${id}`, name: "label", value: data.price, fullWidth: true, onChange: (evt) => onChange("price", evt.target.value), size: "small" })),
            React.createElement(Tooltip, { title: "Drag and drop to add a subcomponent." },
                React.createElement(Handle, { type: "source", id: "choice", style: {
                        width: "fit-content",
                        height: "fit-content",
                        bottom: -24,
                        borderRadius: 4,
                        border: 0,
                        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // visibility: selected ? "visible" : "hidden",
                        // opacity: selected ? 1 : 0,
                        transition: "opacity 0.5s",
                    }, onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }, position: Position.Bottom, children: React.createElement(React.Fragment, null,
                        React.createElement(Paper, { sx: { p: 1, pointerEvents: "none" } },
                            React.createElement(Typography, null, "Choices"))) })))));
};
export default OptionNode;
