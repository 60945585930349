import { createFromCsv } from "@api/componentApi";
import useNotificationStore from "@stores/notificationStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export function useComponentCreateCsv() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: createFromCsv,
        onSuccess: ({ createdComponents, newlyCreatedSuppliers, }) => {
            queryClient.setQueryData(["components"], (oldData) => {
                let newData = [...oldData];
                createdComponents.forEach((component) => {
                    newData.push(component);
                });
                console.log(newData);
                return newData;
            });
            queryClient.setQueryData(["suppliers"], (oldData) => {
                let newData = [...oldData];
                newlyCreatedSuppliers.forEach((supplier) => {
                    newData.push(supplier);
                });
                console.log(newData);
                return newData;
            });
            addNotification("Component created.", "success");
        },
        onError: (error) => {
            console.log(error);
            addNotification("Could not create component.", "error");
        },
    });
}
