import React, { useState, useEffect } from "react";
import { useOrganizationData } from "src/hooks/useOrganizationData";
import { useOrganizationUpdate } from "src/hooks/useOrganizationUpdate";
import { TextField, Button, Box } from "@mui/material";
const Organization = () => {
    const { data: organization } = useOrganizationData();
    const { mutate: updateOrganization } = useOrganizationUpdate();
    const [formData, setFormData] = useState({
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: "",
        website: "",
        user: "",
    });
    const [isEditing, setIsEditing] = useState(false);
    // Update formData when organization data loads
    useEffect(() => {
        if (organization) {
            setFormData({
                name: organization.name || "",
                address: organization.address || "",
                city: organization.city || "",
                state: organization.state || "",
                zip: organization.zip || "",
                phone: organization.phone || "",
                email: organization.email || "",
                website: organization.website || "",
                user: organization.user || "",
            });
        }
    }, [organization]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };
    const handleSubmit = () => {
        const data = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            data.append(key, value);
        });
        updateOrganization(data);
        setIsEditing(false); // Exit editing mode after updating
    };
    const handleCancel = () => {
        if (organization) {
            setFormData({
                name: organization.name || "",
                address: organization.address || "",
                city: organization.city || "",
                state: organization.state || "",
                zip: organization.zip || "",
                phone: organization.phone || "",
                email: organization.email || "",
                website: organization.website || "",
                user: organization.user || "",
            });
        }
        setIsEditing(false); // Exit editing mode without saving changes
    };
    const isFormDataChanged = () => {
        if (!organization)
            return true;
        const keys = Object.keys(formData);
        for (const key of keys) {
            if (formData[key] !== (organization[key] || "")) {
                return true;
            }
        }
        return false;
    };
    return (React.createElement(Box, { component: "form", sx: {
            display: "flex",
            gap: 2,
            mx: "auto",
            mt: 4,
        } },
        React.createElement(Box, { sx: { display: "flex", flexDirection: "column", gap: 1 } },
            React.createElement(TextField, { label: "Name", name: "name", value: formData.name, onChange: handleChange, required: true, fullWidth: true, disabled: !isEditing }),
            React.createElement(TextField, { label: "Address", name: "address", required: true, value: formData.address, onChange: handleChange, fullWidth: true, disabled: !isEditing }),
            React.createElement(TextField, { label: "City", name: "city", required: true, value: formData.city, onChange: handleChange, fullWidth: true, disabled: !isEditing }),
            React.createElement(TextField, { label: "State", name: "state", required: true, value: formData.state, onChange: handleChange, fullWidth: true, disabled: !isEditing }),
            React.createElement(TextField, { label: "Zip Code", name: "zip", required: true, value: formData.zip, onChange: handleChange, fullWidth: true, disabled: !isEditing })),
        React.createElement(Box, { sx: { display: "flex", gap: 1, flexDirection: "column" } },
            React.createElement(TextField, { label: "Phone", name: "phone", required: true, value: formData.phone, onChange: handleChange, fullWidth: true, disabled: !isEditing }),
            React.createElement(TextField, { label: "Email", name: "email", type: "email", required: true, value: formData.email, onChange: handleChange, fullWidth: true, disabled: !isEditing }),
            React.createElement(TextField, { label: "Website", name: "website", value: formData.website, onChange: handleChange, fullWidth: true, disabled: !isEditing })),
        React.createElement(Box, null, isEditing ? (React.createElement(Box, { sx: { display: "flex", gap: 1, mt: 1 } },
            React.createElement(Button, { variant: "outlined", onClick: handleCancel }, "Cancel"),
            React.createElement(Button, { variant: "contained", color: "primary", fullWidth: true, disabled: !isFormDataChanged(), onClick: handleSubmit }, "Save"))) : (React.createElement(Button, { variant: "contained", color: "primary", onClick: () => setIsEditing(true), fullWidth: true, sx: { mt: 1 } }, "Edit")))));
};
export default Organization;
