import { CheckCircle, FmdBad, LockRounded, } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useComponentCreateStore } from "../store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
const selector = (state) => ({
    handleLockClick: state.handleLockClick,
    isLockDisabled: state.isLockDisabled,
});
const Saved = ({ id, status }) => {
    const { handleLockClick, isLockDisabled } = useComponentCreateStore(useShallow(selector));
    const handleClick = () => {
        if (status == "unsaved")
            return;
        handleLockClick(id, status);
    };
    const disabled = isLockDisabled(id);
    React.useEffect(() => {
        if (status !== "locked" && disabled) {
            handleLockClick(id, status);
        }
    }, [disabled]);
    return (React.createElement(Tooltip, { title: disabled && status !== "unsaved"
            ? "This component is unlocked somewhere else."
            : status === "saved"
                ? "Saved"
                : status === "unsaved"
                    ? "Unsaved"
                    : "Click to unlock and edit component everywhere." },
        React.createElement("div", null,
            React.createElement(IconButton, { size: "small", 
                // sx={{ position: "fixed", bottom: 65 }}
                disabled: disabled, onClick: handleClick, color: "info" }, status == "saved" ? (React.createElement(CheckCircle, { color: "success" })) : status == "unsaved" ? (React.createElement(FmdBad, { color: "warning" })) : (React.createElement(LockRounded, null))))));
};
export default Saved;
