import { create } from "zustand";
const useThemeStore = create((set) => ({
    mode: "light",
    primary: "#1976d2",
    secondary: "#dc004e",
    setPrimary: (primary) => set({ primary }),
    setSecondary: (secondary) => set({ secondary }),
    initializeTheme: (theme) => set({
        mode: theme.mode,
        primary: theme.primary,
        secondary: theme.secondary,
    }),
    toggleMode: () => set((state) => ({
        mode: state.mode === "light" ? "dark" : "light",
    })),
}));
export default useThemeStore;
