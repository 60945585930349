import { Box, Button, CircularProgress, Paper, Typography, } from "@mui/material";
import React from "react";
import stripeBlurple from "../utils/stripeBlurple.svg";
import { useSettingsData } from "../hooks/useSettingsData";
import { checkConnect, connectStripe } from "@api/paymentApi";
import { Check, OpenInNew } from "@mui/icons-material";
const StripeIntegration = () => {
    const [loading, setLoading] = React.useState(false);
    const [capability, setCapability] = React.useState({
        canUsePaymentLinks: false,
        canUseTax: false,
    });
    //   const user = settings.user;
    const { data: settings } = useSettingsData();
    const handleStripe = async () => {
        setLoading(true);
        const data = await connectStripe();
        window.location.href = data.data;
        setLoading(false);
    };
    const goToStripe = () => {
        window.open("https://dashboard.stripe.com", "_blank");
    };
    const isConnected = settings?.stripeAccountId;
    React.useEffect(() => {
        console.log(settings);
        const fetchData = async () => {
            if (settings?.stripeAccountId) {
                try {
                    const result = await checkConnect();
                    setCapability(result.data);
                }
                catch (error) {
                    console.log(error);
                }
            }
        };
        fetchData();
    }, [settings]);
    //   const sub = checkSubscription(settings);
    return (React.createElement(Paper, { sx: {
            display: "flex",
            p: 1,
            overflow: "hidden",
            justifyContent: "space-between",
            // alignItems: "center",
            height: 100,
            color: "black",
            backgroundColor: "#f6f9fc",
            //   pl: 8,
            boxShadow: 1,
        }, elevation: 0 },
        React.createElement("img", { src: stripeBlurple, alt: "stripe", style: {
                height: 84,
            } }),
        React.createElement(Box, { sx: { display: "flex" } },
            isConnected ? (React.createElement(Box, { sx: { display: "flex", alignItems: "center", mr: 8 } },
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "body2", sx: { mr: 1 } }, capability.canUsePaymentLinks
                        ? "You can now create and send payment links to your clients."
                        : "Stripe requires additional information to enable payment links. Log in and follow directions at www.stripe.com.")))) : (React.createElement(Box, { sx: { display: "flex", alignItems: "center", mr: 8 } },
                React.createElement(Typography, { variant: "body2", sx: { mr: 1 } }, "Connect your Stripe account to start accepting payments from clients seamlessly."))),
            React.createElement(Box, { sx: { display: "flex", alignItems: "center" } }, isConnected && !capability.canUsePaymentLinks ? (React.createElement(Button, { variant: "contained", onClick: goToStripe, disabled: loading, startIcon: React.createElement(OpenInNew, null), sx: {
                    mr: 3,
                    backgroundColor: "#635bff",
                    ":hover": {
                        backgroundColor: "#635bff",
                        filter: "brightness(1.1)",
                    },
                } }, "Go to Stripe")) : (React.createElement(Button, { variant: "contained", onClick: () => handleStripe(), disabled: loading, endIcon: isConnected ? React.createElement(Check, null) : null, sx: {
                    mr: 3,
                    backgroundColor: isConnected ? "success.main" : "#635bff",
                    ":hover": {
                        backgroundColor: isConnected ? "success.main" : "#635bff",
                        filter: "brightness(1.1)",
                    },
                } }, loading ? (React.createElement(CircularProgress, { size: 24, color: "primary" })) : isConnected ? ("Connected") : ("Connect Stripe")))))));
};
export default StripeIntegration;
