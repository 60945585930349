// src/components/ProjectComponentsDialog.tsx
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, FormControlLabel, Switch, } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import makeComponentColumns from "../utils/makeComponentColumns";
import { SelectedProject } from "../Projects";
import GenerateOrders from "@features/order/components/GenerateOrders";
import { useComponentsData } from "@features/inventory/hooks/useComponentsData";
/**
 * Dialog component to display project components.
 */
const ProjectComponentsDialog = ({ open, onClose, }) => {
    const { data: componentsData } = useComponentsData();
    // Retrieve components from the store
    const project = React.useContext(SelectedProject);
    const components = project?.components || [];
    // State to manage the view mode: combined or uncombined
    const [isCombined, setIsCombined] = React.useState(false);
    // Handler for switch toggle
    const handleToggle = () => {
        setIsCombined((prev) => !prev);
    };
    // Combine components if isCombined is true
    const combinedComponents = React.useMemo(() => {
        if (isCombined) {
            return combineComponents(components);
        }
        return components;
    }, [isCombined, components]);
    // Define columns using the makeComponentColumns function
    const columns = React.useMemo(() => {
        return makeComponentColumns(components);
    }, [components]);
    console.log("combinedComponents", combinedComponents);
    const orderSelection = React.useMemo(() => combineComponents(components)
        .map((c) => {
        let foundComponent = componentsData?.find((c2) => c2._id === c.componentId);
        if (!foundComponent) {
            return null;
        }
        const item = {
            ...foundComponent,
            quantity: c.quantity,
        };
        return item;
    })
        .filter((c) => c !== null), [components, componentsData]);
    return (React.createElement(Dialog, { open: open, onClose: onClose, maxWidth: "lg", fullWidth: true },
        React.createElement(DialogTitle, null, "Project Components"),
        React.createElement(DialogContent, null,
            React.createElement(Box, { sx: {
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    justifyContent: "flex-end",
                } },
                React.createElement(GenerateOrders, { selection: orderSelection }),
                React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: isCombined, onChange: handleToggle, color: "primary" }), label: "Combined View" })),
            React.createElement(Box, { sx: { height: 600, width: "100%" } },
                React.createElement(DataGrid, { density: "compact", rows: combinedComponents, columns: columns, getRowId: (row) => `${row.id}-${row.productId}`, disableRowSelectionOnClick: true, 
                    // Optional: Enable sorting and filtering as needed
                    sortingOrder: ["asc", "desc"], filterMode: "client", slots: {
                        toolbar: () => (React.createElement(Box, { sx: { gap: 1 } },
                            React.createElement(GridToolbarQuickFilter, { variant: "outlined", size: "small" }))),
                    }, sx: {
                        "& .MuiDataGrid-cell": {
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                        },
                    } }))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, variant: "contained" }, "Close"))));
};
export default ProjectComponentsDialog;
const combineComponents = (components) => {
    let combined = [];
    components.forEach((c) => {
        let qty = c.parameters.find((p) => p.name === "qty")?.calculatedValue;
        const existing = combined.find((c2) => c2.id === c.id);
        if (existing && isAlike(existing, c)) {
            existing.quantity += qty;
        }
        else {
            combined.push({ ...c, quantity: qty });
        }
    });
    return combined;
};
// components are alike if their parameters (excluding qty) are the same
const isAlike = (c1, c2) => {
    return (c1.parameters.length === c2.parameters.length &&
        c1.parameters.every((p, i) => p.name === c2.parameters[i].name) &&
        c1.parameters
            .filter((p) => p.name !== "qty")
            .every((p, i) => p.calculatedValue === c2.parameters[i].calculatedValue));
};
