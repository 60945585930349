import React, { useState, useMemo } from "react";
import { FormControl, InputLabel, MenuItem, Select, Chip, Box, ListSubheader, } from "@mui/material";
const CustomSelect = ({ label, options, value, getOptionCategories, categoryColors = {}, ...rest }) => {
    const [selectedFilters, setSelectedFilters] = useState([]);
    // Extract unique categories from options
    const allCategories = useMemo(() => {
        const categoryCounts = {};
        options.forEach((option) => {
            const categories = getOptionCategories ? getOptionCategories(option) : [];
            categories.forEach((category) => {
                categoryCounts[category] = (categoryCounts[category] || 0) + 1;
            });
        });
        const totalOptions = options.length;
        // Only include categories not present in all options
        return Object.keys(categoryCounts).filter((category) => categoryCounts[category] < totalOptions);
    }, [options, getOptionCategories]);
    // Filter options based on selected filters
    const filteredOptions = useMemo(() => {
        if (selectedFilters.length === 0)
            return options;
        const filtered = options.filter((option) => {
            const optionCategories = getOptionCategories
                ? getOptionCategories(option)
                : [];
            return selectedFilters.every((filter) => optionCategories.includes(filter));
        });
        // Ensure the selected value is always included
        const selectedOption = options.find((option) => String(option.id) === String(value));
        if (selectedOption &&
            !filtered.some((option) => String(option.id) === String(value))) {
            filtered.push(selectedOption);
        }
        return filtered;
    }, [options, selectedFilters, getOptionCategories, value]);
    const handleFilterChange = (category) => {
        setSelectedFilters((prevFilters) => prevFilters.includes(category)
            ? prevFilters.filter((c) => c !== category)
            : [...prevFilters, category]);
    };
    return (React.createElement(FormControl, { fullWidth: true, size: rest.size || "medium" },
        label && React.createElement(InputLabel, null, label),
        React.createElement(Select, { labelId: "custom-select-label", id: "custom-select", 
            // Handle undefined/null values by defaulting to empty string
            value: value !== undefined && value !== null ? String(value) : "", label: label, ...rest, renderValue: rest.renderValue ||
                ((selected) => {
                    const selectedOption = options.find((o) => String(o.id) === String(selected));
                    // Return a placeholder if no option is selected
                    if (selected === "")
                        return React.createElement("em", null, "None");
                    return selectedOption ? selectedOption.name : "";
                }) },
            allCategories.length > 0 && (React.createElement(ListSubheader, null,
                React.createElement(Box, { sx: { display: "flex", flexWrap: "wrap", gap: 0.5 } }, allCategories.map((category) => (React.createElement(Chip, { key: category, label: category, size: "small", onClick: () => handleFilterChange(category), variant: selectedFilters.includes(category) ? "filled" : "outlined", sx: {
                        backgroundColor: selectedFilters.includes(category)
                            ? categoryColors[category] || undefined
                            : undefined,
                        color: selectedFilters.includes(category)
                            ? getContrastYIQ(categoryColors[category] || "#ffffff")
                            : undefined,
                    } })))))),
            filteredOptions.map((option) => (React.createElement(MenuItem, { key: option.id, value: String(option.id) }, option.name))))));
};
// Helper function to get contrast color (from your Inventory component)
function getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
}
export default CustomSelect;
