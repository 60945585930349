import React from "react";
import { SelectedProject } from "../Projects";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box, Button, Fade, Paper } from "@mui/material";
import ProductAddDialog from "./ProductAddDialog";
import SmallProducts from "./smallViews/SmallProducts";
import { FocusContext } from "./ProjectView";
import useProductsStore from "../productsStore";
import makeColumns from "../utils/makeColumns";
import { Add, Edit, Inventory } from "@mui/icons-material";
import ProjectComponentsDialog from "./ProjectComponentsDialog";
import { useComponentsData } from "@features/inventory/hooks/useComponentsData";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
const ProjectProducts = () => {
    const project = React.useContext(SelectedProject);
    const { focused, setFocused } = React.useContext(FocusContext);
    const expanded = focused.products;
    const { data } = useComponentsData();
    const { data: settings } = useSettingsData();
    const { products, addProduct, components, setGlobalParameters, unpackProducts, removeProducts, clear, } = useProductsStore();
    const filteredComponents = React.useMemo(() => {
        return data?.filter((c) => c.price || c.priceModifier);
    }, [data]);
    const [componentsDialogOpen, setComponentsDialogOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        if (!settings)
            return;
        const globalParameters = settings.parameters
            .filter((p) => p.global)
            .map((p) => ({ ...p, variable: p.name, id: "global" }));
        setGlobalParameters(globalParameters);
    }, [settings]);
    React.useEffect(() => {
        if (!project || !data || !settings)
            return;
        clear();
        unpackProducts({ products: project.products, components: project.components }, data);
    }, [data, settings, open]);
    if (!products || !data || !project)
        return null;
    const hasProducts = products.length > 0;
    console.log(project);
    return (React.createElement(Paper, { sx: { height: "100%" } },
        React.createElement(Fade, { in: expanded },
            React.createElement(Box, { sx: { minHeight: 0, height: expanded ? "100%" : 0 } },
                React.createElement(ProductAddDialog, { open: open, setOpen: setOpen }),
                React.createElement(DataGrid, { rows: products, slots: {
                        noRowsOverlay: () => React.createElement("div", null, "No products added"),
                        toolbar: () => (React.createElement(Box, { sx: {
                                display: "flex",
                                justifyContent: "space-between",
                                p: 1,
                                pb: 0,
                            } },
                            React.createElement(Box, null,
                                React.createElement(GridToolbarQuickFilter, { variant: "outlined", size: "small" })),
                            React.createElement(Box, null,
                                React.createElement(Button, { variant: "contained", color: "primary", onClick: () => setFocused("products"), sx: { mr: 1 } }, "Collapse"),
                                React.createElement(Button, { onClick: () => setComponentsDialogOpen(true), variant: "contained", sx: { mr: 1 }, startIcon: React.createElement(Inventory, null) }, "Components"),
                                React.createElement(Button, { onClick: () => setOpen(true), variant: "contained", startIcon: hasProducts ? React.createElement(Edit, null) : React.createElement(Add, null) }, hasProducts ? "Edit" : "Add")))),
                    }, columns: makeColumns(products, components, false, project.components), sx: { height: "100%" } }))),
        React.createElement(Fade, { in: !expanded },
            React.createElement("div", null,
                React.createElement(SmallProducts, { setOpen: setOpen }))),
        React.createElement(ProjectComponentsDialog, { open: componentsDialogOpen, onClose: () => setComponentsDialogOpen(false) })));
};
export default ProjectProducts;
