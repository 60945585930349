import React, { Fragment, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import multimonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin from "@fullcalendar/interaction";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import "./utils/Calendar.css";
import { MuiAdapter } from "src/utils/MuiAdapter";
import dayjs from "dayjs";
import { useProjectsData } from "../project/hooks/useProjectsData";
import { Check } from "@mui/icons-material";
const Calendar = () => {
    const theme = useTheme();
    const { data: projects, isLoading, error } = useProjectsData();
    // Extract and combine events from all projects
    const events = React.useMemo(() => {
        let allEvents = [];
        if (!projects)
            return allEvents;
        projects.forEach((project) => {
            const projectEvents = createEvents(project, theme);
            allEvents = allEvents.concat(projectEvents);
        });
        return allEvents;
    }, [projects, theme]);
    const renderEventContent = useCallback((eventInfo) => {
        if (eventInfo.event.display === "background") {
            const info = eventInfo.event.extendedProps;
            // Optionally render the project name on the background
            return (React.createElement("div", { style: {
                    backgroundColor: eventInfo.event.backgroundColor,
                    color: info.color,
                    height: "100%",
                    width: "100%",
                    opacity: 0.1,
                    position: "absolute",
                    top: 0,
                    left: 0,
                } },
                React.createElement(Typography, { variant: "subtitle2" }, eventInfo.event.title)));
        }
        const info = eventInfo.event.extendedProps;
        return (React.createElement(Paper, { id: `${info.project}_${info.milestone}`, sx: {
                p: 0.5,
                display: "flex",
                flexDirection: "column",
                backgroundColor: info.color,
                filter: info.completed ? "brightness(60%)" : "none",
            } },
            React.createElement(Box, { sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    textWrap: "wrap",
                } },
                React.createElement(Typography, { sx: { textWrap: "wrap" }, color: info.fontColor }, eventInfo.event.title),
                info.completed ? React.createElement(Check, { color: "success" }) : null),
            React.createElement(Typography, { variant: "caption", color: info.fontColor },
                info.project,
                " - ",
                info.milestone)));
    }, []);
    // Handle loading and error states
    if (isLoading) {
        return React.createElement(Typography, null, "Loading projects...");
    }
    if (error || !projects) {
        return React.createElement(Typography, null, "Error loading projects.");
    }
    return (React.createElement(Fragment, null,
        React.createElement(MuiAdapter, { theme: theme }),
        React.createElement(Box, { sx: { maxHeight: "calc(100vh - 100px)" } },
            React.createElement(FullCalendar, { editable: false, events: events, height: "calc(100vh - 100px)", plugins: [dayGridPlugin, interactionPlugin, multimonthPlugin], eventContent: renderEventContent, initialView: "dayGridMonth", headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,multiMonthYear",
                }, eventDisplay: "block" }))));
};
export default Calendar;
const createEvents = (project, theme) => {
    let newEvents = [];
    let startDate = dayjs(project.creationDate).toDate();
    let endDate = dayjs(project.creationDate).add(1, "day").toDate();
    project.tasks.forEach((task) => {
        task.start ? (startDate = task.start) : null;
        task.end ? (endDate = task.end) : null;
        newEvents.push({
            title: task.name,
            start: startDate,
            end: endDate,
            allDay: true,
            backgroundColor: project.color,
            fontColor: project.color
                ? getContrastYIQ(project.color)
                : theme.palette.text.primary,
            milestone: task.milestone,
            project: project.name,
            assignedTo: task.assignedTo.map((p) => p),
            extendedProps: {
                assignedTo: task.assignedTo.map((p) => p),
                milestone: task.milestone,
                completed: task.completed,
                color: project.color,
                start: startDate,
                end: endDate,
            },
        });
        startDate = endDate;
        endDate = dayjs(endDate).add(1, "day").toDate();
    });
    return newEvents;
};
function getContrastYIQ(hexcolor) {
    if (!hexcolor)
        return "black";
    // Remove '#' if present
    hexcolor = hexcolor.replace("#", "");
    // Convert hex color to RGB
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    // Calculate YIQ (brightness) value
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    // Return 'black' or 'white' based on the YIQ value
    return yiq >= 128 ? "black" : "white";
}
