import React from "react";
import { useSettingsData } from "../hooks/useSettingsData";
import { Box, Button, ClickAwayListener, Collapse, Fade, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Popover, Stack, useTheme, } from "@mui/material";
import { useSettingsUpdate } from "../hooks/useSettingsUpdate";
import { TransitionGroup } from "react-transition-group";
import { HexColorPicker } from "react-colorful";
import { Check, Delete } from "@mui/icons-material";
import { getContrastYIQ } from "src/utils/colorUtils";
const Categories = () => {
    const { data: settings } = useSettingsData();
    const { mutate: updateSettings } = useSettingsUpdate();
    const [value, setValue] = React.useState("");
    const [color, setColor] = React.useState("grey");
    const categories = settings?.categories || [];
    const handleAddCategory = () => {
        if (!value)
            return;
        updateSettings({
            categories: [...categories, { name: value, color: color }],
        });
        setValue("");
        setColor("grey");
        setIsFocused(false);
    };
    const handleEditCategory = ({ oldName, newName, color, }) => {
        updateSettings({
            categories: categories.map((c) => c.name === oldName ? { ...c, name: newName, color } : c),
        });
    };
    const handleClickAway = () => {
        // setValue("");
        // setColor("grey");
        setIsFocused(false);
    };
    const handleDeleteCategory = (name) => {
        updateSettings({
            categories: categories.filter((c) => c.name !== name),
        });
    };
    const [isFocused, setIsFocused] = React.useState(false);
    return (React.createElement(Box, { sx: { mt: 1 } },
        React.createElement(TransitionGroup, null,
            React.createElement(Stack, { direction: "row", gap: 1, alignItems: "end", flexWrap: "wrap" },
                React.createElement(ClickAwayListener, { onClickAway: handleClickAway },
                    React.createElement(FormControl, { sx: { width: 250 }, variant: "outlined", size: "small" },
                        React.createElement(InputLabel, null, "Add Category"),
                        React.createElement(OutlinedInput, { value: value, onChange: (e) => setValue(e.target.value), label: "Add Category", onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                    handleAddCategory();
                                }
                            }, onFocus: () => setIsFocused(true), startAdornment: (isFocused || value) && (React.createElement(Fade, { in: (isFocused || value), unmountOnExit: true },
                                React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(ColorPicker, { color: color, setColor: setColor })))), endAdornment: isFocused && (React.createElement(Fade, { in: isFocused, unmountOnExit: true },
                                React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { onClick: handleAddCategory, size: "small" },
                                        React.createElement(Check, null))))) }))),
                settings?.categories.map((category) => (React.createElement(Fade, { in: true },
                    React.createElement("div", { key: category.name },
                        React.createElement(Category, { category: category, handleEdit: handleEditCategory, handleDelete: handleDeleteCategory })))))))));
};
export default Categories;
const MIN_INPUT_WIDTH = 100;
const BUTTONS_WIDTH = 90; // Approximate width needed for buttons
const PADDING = 28; // Left and right padding in the input
const getInputSize = (name, editing) => {
    const textWidth = name.length * 12; // Approximate character width
    const baseWidth = Math.max(textWidth + PADDING, MIN_INPUT_WIDTH);
    return editing ? baseWidth + BUTTONS_WIDTH : baseWidth;
};
const Category = ({ category, handleEdit, handleDelete, }) => {
    const [editing, setEditing] = React.useState(false);
    const theme = useTheme();
    const [name, setName] = React.useState(category.name);
    const [color, setColor] = React.useState(category.color);
    const [inputWidth, setInputWidth] = React.useState(getInputSize(category.name, editing));
    React.useEffect(() => {
        setInputWidth(getInputSize(name, editing));
    }, [name, editing]);
    const handleCancel = () => {
        setName(category.name);
        setColor(category.color);
        setEditing(false);
    };
    const handleSave = () => {
        handleEdit({ oldName: category.name, newName: name, color });
        setEditing(false);
    };
    const handleDeleteClick = () => {
        handleDelete(category.name);
    };
    return (React.createElement(ClickAwayListener, { onClickAway: handleCancel },
        React.createElement(Paper, null,
            React.createElement(FormControl, { onClick: () => {
                    if (!editing) {
                        setEditing(true);
                    }
                } },
                React.createElement(OutlinedInput, { value: name, onChange: (e) => setName(e.target.value), size: "small", sx: {
                        width: inputWidth,
                        backgroundColor: editing ? "default" : category.color,
                        color: editing
                            ? theme.palette.text.primary
                            : getContrastYIQ(category.color),
                        transition: "color, background-color 0.5s, width 0.3s",
                        flex: 1,
                    }, startAdornment: editing ? (React.createElement(InputAdornment, { position: "start" },
                        React.createElement(Collapse, { in: editing, unmountOnExit: true, orientation: "horizontal" },
                            React.createElement(ColorPicker, { color: color, setColor: setColor })))) : null, endAdornment: editing ? (React.createElement(InputAdornment, { position: "end", sx: { mr: -1 } },
                        React.createElement(Collapse, { in: editing, unmountOnExit: true, orientation: "horizontal" },
                            React.createElement(IconButton, { onClick: handleSave, size: "small" },
                                React.createElement(Check, null)),
                            React.createElement(IconButton, { onClick: handleDeleteClick, size: "small" },
                                React.createElement(Delete, null))))) : null })))));
};
const ColorPicker = ({ color, setColor, }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation(); // Prevent triggering edit mode
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (React.createElement("div", null,
        React.createElement(Button, { sx: {
                backgroundColor: color,
                "&:hover": {
                    backgroundColor: color,
                    filter: "brightness(0.8)",
                },
                width: 20,
                height: 20,
                minWidth: 20,
                border: 1,
                padding: 0,
                minHeight: 20,
            }, onClick: handleClick }),
        React.createElement(Popover, { anchorEl: anchorEl, open: open, onClose: handleClose, sx: { pt: 0, pb: 0 }, anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "left",
            } },
            React.createElement(HexColorPicker, { color: color, onChange: (c) => setColor(c) }))));
};
