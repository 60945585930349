import api from "./api";
export const getTemplateDocument = async (docxId) => {
    const response = await api.get("/api/template/getDoc", {
        params: { id: docxId },
    });
    return response.data; // The endpoint is expected to return the SFDT JSON object
};
export const saveTemplateDocument = async (sfdt, filename, destinationId) => {
    const response = await api.post("/api/template//exportPdf", {
        body: JSON.stringify({
            sfdt,
            filename,
            destinationId,
        }),
    });
    return response.data; // The endpoint should return the saved file object
};
export const deleteFiles = async (pack) => {
    const response = await api.post(`/api/filemanager`, {
        action: "delete",
        data: pack,
    });
    if (response.status !== 204) {
        throw new Error("Failed to delete file");
    }
    return "Files deleted";
};
export const getFiles = async () => {
    const response = await api.get(`/api/file`);
    return response.data;
};
export const getFile = async (fileId) => {
    const response = await api.get(`/api/file/${fileId}`);
    return response.data;
};
export const updateFile = async (file) => {
    const response = await api.patch(`/api/file/${file._id}`, file);
    return response.data;
};
export const createFile = async (file) => {
    const response = await api.post("/api/file", file);
    return response.data;
};
export const uploadFiles = async (files) => {
    const response = await api.post("/api/file", files);
    return response.data;
};
