import { Divider, ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useComponentCreateStore } from "../store/createComponentStore";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
import useGeneralStore from "@stores/generalStore";
const CategoryMenu = ({ anchor, setAnchor, id, }) => {
    const { data: settings } = useSettingsData();
    const { onNodeChange, getNode } = useComponentCreateStore();
    const categories = getNode(id)?.data.categories;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    React.useEffect(() => {
        setAnchorEl(anchor);
    }, [anchor]);
    const handleClose = () => {
        setAnchor(null);
    };
    const handleClick = (category) => {
        const newCategories = categories.includes(category)
            ? categories.filter((cat) => cat !== category)
            : [...categories, category];
        onNodeChange(id, { field: "categories", value: newCategories });
    };
    const { setSettingsTab } = useGeneralStore();
    return (React.createElement("div", null,
        React.createElement(Menu, { id: "add-menu", anchorEl: anchorEl, open: open, onClose: handleClose, anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            }, MenuListProps: {
                "aria-labelledby": "basic-button",
            } },
            React.createElement(MenuItem, { onClick: () => {
                    setSettingsTab("categories");
                    handleClose();
                } },
                React.createElement(ListItemText, null, "Manage Categories")),
            React.createElement(Divider, null),
            settings?.categories.map((category) => (React.createElement(MenuItem, { key: category.name, onClick: () => handleClick(category.name), sx: {
                    backgroundColor: categories?.includes(category.name)
                        ? "primary.main"
                        : "default",
                    color: categories?.includes(category.name)
                        ? "primary.contrastText"
                        : "default",
                    "&:hover": {
                        backgroundColor: categories?.includes(category.name)
                            ? "primary.light"
                            : "default",
                    },
                } },
                React.createElement(ListItemText, null, category.name)))))));
};
export default CategoryMenu;
