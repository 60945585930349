import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteFinancial } from "@api/financialApi";
import useNotificationStore from "@stores/notificationStore";
export function useFinancialDelete() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: deleteFinancial,
        onSuccess: (deletedFinancialId) => {
            queryClient.setQueryData(["financials"], (oldData) => {
                return oldData.filter((financial) => financial._id !== deletedFinancialId);
            });
            addNotification("Financial deleted.", "success");
        },
        onError: () => {
            addNotification("Could not delete financial.", "error");
        },
    });
}
