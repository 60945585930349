import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import { FocusContext } from "../ProjectView";
import { SelectedProject } from "../../Projects";
import { People } from "../tasks/People";
import { Milestone } from "../tasks/Milestone";
import { CalendarMonth } from "@mui/icons-material";
const SmallPlan = () => {
    const { setFocused } = React.useContext(FocusContext);
    const project = React.useContext(SelectedProject);
    const totalTasks = project?.tasks.length || 0;
    const completedTasks = project?.tasks.filter((task) => task.completed).length;
    if (!project)
        return null;
    return (React.createElement(Paper, { sx: { height: "100%" } },
        React.createElement(Box, { sx: {
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                alignItems: "start",
            } },
            React.createElement(Typography, { variant: "h4" },
                "Tasks ",
                completedTasks,
                "/",
                totalTasks),
            React.createElement(Button, { variant: "contained", color: "primary", onClick: () => setFocused("plan"), startIcon: React.createElement(CalendarMonth, null) }, "Calendar")),
        React.createElement(Box, { sx: { display: "flex", flexDirection: "column", gap: 1, p: 1 } },
            React.createElement(People, null),
            project.milestones.map((milestone) => (React.createElement(Milestone, { key: milestone.name, milestone: milestone }))))));
};
export default SmallPlan;
