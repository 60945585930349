import React from "react";
import { Button, Popover } from "@mui/material";
import { HexColorPicker } from "react-colorful";
const ColorPicker = ({ color, setColor, label, }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (React.createElement("div", null,
        React.createElement(Button, { sx: {
                backgroundColor: color,
                "&:hover": {
                    backgroundColor: color,
                    filter: "brightness(0.8)",
                },
                width: 20,
                height: 20,
                minWidth: 20,
                border: 1,
            }, onClick: handleClick }, label || ""),
        React.createElement(Popover, { anchorEl: anchorEl, open: open, onClose: handleClose, sx: { pt: 0, pb: 0 }, anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "left",
            } },
            React.createElement(HexColorPicker, { color: color, onChange: (c) => setColor(c) }))));
};
export default ColorPicker;
