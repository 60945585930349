import React, { useState } from "react";
import { Handle, Position } from "@xyflow/react";
import { Paper, IconButton, TextField, Box, Stack, useTheme, List, ListItem, ListItemText, } from "@mui/material";
import { ExpandMore, ExpandLess, DragHandle, Delete, } from "@mui/icons-material";
import { useComponentCreateStore } from "./store/createComponentStore";
const GroupNode = ({ id, data, selected }) => {
    const [collapsed, setCollapsed] = useState(data.collapsed);
    const { nodes, setNodes, edges, setEdges, disbandGroup } = useComponentCreateStore();
    const theme = useTheme();
    // Get member nodes
    const memberNodes = nodes.filter((node) => data.nodeIds.includes(node.id));
    const handleToggle = () => {
        const newCollapsed = !collapsed;
        setCollapsed(newCollapsed);
        // Update member nodes and edges
        setNodes(nodes.map((node) => {
            if (data.nodeIds.includes(node.id)) {
                return { ...node, hidden: newCollapsed };
            }
            if (node.id === id) {
                return { ...node, data: { ...node.data, collapsed: newCollapsed } };
            }
            return node;
        }));
        setEdges(edges.map((edge) => {
            if (data.nodeIds.includes(edge.source) ||
                data.nodeIds.includes(edge.target)) {
                return { ...edge, hidden: newCollapsed };
            }
            return edge;
        }));
    };
    const handleNameChange = (event) => {
        const newName = event.target.value;
        setNodes(nodes.map((node) => {
            if (node.id === id) {
                return { ...node, data: { ...node.data, name: newName } };
            }
            return node;
        }));
    };
    const handleDisbandGroup = () => {
        disbandGroup(id);
    };
    return (React.createElement(Paper, { sx: {
            p: 1,
            border: 2,
            borderColor: selected ? "primary.main" : theme.palette.text.primary,
            position: "relative",
        } },
        React.createElement(Handle, { type: "target", position: Position.Top }),
        React.createElement(Stack, { direction: "row", alignItems: "center", spacing: 1 },
            React.createElement(IconButton, { className: "drag-handle", sx: { cursor: "move" } },
                React.createElement(DragHandle, null)),
            React.createElement(TextField, { value: data.name, onChange: handleNameChange, placeholder: "Group Name", size: "small" }),
            React.createElement(IconButton, { size: "small", onClick: handleDisbandGroup },
                React.createElement(Delete, null)),
            React.createElement(IconButton, { size: "small", onClick: handleToggle }, collapsed ? React.createElement(ExpandMore, null) : React.createElement(ExpandLess, null))),
        React.createElement(Box, { sx: {
                mt: 1,
                maxHeight: 150,
                overflowY: "auto",
            } },
            React.createElement(List, { dense: true }, memberNodes.map((memberNode) => (React.createElement(ListItem, { key: memberNode.id },
                React.createElement(ListItemText, { primary: memberNode.data.name })))))),
        React.createElement(Handle, { type: "source", position: Position.Bottom })));
};
export default GroupNode;
