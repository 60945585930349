import React, { useState } from "react";
import { useOrdersData } from "./hooks/useOrdersData";
import { DataGrid, GridToolbarQuickFilter, } from "@mui/x-data-grid";
import { Box, Checkbox } from "@mui/material";
import OrderDialog from "./components/OrderDialog";
import { useOrderUpdate } from "./hooks/useOrderUpdate";
const Orders = () => {
    const { isLoading, data } = useOrdersData();
    const [selectedOrder, setSelectedOrder] = useState(null);
    const { mutate: updateOrder } = useOrderUpdate();
    const [dialogOpen, setDialogOpen] = useState(false);
    // Handle row click to open the dialog
    const handleRowClick = (params) => {
        setSelectedOrder(params.row);
        setDialogOpen(true);
    };
    // Close the dialog
    const handleDialogClose = () => {
        setSelectedOrder(null);
        setDialogOpen(false);
    };
    const columns = [
        {
            field: "name",
            headerName: "Order Name",
            width: 200,
            sortable: true,
        },
        {
            field: "supplier",
            headerName: "Supplier",
            width: 200,
            sortable: true,
            renderCell: (params) => {
                return params.value ? params.value.name : "";
            },
        },
        {
            field: "total",
            headerName: "Total",
            width: 150,
            type: "number",
            sortable: true,
            renderCell: (params) => {
                return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                }).format(params.value || 0);
            },
        },
        {
            field: "createdAt",
            headerName: "Created",
            flex: 1,
            renderCell: (params) => new Date(params.value).toLocaleString(),
        },
        {
            field: "received",
            headerName: "Received",
            width: 150,
            sortable: true,
            renderCell: (params) => (React.createElement(Checkbox, { checked: !!params.value, onClick: (e) => e.stopPropagation(), onChange: (e) => {
                    updateOrder({
                        _id: params.row._id,
                        received: e.target.checked,
                    });
                } })),
        },
        {
            field: "project",
            headerName: "Project",
            width: 200,
            sortable: true,
            renderCell: (params) => {
                return params.value ? params.value.name : "";
            },
        },
        // {
        //   field: "file",
        //   headerName: "File",
        //   width: 150,
        //   sortable: false,
        //   renderCell: (params) => {
        //     console.log(params);
        //     const file = files?.find((f) => f._id === params.value?._id);
        //     if (!file) return "";
        //     return (
        //       <Tooltip title="View File">
        //         <Button
        //           href={file.s3Key}
        //           target="_blank"
        //           rel="noopener noreferrer"
        //           onClick={(e) => e.stopPropagation()}
        //           variant="contained"
        //           color="primary"
        //           size="small"
        //           startIcon={<OpenInNew />}
        //         >
        //           View
        //         </Button>
        //       </Tooltip>
        //     );
        //   },
        // },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(DataGrid, { rows: data || [], columns: columns, getRowId: (row) => row._id, sx: { minHeight: 600, maxHeight: "800px" }, loading: isLoading, onRowClick: handleRowClick, slots: {
                toolbar: () => (React.createElement(Box, { sx: {
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                        pb: 0,
                    } },
                    React.createElement(GridToolbarQuickFilter, { variant: "outlined", size: "small" }))),
            } }),
        dialogOpen && (React.createElement(OrderDialog, { orderData: selectedOrder, open: dialogOpen, onClose: handleDialogClose }))));
};
export default Orders;
