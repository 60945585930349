import React from "react";
import { useSettingsData } from "../hooks/useSettingsData";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Paper, Stack, TextField, Typography, } from "@mui/material";
import { Add, Delete, DeleteForever } from "@mui/icons-material";
import { useSettingsUpdate } from "../hooks/useSettingsUpdate";
import CustomButton from "src/components/CustomButton";
const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "value", headerName: "Value", flex: 1 },
    { field: "global", headerName: "Global", flex: 1 },
];
const ParameterTable = () => {
    const { data: settings } = useSettingsData();
    const [parameter, setParameter] = React.useState(null);
    console.log(settings);
    return (React.createElement(Paper, null,
        React.createElement(DataGrid, { autoHeight: true, columns: columns, density: "compact", rows: settings?.parameters || [], getRowId: (row) => row.name, onRowClick: (row) => setParameter(row.row), disableRowSelectionOnClick: true, hideFooter: true, slots: {
                toolbar: () => (React.createElement(Box, { sx: {
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                        pb: 0,
                    } },
                    React.createElement(Box, { sx: { gap: 1 } },
                        React.createElement(GridToolbarQuickFilter, { variant: "outlined", size: "small" })),
                    React.createElement(Box, { sx: { gap: 1 } },
                        React.createElement(Button, { variant: "contained", sx: { textTransform: "none" }, startIcon: React.createElement(Add, null), onClick: () => setParameter({
                                name: "",
                                description: "",
                                value: "",
                                global: false,
                                new: true,
                            }) },
                            React.createElement(Typography, null, "Parameter"))))),
                noRowsOverlay: () => (React.createElement(Box, { sx: { pt: 4.5 } },
                    React.createElement(Typography, { color: "textSecondary", textAlign: "center" }, "No parameters to show."))),
            } }),
        React.createElement(ParameterDialog, { parameter: parameter, setParameter: setParameter, parameters: settings?.parameters || [] })));
};
export default ParameterTable;
const ParameterDialog = ({ parameter, setParameter, parameters, }) => {
    const { mutate: save } = useSettingsUpdate();
    if (!parameter) {
        return null;
    }
    const handleClose = () => {
        setParameter(null);
    };
    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setParameter({ ...parameter, [name]: name === "global" ? checked : value });
    };
    const handleSave = () => {
        if (parameter.new) {
            save({ parameters: [...parameters, parameter] });
        }
        else {
            save({
                parameters: parameters.map((p) => p.name === parameter.name ? parameter : p),
            });
        }
        setParameter(null);
        return;
    };
    const handleDelete = () => {
        save({ parameters: parameters.filter((p) => p.name !== parameter.name) });
        setParameter(null);
    };
    return (React.createElement(Dialog, { open: !!parameter, onClose: handleClose, maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, null, parameter.new ? "Create Parameter" : "Edit Parameter"),
        React.createElement(DialogContent, null,
            React.createElement(Stack, { spacing: 1, sx: { mt: 1 } },
                React.createElement(TextField, { fullWidth: true, label: "Name", name: "name", disabled: !parameter.new, value: parameter.name, onChange: handleChange }),
                React.createElement(TextField, { fullWidth: true, name: "description", label: "Description", value: parameter.description, onChange: handleChange }),
                React.createElement(TextField, { fullWidth: true, label: "Value", name: "value", value: parameter.value, onChange: handleChange }),
                React.createElement(FormControlLabel, { label: "Global", control: React.createElement(Checkbox, { checked: parameter.global, name: "global", onChange: handleChange }) }),
                " ")),
        React.createElement(DialogActions, { sx: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            } },
            React.createElement(Box, null, !parameter.new && (React.createElement(CustomButton, { onClick: handleDelete, label: "Delete", startIcon: React.createElement(Delete, null), confirmIcon: React.createElement(DeleteForever, null), color: "error" }))),
            React.createElement(Box, null,
                React.createElement(Button, { onClick: handleClose }, "Cancel"),
                React.createElement(Button, { onClick: handleSave }, "Save")))));
};
