import { getComponents } from "@api/componentApi";
import useAuthStore from "@stores/authStore";
import { useQuery } from "@tanstack/react-query";
export const useComponentsData = () => {
    const { user } = useAuthStore();
    if (!user) {
        throw new Error("No user!");
    }
    return useQuery({
        queryKey: ["components"],
        queryFn: getComponents,
        staleTime: 1800 * 1000,
    });
};
