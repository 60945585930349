import { Box, Button, Fade, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { checkout } from "../../api/paymentApi";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const Buy = () => {
    const navigate = useNavigate();
    const [address, setAddress] = useState({
        city: "",
        line1: "",
        line2: "",
        postal_code: "",
        state: "",
    });
    const handleChange = (field, value) => {
        setAddress((prev) => ({ ...prev, [field]: value }));
    };
    const handleBuyClick = async () => {
        try {
            const data = await checkout(address);
            window.location.href = data;
        }
        catch (error) {
            console.error(error);
        }
    };
    return (React.createElement(Box, { sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        } },
        React.createElement(Fade, { key: "back", in: true, timeout: { enter: 2000 } },
            React.createElement(Button, { startIcon: React.createElement(ArrowBack, null), onClick: () => navigate("/"), sx: { mr: 1, width: 100, ml: "-100px" } }, "Back")),
        React.createElement(Stack, { direction: "column", spacing: 1, sx: { width: 300, display: "flex" } },
            React.createElement(Fade, { key: "line1", in: true, timeout: { enter: 500 } },
                React.createElement(TextField, { label: "Address", value: address.line1, fullWidth: true, onChange: (e) => handleChange("line1", e.target.value) })),
            React.createElement(Fade, { key: "line2", in: true, timeout: { enter: 1000 } },
                React.createElement(TextField, { label: "Apartment, suite, etc.", value: address.line2, fullWidth: true, onChange: (e) => handleChange("line2", e.target.value) })),
            React.createElement(Fade, { key: "city", in: true, timeout: { enter: 1500 } },
                React.createElement(TextField, { label: "City", value: address.city, fullWidth: true, onChange: (e) => handleChange("city", e.target.value) })),
            React.createElement(Stack, { direction: "row", spacing: 1 },
                React.createElement(Fade, { key: "postal_code", in: true, timeout: { enter: 2000 } },
                    React.createElement(TextField, { label: "Postal Code", value: address.postal_code, fullWidth: true, onChange: (e) => handleChange("postal_code", e.target.value) })),
                React.createElement(Fade, { key: "state", in: true, timeout: { enter: 2500 } },
                    React.createElement(TextField, { label: "State", value: address.state, fullWidth: true, onChange: (e) => handleChange("state", e.target.value) }))),
            React.createElement(Fade, { key: "buy", in: true, timeout: { enter: 3000 } },
                React.createElement(Button, { variant: "contained", onClick: handleBuyClick }, "Submit")))));
};
export default Buy;
