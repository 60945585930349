import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, } from "@mui/material";
/**
 * Similar to SelectProductsButton, but for components.
 * Toggles the "selected" state on components instead of filtering.
 */
export function SelectComponentsButton({ components, onSelectionChange, }) {
    const [open, setOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    useEffect(() => {
        if (open) {
            const currentlySelected = components
                .filter((c) => c.selected)
                .map((c) => c._id);
            setSelectedIds(currentlySelected);
        }
    }, [open, components]);
    const handleCheckboxChange = (id, checked) => {
        setSelectedIds((prev) => checked ? [...prev, id] : prev.filter((itemId) => itemId !== id));
    };
    const handleConfirm = () => {
        const updated = components.map((c) => ({
            ...c,
            selected: selectedIds.includes(c._id),
        }));
        onSelectionChange(updated);
        setOpen(false);
    };
    const abled = components && components.length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "outlined", disabled: !abled, onClick: () => setOpen(true) }, "Select Components"),
        abled && (React.createElement(Dialog, { open: open, onClose: () => setOpen(false) },
            React.createElement(DialogTitle, null, "Select Components"),
            React.createElement(DialogContent, null, components.map((c) => (React.createElement("div", { key: c._id },
                React.createElement("input", { type: "checkbox", checked: selectedIds.includes(c._id), onChange: (e) => handleCheckboxChange(c._id, e.target.checked) }),
                c.name,
                " (ID: ",
                c.productId,
                ")")))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => setOpen(false) }, "Cancel"),
                React.createElement(Button, { onClick: handleConfirm }, "Confirm"))))));
}
