// ParentComponent.tsx
import React, { useState } from "react";
import { Button, useTheme } from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import { FileManagerComponent, Toolbar as FMToolbar, NavigationPane, DetailsView, Inject, ToolbarItemsDirective, ToolbarItemDirective, } from "@syncfusion/ej2-react-filemanager";
import { DocumentEditorContainerComponent, Toolbar as DEToolbar, } from "@syncfusion/ej2-react-documenteditor";
import { useCustomersData } from "@features/customer/hooks/useCustomersData";
import { VariablesDialog } from "./VariablesDialog";
import { useProjectsData } from "@features/project/hooks/useProjectsData";
import { customerVariables, orderVariables, organizationVariables, projectVariables, supplierVariables, templateVariables, useComponentVariables, useProductVariables, } from "./variables";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
import { useComponentsData } from "@features/inventory/hooks/useComponentsData";
import GenerateFromTemplate from "./components/GenerateFromTemplate";
// import GenerateFromTemplate from "./GenerateFromTemplate";
// Inject toolbar for Document Editor
DocumentEditorContainerComponent.Inject(DEToolbar);
export default function FileManager({ path, projectId, showNavigationPane, view, }) {
    let fileObj;
    const [currentFileId, setCurrentFileId] = useState(null);
    const [showEditor, setShowEditor] = useState(false);
    const [variablesOpen, setVariablesOpen] = useState(false);
    const [customerMenuAnchorEl, setCustomerMenuAnchorEl] = useState(null);
    let container;
    const { data: customers } = useCustomersData();
    const { data: projects } = useProjectsData();
    const ajaxSettings = {
        url: "api/filemanager",
        downloadUrl: "api/filemanager",
        uploadUrl: "api/filemanager",
        getImageUrl: "api/filemanager/image?",
    };
    const onFileOpen = async (args) => {
        if (args.fileDetails.isFile) {
            const fileId = args.fileDetails.id;
            const extension = args.fileDetails.type;
            if (extension.toLowerCase() === "docx") {
                const response = await fetch(`/api/template/getDoc?id=${fileId}`, {
                    credentials: "include",
                });
                if (!response.ok) {
                    console.error("Failed to fetch doc file");
                    return;
                }
                const sfdt = await response.json();
                setShowEditor(true);
                container.documentEditor.open(JSON.stringify(sfdt));
                setCurrentFileId(fileId);
                args.cancel = true;
            }
            else {
                const downloadUrl = `${ajaxSettings.downloadUrl}?action=download&id=${fileId}`;
                window.open(downloadUrl, "_self");
                args.cancel = true;
            }
        }
    };
    const onSave = async () => {
        if (!currentFileId || !container.documentEditor)
            return;
        const editor = container.documentEditor;
        const sfdt = { content: editor.serialize() };
        const response = await fetch("/api/template/updateDoc", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ id: currentFileId, sfdt }),
            credentials: "include",
        });
        if (response.ok) {
            console.log("Document saved successfully!");
        }
        else {
            console.error("Failed to save document");
        }
    };
    const { data: settings } = useSettingsData();
    const { data: components } = useComponentsData();
    const parameters = settings?.parameters.map((p) => p.name) || [];
    const options = components?.filter((c) => c.isOption).map((c) => c.name) || [];
    const productVariables = useProductVariables(parameters, options);
    const variableSets = [
        { name: "Customer", variables: customerVariables },
        { name: "Project", variables: projectVariables },
        { name: "Supplier", variables: supplierVariables },
        { name: "Organization", variables: organizationVariables },
        { name: "Product", variables: productVariables },
        { name: "Component", variables: useComponentVariables(parameters) },
        { name: "Template", variables: templateVariables },
        { name: "Order", variables: orderVariables },
    ];
    const onCloseEditor = () => {
        setShowEditor(false);
        setCurrentFileId(null);
    };
    const handleVariablesToggle = () => {
        setVariablesOpen((prev) => !prev);
    };
    const insertVariable = (displayName) => {
        if (container && container.documentEditor) {
            const fieldCode = `MERGEFIELD ${displayName} \\* MERGEFORMAT`;
            const fieldResult = `«${displayName}»`;
            container.documentEditor.editor.insertField(fieldCode, fieldResult);
        }
    };
    const onBeforeSend = (args) => {
        args.ajaxSettings.beforeSend = function (event) {
            event.httpRequest.withCredentials = true;
        };
    };
    const onBeforeGetImage = (args) => {
        console.log(args);
        args.imageUrl = `${ajaxSettings.getImageUrl}imageId=${args.fileDetails[0].id}`;
    };
    let saveItem = {
        prefixIcon: "e-save icon",
        tooltipText: "Save the Document",
        text: "Save",
        id: "save",
    };
    let variablesItem = {
        prefixIcon: "e-icon e-edit",
        tooltipText: "Insert Variables",
        text: "Variables",
        id: "insertVariables",
        // cssClass: variablesOpen ? "variables-button-active" : "",
    };
    const toolbarItems = [
        saveItem,
        "Undo",
        "Redo",
        "Image",
        "Table",
        "Hyperlink",
        "Header",
        "Footer",
        "PageSetup",
        "PageNumber",
        "Break",
        "Find",
        "FormFields",
        variablesItem,
    ];
    const onToolbarClick = (args) => {
        switch (args.item.id) {
            case "save":
                onSave();
                break;
            case "insertVariables":
                handleVariablesToggle();
                break;
            default:
                break;
        }
    };
    console.log(productVariables);
    console.log(projects);
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const onFileManagerToolbarClick = (args) => {
        setOpenDialog(true);
        console.log(args);
        let fileName = fileObj.itemData[0] || "";
        setCurrentFile(fileName);
    };
    function Generate() {
        return (React.createElement(Button, { onClick: onFileManagerToolbarClick }, "Generate from Template"));
    }
    function onFileDropped(args) {
        fileObj.refreshFiles();
    }
    return (React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
        React.createElement("div", { style: {
                flex: 1,
                width: "100%",
                display: showEditor ? "none" : "block",
            } },
            React.createElement(FileManagerComponent, { id: "filemanager", ref: (s) => (fileObj = s), path: path || "/", ajaxSettings: ajaxSettings, allowDragAndDrop: true, view: view || "LargeIcons", fileDropped: onFileDropped, beforeImageLoad: onBeforeGetImage, navigationPaneSettings: { visible: showNavigationPane }, contextMenuSettings: { visible: true }, fileOpen: onFileOpen, rootAliasName: "All Files", showThumbnail: true, style: { height: "100%" }, beforeSend: onBeforeSend },
                React.createElement(Inject, { services: [DetailsView, FMToolbar, NavigationPane] }),
                React.createElement(ToolbarItemsDirective, null,
                    React.createElement(ToolbarItemDirective, { name: "NewFolder" }),
                    React.createElement(ToolbarItemDirective, { name: "Refresh" }),
                    React.createElement(ToolbarItemDirective, { name: "View" }),
                    React.createElement(ToolbarItemDirective, { name: "Details" }),
                    React.createElement(ToolbarItemDirective, { name: "Upload" }),
                    React.createElement(ToolbarItemDirective, { name: "Delete" }),
                    React.createElement(ToolbarItemDirective, { name: "GenerateTemplate", template: Generate })))),
        React.createElement("div", { style: {
                flex: 1,
                position: "relative",
                display: showEditor ? "block" : "none",
            } },
            React.createElement(Button, { startIcon: React.createElement(KeyboardBackspace, null), onClick: onCloseEditor }, "Back"),
            React.createElement(DocumentEditorContainerComponent, { id: "container", height: "calc(100vh - 110px)", width: "100%", ref: (scope) => {
                    container = scope;
                }, enableToolbar: true, toolbarItems: toolbarItems, showPropertiesPane: false, toolbarClick: onToolbarClick, serviceUrl: "/api/template/" })),
        React.createElement(VariablesDialog, { variablesOpen: variablesOpen, handleVariablesToggle: handleVariablesToggle, variableSets: variableSets, insertVariable: insertVariable }),
        React.createElement(GenerateFromTemplate, { open: openDialog, onClose: () => setOpenDialog(false), destinationId: currentFile?.id, onSuccess: () => {
                fileObj.refreshFiles();
                setOpenDialog(false);
            }, initialSelection: {} })));
}
