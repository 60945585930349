import { Add } from "@mui/icons-material";
import { Button, Fade, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridToolbarQuickFilter, } from "@mui/x-data-grid";
import React from "react";
import CreateFromCsv from "../inventory/components/CreateFromCsv";
import CreateOrderButton from "@features/order/components/CreateOrderButton";
export var Keyword;
(function (Keyword) {
    Keyword["Supplier"] = "Supplier";
    Keyword["Customer"] = "Customer";
    Keyword["Person"] = "Person";
    Keyword["Component"] = "Component";
    Keyword["Template"] = "Template";
    Keyword["Financial"] = "Financial";
})(Keyword || (Keyword = {}));
export default function CustomDataGrid({ rows, columns, keyword, onRowClick, onAddClick, ...rest }) {
    return (React.createElement(Fade, { in: true, timeout: { enter: 500, exit: 0 }, unmountOnExit: true },
        React.createElement("div", null,
            React.createElement(DataGrid, { rows: rows, columns: columns, 
                // autoHeight
                getRowId: (row) => row._id || "", onRowClick: onRowClick, hideFooter: true, ...rest, slots: rest.slots || {
                    toolbar: () => (React.createElement(Box, { sx: {
                            display: "flex",
                            justifyContent: "space-between",
                            p: 1,
                            pb: 0,
                        } },
                        React.createElement(Box, { sx: { gap: 1 } },
                            React.createElement(GridToolbarQuickFilter, { variant: "outlined", size: "small" })),
                        React.createElement(Box, { sx: { gap: 1 } },
                            keyword === Keyword.Component && React.createElement(CreateOrderButton, null),
                            keyword === Keyword.Component && React.createElement(CreateFromCsv, null),
                            React.createElement(Button, { variant: "contained", sx: { textTransform: "none", ml: 1 }, startIcon: React.createElement(Add, null), onClick: onAddClick },
                                React.createElement(Typography, null, keyword))))),
                    noRowsOverlay: () => (React.createElement(Box, { sx: { pt: 4.5 } },
                        React.createElement(Typography, { color: "textSecondary", textAlign: "center" }, `No ${keyword}s to show.`))),
                } }))));
}
