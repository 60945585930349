import { SupplierFields } from "@mytypes/supplierTypes";
import React from "react";
import { showSupplierDialog } from "../Dialogs/DialogManager";
import CustomDataGrid, { Keyword } from "../core/CustomDataGrid";
import { useSuppliersData } from "./hooks/useSuppliersData";
const Suppliers = () => {
    const { isLoading, data } = useSuppliersData();
    const columns = Object.entries(SupplierFields).map(([field, label]) => ({
        field,
        headerName: label,
        width: 200,
    }));
    const onRowClick = async ({ row }) => {
        await showSupplierDialog(row);
    };
    const onAddClick = async () => {
        const result = await showSupplierDialog();
    };
    return (React.createElement(CustomDataGrid, { rows: data || [], columns: columns, 
        // fields={SupplierFields}
        loading: isLoading, keyword: Keyword.Supplier, onRowClick: onRowClick, onAddClick: onAddClick }));
};
export default Suppliers;
