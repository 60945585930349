import { checkout } from "@api/paymentApi";
import { Box, Button, Fade, Stack, Tooltip, Typography, useTheme, } from "@mui/material";
import useAuthStore from "@stores/authStore";
import React from "react";
import { useNavigate } from "react-router-dom";
const Welcome = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { user, logout } = useAuthStore();
    const handleLoginClick = () => {
        navigate("/login");
    };
    const handleLogoutClick = () => {
        logout();
    };
    const handleRegisterClick = () => {
        navigate("/register");
    };
    const handleBuyClick = async () => {
        if (!user?.id) {
            navigate("/login");
        }
        else {
            try {
                const data = await checkout();
                window.location.href = data;
            }
            catch (error) {
                console.error(error);
            }
        }
    };
    console.log(user);
    return (React.createElement(Box, { sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        } },
        React.createElement(Stack, { direction: "column", spacing: 1 },
            React.createElement(Fade, { key: "title", in: true },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "h6", sx: { display: "flex", alignItems: "end" } },
                        "[MANUFACTURE]",
                        React.createElement(Typography, { sx: {
                                ml: -0.54,
                                mb: 0.3,
                                color: "primary.main",
                                fontSize: 13,
                            } }, ".app")))),
            user ? (React.createElement(React.Fragment, null,
                React.createElement(Fade, { key: "profile", in: true, timeout: { enter: 500 } },
                    React.createElement(Typography, { align: "center" }, user.email)),
                React.createElement(Fade, { key: "enterApp", in: true, timeout: { enter: 500 } },
                    React.createElement(Tooltip, { title: !user.role || user.status !== "active"
                            ? "Click below to buy access."
                            : null, arrow: true },
                        React.createElement("div", { style: { width: "100%" } },
                            React.createElement(Button, { variant: "outlined", disabled: !user.role || user.status !== "active", onClick: () => navigate(`/${user.role}`), sx: { width: "100%" } }, "Enter App")))),
                React.createElement(Fade, { key: "logout", in: true, timeout: { enter: 1000 } },
                    React.createElement(Button, { variant: "outlined", onClick: handleLogoutClick }, "Logout")))) : (React.createElement(React.Fragment, null,
                React.createElement(Fade, { key: "login", in: true, timeout: { enter: 500 } },
                    React.createElement(Button, { variant: "outlined", onClick: handleLoginClick }, "Login")),
                React.createElement(Fade, { key: "register", in: true, timeout: { enter: 1000 } },
                    React.createElement(Button, { variant: "outlined", onClick: handleRegisterClick }, "Register")))),
            user?.status !== "active" ? (React.createElement(Fade, { key: "buy", in: true, timeout: { enter: 1500 } },
                React.createElement(Button, { variant: "outlined", onClick: handleBuyClick }, "Buy"))) : null)));
};
export default Welcome;
