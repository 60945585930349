import React from "react";
import Zone from "../components/Zone";
import Option from "../components/Option";
import ParameterSelect from "../components/Parameter";
import { Image } from "@mui/icons-material";
const makeColumns = (products, components, editable, projectComponents = []) => {
    console.log(products, components, projectComponents);
    let parameters = new Set();
    let subcomponentSelectParams = new Set();
    let options = new Set();
    products.forEach((product) => {
        product.parameters.forEach((param) => {
            if (param.id.includes("_")) {
                subcomponentSelectParams.add(`${param.id}|${param.name}`);
            }
            else {
                parameters.add(param.name);
            }
        });
    });
    products.forEach((product) => {
        if (product.options) {
            product.options.forEach((option) => {
                options.add(option.name);
            });
        }
        product.subcomponents
            ?.filter((c) => c.isOption)
            .forEach((option) => {
            options.add(option.name);
        });
    });
    products.forEach((product) => {
        product.allParameters
            ?.filter((p) => p.options?.length && p.id.includes("_"))
            .forEach((param) => {
            let subcomp = components.find((c) => c.id === param.id);
            console.log(param, subcomp);
            subcomponentSelectParams.add(`${param.id}|${param.name}`);
        });
    });
    let columns = [
        {
            field: "id",
            headerName: "ID",
            width: 90,
        },
        {
            field: "name",
            headerName: "Product Name",
            width: 200,
        },
        {
            field: "image",
            headerName: "Image",
            renderHeader: () => (React.createElement("div", { style: { width: 25 } },
                React.createElement(Image, null))),
            width: 25,
            renderCell: (params) => {
                if (!params.row.image)
                    return null;
                return (React.createElement("img", { src: params.row.image, alt: params.row.name, style: { height: "100%", aspectRatio: "1/1" } }));
            },
        },
        {
            field: "price",
            headerName: "Price",
            width: 150,
            type: "number",
            renderCell: (params) => {
                const price = parseFloat(params.row.price);
                if (price === undefined || price === null || isNaN(price))
                    return "";
                let formattedPrice = formatPrice(price);
                return React.createElement("div", null, formattedPrice);
            },
        },
        {
            field: "zone",
            headerName: "Zone",
            width: 150,
            renderCell: (params) => {
                if (editable)
                    return React.createElement(Zone, { productId: params.row.id, zone: params.row.zone });
                return params.row.zone;
            },
        },
    ];
    options.forEach((option) => {
        columns.push({
            field: option,
            headerName: option,
            width: 150,
            renderCell: (params) => {
                if (params.row.incomplete) {
                    let opt = params.row.options?.find((o) => o.name === option);
                    if (!opt)
                        return null;
                    return opt.choiceName;
                }
                let opt = params.row.subcomponents.find((s) => s.name === option);
                if (!opt)
                    return null;
                if (editable)
                    return React.createElement(Option, { productId: params.row.id, option: opt });
                let choice = params.row.subcomponents.find((s) => s.id === opt.selection);
                if (!choice)
                    return null;
                return choice.name;
            },
        });
    });
    parameters.forEach((param) => {
        columns.push({
            field: param,
            headerName: param,
            width: 150,
            renderCell: (params) => {
                if (params.row.incomplete) {
                    let p = params.row.parameters.find((p) => p.name === param && !p.id.includes("_"));
                    if (!p)
                        return null;
                    return formatParameter(p.calculatedValue);
                }
                let p = params.row.allParameters.find((p) => p.name === param && !p.id.includes("_"));
                if (!p)
                    return null;
                if (editable)
                    return React.createElement(ParameterSelect, { productId: params.row.id, parameter: p });
                return formatParameter(p.calculatedValue);
            },
        });
    });
    subcomponentSelectParams.forEach((param) => {
        columns.push({
            field: param,
            headerName: createParamHeader(components, param, projectComponents),
            width: 150,
            renderCell: (params) => {
                if (params.row.incomplete) {
                    let p = params.row.parameters.find((p) => p.name === param.split("|")[1] && p.id === param.split("|")[0]);
                    if (!p)
                        return null;
                    return formatParameter(p.calculatedValue);
                }
                let p = params.row.allParameters.find((p) => p.name === param.split("|")[1] && p.id === param.split("|")[0]);
                if (!p)
                    return null;
                if (editable)
                    return React.createElement(ParameterSelect, { productId: params.row.id, parameter: p });
                return formatParameter(p.calculatedValue);
            },
        });
    });
    console.log(columns);
    return columns;
};
const formatPrice = (price) => {
    if (price === undefined || price === null)
        return "";
    return price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
};
export const formatParameter = (paramValue) => {
    let value = `${paramValue}`;
    if (value.includes(".")) {
        return parseFloat(value).toFixed(3);
    }
    else {
        return value;
    }
};
const createParamHeader = (components, param, projectComponents) => {
    console.log(components, param, projectComponents);
    let comp = components.find((c) => c.id === param.split("|")[0]);
    if (!comp) {
        comp = projectComponents.find((c) => c.id === param.split("|")[0]);
    }
    return comp?.name + " " + param.split("|")[1];
};
export default makeColumns;
