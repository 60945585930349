import { useTheme } from "@mui/material";
import React from "react";
import { ReactFlow, Background, useReactFlow, ReactFlowProvider, } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useComponentCreateStore, useTemporalStore, } from "./store/createComponentStore";
import { useShallow } from "zustand/react/shallow";
import { useComponentsData } from "../inventory/hooks/useComponentsData";
import { edgeTypes, nodeTypes } from "./store/helpers";
import CreationControls from "./CreationControls";
const selector = (state) => ({
    nodes: state.nodes,
    edges: state.edges,
    reset: state.reset,
    onNodesChange: state.onNodesChange,
    onEdgesChange: state.onEdgesChange,
    onConnectStart: state.onConnectStart,
    onConnectEnd: state.onConnectEnd,
    initializeComponentEdit: state.initializeComponentEdit,
    duplicateComponent: state.duplicateComponent,
    canSave: state.canSave,
    collapseOptions: state.collapseOptions,
    expandOptions: state.expandOptions,
    areOptionsCollapsed: state.areOptionsCollapsed,
    // setParametersOverride: state.setParametersOverride,
});
export const ParameterOverrideContext = React.createContext(null);
const ComponentCreation = ({ 
// handleBack,
editComponent, }) => {
    const { nodes, edges, reset, onNodesChange, onEdgesChange, onConnectStart, onConnectEnd, initializeComponentEdit, duplicateComponent,
    // setParametersOverride,
    // collapseOptions,
    // expandOptions,
    // areOptionsCollapsed,
     } = useComponentCreateStore(useShallow(selector));
    const nodeTypesMemo = React.useMemo(() => nodeTypes, []);
    const edgeTypesMemo = React.useMemo(() => edgeTypes, []);
    const { data: components } = useComponentsData();
    const [rfInstance, setRfInstance] = React.useState(null);
    const { screenToFlowPosition, zoomOut } = useReactFlow();
    const clear = useTemporalStore((state) => state.clear);
    const onInit = (reactFlowInstance) => {
        console.log("init");
        reset();
        // clear();
        setRfInstance(reactFlowInstance);
        if (components && editComponent) {
            if (editComponent.duplicate) {
                duplicateComponent(editComponent, components?.filter((c) => editComponent.flowData.includes(c._id)));
            }
            else if (editComponent) {
                initializeComponentEdit(editComponent, components?.filter((c) => editComponent.flowData.includes(c._id)));
            }
        }
        clear();
        zoomOut({ duration: 500 });
    };
    React.useEffect(() => {
        if (rfInstance) {
            rfInstance.fitView();
            rfInstance.zoomOut({ duration: 500 });
            // zoomOut({ duration: 500 });
        }
    }, [editComponent, rfInstance]);
    // const onBackClick = () => {
    //   handleBack();
    //   reset();
    //   clear();
    // };
    // const fitScreen = () => {
    //   rfInstance.fitView({
    //     duration: 500,
    //   });
    // };
    // const handleCollapseAndExpand = () => {
    //   areOptionsCollapsed() ? expandOptions() : collapseOptions();
    // };
    const theme = useTheme().palette;
    // React.useEffect(() => {
    //   setParametersOverride();
    // }, [nodes]);
    console.log("componentCreation", nodes);
    return (
    // <ParameterOverrideContext.Provider value={parametersOverride}>
    React.createElement(ReactFlow, { style: {
            // padding: "12px",
            backgroundColor: theme.background.default,
        }, nodes: nodes, edges: edges, onNodesChange: onNodesChange, onEdgesChange: onEdgesChange, snapToGrid: false, onConnectStart: onConnectStart, onConnectEnd: (e) => onConnectEnd(e, screenToFlowPosition), onInit: onInit, fitView: true, proOptions: { hideAttribution: true }, maxZoom: 1, nodeTypes: nodeTypesMemo, edgeTypes: edgeTypesMemo, nodeOrigin: [0.5, 0] },
        React.createElement(Background, { style: {
                boxShadow: "inset 0px 8px 8px -6px rgba(0,0,0,0.75)",
            } }))
    // {/* </ParameterOverrideContext.Provider> */}
    );
};
function FlowWithProvider(props) {
    console.log("flowProvider");
    console.log(props);
    // const { clear } = useTemporalStore((state) => state);
    // React.useEffect(() => {
    //   return () => {
    //     clear();
    //   };
    // }, []);
    return (React.createElement(ReactFlowProvider, null,
        React.createElement(CreationControls, { onBackClick: props.handleBack }),
        React.createElement(ComponentCreation, { ...props })));
}
export default FlowWithProvider;
