import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Add, ControlPointDuplicate, Delete, DeleteForever, Edit, FilterList, } from "@mui/icons-material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import CreateOrderButton from "@features/order/components/CreateOrderButton";
import CustomButton from "src/components/CustomButton";
import CustomDataGrid, { Keyword } from "@features/core/CustomDataGrid";
import CreateFromCsv from "./CreateFromCsv";
import CategoryFilter from "./CategoriesFilter";
const InventoryDataGrid = ({ rows, columns, keyword, onAddClick, onEditClick, onDuplicateClick, onDeleteClick, canEdit, canDuplicate, canDelete, ...rest }) => {
    const [categoriesFilterOpen, setCategoriesFilterOpen] = React.useState(false);
    const handleCategoriesFilterOpen = () => setCategoriesFilterOpen((prev) => !prev);
    const [filteredCategories, setFilteredCategories] = React.useState([]);
    // const cellModesModel = React.useMemo(
    //   () => ({ [rows[0]._id]: { name: { mode: GridCellModes.Edit } } }),
    //   [rows]
    // );
    return (React.createElement(CustomDataGrid, { rows: rows, 
        // cellModesModel={cellModesModel}
        columns: columns, keyword: keyword, onAddClick: onAddClick, filterModel: {
            items: filteredCategories.map((category) => ({
                field: "categories",
                operator: "contains",
                value: category,
            })),
        }, ...rest, slots: {
            toolbar: () => (React.createElement(React.Fragment, null,
                React.createElement(Box, { sx: {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 1,
                        pb: 0,
                    } },
                    React.createElement(Box, { sx: { display: "flex", alignItems: "center", gap: 1 } },
                        React.createElement(CustomButton, { variant: categoriesFilterOpen ? "contained" : "outlined", onClick: handleCategoriesFilterOpen, tooltip: "Filter by categories", icon: React.createElement(FilterList, null) }),
                        React.createElement(GridToolbarQuickFilter, { variant: "outlined", size: "small" }),
                        React.createElement(Button, { variant: "contained", sx: { textTransform: "none" }, startIcon: React.createElement(Add, null), onClick: onAddClick },
                            React.createElement(Typography, null, keyword)),
                        React.createElement(CustomButton, { variant: "contained", disabled: !canDuplicate, onClick: onDuplicateClick, tooltip: "Duplicate", icon: React.createElement(ControlPointDuplicate, null) }, "Duplicate"),
                        React.createElement(CustomButton, { variant: "contained", disabled: !canEdit, onClick: onEditClick, tooltip: "Edit (or you can double click a row)", icon: React.createElement(Edit, null) }, "Edit"),
                        React.createElement(CustomButton, { variant: "contained", disabled: !canDelete, onClick: onDeleteClick, icon: React.createElement(Delete, null), tooltip: "Delete", confirmIcon: React.createElement(DeleteForever, null) }, "Delete")),
                    React.createElement(Box, { sx: { display: "flex", gap: 1 } },
                        keyword === Keyword.Component && React.createElement(CreateOrderButton, null),
                        keyword === Keyword.Component && React.createElement(CreateFromCsv, null))),
                React.createElement(Box, { sx: {
                        p: 1,
                        display: categoriesFilterOpen ? "block" : "none",
                        transition: "display 0.3s",
                    } },
                    React.createElement(CategoryFilter, { selectedCategories: filteredCategories, setSelectedCategories: setFilteredCategories })))),
        } }));
};
export default InventoryDataGrid;
