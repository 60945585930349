import api from "./api";
export const getOrders = async () => {
    const response = await api.get(`/api/order`);
    return response.data;
};
export const updateOrder = async (order) => {
    const response = await api.patch(`/api/order/${order._id}`, order);
    return response.data;
};
export const deleteOrder = async (orderId) => {
    const response = await api.delete(`/api/order/${orderId}`);
    if (response.status !== 204) {
        throw new Error("Failed to delete order.");
    }
    return orderId;
};
export const createOrder = async (order) => {
    const response = await api.post(`/api/order`, order);
    return response.data;
};
