// useOrderUpdate.ts
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateOrder } from "@api/orderApi";
import useNotificationStore from "@stores/notificationStore";
export function useOrderUpdate() {
    const queryClient = useQueryClient();
    const { addNotification } = useNotificationStore();
    return useMutation({
        mutationFn: updateOrder,
        onSuccess: (updatedOrder) => {
            // Update the cache
            queryClient.setQueryData(["orders"], (oldData = []) => {
                if (!oldData)
                    return [updatedOrder];
                return oldData.map((order) => {
                    if (order._id === updatedOrder._id) {
                        if (order.received === false && updatedOrder.received === true) {
                            queryClient.invalidateQueries({ queryKey: ["components"] });
                            addNotification("Updating inventory.", "success");
                        }
                        return updatedOrder;
                    }
                    else {
                        return order;
                    }
                });
            });
            addNotification("Order updated successfully.", "success");
        },
        onError: () => {
            addNotification("Failed to update order.", "error");
        },
    });
}
