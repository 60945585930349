import * as React from "react";
import Chip from "@mui/material/Chip";
import { Box, Collapse } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import { getContrastYIQ } from "src/utils/colorUtils";
import { useSettingsData } from "@features/userSettings/hooks/useSettingsData";
export const Categories = ({ categories }) => {
    if (!categories.length) {
        return null;
    }
    const { data: settings } = useSettingsData();
    return (React.createElement("div", null,
        React.createElement(TransitionGroup, null,
            React.createElement(Box, { sx: {
                    p: 0.5,
                    m: 0,
                    mt: 1,
                    display: "flex",
                    gap: 0.5,
                    justifyContent: "center",
                    flexWrap: "wrap",
                } }, categories.map((category, i) => {
                return (React.createElement(Collapse, { key: i, in: true },
                    React.createElement(Chip, { size: "small", label: category, sx: {
                            borderRadius: 1,
                            backgroundColor: settings?.categories.find((cat) => cat.name === category)?.color,
                            color: getContrastYIQ(settings?.categories.find((cat) => cat.name === category)
                                ?.color || "grey"),
                        } })));
            })))));
};
