import useAuthStore from "../../../stores/authStore";
import { useQuery } from "@tanstack/react-query";
import { getCustomers } from "../../../api/customerApi";
export const useCustomersData = () => {
    const { user } = useAuthStore();
    if (!user) {
        throw new Error("No user!");
    }
    return useQuery({
        queryKey: ["customers"],
        queryFn: getCustomers,
        staleTime: Infinity,
    });
};
