import axios from "axios";
const api = axios.create({
    withCredentials: true, // Ensure cookies are sent with each request
});
// Add a response interceptor
api.interceptors.response.use((response) => response, // Pass through any successful response
async (error) => {
    const originalRequest = error.config; // Keep a reference to the original request
    console.log("Error response:", error.response?.status, error.response?.data);
    if (!error.response) {
        console.error("Network or CORS error:", error);
        return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Mark this request as a retry to prevent infinite loops
        // Prevent retry loop if no refresh token is provided
        if (error.response.data.message === "Refresh error.") {
            console.error("No refresh token provided. Logging out.");
            // Optionally, redirect to login or take other actions
            // window.location.href = '/login';
            return Promise.reject(error);
        }
        try {
            // Attempt to refresh the token
            const res = await api.post("/api/auth/refresh-token");
            // Set the new token in the default headers for future requests
            api.defaults.headers.common["Authorization"] =
                "Bearer " + res.data.authToken;
            // Retry the original request with the new token
            originalRequest.headers["Authorization"] =
                "Bearer " + res.data.authToken;
            return api(originalRequest);
        }
        catch (refreshError) {
            // Handle token refresh error
            console.error("Failed to refresh token:", refreshError);
            // If the refresh token request also returns 401, clear any stored tokens and redirect to login
            if (refreshError.response?.status === 401) {
                console.error("Refresh token also expired or invalid. Logging out.");
                // Optionally, redirect to login or take other actions
                // window.location.href = "/login";
            }
        }
    }
});
export default api;
